/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './headline-three.scss';

class HeadlineThree extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { text, classes, id } = this.props;

        return (
            <h3 id={id} className={'bt-headline-three ' + classes}>
                {text}
            </h3>
        );
    }
}

HeadlineThree.propTypes = {
    text: PropTypes.string,
    classes: PropTypes.string
};

HeadlineThree.defaultProps = {
    text: ''
};

export default HeadlineThree;
