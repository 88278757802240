/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import './fellow-profile.scss';
import HeadlineThree from '../elements/HeadlineThree';
import CopyTextM from '../elements/CopyTextM';
import IconInfo40 from '../../assets/icons/IconInfo40';
import IconHospital40 from '../../assets/icons/IconHospital40';
import IconCV40 from '../../assets/icons/IconCV40';
import IconContact40 from '../../assets/icons/IconContact40';
import Button from '../elements/Button';
import IconDownloads20 from '../../assets/icons/IconDownloads20';
import IconLinkedIn20 from '../../assets/icons/IconLinkedIn20';
import IconPhone20 from '../../assets/icons/IconPhone20';
import IconEmail20 from '../../assets/icons/IconEmail20';
import IconLink20 from '../../assets/icons/IconLink20';
import IconProjects40 from '../../assets/icons/IconProjects40';
import IconTwitter20 from '../../assets/icons/IconTwitter20';

class FellowProfile extends React.Component {
  render() {
    const { status, employers, links, cv, emailArray, linkedinArray, twitterArray, phoneArray, otherArray } =
      this.props;

    return (
      <div className="bt-fellow-profile">
        <div className="bt-fellow-profile__item">
          <div className="bt-fellow-profile__head">
            <div className="bt-fellow-profile__icon">{IconInfo40}</div>
            <div className="bt-fellow-profile__headline">
              <HeadlineThree text="Status" />
            </div>
          </div>

          <div className="bt-fellow-profile__content">
            <CopyTextM text={status} />
          </div>
        </div>
        <div className="bt-fellow-profile__item">
          <div className="bt-fellow-profile__head">
            <div className="bt-fellow-profile__icon">{IconHospital40}</div>
            <div className="bt-fellow-profile__headline">
              <HeadlineThree text="Hospital/Company" />
            </div>
          </div>
          {employers &&
            employers.map((employer, index) => (
              <div key={index} className="bt-fellow-profile__content">
                <p>- {employer.name}</p>
                {employer.email && (
                  <Button path={`mailto:${employer.email}`} label={employer.email} iconLeft={IconEmail20} link />
                )}
              </div>
            ))}
        </div>
        {cv && (
          <div className="bt-fellow-profile__item">
            <div className="bt-fellow-profile__head">
              <div className="bt-fellow-profile__icon">{IconCV40}</div>
              <div className="bt-fellow-profile__headline">
                <HeadlineThree text="Curiculum Vitae" />
              </div>
            </div>
            <div className="bt-fellow-profile__content">
              <div>
                <Button target="_blank" path={cv.url} label={cv.title} iconLeft={IconDownloads20} link />
              </div>
            </div>
          </div>
        )}

        {links && (
          <div className="bt-fellow-profile__item">
            <div className="bt-fellow-profile__head">
              <div className="bt-fellow-profile__icon">{IconProjects40}</div>
              <div className="bt-fellow-profile__headline">
                <HeadlineThree text="Research & Projects" />
              </div>
            </div>

            <div className="bt-fellow-profile__content">
              {links.length > 0 && (
                <div className="bt-profile-links__section-content-links">
                  {links.map((link, i) => {
                    return (
                      <div key={i} className="bt-profile-links__section-content-item">
                        <div key={i}>
                          <Button
                            path={link.url}
                            label={link.url}
                            iconLeft={IconLink20}
                            onClick={() => window.open(link.url)}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        )}

        <div className="bt-fellow-profile__item">
          <div className="bt-fellow-profile__head">
            <div className="bt-fellow-profile__icon">{IconContact40}</div>
            <div className="bt-fellow-profile__headline">
              <HeadlineThree text="Contact" />
            </div>
          </div>

          <div className="bt-fellow-profile__content">
            {emailArray.length > 0 &&
              emailArray.map((item, index) => (
                <div key={index}>
                  <Button path={`mailto:${emailArray[index]}`} label={emailArray[index]} iconLeft={IconEmail20} link />
                </div>
              ))}
            {linkedinArray.length > 0 &&
              linkedinArray.map((item, index) => (
                <div key={index}>
                  <Button path={linkedinArray[index]} label={linkedinArray[index]} iconLeft={IconLinkedIn20} link />
                </div>
              ))}
            {twitterArray.length > 0 &&
              twitterArray.map((item, index) => (
                <div key={index}>
                  <Button path={twitterArray[index]} label={twitterArray[index]} iconLeft={IconTwitter20} link />
                </div>
              ))}
            {phoneArray.length > 0 &&
              phoneArray.map((item, index) => (
                <div key={index}>
                  <Button path={`tel:${phoneArray[index]}`} label={phoneArray[index]} iconLeft={IconPhone20} link />
                </div>
              ))}
            {otherArray.length > 0 &&
              otherArray.map((item, index) => (
                <div key={index}>
                  <Button path={otherArray[index]} label={otherArray[index]} iconLeft={IconLink20} link />
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

FellowProfile.propTypes = {
  status: PropTypes.string,
  employers: PropTypes.array,
  cv: PropTypes.array,
  links: PropTypes.array,
  emailArray: PropTypes.array,
  linkedinArray: PropTypes.array,
  twitterArray: PropTypes.array,
  phoneArray: PropTypes.array,
  otherArray: PropTypes.array,
};

FellowProfile.defaultProps = {
  emailArray: [],
  linkedinArray: [],
  twitterArray: [],
  phoneArray: [],
  otherArray: [],
};

export default FellowProfile;
