/* eslint-disable */
import React from 'react';

const IconImage14 = (
    <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="evenodd">
            <path d="M3.75846154 6.49815385c.8274359 0 1.49907692-.672 1.49907692-1.49907693 0-.826-.67164102-1.49907692-1.49907692-1.49907692s-1.49907692.67307692-1.49907692 1.49907692c0 .82707693.67164102 1.49907693 1.49907692 1.49907693zm0-2.45969231c.53020513 0 .96061538.43184615.96061538.96061538 0 .52984616-.43041025.96061539-.96061538.96061539s-.96061539-.43076923-.96061539-.96061539c0-.52876923.43041026-.96061538.96061539-.96061538z" />
            <path d="M-.02153846 14H13.9784615V0H-.02153846v14zm.56-13.44H13.4184615v7.18878386l-2.8903466-2.54675228c-.0556267-.04769333-.1269334-.0713607-.2012267-.06992632-.0742933.00286878-.14448.03514246-.1956267.08821474L7.38987487 8.10451158 6.06118154 6.82970105c-.10490667-.10076561-.27141333-.10614456-.38192-.01255087L.99206154 10.78h-.4536V.56zm0 12.88H13.4184615v-2.1H.53846154v2.1zm1.26-2.66l4.02495937-3.41966423 2.89525796 2.79264743c.10978525.107034.28701535.107034.3968006 0 .10978524-.1059492.10978524-.27698664 0-.38293585L7.7643341 8.46647439 10.3448494 5.74l3.0736121 2.71779595V10.78H1.79846154z" />
        </g>
    </svg>
);

export default IconImage14;
