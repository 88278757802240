/* eslint-disable */
import React from 'react';

const IconSearch16 = (
    <svg width="16" height="16" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="nonzero">
            <path d="M7.576 14.161c-3.919 0-7.096-3.17-7.096-7.08S3.657 0 7.576 0c3.92 0 7.096 3.17 7.096 7.08 0 3.911-3.177 7.081-7.096 7.081zm0-.833c3.458 0 6.261-2.797 6.261-6.247 0-3.45-2.803-6.248-6.26-6.248-3.459 0-6.262 2.797-6.262 6.248 0 3.45 2.803 6.247 6.261 6.247z" />
            <path d="M11.577 12.495l.59-.589 3.513 3.505-.59.589z" />
        </g>
    </svg>
);

export default IconSearch16;
