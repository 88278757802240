import React from 'react';
import EventDescription from '../EventDescription';
import { useAppSelector } from '../../app/hooks';

const LayerViewEventDescription: React.FC = () => {
  const { activeEvent } = useAppSelector(({ eventsState }) => eventsState);
  const eventData = activeEvent as EventData | undefined;

  return <EventDescription eventId={eventData?.id} copyText={eventData?.description} />;
};

export default LayerViewEventDescription;
