/* eslint-disable */
import { REDUX_ACTIONS } from '../global/constants';

function fellowsReducerLegacy(
  state = {
    fellowModels: [],
  },
  action
) {
  switch (action.type) {
    case REDUX_ACTIONS.UPDATE_FELLOW_MODELS:
      return Object.assign({}, state, {
        fellowModels: action.fellowModels,
      });

    default:
      return state;
  }
}

export default fellowsReducerLegacy;
