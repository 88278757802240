/* eslint-disable */
import React from 'react';

const IconSites17 = (
    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17">
        <path d="M15.8 0H1.2C.5 0 0 .5 0 1.2v10.6c0 .6.5 1.2 1.2 1.2H6v1.6c0 .7-.6 1.4-1.4 1.4-.3 0-.5.2-.5.5s.2.5.5.5h7.8c.3 0 .5-.2.5-.5s-.2-.5-.5-.5c-.8 0-1.4-.6-1.4-1.4V13h4.9c.6 0 1.2-.5 1.2-1.2V1.2C17 .5 16.5 0 15.8 0zm-5.4 16H6.6c.3-.4.5-.9.5-1.4V13H10v1.6c-.1.5.1.9.4 1.4zM7 9.9H1V1.2c0-.1.1-.2.2-.2h14.7s.1.1.1.2V10h-6c-.3 0-.5.2-.5.5s.2.5.5.5h6v.8c0 .1-.1.1-.1.1H1.2c-.1 0-.1-.1-.1-.1V11h6c.3 0 .5-.2.5-.5s-.3-.6-.6-.6zM13.3 8H3.7c-.3 0-.5-.3-.5-.5 0-.3.2-.5.5-.5h9.5c.3 0 .5.2.5.5.1.2-.2.5-.4.5zM9 10.4c0 .3-.2.5-.5.6h-.1c-.2 0-.4-.2-.4-.5 0-.1 0-.3.1-.4.1-.1.2-.2.4-.2s.5.2.5.5zM3.2 3.6c0-.3.2-.6.5-.6h9.5c.3 0 .5.2.5.5s-.2.5-.5.5H3.7c-.3.1-.5-.2-.5-.4zm0 1.9c0-.3.2-.5.5-.5h9.5c.3 0 .5.2.5.5s-.2.5-.5.5H3.7c-.3 0-.5-.2-.5-.5z" fill="currentColor"/>
    </svg>
);

export default IconSites17;
