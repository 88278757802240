/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import './fellow-teaser-mobile.scss';
import ImageThumb from '../elements/ImageThumb';
import HeadlineThree from '../elements/HeadlineThree';
import LabelL from '../elements/LabelL';
import CopyTextM from '../elements/CopyTextM';
import FellowTeaserNavigation from '../FellowTeaserNavigation';
import Button from '../elements/Button';
import IconEmail20 from '../../assets/icons/IconEmail20';
import IconLinkedIn20 from '../../assets/icons/IconLinkedIn20';
import IconTwitter20 from '../../assets/icons/IconTwitter20';
import IconPhone20 from '../../assets/icons/IconPhone20';
import IconLink20 from '../../assets/icons/IconLink20';

class FellowTeaserMobile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeMenu: 'employer',
    };
    this.handleClickNavigationItem = this.handleClickNavigationItem.bind(this);
    this.handleClickMore = this.handleClickMore.bind(this);
  }

  handleClickMore(e, path) {
    const { onClickMore } = this.props;
    onClickMore(e, path);
  }

  handleClickNavigationItem(menuItem) {
    this.setState({
      activeMenu: menuItem,
    });
  }

  render() {
    const { emailArray, linkedinArray, twitterArray, phoneArray, otherArray } = this.props;

    return (
      <div className={`${this.props.isActive ? 'bt-fellow-teaser' : 'bt-fellow-teaser-compact'}`}>
        <div className="bt-fellow-teaser__picture">
          <ImageThumb
            size={this.props.isActive ? 'L' : 'M'}
            profileInitials={this.props.initials}
            imageSrc={this.props.imageSrc}
          />
        </div>
        <div className="bt-fellow-teaser__info">
          <HeadlineThree text={this.props.fullName} />
          <div className="bt-fellow-teaser__sub-info">
            <LabelL text={this.props.profession} />
            <br />
            <LabelL text={this.props.location} />
          </div>
        </div>
        <FellowTeaserNavigation
          onClickNavigationItem={this.handleClickNavigationItem}
          onClickMore={this.handleClickMore}
          activeMenu={this.state.activeMenu}
          profilePath={this.props.profilePath}
          employers={this.props.employers}
          email={this.props.email}
          statusMessage={this.props.statusMessage}
          statusHidden={this.props.statusHidden}
          emailArray={emailArray}
          linkedinArray={linkedinArray}
          twitterArray={twitterArray}
          phoneArray={phoneArray}
          otherArray={otherArray}
        />
        <div className="bt-fellow-teaser__body">
          {this.state.activeMenu === 'employer' &&
            this.props.employers.map((employer, i) => (
              <div className="bt-fellow-teaser__contact-element" key={i}>
                <CopyTextM grey heavy text={employer.name} />
              </div>
            ))}
          {this.state.activeMenu === 'contact' &&
            emailArray.length > 0 &&
            emailArray.map((item, index) => (
              <div className="bt-fellow-teaser__contact-element" key={index}>
                <Button path={`mailto:${emailArray[index]}`} link label={emailArray[index]} iconLeft={IconEmail20} />
              </div>
            ))}
          {this.state.activeMenu === 'contact' &&
            linkedinArray.length > 0 &&
            linkedinArray.map((item, index) => (
              <div className="bt-fellow-teaser__contact-element" key={index}>
                <Button
                  path={linkedinArray[index]}
                  link
                  label={linkedinArray[index]}
                  iconLeft={IconLinkedIn20}
                  target="_blank"
                />
              </div>
            ))}
          {this.state.activeMenu === 'contact' &&
            twitterArray.length > 0 &&
            twitterArray.map((item, index) => (
              <div className="bt-fellow-teaser__contact-element" key={index}>
                <Button
                  path={twitterArray[index]}
                  link
                  label={twitterArray[index]}
                  iconLeft={IconTwitter20}
                  target="_blank"
                />
              </div>
            ))}
          {this.state.activeMenu === 'contact' &&
            phoneArray.length > 0 &&
            phoneArray.map((item, index) => (
              <div className="bt-fellow-teaser__contact-element" key={index}>
                <Button path={`tel:${phoneArray[index]}`} link label={phoneArray[index]} iconLeft={IconPhone20} />
              </div>
            ))}
          {this.state.activeMenu === 'contact' &&
            otherArray.length > 0 &&
            otherArray.map((item, index) => (
              <div className="bt-fellow-teaser__contact-element" key={index}>
                <Button path={otherArray[index]} link label={otherArray[index]} iconLeft={IconLink20} target="_blank" />
              </div>
            ))}

          {this.state.activeMenu === 'status' && <CopyTextM grey text={this.props.statusMessage} />}
        </div>
      </div>
    );
  }
}

FellowTeaserMobile.propTypes = {
  id: PropTypes.number,
  imageSrc: PropTypes.string,
  fullName: PropTypes.string,
  profession: PropTypes.string,
  location: PropTypes.string,
  employers: PropTypes.array,
  email: PropTypes.string,
  statusMessage: PropTypes.string,
  statusHidden: PropTypes.bool,
  profilePath: PropTypes.string,
  isActive: PropTypes.bool,
  initials: PropTypes.string,
  contact: PropTypes.object,
  emailArray: PropTypes.array,
  linkedinArray: PropTypes.array,
  twitterArray: PropTypes.array,
  phoneArray: PropTypes.array,
  otherArray: PropTypes.array,
  onClickMore: PropTypes.func,
};

FellowTeaserMobile.defaultProps = {
  id: '',
  imageSrc: '',
  fullName: '',
  profession: '',
  location: '',
  employers: [],
  email: '',
  emailArray: [],
  linkedinArray: [],
  twitterArray: [],
  phoneArray: [],
  otherArray: [],
  statusMessage: '',
  statusHidden: false,
  profilePath: '/fellows/',
  isActive: false,
  initials: '',
  contact: {},
  onClickMore: () => {},
};

export default FellowTeaserMobile;
