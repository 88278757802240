/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppUtil from '../../utils/AppUtil';
import ImageThumb from '../elements/ImageThumb';
import Button from '../elements/Button';
import HeadlineTwo from '../elements/HeadlineTwo';
import LabelL from '../elements/LabelL';
import IconCamera15 from '../../assets/icons/IconCamera15';
import './profile-header.scss';

class ProfileHeader extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isButtonOverlayVisible: false
        };

        this.handleOnClickOutsideOverlay = this.handleOnClickOutsideOverlay.bind(this);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleOnClickOutsideOverlay);
    }

    showButtonOverlay() {
        this.setState({ isButtonOverlayVisible: true });
        document.addEventListener('mousedown', this.handleOnClickOutsideOverlay);
    }

    hideButtonOverlay() {
        this.setState({ isButtonOverlayVisible: false });
        document.removeEventListener('mousedown', this.handleOnClickOutsideOverlay);
    }

    handleOnClickProfileImageButton() {
        this.showButtonOverlay();
    }

    handleOnClickOutsideOverlay(event) {
        if (this.refButtonOverlay && !this.refButtonOverlay.contains(event.target)) {
            this.hideButtonOverlay();
        }
    }

    handleOnClickEditProfileImage() {
        this.hideButtonOverlay();

        const { onClickChangeProfileImage } = this.props;
        onClickChangeProfileImage();
    }

    handleOnClickRemoveProfileImage() {
        this.hideButtonOverlay();

        const { onClickRemoveProfileImage } = this.props;
        onClickRemoveProfileImage();
    }

    render() {
        const { isButtonOverlayVisible } = this.state;
        const { firstName, lastName, profession, imgSrc } = this.props;

        let fullName = firstName;
        if (lastName) {
            fullName += ' ' + lastName.toUpperCase();
        }

        return (
            <div className="bt-profile-header">
                <div className="bt-profile-header-inner">
                    <div className="bt-profile-header__picture-wrapper">
                        <ImageThumb
                            size="L"
                            sizeMobile="M"
                            profileInitials={AppUtil.getInitials(firstName, lastName)}
                            imageSrc={imgSrc}
                        />
                        <div
                            className="bt-profile-header__button-upload"
                            onClick={() => this.handleOnClickProfileImageButton()}
                        >
                            {IconCamera15}
                        </div>
                    </div>
                    {isButtonOverlayVisible && (
                        <div
                            className="bt-profile-header__button-overlay"
                            ref={(node) => {
                                this.refButtonOverlay = node;
                            }}
                        >
                            <div>
                                <Button
                                    label="Change profile photo"
                                    onClick={() => this.handleOnClickEditProfileImage()}
                                />
                            </div>
                            <div className="bt-profile-header__button-overlay-remove">
                                <Button label="Remove" onClick={() => this.handleOnClickRemoveProfileImage()} />
                            </div>
                        </div>
                    )}
                    <div className="bt-profile-header__info">
                        <div className="bt-profile-header__info-name">
                            <HeadlineTwo text={fullName} />
                        </div>
                        <div className="bt-profile-header__info-profession">
                            <LabelL text={profession} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ProfileHeader.propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    profession: PropTypes.string,
    imgSrc: PropTypes.string,
    onClickChangeProfileImage: PropTypes.func,
    onClickRemoveProfileImage: PropTypes.func
};

ProfileHeader.defaultProps = {
    firstName: '',
    lastName: '',
    profession: '',
    imgSrc: '',
    onClickChangeProfileImage: () => {},
    onClickRemoveProfileImage: () => {}
};

export default ProfileHeader;
