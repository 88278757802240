/* eslint-disable @typescript-eslint/naming-convention */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from '../store';
import { apiAuthUrl, apiUrl } from '../api';

export interface AuthState {
  authStatus: 'idle' | 'loading' | 'autoLogin' | 'loggedIn' | 'failed';
  userToken?: string;
  userEmail?: string | undefined;
  userDisplayName?: string | undefined;
}

const initialState: AuthState = {
  authStatus: 'idle',
  userToken: undefined,
  userEmail: undefined,
  userDisplayName: undefined,
};

export const login = createAsyncThunk(
  'auth/login',
  async ({ identifier, password, rememberMe }: { identifier: string; password: string; rememberMe: boolean }) => {
    const { data: authData } = await axios.post(`${apiAuthUrl}/token`, {
      username: identifier,
      password,
    });
    const { data: profileResponseData } = await axios.get(`${apiUrl}/profile`, {
      headers: {
        Authorization: `Bearer ${authData.token}`,
      },
    });
    return { authData, userData: profileResponseData?.data, rememberMe };
  }
);

export const autoLogin = createAsyncThunk('auth/autoLogin', async () => {
  const userToken = localStorage.getItem('btUserToken') || undefined;

  if (userToken) {
    try {
      const { data: responseData } = await axios.get(`${apiUrl}/profile`, {
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      return {
        authData: {
          token: userToken,
        },
        userData: responseData?.data,
      };
    } catch {
      return null;
    }
  }

  return null;
});

const clearLocalStorage = () => {
  localStorage.removeItem('btUserToken');
  localStorage.removeItem('btUserEmail');
  localStorage.removeItem('btUserDisplayName');
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    logout: () => {
      clearLocalStorage();
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.authStatus = 'loading';
      })
      .addCase(login.fulfilled, (state, action) => {
        const { authData, rememberMe } = action.payload;
        const { token, user_display_name, user_email } = authData;

        if (rememberMe) {
          localStorage.setItem('btUserToken', token);
          localStorage.setItem('btUserEmail', user_email);
          localStorage.setItem('btUserDisplayName', user_display_name);
        }

        return {
          ...state,
          authStatus: 'loggedIn',
          userToken: token,
          userEmail: user_email,
          userDisplayName: user_display_name,
        } as AuthState;
      })
      .addCase(login.rejected, () => {
        return {
          ...initialState,
          authStatus: 'failed',
        };
      })
      .addCase(autoLogin.pending, (state) => {
        state.authStatus = 'autoLogin';
      })
      .addCase(autoLogin.fulfilled, (state, action) => {
        if (action.payload) {
          const userToken = localStorage.getItem('btUserToken') || undefined;
          const userEmail = localStorage.getItem('btUserEmail') || undefined;
          const userDisplayName = localStorage.getItem('btUserDisplayName') || undefined;

          return {
            ...state,
            authStatus: 'loggedIn',
            userToken,
            userEmail,
            userDisplayName,
          };
        }

        clearLocalStorage();
        return { ...initialState };
      })
      .addCase(autoLogin.rejected, () => {
        clearLocalStorage();
        return { ...initialState };
      });
  },
});

export const { logout } = authSlice.actions;
export const selectAuthState = (state: RootState): AuthState => state.authState;
export default authSlice.reducer;
