import React from 'react';
import { UiDiv, UiFlex } from '@webfox-sc/core';
import ProfileEntry from '../ProfileEntry';
import SearchInput from '../../molecules/SearchInput/SearchInput';
import AppUtil from '../../utils/AppUtil';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { logout } from '../../app/slices/authSlice';
import { useNavigate } from 'react-router-dom';
import { useOverlayContext } from '../overlay/OverlayProvider';

interface PageContentTopProps {
  userProfilePath: string;
  showAdvice: boolean;
}

const PageContentTop: React.FC<PageContentTopProps> = ({ userProfilePath, showAdvice }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { overlayNavigate } = useOverlayContext();
  const { pageId } = useAppSelector(({ appState }) => appState);
  const { profileFirstName, profileLastName, profilePictureSrc } = useAppSelector(({ profileState }) => profileState);

  const handleOnSubmitSearchInput = (searchValue) => {
    navigate('/search');
    AppUtil.submitSearch(searchValue);
  };

  const handleOnClickUserProfile = () => {
    overlayNavigate(userProfilePath);
  };

  const handleOnClickLogout = (e) => {
    e.preventDefault();
    dispatch(logout());
    navigate('/');
  };

  return (
    <UiDiv background={pageId === 'events' ? 'btLightBlueGrey' : 'transparent'} transition="background-color 0.3s ease">
      <UiFlex
        display={{ mobile: 'none', tablet: 'flex' }}
        justifyContent="space-between"
        maxWidth="1140px"
        minHeight="152px"
      >
        <UiDiv width="240px" padding="49px 0 0 28px">
          <SearchInput onSubmit={(searchValue) => handleOnSubmitSearchInput(searchValue)} />
        </UiDiv>
        <UiDiv padding="padding: 12px 0 0 0">
          <ProfileEntry
            firstName={profileFirstName}
            lastName={profileLastName}
            imgSrc={profilePictureSrc}
            profilePath={userProfilePath}
            showAdvice={showAdvice}
            adviceText="Please complete your personal data here."
            onClickProfile={handleOnClickUserProfile}
            onClickLogout={handleOnClickLogout}
          />
        </UiDiv>
      </UiFlex>
    </UiDiv>
  );
};

export default PageContentTop;
