/* eslint-disable */
import React from 'react';

const IconPlus20 = (
    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="evenodd">
            <path d="M9.6 9.6V5.016c0-.23.178-.416.399-.416a.41.41 0 0 1 .401.416V9.6h4.584c.23 0 .416.178.416.399a.409.409 0 0 1-.416.401H10.4v4.584c0 .23-.18.416-.401.416a.407.407 0 0 1-.399-.416V10.4H5.016a.41.41 0 0 1-.416-.401c0-.221.187-.399.416-.399H9.6z" />
            <path d="M2.928 2.93c3.9-3.9 10.243-3.9 14.142-.002 3.9 3.9 3.9 10.245 0 14.144-3.899 3.899-10.243 3.897-14.142-.002C-.97 13.171-.97 6.83 2.928 2.93zm13.637 13.636c3.621-3.621 3.62-9.512 0-13.132-3.62-3.62-9.51-3.62-13.131.001-3.62 3.62-3.62 9.51 0 13.13 3.62 3.62 9.51 3.621 13.13.001z" />
        </g>
    </svg>
);

export default IconPlus20;
