/* eslint-disable */
import React from 'react';

const IconNewsFeed25 = (
    <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25">
        <path d="M0 25h7.4V13.1H0V25zm6.2-10.6v9.4H1.3v-9.4h4.9zM0 11.9h7.4V0H0v11.9zM6.2 1.3v9.4H1.3V1.3h4.9zM17.6 0v11.9H25V0h-7.4zm1.2 10.6V1.3h4.9v9.4h-4.9zM17.6 25H25V13.1h-7.4V25zm6.1-10.6v9.4h-4.9v-9.4h4.9zM8.7 25h7.7V13.1H8.7V25zm6.4-10.6v9.4H9.9v-9.4h5.2zm-6.4-2.5h7.7V0H8.7v11.9zm6.4-10.6v9.4H9.9V1.3h5.2z" fill="currentColor" />
    </svg>
);

export default IconNewsFeed25;
