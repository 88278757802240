/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './checkbox.scss';

class Checkbox extends Component {
    constructor(props) {
        super(props);
        this.state = {
            checkedByProp: false,
            checked: false,
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.checked !== state.checkedByProp) {
            return {
                checkedByProp: props.checked,
                checked: props.checked,
            };
        }
        return null;
    }

    handleChangeCheckbox(e) {
        const { checked } = this.state;
        const { onChange } = this.props;
        const checkedNew = !checked;
        this.setState({
            checked: checkedNew,
        });
        onChange(checkedNew);
    }

    render() {
        const { checked } = this.state;
        const { label } = this.props;

        return (
            <div className={`bt-checkbox`}>
                <input
                    type='checkbox'
                    onChange={e => this.handleChangeCheckbox(e)}
                    checked={checked ? 'checked' : ''}
                    value={String(checked)}
                />
                <label>{label}</label>
            </div>
        );
    }
}

Checkbox.propTypes = {
    label: PropTypes.string,
    checked: PropTypes.bool,
    onChange: PropTypes.func,
};

Checkbox.defaultProps = {
    label: '',
    checked: false,
    onChange: () => false
};

export default Checkbox;
