import React, { useState } from 'react';
import LoginForm from '../../molecules/LoginForm/LoginForm';
import RegistrationTeaser from '../RegistrationTeaser';
import { login } from '../../app/slices/authSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';
import './page-view-login.scss';

const PageViewLogin: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { authStatus } = useAppSelector(({ authState }) => authState);
  const { baseDataTitleLeft, baseDataTitleRight, baseDataTextLeft, baseDataTextRight } = useAppSelector(
    ({ appState }) => appState
  );

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [loginFailed, setLoginFailed] = useState(false);

  const handleOnSubmit = async () => {
    const res = await dispatch(login({ identifier: email, password, rememberMe }));
    setLoginFailed(res?.type === 'auth/login/rejected');
  };

  const handleOnClickForgotPassword = (e) => {
    e.preventDefault();
    navigate('/password');
  };

  const handleOnClickRegister = () => {
    navigate('/register');
  };

  if (authStatus === 'loggedIn') {
    return null;
  }

  return (
    <div className="bt-page-view-login">
      <LoginForm
        rememberMe={rememberMe}
        loginFailed={loginFailed}
        onChangeEmail={(value) => setEmail(value)}
        onChangePassword={(value) => setPassword(value)}
        onChangeRememberMe={(checked) => setRememberMe(checked)}
        onSubmit={() => handleOnSubmit()}
        onClickForgotPassword={(e) => handleOnClickForgotPassword(e)}
      />
      <div className="bt-page-view-login__registration-teaser">
        <RegistrationTeaser
          registerTitle={baseDataTitleLeft}
          registerText={baseDataTextLeft}
          benefitsTitle={baseDataTitleRight}
          benefitsText={baseDataTextRight}
          onClickRegister={() => handleOnClickRegister()}
        />
      </div>
    </div>
  );
};

export default PageViewLogin;
