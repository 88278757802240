/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ProfileUtil from '../../utils/ProfileUtil';
import ProfileLinks from '../ProfileLinks';
import { uploadProfileCVFile } from '../../redux-actions/actionsProfile';

class LayerViewProfileLinks extends React.Component {
  // constructor(props) {
  //     super(props);
  // }

  handleSelectCvFile(file) {
    const { dispatch } = this.props;
    dispatch(uploadProfileCVFile(file));
  }

  handleDeleteCvFile() {
    ProfileUtil.deleteProfileCv();
  }

  handleAddLink(linkUrl) {
    ProfileUtil.addProfileLink(linkUrl);
  }

  handleDeleteLink(linkId) {
    ProfileUtil.deleteProfileLink(linkId);
  }

  render() {
    const { profileCv, isCVFileUploadError, profileLinks } = this.props;

    return (
      <ProfileLinks
        cv={profileCv}
        isCVFileUploadError={isCVFileUploadError}
        links={profileLinks}
        onSelectCvFile={(file) => this.handleSelectCvFile(file)}
        onDeleteCvFile={() => this.handleDeleteCvFile()}
        onAddLink={(linkUrl) => this.handleAddLink(linkUrl)}
        onDeleteLink={(linkId) => this.handleDeleteLink(linkId)}
      />
    );
  }
}

LayerViewProfileLinks.propTypes = {
  profileCv: PropTypes.object,
  isCVFileUploadError: PropTypes.bool,
  profileLinks: PropTypes.array,
  dispatch: PropTypes.func,
};

LayerViewProfileLinks.defaultProps = {
  profileCv: {},
  isCVFileUploadError: false,
  profileLinks: [],
  dispatch: () => {},
};

const mapStateToProps = ({ profileState }) => {
  const { profileCv, isCVFileUploadError, profileLinks } = profileState;
  return { profileCv, isCVFileUploadError, profileLinks };
};

export default connect(mapStateToProps)(LayerViewProfileLinks);
