import React, { useState } from 'react';
import VideoCanvas from '../../atoms/VideoCanvas/VideoCanvas';
import ToggleBox from '../elements/ToggleBox';
import IconArrowDown20 from '../../assets/icons/IconArrowDown20';
import IconArrowUp20 from '../../assets/icons/IconArrowUp20';
import IconDownloads20 from '../../assets/icons/IconDownloads20';
import IconPdf30 from '../../assets/icons/IconPdf30';
import IconVideo30 from '../../assets/icons/IconVideo30';
import IconImage30 from '../../assets/icons/IconImage30';
import IconExpandImage from '../../assets/icons/IconExpandImage';
import IconVideoPlay from '../../assets/icons/IconVideoPlay';
import Button from '../elements/Button';
import styled from 'styled-components';
import Wrapper from '../elements/Wrapper';
import ButtonDownload from '../elements/ButtonDownload';
import { useResponsive } from '../../hooks/responsive';
import DownloadsUtil from '../../utils/DownloadsUtil';
import ImageLayer from '../ImageLayer/ImageLayer';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';

const HeaderSection = styled.div({
  padding: '28px 30px 80px 30px',
});

const MainVideoContainer = styled.div({
  position: 'relative',
  width: '100%',
  height: '400px',
  display: 'flex',
  cursor: 'pointer',
  img: {
    // height: '100%',
    width: '100%',
    objectFit: 'cover',
    objectPosition: '0 0',
  },
  svg: {
    height: '60px',
    width: '60px',
  },
});

const MainVideoOuterContainer = styled.div({
  width: '100%',
  height: '375px',
});

const MainVideoDescription = styled.p({
  fontWeight: 300,
  fontSize: '18px',
  lineHeight: '24px',
  color: '#00234C',
  margin: 0,
});

const EventDate = styled.p({
  fontWeight: 300,
  fontSize: '1gpx',
  lineHeight: '24px',
  color: '#00234C',
  margin: 0,
  paddingTop: '20px',
});

const MainVideoCaption = styled.p({
  fontWeight: 500,
  fontSize: '30px',
  lineHeight: '36px',
  color: '#00234C',
  marginTop: '30px',
  marginBottom: '5px',
});

interface VideoSectionProps {
  isMobile: boolean;
}
const VideoSection = styled.div<VideoSectionProps>(({ isMobile }) => {
  return {
    paddingLeft: '30px',
    display: 'flex',
    width: '100%',
    overflowX: 'scroll',
    whiteSpace: 'nowrap',
    marginBottom: '50px',
    paddingBottom: '30px',
    '-ms-overflow-style': isMobile ? 'none' : undefined /* IE and Edge */,
    scrollbarColor: '#EBEBEB #8E9295' /* Firefox */,
    scrollbarWidth: isMobile ? 'none' : 'thin' /* Firefox */,
    '::-webkit-scrollbar': {
      width: '8px',
      height: '8px',
      display: isMobile ? 'none' : undefined,
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: '#EBEBEB',
      margin: '0 60px 0 30px',
      borderRadius: '4px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#8E9295',
      borderRadius: '4px',
      widht: '10px',
      backgroundClip: 'content-box',
    },
  };
});

const DocumentSection = styled.div({
  paddingLeft: '30px',
  display: 'flex',
  flexDirection: 'column',
  height: 'auto',
  width: '100%',
  paddingBottom: '30px',
});

const PictureSection = styled.div({
  paddingLeft: '30px',
  display: 'flex',
  width: '100%',
  overflowX: 'scroll',
  whiteSpace: 'nowrap',
});

interface VideoContainerProps {
  activeIndex: number;
  currentIndex: number;
}
const VideoContainer = styled.div<VideoContainerProps>(({ activeIndex, currentIndex }) => {
  return {
    position: 'relative',
    width: '215px',
    height: '120px',
    border: activeIndex === currentIndex ? '4px solid #2183CA' : '4px solid transparent',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
    img: {
      height: '100%',
      width: '100%',
    },
    svg: {
      height: '20px',
      width: '20px',
    },
  };
});

const VideoOuterContainer = styled.div({
  width: '215px',
  marginRight: '30px',
});

interface VideoCaptionProps {
  activeIndex: number;
  currentIndex: number;
}
const VideoCaption = styled.p<VideoCaptionProps>(({ activeIndex, currentIndex }) => {
  return {
    fontWeight: 300,
    fontSize: '16px',
    lineHeight: '23px',
    color: activeIndex === currentIndex ? '#00234C' : '#2183CA',
    whiteSpace: 'normal',
    overflow: 'auto',
  };
});

const VideoPlayButton = styled.div({
  position: 'absolute',
  left: 0,
  top: 0,
  bottom: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
});

const SectionHeader = styled.div({
  borderTop: '1px solid #EBEBEB',
  display: 'flex',
  flexFlow: 'row nowrap',
  alignItem: 'center',
  justifyContent: 'space-between',
  cursor: 'pointer',
  padding: '25px 30px 50px 30px',
});

const DocumentContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  paddingBottom: '20px',
});

const ImageContainer = styled.div({
  position: 'relative',
  width: '180px',
  height: '120px',
  img: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
});

interface ImageGridProps {
  isMobile: boolean;
}
const ImageGrid = styled.div<ImageGridProps>(({ isMobile }) => {
  return {
    marginRight: '60px',
    display: 'grid',
    gridTemplateRows: 'auto auto auto',
    gridAutoFlow: 'column',
    overflowX: 'scroll',
    gap: '30px',
    whiteSpace: 'nowrap',
    marginBottom: '30px',
    paddingBottom: '20px',
    '-ms-overflow-style': isMobile ? 'none' : undefined /* IE and Edge */,
    scrollbarColor: '#EBEBEB #8E9295' /* Firefox */,
    scrollbarWidth: isMobile ? 'none' : 'thin' /* Firefox */,
    '::-webkit-scrollbar': {
      width: '8px',
      height: '8px',
      display: isMobile ? 'none' : undefined,
    },
    '::-webkit-scrollbar-track': {
      backgroundColor: '#EBEBEB',
      margin: '0 60px 0 30px',
      borderRadius: '4px',
    },
    '::-webkit-scrollbar-thumb': {
      background: '#8E9295',
      borderRadius: '4px',
      widht: '10px',
      backgroundClip: 'content-box',
    },
  };
});

const ImageOverlay = styled.div({
  position: 'absolute',
  background: 'linear-gradient(0deg, rgba(110, 110, 110, 0.7), rgba(110, 110, 110, 0.7))',
  height: '99%',
  width: '100%',
  left: 0,
  top: 0,
  bottom: 0,
  right: 0,
  opacity: 0,
  '-webkit-transition': 'all 0.4s ease-in-out 0s',
  '-moz-transition': 'all 0.4s ease-in-out 0s',
  transition: 'all 0.4s ease-in-out 0s',
  '&:hover': {
    opacity: 1,
  },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
});

const ButtonContainer = styled.div({
  paddingBottom: 50,
  paddingLeft: 30,
});

const LayerViewDownloadVideo: React.FC = () => {
  const { sEventId, sVideoIndex } = useParams();

  const [activeIndex, setActiveIndex] = useState(parseInt(sVideoIndex || '') - 1);
  const [videoSectionIsExtended, setVideoSectionIsExtended] = useState(true);
  const [documentSectionIsExtended, setDocumentSectionIsExtended] = useState(true);
  const [pictureSectionIsExtended, setPictureSectionIsExtended] = useState(true);
  const [videoIsPlaying, setVideoIsPlaying] = useState(false);
  const [showImageCarousel, setShowImageCarousel] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);
  const { isMobile } = useResponsive();

  const eventId = parseInt(sEventId || '');

  const downloadModels = useAppSelector(({ downloadsState }) => downloadsState.downloadModels);

  const filteredData = downloadModels.filter((downloadModel) => {
    return downloadModel.id === eventId;
  });
  if (filteredData.length < 1) {
    return null;
  }
  const [videoData] = filteredData;

  const handleOnClickThumbnail = (index) => {
    setActiveIndex(index);
    setVideoIsPlaying(false);
  };
  const handleOnClickVideo = () => {
    setVideoIsPlaying(true);
  };

  const handleOnClickHeader = (downloadGroupId) => {
    switch (downloadGroupId) {
      case 1:
        setVideoSectionIsExtended(!videoSectionIsExtended);
        break;
      case 2:
        setDocumentSectionIsExtended(!documentSectionIsExtended);
        break;
      case 3:
        setPictureSectionIsExtended(!pictureSectionIsExtended);
        break;
      default:
        return;
    }
  };
  const handleOnClickDownload = (e, url) => {
    e.stopPropagation();
    window.location.href = url;
  };

  const handleOnClickImage = (index) => {
    setSlideIndex(index);
    setShowImageCarousel(true);
  };
  const closeOverlay = () => {
    setShowImageCarousel(false);
  };

  const handleOnVideoPlayerEvent = (eventAction) => {
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({
      eventAction,
      videoTitle: videoData.videoCaptions[activeIndex],
      eventName: videoData.title,
      event: 'video',
    });
  };

  const eventDate = videoData.dateSpanSolo + ', ' + videoData.locationCombined;
  const picturesDownloadUrl = videoData.videoDownloads[activeIndex].pictures.link;
  const documentsDownloadUrl = videoData.videoDownloads[activeIndex].documents.link;
  const picturesDownloadSize = DownloadsUtil.formatFileSize(videoData.videoDownloads[activeIndex].pictures.size);
  let documentSize = 0;
  videoData.videoRelatedDocuments.flat().map((x) => {
    documentSize += x.filesize;
  });
  const documentDownloadSize = DownloadsUtil.formatFileSize(documentSize);
  return (
    <div>
      <HeaderSection>
        {videoIsPlaying ? (
          <VideoCanvas
            aspectRatio={'16:9'}
            url={videoData.videoUrls[activeIndex]}
            playing
            onEventAction={handleOnVideoPlayerEvent}
          />
        ) : (
          <MainVideoOuterContainer>
            <MainVideoContainer onClick={() => handleOnClickVideo()}>
              <img src={videoData.videoThumbnails[activeIndex][0].url} alt="" />
              <VideoPlayButton>{IconVideoPlay}</VideoPlayButton>
            </MainVideoContainer>
          </MainVideoOuterContainer>
        )}
        <MainVideoCaption>{videoData.videoCaptions[activeIndex]}</MainVideoCaption>
        <MainVideoDescription>{videoData.videoDescriptions[activeIndex]}</MainVideoDescription>
        <EventDate>{eventDate}</EventDate>
      </HeaderSection>
      <SectionHeader onClick={() => handleOnClickHeader(1)}>
        <div className="bt-downloads-group__header-group">
          <div className="bt-downloads-group__header-icon">{IconVideo30}</div>
          <div className="bt-downloads-group__header-title">
            <span>{'All Videos on the topic'}</span>
          </div>
        </div>
        <div className="bt-downloads-group__header-group">
          <div className="bt-downloads-group__header-btn-toggle">
            <Button
              layout="akkordeon-toggle"
              path="#"
              iconRight={videoSectionIsExtended ? IconArrowUp20 : IconArrowDown20}
            />
          </div>
        </div>
      </SectionHeader>
      <ToggleBox show={videoSectionIsExtended}>
        <VideoSection isMobile={isMobile}>
          {videoData.videoThumbnails.map((item, i) => (
            <div key={String(i)}>
              <VideoOuterContainer>
                <VideoContainer activeIndex={activeIndex} currentIndex={i} onClick={() => handleOnClickThumbnail(i)}>
                  <img src={item[0].url} alt="" />
                  <VideoPlayButton>{IconVideoPlay}</VideoPlayButton>
                </VideoContainer>
                <VideoCaption activeIndex={activeIndex} currentIndex={i}>
                  {videoData.videoCaptions[i]}
                </VideoCaption>
              </VideoOuterContainer>
            </div>
          ))}
        </VideoSection>
      </ToggleBox>
      {videoData.videoRelatedDocuments[0].length > 0 && (
        <>
          <SectionHeader onClick={() => handleOnClickHeader(2)}>
            <div className="bt-downloads-group__header-group">
              <div className="bt-downloads-group__header-icon">{IconPdf30}</div>
              <div className="bt-downloads-group__header-title">
                <span>{'Related documents'}</span>
              </div>
            </div>
            <div className="bt-downloads-group__header-group">
              <div className="bt-downloads-group__header-btn-toggle">
                <Button
                  layout="akkordeon-toggle"
                  path="#"
                  iconRight={documentSectionIsExtended ? IconArrowUp20 : IconArrowDown20}
                />
              </div>
            </div>
          </SectionHeader>
          <ToggleBox show={documentSectionIsExtended}>
            <DocumentSection>
              {videoData
                ? videoData.videoRelatedDocuments.flat().map((agenda, index) => {
                    return (
                      <div key={String(index)}>
                        <DocumentContainer>
                          <Wrapper marginTop20>
                            <ButtonDownload
                              path={agenda.url}
                              label={`${agenda.title} (PDF ${DownloadsUtil.formatFileSize(agenda.filesize)})`}
                              iconLeft={IconDownloads20}
                            />
                          </Wrapper>
                        </DocumentContainer>
                      </div>
                    );
                  })
                : null}
            </DocumentSection>
            <ButtonContainer>
              <Button
                layout="secondary"
                label={`Download all PDFs (${documentDownloadSize})`}
                onClick={(e) => handleOnClickDownload(e, documentsDownloadUrl)}
              />
            </ButtonContainer>
          </ToggleBox>
        </>
      )}

      {videoData.videoRelatedPictures[0].length > 0 && (
        <>
          <SectionHeader onClick={() => handleOnClickHeader(3)}>
            <div className="bt-downloads-group__header-group">
              <div className="bt-downloads-group__header-icon">{IconImage30}</div>
              <div className="bt-downloads-group__header-title">
                <span>{'Related pictures'}</span>
              </div>
            </div>
            <div className="bt-downloads-group__header-group">
              <div className="bt-downloads-group__header-btn-toggle">
                <Button
                  layout="akkordeon-toggle"
                  path="#"
                  iconRight={pictureSectionIsExtended ? IconArrowUp20 : IconArrowDown20}
                />
              </div>
            </div>
          </SectionHeader>
          <ToggleBox show={pictureSectionIsExtended}>
            <PictureSection>
              <ImageGrid isMobile={isMobile}>
                {videoData.videoRelatedPictures[activeIndex].map((item, i) => (
                  <div key={String(i)}>
                    <ImageContainer onClick={() => handleOnClickImage(i)}>
                      <img src={item.url} alt="" />
                      <ImageOverlay>
                        <div className="bt-downloads-group__header-icon">{IconExpandImage}</div>
                      </ImageOverlay>
                    </ImageContainer>
                  </div>
                ))}
              </ImageGrid>
            </PictureSection>
            <ButtonContainer>
              <Button
                layout="secondary"
                label={`Download all pictures (${picturesDownloadSize})`}
                onClick={(e) => handleOnClickDownload(e, picturesDownloadUrl)}
              />
            </ButtonContainer>
          </ToggleBox>
        </>
      )}

      {showImageCarousel && (
        <ImageLayer
          slideIndex={slideIndex}
          data={videoData.videoRelatedPictures[activeIndex]}
          closeOverlay={closeOverlay}
        />
      )}
    </div>
  );
};

export default LayerViewDownloadVideo;
