import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@webfox-sc/core';
import { themeColors } from '../../styles/theme';

const theme = {
  root: {
    padding: 0,
    background: 'transparent',
    fontFamily: '"Barlow", sans-serif',
    fontWeight: '300',
    fontSize: '16px',
    lineHeight: '23px',
    textDecoration: 'none',
    color: themeColors.SKY,
    span: {
      marginLeft: '10px',
    },
    svg: {
      width: '20px',
      height: '20px',
    },
    '&:hover': {
      textDecoration: 'underline',
    },
    '&[data-disabled]': {
      opacity: 1,
      color: themeColors.BLUE,
    },
  },
};

const IconLink = ({ label, href, icon, disabled }) => {
  return <Button label={label} href={href} icon={icon} disabled={disabled} theme={theme} />;
};

IconLink.propTypes = {
  label: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.object,
  disabled: PropTypes.bool,
};

IconLink.defaultProps = {
  label: '',
  href: '',
  icon: null,
  disabled: false,
};

export default IconLink;
