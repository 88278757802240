import React, { Fragment } from 'react';
import PageContentHeader from '../PageContentHeader';
import CopyTextM from '../elements/CopyTextM';
import HeadlineThree from '../elements/HeadlineThree';
import Link from '../elements/Link';
import Button from '../elements/Button';
import { useBaseData } from '../../hooks/useBaseData';
import './page-view-privacy.scss';

const defaultTexts = {
  title: 'BIOTRONIK International Fellows Program Data Privacy Notice',
};

const PageViewPrivacy = () => {
  const { baseData } = useBaseData();
  const { privacy } = baseData?.texts || {};

  return (
    <div className="bt-imprint">
      <PageContentHeader title={privacy?.title || defaultTexts.title} />
      <div className="bt-terms__content">
        <br />
        <CopyTextM text={privacy?.intro} />
        <br />
        <br />
        {(privacy?.sections || []).map((section, index) => {
          return (
            <Fragment key={`achor-link-${String(index)}`}>
              <Link label={section?.title} path={`section-${String(index + 1)}`} />
              <br />
              <br />
            </Fragment>
          );
        })}
        <br />
        <br />
        {(privacy?.sections || []).map((section, index) => {
          const showCookieSettingsButton = (section?.content || '').includes('%COOKIE_SETTINGS%');
          return (
            <Fragment key={`content-${String(index)}`}>
              <HeadlineThree id={`section-${String(index + 1)}`} text={section?.title} />
              <br />
              {showCookieSettingsButton ? (
                <>
                  {(section?.content || '').split('%COOKIE_SETTINGS%').map((content, subcontentIndex) => {
                    return (
                      <Fragment key={`content-${String(index)}-${String(subcontentIndex)}`}>
                        <CopyTextM text={content} />
                        {subcontentIndex === 0 && (
                          <Button path="#uc-corner-modal-show" layout="primary" label="Cookie Settings" />
                        )}
                      </Fragment>
                    );
                  })}
                </>
              ) : (
                <CopyTextM text={section?.content} />
              )}
              <br />
              <br />
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

PageViewPrivacy.propTypes = {};
PageViewPrivacy.defaultProps = {};

export default PageViewPrivacy;
