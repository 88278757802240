/* eslint-disable */
import React from 'react';

const IconInfo20 = (
    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="nonzero">
            <path d="M8.796 6.643c.039.07.078.141.117.195.038.052.097.105.136.158.058.054.116.107.194.16.058.035.136.088.233.105.159.063.331.093.504.089.078 0 .175 0 .253-.018a.712.712 0 0 0 .214-.07h.019c.154-.063.292-.153.407-.266l.02-.018c.058-.052.097-.105.137-.158a.534.534 0 0 0 .096-.177c0-.017 0-.017.02-.035.038-.07.058-.159.077-.23.02-.07.02-.159.02-.23 0-.07 0-.159-.02-.23a1.533 1.533 0 0 0-.077-.23 1.002 1.002 0 0 0-.233-.317l-.039-.036c-.058-.053-.117-.088-.194-.141-.078-.035-.137-.07-.214-.106a1.171 1.171 0 0 0-.233-.07C10.136 5 10.058 5 9.98 5c-.077 0-.174 0-.252.017-.08.016-.158.04-.233.071h-.02a2.193 2.193 0 0 0-.213.106c-.078.053-.136.088-.194.141-.058.054-.117.107-.155.16a1.932 1.932 0 0 0-.117.194.919.919 0 0 0-.077.212c-.02.088-.02.159-.02.23 0 .07 0 .159.02.23.018.14.058.212.077.283m2.699 6.466h-.446V8.78a.436.436 0 0 0-.147-.325.527.527 0 0 0-.358-.133h-2.04a.527.527 0 0 0-.357.133.436.436 0 0 0-.147.326v.954a.435.435 0 0 0 .146.326.527.527 0 0 0 .359.134h.408v2.932h-.408a.527.527 0 0 0-.359.134.435.435 0 0 0-.146.326v.954a.436.436 0 0 0 .147.326.527.527 0 0 0 .358.133h2.99a.527.527 0 0 0 .358-.133.436.436 0 0 0 .147-.326v-.954c.003-.258-.221-.47-.505-.477" />
            <path d="M2.926 2.927c3.9-3.902 10.247-3.902 14.148-.002 3.901 3.901 3.901 10.25 0 14.15-3.9 3.9-10.248 3.9-14.149-.002-3.9-3.9-3.9-10.246 0-14.146zm13.642 13.642c3.623-3.623 3.622-9.516 0-13.139C12.946-.19 7.055-.19 3.43 3.433c-3.621 3.621-3.621 9.513 0 13.134 3.623 3.623 9.515 3.624 13.137.003v-.001z" />
        </g>
    </svg>
);

export default IconInfo20;
