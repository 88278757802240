/* eslint-disable */
import React from 'react';

const IconPdf30 = (
    <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="evenodd">
            <path d="M29.466 30H.497A.497.497 0 0 1 0 29.502V.5C0 .225.222.002.497.002h24.471a.49.49 0 0 1 .351.147l4.492 4.5a.502.502 0 0 1 .146.351v24.496a.49.49 0 0 1-.49.504zM1 28.998H28.97V5.211l-4.2-4.207H1v27.994z" />
            <path d="M29.466 5.504h-4.492a.497.497 0 0 1-.497-.498V.5c0-.199.122-.386.31-.462a.494.494 0 0 1 .544.11l4.492 4.5c.14.141.187.358.111.545a.524.524 0 0 1-.468.311zm-3.995-1.002h2.79l-2.79-2.795v2.795z" />
            <path d="M9.455 11.7c.462 0 .868.096 1.22.29.352.192.625.465.82.817.193.352.29.759.29 1.219 0 .452-.098.853-.296 1.201a2.018 2.018 0 0 1-.841.807c-.363.19-.783.284-1.259.284H7.641c-.044 0-.066.022-.066.066v2.938c0 .119-.058.178-.176.178h-.923c-.117 0-.176-.06-.176-.178v-7.444c0-.119.059-.178.176-.178h2.979zm-.187 3.572c.374 0 .674-.112.902-.334.227-.223.34-.516.34-.88 0-.37-.113-.67-.34-.9-.228-.23-.528-.345-.902-.345H7.641c-.044 0-.066.022-.066.066v2.326c0 .045.022.067.066.067h1.627zm3.815 4.228c-.118 0-.176-.06-.176-.178v-7.433c0-.119.058-.178.176-.178h2.638c.52 0 .978.093 1.374.278.396.186.702.45.918.796.216.345.324.747.324 1.207v3.227c0 .46-.108.862-.324 1.207a2.11 2.11 0 0 1-.918.796c-.396.185-.854.278-1.374.278h-2.638zm1.099-1.18c0 .045.022.067.066.067h1.528c.388 0 .698-.124.929-.372.23-.249.35-.58.357-.996v-2.815c0-.423-.117-.759-.352-1.007-.234-.249-.55-.373-.945-.373h-1.517c-.044 0-.066.022-.066.067v5.43zM24.9 12.647c0 .118-.059.178-.176.178h-3.683c-.043 0-.065.022-.065.067v2.058c0 .045.022.067.065.067h2.463c.117 0 .176.06.176.178v.756c0 .12-.059.179-.176.179H21.04c-.043 0-.065.022-.065.066v3.127c0 .119-.06.178-.176.178h-.924c-.117 0-.176-.06-.176-.178v-7.433c0-.119.059-.178.176-.178h4.848c.117 0 .176.06.176.178v.757z" fillRule="nonzero" />
        </g>
    </svg>
);

export default IconPdf30;
