import React, { useContext, useState } from 'react';
import { Params, useNavigate } from 'react-router-dom';

type NavigateOptions = {
  replace?: boolean;
  state?: any;
  preventScrollReset?: boolean;
  relative?: 'route' | 'path';
};

interface OverlayContextInterface {
  overlayNavigate: (to: string, options?: NavigateOptions) => void;
  closeOverlay: () => void;
  setOverlayParams: React.Dispatch<React.SetStateAction<Readonly<Params<string>>>>;
}

export const OverlayContext = React.createContext<OverlayContextInterface>({} as OverlayContextInterface);

export const useOverlayContext = () => {
  return useContext(OverlayContext);
};

interface OverlayProviderProps {
  children?: React.ReactNode;
}

const OverlayProvider: React.FC<OverlayProviderProps> = ({ children }) => {
  const navigate = useNavigate();

  const [overlayParams, setOverlayParams] = useState<Readonly<Params<string>>>({});

  const overlayNavigate = (to: string, options?: NavigateOptions) => {
    navigate(to, {
      preventScrollReset: true,
      ...options,
    });
  };

  const closeOverlay = () => {
    /**
     * use background path
     */
    const { bgSegment1, bgSegment2 } = overlayParams;
    let bgPath = '/';
    if (bgSegment1) {
      bgPath = `${bgPath}${bgSegment1}`;
    }
    if (bgSegment2) {
      bgPath = `${bgPath}/${bgSegment2}`;
    }
    navigate(bgPath, {
      preventScrollReset: true,
    });
  };

  return (
    <OverlayContext.Provider
      value={{
        overlayNavigate,
        closeOverlay,
        setOverlayParams,
      }}
    >
      {children}
    </OverlayContext.Provider>
  );
};

export default OverlayProvider;
