/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconCheckCircle30 from '../../../assets/icons/IconCheckCircle30';
import './button-checked.scss';

class ButtonChecked extends Component {
  renderIconRight() {
    return <span className="bt-button-checked__icon-right">{IconCheckCircle30}</span>;
  }

  renderLabel() {
    const { label } = this.props;
    if (!label) {
      return null;
    }
    return (
      <span className="bt-button-checked__label-wrapper">
        <span className="bt-button-checked__label">{label}</span>
      </span>
    );
  }

  render() {
    return (
      <span className="bt-button-checked">
        <span className="bt-button-checked__wrapper">
          {this.renderLabel()}
          {this.renderIconRight()}
        </span>
      </span>
    );
  }
}

ButtonChecked.propTypes = {
  label: PropTypes.string,
};

ButtonChecked.defaultProps = {
  label: '',
};

export default ButtonChecked;
