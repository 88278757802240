import React from 'react';
import PropTypes from 'prop-types';
import HeadlineThree from '../../components/elements/HeadlineThree';
import CopyTextM from '../../components/elements/CopyTextM';
import Button from '../../components/elements/Button';
import './ContactElement.scss';

const ContactElement = ({ imgSrc, name, location, tel, email }) => {
  return (
    <div className="bt-contact-element">
      {imgSrc && (
        <div className="bt-contact-element__picture">
          <img src={imgSrc} alt="" />
        </div>
      )}
      <div className="bt-contact-element__info">
        <HeadlineThree text={name} />
        <CopyTextM text={location} />
      </div>

      <Button layout="secondary" link path={`tel:${tel}`} label={tel} />
      <Button layout="secondary" link path={`mailto:${email}`} label={email} />
    </div>
  );
};

ContactElement.propTypes = {
  imgSrc: PropTypes.string,
  name: PropTypes.string,
  location: PropTypes.string,
  tel: PropTypes.string,
  email: PropTypes.string,
};

ContactElement.defaultProps = {
  imgSrc: '',
  name: '',
  location: '',
  tel: '',
  email: '',
};

export default ContactElement;
