/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import HeadlineThree from '../elements/HeadlineThree';
import CopyTextL from '../elements/CopyTextL';
import Button from '../elements/Button';
import './newsfeed-status.scss';
import IconInfo20 from '../../assets/icons/IconInfo20';

class NewsfeedStatus extends React.Component {
    render() {
        const { imageUrl, name, title, location, status, onClickDetails, initials } = this.props;

        return (
            <div className="bt-newsfeed-status__list-item">
                <span className="bt-newsfeed-status__status">{status}</span>
                <div className="bt-newsfeed-status__inner-container">
                    {/* <div className="bt-newsfeed-status__image">
                        <img src={imageUrl} alt="" />
                    </div> */}
                    <div className="bt-newsfeed-status__image">
                        {imageUrl ? (
                            <img src={imageUrl} alt="" />
                        ) : (
                            <span className="bt-newsfeed-status__no-picture">{initials}</span>
                        )}
                    </div>
                    <div className="bt-newsfeed-status__info-container">
                        <div className="bt-newsfeed-status__info">
                            <HeadlineThree text={name} />
                            <CopyTextL text={title} />
                            <br />
                            <CopyTextL text={location} />
                        </div>
                        <div className="bt-newsfeed-status__button">
                            <Button
                                layout="secondary-side"
                                path="#"
                                label="Details"
                                onClick={(e) => onClickDetails(e)}
                            />
                        </div>
                        <div className="bt-newsfeed-status__button-mobile">
                            <Button
                                layout="primary-side-small"
                                path={'#'}
                                iconInfo={IconInfo20}
                                onClick={(e) => onClickDetails(e)}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

NewsfeedStatus.propTypes = {
    imageUrl: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    location: PropTypes.string,
    status: PropTypes.string,
    onClickDetails: PropTypes.func,
    initials: PropTypes.string
};

NewsfeedStatus.defaultProps = {
    initials: '',
    imageUrl: '',
    name: '',
    title: '',
    location: '',
    status: '',
    onClickDetails: () => false
};

export default NewsfeedStatus;
