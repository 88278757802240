import { configureStore } from '@reduxjs/toolkit';
import authReducer from './slices/authSlice';
import appReducer from '../redux-reducers/appReducer';
import downloadsReducer from '../redux-reducers/downloadsReducer';
import eventsReducer from '../redux-reducers/eventsReducer';
import fellowsReducerLegacy from '../redux-reducers/fellowsReducerLegacy';
import fellowsReducer from './slices/fellowsSlice';
import newsfeedReducer from '../redux-reducers/newsfeedReducer';
import profileReducer from '../redux-reducers/profileReducer';
import searchReducer from '../redux-reducers/searchReducer';

export const store = configureStore({
  devTools: true,
  reducer: {
    authState: authReducer,
    appState: appReducer,
    downloadsState: downloadsReducer,
    eventsState: eventsReducer,
    fellowsStateLegacy: fellowsReducerLegacy,
    fellowsState: fellowsReducer,
    newsfeedState: newsfeedReducer,
    profileState: profileReducer,
    searchState: searchReducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: false,
    });
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type ThunkAPIConfig = {
  dispatch: AppDispatch;
  state: RootState;
};
