import axios from 'axios';
import { handleErrorResponse } from './actionsApp';
import { REDUX_ACTIONS } from '../global/constants';
import { apiUrl } from '../app/api';

export function fetchTweets() {
  return async (dispatch, getState) => {
    const { authState } = getState();
    const { userToken } = authState;

    dispatch({
      type: REDUX_ACTIONS.REQUEST_TWEETS,
    });

    return axios({
      method: 'get',
      url: `${apiUrl}/tweets`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((res) => {
        const resData = res.data || {};
        const tweets = resData.data || [];
        dispatch({
          type: REDUX_ACTIONS.RECIEVE_TWEETS,
          tweets,
        });
        return tweets;
      })
      .catch((error) => {
        dispatch({
          type: REDUX_ACTIONS.REQUEST_TWEETS_ERROR,
        });
        dispatch(handleErrorResponse(error.response));
      });
  };
}

export function updateEventModels(eventModels) {
  return {
    type: REDUX_ACTIONS.UPDATE_EVENT_MODELS_NEWSFEED,
    eventModels,
  };
}

export function updateNewsTeaserData(newsTeaserData) {
  return {
    type: REDUX_ACTIONS.UPDATE_NEWS_TEASER_DATA,
    newsTeaserData,
  };
}

export function updateFellowModels(fellowModels) {
  return {
    type: REDUX_ACTIONS.UPDATE_FELLOW_MODELS_NEWSFEED,
    fellowModels,
  };
}

export function updateFellowStatusModels(fellowStatusModels) {
  return {
    type: REDUX_ACTIONS.UPDATE_FELLOW_STATUS_MODELS_NEWSFEED,
    fellowStatusModels,
  };
}

export function updateDownloadModels(downloadModels) {
  return {
    type: REDUX_ACTIONS.UPDATE_DOWNLOAD_MODELS_NEWSFEED,
    downloadModels,
  };
}

export function updateIsNewsfeedLoading(isNewsfeedLoading) {
  return {
    type: REDUX_ACTIONS.IS_NEWSFEED_LOADING,
    isNewsfeedLoading,
  };
}

export function updateIsNewsfeedLoaded() {
  return {
    type: REDUX_ACTIONS.IS_NEWSFEED_LOADED,
  };
}

export function updateNews(newsData) {
  return {
    type: REDUX_ACTIONS.UPDATE_NEWS,
    newsData,
  };
}
