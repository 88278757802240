/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import HeadlineOne from '../elements/HeadlineOne';
import './page-content-header.scss';

class PageContentHeader extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        const { title, children } = this.props;
        return (
            <div className="bt-page-content-header">
                <div className="bt-page-content-header-title">
                    <HeadlineOne
                        text={title}
                    />
                </div>
                {children}
            </div>
        );
    }
}

PageContentHeader.propTypes = {
    title: PropTypes.string,
};

PageContentHeader.defaultProps = {
    title: ''
};

export default PageContentHeader;
