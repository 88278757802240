import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import LayerNavigationItem from '../LayerNavigationItem';
import IconArrowRight12 from '../../assets/icons/IconArrowRight12';
import './layer-navigation.scss';

class LayerNavigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showArrowMore: false,
    };

    this.handleWindowResize = this.handleWindowResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleWindowResize);
    this.handleWindowResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }

  handleWindowResize() {
    if (!this.refWrapper || !this.refItems) {
      return false;
    }
    this.setState({
      showArrowMore: this.refItems.offsetWidth > this.refWrapper.offsetWidth,
    });
  }

  getActiveTabId() {
    const { layerNavigationModel, pathname } = this.props;

    if (!layerNavigationModel.length) {
      return '';
    }

    let activeTabId = layerNavigationModel[0].tabId;
    const pathSegments = pathname.split('/');
    const lastPathSegment = pathSegments[pathSegments.length - 1];

    layerNavigationModel.forEach((item) => {
      if (item.tabId === lastPathSegment) {
        activeTabId = item.tabId;
      }
    });

    return activeTabId;
  }

  render() {
    const { showArrowMore } = this.state;
    const { layerNavigationModel, handleClickItem } = this.props;
    const activeTabId = this.getActiveTabId();

    return (
      <div
        className="bt-layer-navigation"
        ref={(node) => {
          this.refWrapper = node;
        }}
      >
        <div className="bt-layer-navigation__wrapper">
          <div className="bt-layer-navigation__scroller">
            <div
              className="bt-layer-navigation__items"
              ref={(node) => {
                this.refItems = node;
              }}
            >
              {layerNavigationModel.map((layerNavigationItem, i) => {
                return (
                  <div key={i} className="bt-layer-navigation__item">
                    <LayerNavigationItem
                      label={layerNavigationItem.label}
                      path={layerNavigationItem.path}
                      isActive={layerNavigationItem.tabId === activeTabId}
                      onClick={handleClickItem}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {showArrowMore && <div className="bt-layer-navigation__arrow-more">{IconArrowRight12}</div>}
      </div>
    );
  }
}

LayerNavigation.propTypes = {
  layerNavigationModel: PropTypes.array,
  handleClickItem: PropTypes.func,
  pathname: PropTypes.string,
};

LayerNavigation.defaultProps = {
  layerNavigationModel: [],
  handleClickItem: () => false,
  pathname: '',
};

const mapStateToProps = ({ appState }) => {
  const { pathname } = appState;
  return {
    pathname,
  };
};

export default connect(mapStateToProps)(LayerNavigation);
