/**
 * breakpoints
 */

export const themeBreakpoints = {
  MOBILE_S: 320,
  MOBILE_M: 375,
  MOBILE_L: 425,
  TABLET: 768,
  TABLET_L: 901,
  DESKTOP_S: 1024,
  DESKTOP_M: 1440,
  DESKTOP_L: 1920,
};

export const themeDevices = {
  MOBILE_S: `@media (min-width: ${themeBreakpoints.MOBILE_S}px)`,
  MOBILE_M: `@media (min-width: ${themeBreakpoints.MOBILE_M}px)`,
  MOBILE_L: `@media (min-width: ${themeBreakpoints.MOBILE_L}px)`,
  TABLET: `@media (min-width: ${themeBreakpoints.TABLET}px)`,
  TABLET_L: `@media (min-width: ${themeBreakpoints.TABLET_L}px)`,
  DESKTOP_S: `@media (min-width: ${themeBreakpoints.DESKTOP_S}px)`,
  DESKTOP_M: `@media (min-width: ${themeBreakpoints.DESKTOP_M}px)`,
  DESKTOP_L: `@media (min-width: ${themeBreakpoints.DESKTOP_L}px)`,
};

/**
 * colors
 */

export const themeColors = {
  BLUE: '#00234c',
  SKY: '#2182c9',
};
