import React from 'react';
import PropTypes from 'prop-types';
import DownloadsPanel from '../../molecules/DownloadsPanel/DownloadsPanel';
import { useDownload } from './useDownload';
import DownloadsGroup from './DownloadsGroup';
import DownloadsUtil from '../../utils/DownloadsUtil';
import IconPdf30 from '../../assets/icons/IconPdf30';
import IconVideo30 from '../../assets/icons/IconVideo30';
import IconImage30 from '../../assets/icons/IconImage30';

const DownloadsPanelWrapper = ({ downloadId, speakerSearch }) => {
  const {
    download,
    downloadModel,
    downloadCategoryFormatted,
    downloadDateTimeFormatted,
    downloadCoverPictureMediumLarge,
    downloadIsDigital,
  } = useDownload(downloadId);

  if (!download || !downloadModel) {
    return null;
  }

  return (
    <DownloadsPanel
      title={download.title}
      date={downloadDateTimeFormatted}
      description={download.subTitle}
      imgSrc={downloadCoverPictureMediumLarge}
      isExtended
      category={downloadCategoryFormatted}
      isDigital={downloadIsDigital}
    >
      {(downloadModel.presentations.length >= 1 || downloadModel.agenda.length >= 1) && (
        <DownloadsGroup
          title={`Agenda & Presentations (${DownloadsUtil.calculateTotalFileSize(downloadModel, 'presentations')})`}
          icon={IconPdf30}
          agendas={downloadModel.agenda}
          presentations={downloadModel.presentations}
          type="presentations"
          eventId={downloadModel.id}
          speakerSearch={speakerSearch}
        />
      )}
      {downloadModel.videos.length >= 1 && (
        <DownloadsGroup
          title={`Videos  (${DownloadsUtil.calculateTotalFileSize(downloadModel, 'videos')})`}
          icon={IconVideo30}
          videos={downloadModel.videos}
          type="videos"
          eventId={downloadModel.id}
          speakerSearch={speakerSearch}
        />
      )}
      {downloadModel.pictures.length >= 1 && (
        <DownloadsGroup
          title={`Pictures (${DownloadsUtil.calculateTotalFileSize(downloadModel, 'pictures')})`}
          icon={IconImage30}
          pictures={downloadModel.pictures}
          type="pictures"
          eventId={downloadModel.id}
          speakerSearch={speakerSearch}
        />
      )}
      {/* In case there are now downloads at all */}
      {downloadModel.pictures.length < 1 &&
        downloadModel.videos.length < 1 &&
        downloadModel.presentations.length < 1 &&
        downloadModel.agenda.length < 1 && (
          <div className="bt-downloads-group">
            <div className="bt-downloads-group__header">
              <div className="bt-downloads-group__header-group">
                <div className="bt-downloads-group__header-title">
                  <span>No downloads found for this event.</span>
                </div>
              </div>
            </div>
          </div>
        )}
    </DownloadsPanel>
  );
};

DownloadsPanelWrapper.propTypes = {
  downloadId: PropTypes.number,
  speakerSearch: PropTypes.string,
};

DownloadsPanelWrapper.defaultProps = {
  downloadId: undefined,
  speakerSearch: '',
};

export default DownloadsPanelWrapper;
