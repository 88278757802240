import { colors } from './colors';
import { mediaQueries } from './screensAndMediaQueries';

export const fontFamily = '"Barlow", sans-serif';

const h1Mobile = {
  fontWeight: 300,
  fontSize: '30px',
  lineHeight: '36px',
};

const h1Desktop = {
  fontWeight: 300,
  fontSize: '40px',
  lineHeight: '48px',
};

const h1Responsive = {
  ...h1Mobile,
  [mediaQueries.desktop]: {
    ...h1Desktop,
  },
};

const h2Mobile = {
  fontWeight: 500,
  fontSize: '24px',
  lineHeight: '29px',
};

const h2Desktop = {
  fontWeight: 500,
  fontSize: '30px',
  lineHeight: '36px',
};

const h2Responsive = {
  ...h2Mobile,
  [mediaQueries.desktop]: {
    ...h2Desktop,
  },
};

const h3 = {
  fontWeight: 500,
  fontSize: '20px',
  lineHeight: '24px',
};

const h4 = {
  fontWeight: 300,
  fontSize: '18px',
  lineHeight: '24px',
};

const copyBaseStyle = {
  fontWeight: 300,
  a: {
    color: colors.btSky,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  '& > :first-child:not(style), & > *  > style:first-child + *': {
    marginTop: 0,
  },
  '& > :last-child:not(style), & > *  > style:last-child + *': {
    marginBottom: 0,
  },
};

const copy = {
  ...copyBaseStyle,
  fontSize: '16px',
  lineHeight: '23px',
};

const copySmall = {
  ...copyBaseStyle,
  fontSize: '14px',
  lineHeight: '20px',
};

const quoteMobile = {
  fontWeight: 300,
  fontSize: '18px',
  lineHeight: '26px',
};

const quoteDesktop = {
  fontWeight: 300,
  fontSize: '20px',
  lineHeight: '28px',
};

const quoteResponsive = {
  ...quoteMobile,
  [mediaQueries.desktop]: {
    ...quoteDesktop,
  },
};

export const textVariants = {
  h1Mobile,
  h1Desktop,
  h1Responsive,
  h2Mobile,
  h2Desktop,
  h2Responsive,
  h3,
  h4,
  copy,
  copySmall,
  quoteMobile,
  quoteDesktop,
  quoteResponsive,
};
