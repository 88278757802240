/* eslint-disable */
import React from 'react';

const IconLocation20 = (
    <svg width='20' height='20'>
        <defs>
            <path
                d='M10 8.667a2.336 2.336 0 0 1-2.333-2.334A2.336 2.336 0 0 1 10 4a2.336 2.336 0 0 1 2.333 2.333A2.336 2.336 0 0 1 10 8.667zm0-4a1.67 1.67 0 0 0-1.667 1.666A1.67 1.67 0 0 0 10 8a1.67 1.67 0 0 0 1.667-1.667A1.67 1.67 0 0 0 10 4.667zm-.058 13.585l-5.123-7.396c-1.909-2.547-1.631-6.75.601-8.984A6.358 6.358 0 0 1 9.94 0c1.708 0 3.313.665 4.522 1.873 2.234 2.233 2.51 6.436.591 8.993l-5.112 7.386zM5.892 2.343C3.877 4.36 3.627 8.157 5.36 10.467l4.58 6.615 4.573-6.605c1.742-2.32 1.494-6.118-.525-8.133A5.69 5.69 0 0 0 9.942.667a5.69 5.69 0 0 0-4.049 1.676h-.001zm8.146 11.992c3.51.413 5.962 1.578 5.962 2.832C20 18.575 16.564 20 10 20S0 18.575 0 17.167c0-1.254 2.452-2.419 5.962-2.832a.334.334 0 0 1 .076.663C2.532 15.41.667 16.523.667 17.167c0 .904 3.55 2.166 9.333 2.166s9.333-1.262 9.333-2.166c0-.645-1.866-1.757-5.371-2.169a.334.334 0 1 1 .076-.663z'
                id='a'
            />
        </defs>
        <g fill='none' fillRule='evenodd'>
            <path
                d='M10 8.667a2.336 2.336 0 0 1-2.333-2.334A2.336 2.336 0 0 1 10 4a2.336 2.336 0 0 1 2.333 2.333A2.336 2.336 0 0 1 10 8.667zm0-4a1.67 1.67 0 0 0-1.667 1.666A1.67 1.67 0 0 0 10 8a1.67 1.67 0 0 0 1.667-1.667A1.67 1.67 0 0 0 10 4.667zm-.058 13.585l-5.123-7.396c-1.909-2.547-1.631-6.75.601-8.984A6.358 6.358 0 0 1 9.94 0c1.708 0 3.313.665 4.522 1.873 2.234 2.233 2.51 6.436.591 8.993l-5.112 7.386zM5.892 2.343C3.877 4.36 3.627 8.157 5.36 10.468l4.58 6.614 4.573-6.604c1.742-2.321 1.494-6.119-.525-8.134A5.69 5.69 0 0 0 9.942.667a5.69 5.69 0 0 0-4.049 1.676h-.001zm8.146 11.992c3.51.413 5.962 1.578 5.962 2.832C20 18.575 16.564 20 10 20S0 18.575 0 17.167c0-1.254 2.452-2.419 5.962-2.832a.334.334 0 0 1 .076.663C2.532 15.41.667 16.523.667 17.167c0 .904 3.55 2.166 9.333 2.166s9.333-1.262 9.333-2.166c0-.644-1.866-1.757-5.371-2.169a.334.334 0 1 1 .076-.663z'
                fill='#000'
                fillRule='nonzero'
            />
            <mask id='b' fill='#fff' />
            <g mask='url(#b)' fill='#000'>
                <path d='M0 0h20v20H0z' />
            </g>
        </g>
    </svg>
);

export default IconLocation20;
