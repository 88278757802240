/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './select-simple.scss';

class SelectSimple extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valueByProp: '',
            value: ''
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.value !== state.valueByProp) {
            return {
                valueByProp: props.value,
                value: props.value
            };
        }
        return null;
    }

    handleOnChange(e) {
        const { onChange } = this.props;
        const { value } = e.target;
        this.setState({
            value
        });
        onChange(value);
    }

    render() {
        const { value } = this.state;
        const { options } = this.props;

        return (
            <div className="bt-select-simple">
                <select value={value} onChange={(e) => this.handleOnChange(e)}>
                    {options.map((option, i) => (
                        <option key={i} value={option.value}>
                            {option.label}
                        </option>
                    ))}
                </select>
            </div>
        );
    }
}

SelectSimple.propTypes = {
    /**
     * @prop options
     * @type array
     * @description array with data objects (value | label)
     */
    options: PropTypes.array,

    /**
     * @prop value
     * @type string | number | boolean
     * @description default value
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),

    /**
     * @prop onChange
     * @type function (callback)
     */
    onChange: PropTypes.func
};

SelectSimple.defaultProps = {
    options: [],
    value: '',
    onChange: () => false
};

export default SelectSimple;
