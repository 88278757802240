/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './label-m-regular.scss';

class LabelMRegular extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { text } = this.props;

        return <span className={`bt-label-m-regular`}>{text}</span>;
    }
}

LabelMRegular.propTypes = {
    text: PropTypes.string,
};

LabelMRegular.defaultProps = {
    text: '',
};

export default LabelMRegular;
