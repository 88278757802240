import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Block } from '@webfox-sc/core';
import HeadlineThree from '../../components/elements/HeadlineThree';
import CopyTextL from '../../components/elements/CopyTextL';
import CopyTextM from '../../components/elements/CopyTextM';
import IconLocation20 from '../../assets/icons/IconLocation20';
import IconIcal24 from '../../assets/icons/IconIcal24';
import EventTeaserAttendees from '../../components/events/EventTeaserAttendees';
import EventAttend from '../EventAttend/EventAttend';
import IconInfo20 from '../../assets/icons/IconInfo20';
import FallbackEventIcon from '../../components/FallbackEventIcon';
import { themeDevices } from '../../styles/theme';
import EventTeaserAttend from './EventTeaserAttend';
import './EventTeaser.scss';

const StyledTeaser = styled.div({
  position: 'relative',
  display: 'flex',
  flexFlow: 'column',
  borderRadius: '3px',
  boxShadow: '0 0 30px 0 rgba(0, 0, 0, 0.08)',
  background: '#fff',
  [themeDevices.TABLET_L]: {
    flexFlow: 'row',
  },
});

const StyledInfoArea = styled.div({
  position: 'relative',
  display: 'flex',
  flexFlow: 'row nowrap',
  flexGrow: 1,
  padding: '25px',
  boxSizing: 'border-box',
});

const StyledInfoAreaLabelWrapper = styled.div({
  flex: '0 0 auto',
});

const StyledInfoAreaLabel = styled.div({
  borderRadius: '50%',
  a: {
    textDecoration: 'none',
  },
});

const StyledInfoAreaContent = styled.div({
  flexGrow: 1,
  padding: '5px 10px 5px 20px',
  [themeDevices.TABLET_L]: {
    padding: '5px 20px 5px 30px',
  },
});

const EventTeaser = ({
  id,
  category,
  categoryFormatted,
  title,
  subTitle,
  date,
  time,
  location,
  detailsPath,
  eventPath,
  onClickCalendar,
  onClickDetails,
  hrefCalendar,
  attendees,
  isAttending,
  canAttend,
  imgSrc,
  onToggleAttend,
  attendanceType,
  isDigital,
  registrationLink,
  informationLink,
}) => {
  return (
    <StyledTeaser>
      <StyledInfoArea>
        <StyledInfoAreaLabelWrapper>
          <StyledInfoAreaLabel>
            <a href={detailsPath} title="Details" onClick={onClickDetails}>
              {imgSrc ? (
                <div className="bt-event-teaser__picture">{imgSrc && <img src={imgSrc} alt="" />}</div>
              ) : (
                <FallbackEventIcon category={categoryFormatted} isDigital={isDigital} />
              )}
            </a>
          </StyledInfoAreaLabel>
        </StyledInfoAreaLabelWrapper>
        <StyledInfoAreaContent>
          <div className="bt-event-teaser__info">
            <a href={detailsPath} title="Details" onClick={onClickDetails}>
              <div className="bt-event-teaser__title">
                <HeadlineThree text={title} />
                {IconInfo20}
              </div>
              <div className="bt-event-teaser__sub-title">
                <CopyTextL text={subTitle} />
              </div>
            </a>
          </div>
          <div className="bt-event-teaser__options">
            <div className="bt-event-teaser__location">
              {IconLocation20}
              <CopyTextM text={location} />
            </div>
            <div className="bt-event-teaser__datelink">
              <a
                href={hrefCalendar}
                onClick={(e) => {
                  e.preventDefault();
                  onClickCalendar(id);
                }}
              >
                <span className="bt-event-teaser__dateicon">{IconIcal24}</span>
                <span className="bt-event-teaser__date">{date} </span>

                {time && (
                  <>
                    {' '}
                    <span
                      className="
                                    bt-event-teaser__datetime"
                    >
                      {time}
                    </span>
                  </>
                )}
              </a>
            </div>
          </div>
          {category === 'ehra' && canAttend && attendanceType !== 'register' && !!registrationLink && (
            <Block paddingTop="10px">
              <EventTeaserAttend
                eventId={id}
                isAttending={isAttending}
                registrationLink={registrationLink}
                onToggleAttend={onToggleAttend}
              />
            </Block>
          )}
          {category === 'other' && !!informationLink && (
            <Block paddingTop="10px">
              <CopyTextM text={`<a href="${informationLink}" target="_blank">More information</a>`} />
            </Block>
          )}
        </StyledInfoAreaContent>
      </StyledInfoArea>

      <div className="bt-event-teaser__attend-section">
        <div className="bt-event-teaser__attend">
          <EventTeaserAttendees attendees={attendees} id={id} pageId="events" />
          <EventAttend
            id={id}
            isAttending={isAttending}
            canAttend={canAttend}
            eventPath={eventPath}
            attendanceType={attendanceType}
            onToggleAttend={onToggleAttend}
          />
        </div>
      </div>
    </StyledTeaser>
  );
};

EventTeaser.propTypes = {
  id: PropTypes.number,
  category: PropTypes.string,
  categoryFormatted: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  date: PropTypes.string,
  time: PropTypes.string,
  location: PropTypes.string,
  detailsPath: PropTypes.string,
  eventPath: PropTypes.string,
  onClickDetails: PropTypes.func,
  onClickCalendar: PropTypes.func,
  hrefCalendar: PropTypes.string,
  attendees: PropTypes.array,
  isAttending: PropTypes.bool,
  canAttend: PropTypes.bool,
  imgSrc: PropTypes.string,
  onToggleAttend: PropTypes.func,
  attendanceType: PropTypes.string,
  isDigital: PropTypes.bool,
  registrationLink: PropTypes.string,
  informationLink: PropTypes.string,
};

EventTeaser.defaultProps = {
  category: undefined,
  categoryFormatted: '',
  title: '',
  subTitle: '',
  date: '',
  time: '',
  location: '',
  detailsPath: '',
  eventPath: '',
  onClickDetails: () => false,
  onClickCalendar: () => false,
  hrefCalendar: '',
  attendees: [],
  id: null,
  isAttending: false,
  canAttend: null,
  imgSrc: '',
  onToggleAttend: () => {},
  attendanceType: null,
  isDigital: false,
  registrationLink: undefined,
  informationLink: undefined,
};

export default EventTeaser;
