import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Input from '../../components/elements/Input/Input';
import Checkbox from '../../components/elements/Checkbox/Checkbox';
import Button from '../../components/elements/Button';
import './LoginForm.scss';

class LoginForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      rememberMeByProp: false,
      rememberMe: false,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.rememberMe !== state.rememberMeByProp) {
      return {
        rememberMeByProp: props.rememberMe,
        rememberMe: props.rememberMe,
      };
    }
    return null;
  }

  handleOnChangeEmail(value) {
    const { onChangeEmail } = this.props;
    this.setState({
      email: value,
    });
    onChangeEmail(value);
  }

  handleOnChangePassword(value) {
    const { onChangePassword } = this.props;
    this.setState({
      password: value,
    });
    onChangePassword(value);
  }

  handleOnChangeRememberMe(checked) {
    const { onChangeRememberMe } = this.props;
    this.setState({
      rememberMe: checked,
    });
    onChangeRememberMe(checked);
  }

  handleOnSubmit(e) {
    e.preventDefault();
    const { onSubmit } = this.props;
    onSubmit();
  }

  render() {
    const { rememberMe } = this.state;
    const { loginFailed, onClickForgotPassword } = this.props;

    return (
      <form onSubmit={(e) => this.handleOnSubmit(e)}>
        <div className="bt-login-form">
          <div className="bt-login-form__inputs">
            <div className="bt-login-form__email">
              <Input
                type="text"
                name="email"
                label="Email"
                error={loginFailed}
                errorMessage={loginFailed ? 'Wrong email or password.' : ''}
                onChange={(value) => this.handleOnChangeEmail(value)}
              />
            </div>
            <div className="bt-login-form__password">
              <Input
                type="password"
                name="password"
                label="Password"
                error={loginFailed}
                onChange={(value) => this.handleOnChangePassword(value)}
              />
              <a className="bt-login-form__password-forgot" href="/password" onClick={(e) => onClickForgotPassword(e)}>
                Forgot your password?
              </a>
            </div>
          </div>
          <div className="bt-login-form__lower-section">
            <div className="bt-login-form__checkbox">
              <Checkbox checked={rememberMe} onChange={(checked) => this.handleOnChangeRememberMe(checked)} />
              <span>Remain signed in</span>
            </div>
            <div className="bt-login-form__button">
              <Button layout="primary" path="#" label="Sign in" onClick={(e) => this.handleOnSubmit(e)} />
              <div className="bt-login-form__submit-button">
                <button type="submit">+</button>
              </div>
            </div>
          </div>
        </div>
      </form>
    );
  }
}

LoginForm.propTypes = {
  rememberMe: PropTypes.bool,
  loginFailed: PropTypes.bool,
  onChangeEmail: PropTypes.func,
  onChangePassword: PropTypes.func,
  onChangeRememberMe: PropTypes.func,
  onSubmit: PropTypes.func,
  onClickForgotPassword: PropTypes.func,
};

LoginForm.defaultProps = {
  rememberMe: false,
  loginFailed: false,
  onChangeEmail: () => false,
  onChangePassword: () => false,
  onChangeRememberMe: () => false,
  onSubmit: () => false,
  onClickForgotPassword: () => false,
};

export default LoginForm;
