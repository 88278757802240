/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ErrorDialog from '../../molecules/ErrorDialog/ErrorDialog';
import { hideErrorDialog } from '../../redux-actions/actionsApp';
import './ErrorDialogOverlay.scss';

class ErrorDialogOverlay extends Component {
  constructor(props) {
    super(props);
  }

  handleOnClickClose() {
    const { dispatch } = this.props;
    dispatch(hideErrorDialog());
  }

  render() {
    const { showErrorDialog } = this.props;

    if (!showErrorDialog) {
      return null;
    }

    return (
      <div className="error-dialog-overlay">
        <ErrorDialog
          heading="Data Request Error"
          text={`<p>Oops, something went wrong.</p>
                    <p>Please try to refresh this page or feel free to <a href="mailto:support.ifp@biotronik.com">contact us</a> if the problem persists!</p>`}
          onClickClose={() => this.handleOnClickClose()}
        />
      </div>
    );
  }
}

ErrorDialogOverlay.propTypes = {
  showErrorDialog: PropTypes.bool,
  dispatch: PropTypes.func,
};

ErrorDialogOverlay.defaultProps = {
  showErrorDialog: false,
  dispatch: () => false,
};

const mapStateToProps = ({ appState }) => {
  const { showErrorDialog } = appState;
  return { showErrorDialog };
};

export default connect(mapStateToProps)(ErrorDialogOverlay);
