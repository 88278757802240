import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Grid, GridItem } from '@webfox-sc/core';
import Select from '../elements/Select';
import Input from '../elements/Input/Input';
import { useDownloadsToolbar } from './useDownloadsToolbar';
import './downloads-toolbar.scss';
import DownloadsUtil from '../../utils/DownloadsUtil';

const DownloadsToolbar = ({ onChangeSpeaker }) => {
  const { yearOptions, eventOptions, categoryOptions, locationOptions, keywordOptions } = useDownloadsToolbar();

  const [selectParams, setSelectParams] = useState({
    year: undefined,
    event: undefined,
    category: undefined,
    location: undefined,
    keyword: undefined,
  });

  const [speaker, setSpeaker] = useState('');

  const handleOnChangeAnySelect = (key, value) => {
    const selectParamsNew = { ...selectParams };
    selectParamsNew[key] = value === 'all' ? undefined : value;
    setSelectParams(selectParamsNew);
    DownloadsUtil.loadDownloadsByParams({ ...selectParamsNew, speaker: speaker || undefined });
  };

  const handleOnSpeakerInputKeyDown = (e) => {
    if (e.keyCode === 13) {
      DownloadsUtil.loadDownloadsByParams({ ...selectParams, speaker: speaker || undefined });
    }
  };

  return (
    <div className="bt-downloads-toolbar">
      <Grid columns={6} hSpacing="30px">
        <GridItem>
          <Select
            options={yearOptions}
            value="all"
            placeholder="Auswählen..."
            label="Year"
            onChange={(value) => handleOnChangeAnySelect('year', value)}
          />
        </GridItem>
        <GridItem>
          <Select
            options={eventOptions}
            value="all"
            placeholder="Auswählen..."
            label="Event"
            onChange={(value) => handleOnChangeAnySelect('event', value)}
          />
        </GridItem>
        <GridItem>
          <Select
            options={categoryOptions}
            value="all"
            placeholder="Auswählen..."
            label="Category"
            onChange={(value) => handleOnChangeAnySelect('category', value)}
          />
        </GridItem>
        <GridItem>
          <Select
            options={locationOptions}
            value="all"
            placeholder="Auswählen..."
            label="Location"
            onChange={(value) => handleOnChangeAnySelect('location', value)}
          />
        </GridItem>
        <GridItem>
          <Select
            options={keywordOptions}
            value="all"
            placeholder="Auswählen..."
            label="Keyword"
            onChange={(value) => handleOnChangeAnySelect('keyword', value)}
          />
        </GridItem>
        <GridItem>
          <Input
            onChange={(value) => {
              setSpeaker(value);
              onChangeSpeaker(value);
            }}
            onKeyDown={(e) => handleOnSpeakerInputKeyDown(e)}
            label="Speaker"
            search
          />
        </GridItem>
      </Grid>
    </div>
  );
};

DownloadsToolbar.propTypes = {
  onChangeSpeaker: PropTypes.func,
};

DownloadsToolbar.defaultProps = {
  onChangeSpeaker: () => false,
};

export default DownloadsToolbar;
