/* eslint-disable */
import React from 'react';

const IconInvisible20 = (
    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="evenodd">
            <path d="M19.91 9.234a11.399 11.399 0 0 0-3.299-3.527.682.682 0 0 0-.936.155.644.644 0 0 0 .16.912 10.104 10.104 0 0 1 2.71 2.788C16.698 12.408 13.46 14.16 10 14.16a.663.663 0 0 0-.671.655c0 .362.3.654.67.654 4.071 0 7.868-2.137 9.91-5.578a.64.64 0 0 0 0-.656z" />
            <path d="M10 13.447c2.197 0 3.984-1.743 3.984-3.885 0-.283-.031-.566-.094-.841a.671.671 0 0 0-.8-.498.654.654 0 0 0-.51.78c.04.182.062.37.062.56 0 1.42-1.185 2.575-2.642 2.575-.486 0-.94-.129-1.332-.352l9.712-9.469a.643.643 0 0 0 0-.925.683.683 0 0 0-.95 0L7.72 10.86a2.508 2.508 0 0 1-.362-1.298c0-1.42 1.185-2.576 2.642-2.576.209 0 .416.024.616.07a.673.673 0 0 0 .81-.484.654.654 0 0 0-.498-.788A4.114 4.114 0 0 0 10 5.678c-2.197 0-3.984 1.742-3.984 3.884 0 .835.272 1.61.734 2.244l-1.321 1.288a10.007 10.007 0 0 1-3.973-3.532C3.3 6.717 6.538 4.965 10 4.965c.717 0 1.433.073 2.128.219.362.076.72-.15.797-.503a.655.655 0 0 0-.516-.777A11.801 11.801 0 0 0 10 3.656c-4.069 0-7.866 2.137-9.908 5.579a.638.638 0 0 0 0 .655 11.311 11.311 0 0 0 4.346 4.17l-3.92 3.823a.643.643 0 0 0 0 .925A.678.678 0 0 0 .99 19a.677.677 0 0 0 .475-.192l6.233-6.077c.65.45 1.444.716 2.3.716z" />
        </g>
    </svg>
);

export default IconInvisible20;
