/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { useParams, Outlet } from 'react-router-dom';
import ProfileHeader from '../ProfileHeader';
import LayerNavigation from '../LayerNavigation';
import LayerViewHeader from '../overlay/LayerViewHeader';
import LayerViewBody from '../overlay/LayerViewBody';
import { showProfileImageOverlay } from '../../redux-actions/actionsApp';
import { removeProfileImage } from '../../redux-actions/actionsProfile';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useOverlayContext } from '../overlay/OverlayProvider';

const LayerViewProfile: React.FC = () => {
  const dispatch = useAppDispatch();
  const { overlayNavigate } = useOverlayContext();

  const { bgSegment1: pageId, sContentId: contentId } = useParams();
  const { authStatus } = useAppSelector(({ authState }) => authState);
  const { profileFirstName, profileLastName, profilePictureSrc, profileProfession, profileDegree } = useAppSelector(
    ({ profileState }) => profileState
  );

  const handleOnClickChangeProfileImage = () => {
    dispatch(showProfileImageOverlay());
  };

  const handleOnClickRemoveProfileImage = () => {
    dispatch(removeProfileImage());
  };

  const handleOnClickItem = (e, path) => {
    e.preventDefault();
    overlayNavigate(path);
  };

  if (authStatus !== 'loggedIn') {
    return null;
  }

  const layerNavigationModel = [
    {
      label: 'Personal Data',
      path: `/${pageId}/${contentId}/profile/data`,
      tabId: 'data',
    },
    {
      label: 'CV & Links',
      path: `/${pageId}/${contentId}/profile/links`,
      tabId: 'links',
    },
    {
      label: 'Delete account',
      path: `/${pageId}/${contentId}/profile/delete`,
      tabId: 'delete',
    },
  ];

  let profession = profileProfession;
  if (profileDegree) {
    profession += ', ' + profileDegree;
  }

  return (
    <div>
      <LayerViewHeader>
        <ProfileHeader
          firstName={profileFirstName}
          lastName={profileLastName}
          profession={profession}
          imgSrc={profilePictureSrc}
          onClickChangeProfileImage={() => handleOnClickChangeProfileImage()}
          onClickRemoveProfileImage={() => handleOnClickRemoveProfileImage()}
        />
      </LayerViewHeader>
      <LayerNavigation
        // @ts-ignore
        layerNavigationModel={layerNavigationModel}
        // @ts-ignore
        handleClickItem={handleOnClickItem}
      />
      <LayerViewBody>
        <Outlet />
      </LayerViewBody>
    </div>
  );
};

export default LayerViewProfile;
