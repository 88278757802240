/* eslint-disable */
import React from 'react';

const IconClose20 = (
    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 9.43431458l3.2414596-3.24145962c.1619061-.16190606.4198215-.16832706.5760315-.01211712.1565641.15656416.15156.41589648-.0103461.57780254L10.5656854 10l3.2414596 3.2414596c.1622732.1622732.1683271.4198215.0121172.5760315-.1565642.1565641-.4155294.1519271-.5778026-.0103461L10 10.5656854 6.75854038 13.807145c-.1622732.1622732-.42123838.1669102-.57780254.0103461-.15620994-.15621-.15015608-.4137583.01211712-.5760315L9.43431458 10 6.19285496 6.75854038c-.16190606-.16190606-.1669102-.42123838-.01034604-.57780254.15620994-.15620994.4141254-.14978894.57603146.01211712L10 9.43431458zM9.99888396 0C15.5130466 0 20 4.48516774 20 9.99888396 20 15.5130466 15.5130466 20 9.99888396 20 4.48516774 20 0 15.5130466 0 9.99888396 0 4.48516774 4.48516774 0 9.99888396 0zm0 19.2848374c5.12176064 0 9.28595344-4.1659784 9.28595344-9.28595344 0-5.11952858-4.1641928-9.28372135-9.28595344-9.28372135-5.11952858 0-9.28372135 4.16419277-9.28372135 9.28372135 0 5.11997504 4.16419277 9.28595344 9.28372135 9.28595344z" fill="currentColor" fillRule="evenodd" />
    </svg>
);

export default IconClose20;
