/* eslint-disable */
import React from 'react';

const IconVideo30 = (
    <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="evenodd">
            <path d="M11.989 14.33a.541.541 0 0 0 .562-.017l6.092-3.939a.566.566 0 0 0 0-.95l-6.092-3.937a.551.551 0 0 0-.565-.017.56.56 0 0 0-.286.492v7.875c0 .205.11.396.289.492zm.819-7.343L17.314 9.9l-4.506 2.912V6.987zM26.147 24.075H12.872v-1.05a.54.54 0 0 0-.553-.525.54.54 0 0 0-.553.525v1.05H9.553A.54.54 0 0 0 9 24.6c0 .29.248.525.553.525h2.213v1.05c0 .29.247.525.553.525a.54.54 0 0 0 .553-.525v-1.05h13.275a.54.54 0 0 0 .553-.525.54.54 0 0 0-.553-.525zM3.9 22.5c-.331 0-.6.235-.6.525v3.15c0 .29.269.525.6.525.331 0 .6-.235.6-.525v-3.15c0-.29-.269-.525-.6-.525zM6 22.5c-.331 0-.6.235-.6.525v3.15c0 .29.269.525.6.525.331 0 .6-.235.6-.525v-3.15c0-.29-.269-.525-.6-.525z" />
            <path d="M0 30h30V0H0v30zm1.111-10.91H28.89v-18H1.11v18zm0 9.82H28.89v-8.728H1.11v8.727z" />
        </g>
    </svg>
);

export default IconVideo30;
