/* eslint-disable */
import React from 'react';

const IconReload14 = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
    >
        <g>
            <path
                d="M13.65 6.65A.35.35 0 0114 7a7.3 7.3 0 01-1.87 4.71A6.74 6.74 0 017 14a6.86 6.86 0 01-3.5-1 7.25 7.25 0 01-1.64-1.33v1.32a.37.37 0 01-.35.37.35.35 0 01-.3-.36l-.08-2.29a.36.36 0 01.46-.46l2.27.08a.35.35 0 110 .7L2.2 11a6.83 6.83 0 001.66 1.41A6.19 6.19 0 007 13.3a6.08 6.08 0 004.57-2.06A6.56 6.56 0 0013.3 7a.35.35 0 01.35-.35zM7 0a6.86 6.86 0 013.5 1 7.25 7.25 0 011.64 1.33L12.09 1a.37.37 0 01.35-.37.35.35 0 01.35.35l.08 2.29a.36.36 0 01-.46.46l-2.27-.08a.35.35 0 110-.7L11.8 3a6.83 6.83 0 00-1.66-1.41A6.19 6.19 0 007 .7a6.08 6.08 0 00-4.61 2.06A6.56 6.56 0 00.7 7a.35.35 0 01-.35.35A.35.35 0 010 7a7.3 7.3 0 011.87-4.71A6.74 6.74 0 017 0z"
                fill="currentColor"
                fillRule="evenodd"
            />
        </g>
    </svg>
);

export default IconReload14;
