/* eslint-disable */
import React from 'react';

const IconCalendar = (
    <svg width="20" height="20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14.794 1.565C14.632.672 13.937 0 13.104 0c-.19 0-.345.175-.345.391 0 .216.154.391.344.391.572 0 1.035.526 1.035 1.174 0 .647-.463 1.173-1.035 1.173-.19 0-.344.175-.344.391 0 .216.154.391.344.391.832 0 1.529-.672 1.69-1.564h4.517v3.52H.69v-3.52h5.862c.19 0 .345-.175.345-.391 0-.216-.155-.391-.345-.391h-.627c.144-.455.522-.783.972-.783.57 0 1.034.526 1.034 1.174 0 .647-.463 1.173-1.034 1.173-.19 0-.345.175-.345.391 0 .216.154.391.345.391.95 0 1.724-.878 1.724-1.955C8.62.878 7.846 0 6.897 0c-.832 0-1.529.672-1.69 1.565H0V20h20V1.565h-5.206zM.69 19.217h18.62V6.65H.69v12.568zM8.966.782c.57 0 1.034.526 1.034 1.174 0 .647-.463 1.173-1.034 1.173-.19 0-.345.175-.345.391 0 .216.154.391.345.391.95 0 1.724-.878 1.724-1.955C10.69.878 9.915 0 8.966 0c-.19 0-.345.175-.345.391 0 .216.154.391.345.391zm3.103 1.174c0-.648-.464-1.174-1.035-1.174-.19 0-.344-.175-.344-.39 0-.217.154-.392.345-.392.95 0 1.724.878 1.724 1.956 0 1.077-.775 1.955-1.725 1.955-.19 0-.344-.175-.344-.39 0-.217.154-.392.345-.392.57 0 1.034-.526 1.034-1.173z"
            fill="currentColor"
        />
        <mask id="a" maskUnits="userSpaceOnUse" x="0" y="0" width="20" height="20">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14.794 1.565C14.632.672 13.937 0 13.104 0c-.19 0-.345.175-.345.391 0 .216.154.391.344.391.572 0 1.035.526 1.035 1.174 0 .647-.463 1.173-1.035 1.173-.19 0-.344.175-.344.391 0 .216.154.391.344.391.832 0 1.529-.672 1.69-1.564h4.517v3.52H.69v-3.52h5.862c.19 0 .345-.175.345-.391 0-.216-.155-.391-.345-.391h-.627c.144-.455.522-.783.972-.783.57 0 1.034.526 1.034 1.174 0 .647-.463 1.173-1.034 1.173-.19 0-.345.175-.345.391 0 .216.154.391.345.391.95 0 1.724-.878 1.724-1.955C8.62.878 7.846 0 6.897 0c-.832 0-1.529.672-1.69 1.565H0V20h20V1.565h-5.206zM.69 19.217h18.62V6.65H.69v12.568zM8.966.782c.57 0 1.034.526 1.034 1.174 0 .647-.463 1.173-1.034 1.173-.19 0-.345.175-.345.391 0 .216.154.391.345.391.95 0 1.724-.878 1.724-1.955C10.69.878 9.915 0 8.966 0c-.19 0-.345.175-.345.391 0 .216.154.391.345.391zm3.103 1.174c0-.648-.464-1.174-1.035-1.174-.19 0-.344-.175-.344-.39 0-.217.154-.392.345-.392.95 0 1.724.878 1.724 1.956 0 1.077-.775 1.955-1.725 1.955-.19 0-.344-.175-.344-.39 0-.217.154-.392.345-.392.57 0 1.034-.526 1.034-1.173z"
                fill="#fff"
            />
        </mask>
        <g mask="url(#a)">
            <path fill="currentColor" d="M-1.214-2.524h21.989v24.942H-1.214z" />
        </g>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M4.98 9.287H3.352v1.666h1.626V9.287zM2.702 8.62v3h2.926v-3H2.703zM8.881 9.287H7.255v1.666h1.626V9.287zM6.605 8.62v3h2.927v-3H6.605zM12.784 9.287h-1.626v1.666h1.626V9.287zm-2.277-.667v3h2.927v-3h-2.927zM16.686 9.287H15.06v1.666h1.626V9.287zM14.41 8.62v3h2.927v-3H14.41zM4.98 13.287H3.352v1.666h1.626v-1.666zm-2.277-.667v3h2.926v-3H2.703zM8.881 13.287H7.255v1.666h1.626v-1.666zm-2.276-.667v3h2.927v-3H6.605zM12.784 13.287h-1.626v1.666h1.626v-1.666zm-2.277-.667v3h2.927v-3h-2.927zM16.686 13.287H15.06v1.666h1.626v-1.666zm-2.276-.667v3h2.927v-3H14.41z"
            fill="currentColor"
        />
    </svg>
);

export default IconCalendar;
