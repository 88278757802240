/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './link.scss';

class Link extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { path, label, onClick } = this.props;

        return (
            <a href={'#' + path} className="bt-link" onClick={onClick}>
                {label}
            </a>
        );
    }
}

Link.propTypes = {
    path: PropTypes.string,
    label: PropTypes.string,
    onClick: PropTypes.func
};

Link.defaultProps = {
    path: '',
    label: '',
    onClick: () => false
};

export default Link;
