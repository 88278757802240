/* eslint-disable */
import React from 'react';

const IconHospital40 = (
    <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="nonzero">
            <path d="M39.38158 13.856H32.6166V3.41604c0-.33987-.27666-.61605-.61841-.61605H8.00182c-.34175 0-.61841.27618-.61841.61605V13.856H.6184c-.34174 0-.61841.27617-.61841.61598v22.11198c0 .33987.27666.61605.61842.61605h38.76316c.34176 0 .61842-.27618.61842-.61605V14.47196c0-.3398-.27667-.61597-.61842-.61597zM1.23675 35.9679V15.08801h6.14666v20.87987H1.23675zM8.62016 4.03202h22.75968v31.93586h-6.14697V23.68528c0-.34018-.27634-.61606-.61834-.61606h-9.22906c-.342 0-.61834.27588-.61834.61606v12.28261H8.62016V4.03203zm7.3834 31.93586V24.30125h7.99255V35.9679h-7.99254zm16.61303 0V15.08802h6.14666v20.87987h-6.14666z" />
            <path d="M20.84454 11.36695H19.3141V8.0212c0-.50405-.34718-.85063-.8528-.85063h-2.37944c-.50561 0-.85279.34658-.85279.85063v10.48298c0 .50405.34718.85063.8528.85063h2.37943c.50562 0 .8528-.34658.8528-.85063v-3.48826h1.53045v3.48826c0 .50405.34718.85063.8528.85063h2.37944c.5056 0 .85279-.34658.85279-.85063V8.02121c0-.50405-.34718-.85063-.8528-.85063h-2.37944c-.5056 0-.85279.34658-.85279.85063v3.34574zm1.1028-3.09687h1.87944v9.98524h-1.87945v-3.66245c0-.38792-.28901-.67643-.6783-.67643H18.8896c-.38928 0-.6783.2885-.6783.67643v3.66245h-1.87944V8.27008h1.87944v3.51993c0 .38792.28902.67644.6783.67644h2.37945c.38928 0 .6783-.28852.6783-.67644V8.27008z" />
        </g>
    </svg>
);

export default IconHospital40;
