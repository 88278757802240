/* eslint-disable */
import { REDUX_ACTIONS } from '../global/constants';

function eventsReducer(
    state = {
        events: [],
        eventModels: [],
        activeEvent: undefined,
        activeEventModel: {}
    },
    action
) {
    switch (action.type) {
        case REDUX_ACTIONS.RECIEVE_EVENTS:
            return Object.assign({}, state, {
                events: action.events
            });

        case REDUX_ACTIONS.UPDATE_EVENT_MODELS:
            return Object.assign({}, state, {
                eventModels: action.eventModels
            });

        case REDUX_ACTIONS.UPDATE_ACTIVE_EVENT:
            return Object.assign({}, state, {
                activeEvent: action.activeEvent,
                activeEventModel: action.activeEventModel
            });

        case REDUX_ACTIONS.RESET_ACTIVE_EVENT:
            return Object.assign({}, state, {
                activeEvent: undefined,
                activeEventModel: {}
            });

        default:
            return state;
    }
}

export default eventsReducer;
