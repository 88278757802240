import axios from 'axios';
import { REDUX_ACTIONS } from '../global/constants';
import { apiUrl } from '../app/api';

export function handleErrorResponse(_errorResponse) {
  const errorResponse = _errorResponse || {};
  const { status } = errorResponse;
  const showErrorDialog = status === 404 || status === 500;
  return {
    type: REDUX_ACTIONS.REQUEST_ERROR,
    showErrorDialog,
    errorStatus: status,
    errorResponse,
  };
}

export function hideErrorDialog() {
  return {
    type: REDUX_ACTIONS.HIDE_ERROR_DIALOG,
  };
}

export function fetchBaseData() {
  return async (dispatch) => {
    dispatch({
      type: REDUX_ACTIONS.REQUEST_BASE_DATA,
    });

    return axios({
      method: 'get',
      url: `${apiUrl}/frontend`,
    })
      .then((res) => {
        const responseData = res.data || {};
        const baseData = responseData.data || {};
        const baseDataLinks = baseData.links || {};

        return dispatch({
          type: REDUX_ACTIONS.RECIEVE_BASE_DATA,
          baseData,
          baseDataLinks,
        });
      })
      .catch((error) => {
        dispatch({
          type: REDUX_ACTIONS.REQUEST_BASE_DATA_ERROR,
        });
        dispatch(handleErrorResponse(error.response));
      });
  };
}

export function locationChanged(pathname, pageId, contentId, subContentId) {
  return {
    type: REDUX_ACTIONS.LOCATION_CHANGED,
    pathname,
    pageId,
    contentId,
    subContentId,
  };
}

export function showProfileImageOverlay() {
  return {
    type: REDUX_ACTIONS.SHOW_PROFILE_IMAGE_OVERLAY,
  };
}

export function hideProfileImageOverlay() {
  return {
    type: REDUX_ACTIONS.HIDE_PROFILE_IMAGE_OVERLAY,
  };
}
