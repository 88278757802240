/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './label-s.scss';

class LabelS extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { text } = this.props;

        return <span className={`bt-label-s`}>{text}</span>;
    }
}

LabelS.propTypes = {
    text: PropTypes.string,
};

LabelS.defaultProps = {
    text: '',
};

export default LabelS;
