/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ProfileUtil from '../../utils/ProfileUtil';
import ProfileImageDialog from './ProfileImageDialog';
import { hideProfileImageOverlay } from '../../redux-actions/actionsApp';
import './profile-image-overlay.scss';

class ProfileImageOverlay extends Component {
  constructor(props) {
    super(props);
  }

  handleOnClickCancel() {
    const { dispatch } = this.props;
    dispatch(hideProfileImageOverlay());
  }

  handleOnClickUpload(imgBlob) {
    const now = Date.now();
    const filename = `profile-${now}.jpeg`;
    ProfileUtil.uploadProfileImage(imgBlob, filename);

    const { dispatch } = this.props;
    dispatch(hideProfileImageOverlay());
  }

  render() {
    const { showProfileImageOverlay, profileFirstName, profileLastName, profilePictureSrc } = this.props;

    if (!showProfileImageOverlay) {
      return null;
    }

    return (
      <div className="profile-image-overlay">
        <ProfileImageDialog
          firstName={profileFirstName}
          lastName={profileLastName}
          imgSrc={profilePictureSrc}
          onClickUpload={(imgBlob) => this.handleOnClickUpload(imgBlob)}
          onClickCancel={() => this.handleOnClickCancel()}
        />
      </div>
    );
  }
}

ProfileImageOverlay.propTypes = {
  showProfileImageOverlay: PropTypes.bool,
  profileFirstName: PropTypes.string,
  profileLastName: PropTypes.string,
  profilePictureSrc: PropTypes.string,
  dispatch: PropTypes.func,
};

ProfileImageOverlay.defaultProps = {
  showProfileImageOverlay: false,
  profileFirstName: '',
  profileLastName: '',
  profilePictureSrc: '',
  dispatch: () => false,
};

const mapStateToProps = ({ appState, profileState }) => {
  const { showProfileImageOverlay } = appState;

  const { profileFirstName, profileLastName, profilePictureSrc } = profileState;

  return {
    showProfileImageOverlay,
    profileFirstName,
    profileLastName,
    profilePictureSrc,
  };
};

export default connect(mapStateToProps)(ProfileImageOverlay);
