import React from 'react';
import IconVideoPlay from '../../assets/icons/IconVideoPlay';
import styled from 'styled-components';
import { useOverlayContext } from '../overlay/OverlayProvider';

const VideoCaption = styled.p({
  fontWeight: 300,
  fontSize: '18px',
  lineHeight: '25px',
  color: '#2183CA',
  margin: 0,
  paddingBottom: '5px',
  cursor: 'pointer',
});

const VideoDescription = styled.p({
  fontWeight: 300,
  fontSize: '16px',
  lineHeight: '23px',
  color: '#00234C',
  margin: 0,
  cursor: 'pointer',
});

const VideoContainer = styled.div({
  flexShrink: 0,
  marginRight: '30px',
  border: '1px solid #D8D8D8',
  position: 'relative',
  width: '215px',
  height: '120px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
  img: {
    height: '100%',
    width: '100%',
  },
  svg: {
    height: '20px',
    width: '20px',
  },
});
const VideoPlayButton = styled.div({
  position: 'absolute',
  left: 0,
  top: 0,
  bottom: 0,
  right: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
});

const VideoSection = styled.div({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginBottom: '50px',
  paddingBottom: '30px',
});

const VideoInfo = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});
const VideoItem = styled.div({
  display: 'flex',
  paddingBottom: '30px',
});

interface VideoSectionDownloadsProps {
  videoData: any;
  eventId: any;
  downloadModels: any;
}

const VideoSectionDownloads: React.FC<VideoSectionDownloadsProps> = (props) => {
  const { overlayNavigate } = useOverlayContext();
  const { videoData, eventId } = props;

  const handleOnClickVideo = (_eventId, videoIndex) => {
    const path = `/downloads/media-library/${_eventId}/${videoIndex}`;
    overlayNavigate(path);
  };

  return (
    <VideoSection>
      {videoData.map((item: any, i: number) => {
        const thumbnail = item.thumbnails[0] || {};
        const imageUrl = thumbnail.url || '';
        const caption = item.caption || '';
        const description = item.description || '';

        return (
          <div key={String(i)}>
            <VideoItem>
              <VideoContainer onClick={() => handleOnClickVideo(eventId, i + 1)}>
                <img src={imageUrl} alt="" />
                <VideoPlayButton>{IconVideoPlay}</VideoPlayButton>
              </VideoContainer>
              <VideoInfo>
                <VideoCaption onClick={() => handleOnClickVideo(eventId, i + 1)}>{caption}</VideoCaption>
                <VideoDescription onClick={() => handleOnClickVideo(eventId, i + 1)}>{description}</VideoDescription>
              </VideoInfo>
            </VideoItem>
          </div>
        );
      })}
    </VideoSection>
  );
};

export default VideoSectionDownloads;
