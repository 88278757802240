import React, { useEffect, useState } from 'react';
import Section from '../elements/Section';
import PageContentHeader from '../PageContentHeader';
import NewsfeedEventAttendee from '../NewsfeedEventAttendee/NewsfeedEventAttendee';
import NewsfeedStatus from '../NewsfeedStatus/NewsfeedStatus';
import NewsFeedBoxTweets from '../news-tweets/NewsFeedBoxTweets';
import NewsfeedUtil from '../../utils/NewsfeedUtil';
import { updateScrollAnkerEventId } from '../../redux-actions/actionsDownloads';
import Button from '../elements/Button';
import NewsTeaserList from './NewsTeaserList';
import NewsFeedBoxEvents from './NewsFeedBoxEvents';
import NewsfeedEventTeaserWrapper from './NewsfeedEventTeaserWrapper';
import './page-view-news-feed.scss';
import NewsFeedBox from './NewsFeedBox';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';
import { useOverlayContext } from '../overlay/OverlayProvider';

interface PageViewNewsFeedProps {}

const PageViewNewsFeed: React.FC<PageViewNewsFeedProps> = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { overlayNavigate } = useOverlayContext();

  const { eventModels, fellowModels, fellowStatusModels, isNewsfeedLoaded, newsTeaserData } = useAppSelector(
    ({ newsfeedState }) => newsfeedState
  );

  const [numVisibleEvents, setNumVisibleEvents] = useState(3);

  useEffect(() => {
    NewsfeedUtil.loadNewsfeed();
  }, []);

  const handleOnClickBoxButton = (path: string) => {
    overlayNavigate(path);
  };

  const handleClickDetails = (e, path) => {
    e.preventDefault();
    overlayNavigate(path);
  };

  const handleClickShowDocuments = (path: any, eventId: any) => {
    dispatch(updateScrollAnkerEventId(eventId));
    navigate(path);
  };

  const handleOnToggleAttend = () => {
    NewsfeedUtil.loadNewsfeed();
  };

  const handleShowMoreEvents = () => {
    setNumVisibleEvents(numVisibleEvents + 2);
  };

  let classNameGrid = 'bt-page-view-news__grid';
  if (isNewsfeedLoaded) {
    classNameGrid += ' bt-page-view-news__grid--show';
  }

  return (
    <div className="bt-page-view-news-feed">
      <PageContentHeader title="News feed" />
      <Section paddingTop="M">
        <div className={classNameGrid}>
          {newsTeaserData.length > 0 && (
            <div className="bt-page-view-news__grid-col--full">
              <div className="bt-page-view-news__grid-col-item">
                <NewsTeaserList data={newsTeaserData} />
              </div>
            </div>
          )}
          <div className="bt-page-view-news__grid-col">
            <div className="bt-page-view-news__grid-col-item">
              <div className="bt-page-view-news__events">
                <NewsFeedBoxEvents>
                  {eventModels.slice(0, numVisibleEvents).map((eventModel) => {
                    return (
                      <NewsfeedEventTeaserWrapper
                        key={eventModel.id}
                        event={eventModel.event}
                        onToggleAttend={handleOnToggleAttend}
                        onClickShowDocuments={() => handleClickShowDocuments('/downloads', eventModel.id)}
                      />
                    );
                  })}
                  {numVisibleEvents < 5 && eventModels.length > 3 && (
                    <div className="bt-page-view-news__button_container">
                      <Button layout="secondary" path="#" label="Load more" onClick={handleShowMoreEvents} />
                    </div>
                  )}
                </NewsFeedBoxEvents>
              </div>
              <div className="bt-page-view-news__events-mobile">
                <NewsFeedBoxEvents>
                  {eventModels.slice(0, numVisibleEvents).map((eventModel) => {
                    return (
                      <NewsfeedEventTeaserWrapper
                        key={eventModel.id}
                        event={eventModel}
                        onToggleAttend={handleOnToggleAttend}
                        onClickShowDocuments={() => handleClickShowDocuments('/downloads', eventModel.id)}
                      />
                    );
                  })}
                </NewsFeedBoxEvents>
              </div>
            </div>
            <div className="bt-page-view-news__grid-col-item">
              <NewsFeedBox
                title="Fellows"
                showButton
                buttonLabel="See all fellows"
                buttonPath="/fellows"
                onClickButton={(path) => handleOnClickBoxButton(path)}
              >
                {fellowModels.slice(0, 3).map((attendee) => {
                  return (
                    <div key={attendee.id}>
                      <NewsfeedEventAttendee
                        imageUrl={attendee.profilePictureUrl}
                        name={attendee.fullName}
                        title={attendee.jobDegree}
                        location={attendee.locationCombined}
                        initials={attendee.initials}
                        onClickDetails={(e: any) => handleClickDetails(e, `/news/fellow/${attendee.id}`)}
                      />
                    </div>
                  );
                })}
              </NewsFeedBox>
            </div>
          </div>

          <div className="bt-page-view-news__grid-col">
            <div className="bt-page-view-news__grid-col-item">
              <NewsFeedBoxTweets />
            </div>
            <div className="bt-page-view-news__grid-col-item">
              <NewsFeedBox
                title="Status news"
                showButton
                buttonLabel="Show all messages"
                buttonPath="/news/status-messages"
                onClickButton={(path) => handleOnClickBoxButton(path)}
              >
                {fellowStatusModels.slice(0, 3).map((attendee) => {
                  return (
                    <div key={attendee.id}>
                      <NewsfeedStatus
                        status={attendee.status.message}
                        imageUrl={attendee.profilePictureUrl}
                        name={attendee.fullName}
                        title={attendee.jobDegree}
                        location={attendee.locationCombined}
                        initials={attendee.initials}
                        onClickDetails={(e: any) => handleClickDetails(e, `/news/fellow/${attendee.id}`)}
                      />
                    </div>
                  );
                })}
              </NewsFeedBox>
            </div>
          </div>
        </div>
      </Section>
    </div>
  );
};

export default PageViewNewsFeed;
