/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './input-auto-suggest.scss';
import searchIcon from '../../../assets/icons/IconSearch16';

class InputFellowSearch extends Component {
    render() {
        const { type, placeholder, label, search } = this.props;

        return (
            <div className={`bt-input__interior ${search && 'search'}`}>
                <label>{label}</label>
                <input
                    type={type}
                    placeholder={placeholder}
                    value={this.props.searchFilter}
                    onChange={this.props.handleChangeInput}
                />
                {search && (
                    <span className='bt-input__search-icon'>{searchIcon}</span>
                )}
            </div>
        );
    }
}

InputFellowSearch.propTypes = {
    type: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    search: PropTypes.bool
};

InputFellowSearch.defaultProps = {
    type: 'text',
    placeholder: '',
    label: '',
    value: '',
    error: false,
    unselected: false,
    errorMessage: 'Error',
    startValue: '',
    search: false
};

export default InputFellowSearch;
