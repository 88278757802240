/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import _orderBy from 'lodash/orderBy';

export function useDownloads() {
  const downloads = useSelector(({ downloadsState }) => downloadsState.downloads) || [];

  /**
   * upcoming and past downloads
   */
  const upcomingDownloadsSorted = useMemo(() => {
    const upcomingDownloads = downloads.filter(
      (download) => download.status === 'current' || download.status === 'upcoming'
    );
    return _orderBy(upcomingDownloads, ['startDateUtc'], ['asc']);
  }, [downloads]);

  const pastDownloadsSorted = useMemo(() => {
    const pastDownloads = downloads.filter((download) => download.status === 'past');
    return _orderBy(pastDownloads, ['startDateUtc'], ['desc']);
  }, [downloads]);

  return {
    downloads,
    upcomingDownloadsSorted,
    pastDownloadsSorted,
  };
}
