import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../elements/Button';
import ToggleBox from '../elements/ToggleBox';
import Wrapper from '../elements/Wrapper';
import IconArrowDown20 from '../../assets/icons/IconArrowDown20';
import IconArrowUp20 from '../../assets/icons/IconArrowUp20';
import IconDownloads20 from '../../assets/icons/IconDownloads20';
import './downloads-group.scss';
import ButtonDownload from '../elements/ButtonDownload';
import DownloadsUtil from '../../utils/DownloadsUtil';
import VideoSectionDownloads from './VideoSectionDownloads';
import ImageSectionDownloads from '../ImageSectionDownloads';

class DownloadsGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      extendedDownloadGroupId: 0,
    };
  }

  handleOnClickHeader() {
    if (this.props.type === 'presentations') {
      this.handleOnToggleDownloadGroup(1);
    } else if (this.props.type === 'videos') {
      this.handleOnToggleDownloadGroup(2);
    } else if (this.props.type === 'pictures') {
      this.handleOnToggleDownloadGroup(3);
    }
  }

  handleOnClickDownloadAll(e) {
    const { type, eventId, speakerSearch } = this.props;
    e.stopPropagation();
    window.location.href = `${apiUrl}/downloads/download?type=${type}&eventId=${eventId}${
      speakerSearch && '&speaker=' + speakerSearch
    }`;
  }

  handleOnToggleDownloadGroup(downloadGroupId) {
    const { extendedDownloadGroupId } = this.state;
    let newExtendedDownloadGroupId = 0;
    if (extendedDownloadGroupId !== downloadGroupId) {
      newExtendedDownloadGroupId = downloadGroupId;
    }
    this.setState({
      extendedDownloadGroupId: newExtendedDownloadGroupId,
    });
  }

  render() {
    const { title, icon, pictures, agendas, presentations, videos, type, eventId } = this.props;

    const { extendedDownloadGroupId } = this.state;
    let isExtended = false;
    if (extendedDownloadGroupId === 1 && type === 'presentations') {
      isExtended = true;
    } else if (extendedDownloadGroupId === 2 && type === 'videos') {
      isExtended = true;
    } else if (extendedDownloadGroupId === 3 && type === 'pictures') {
      isExtended = true;
    }

    return (
      <div className="bt-downloads-group">
        <div className="bt-downloads-group__header" onClick={() => this.handleOnClickHeader()}>
          <div className="bt-downloads-group__header-group">
            <div className="bt-downloads-group__header-icon">{icon}</div>
            <div className="bt-downloads-group__header-title">
              <span>{title}</span>
            </div>
          </div>
          <div className="bt-downloads-group__header-group">
            <div className="bt-downloads-group__header-btn-toggle">
              <Button
                layout="akkordeon-toggle"
                path="#"
                // label={isExtended ? 'Hide all' : 'Show all'}
                iconRight={isExtended ? IconArrowUp20 : IconArrowDown20}
              />
            </div>
          </div>
        </div>
        <ToggleBox show={isExtended}>
          <div className="bt-downloads-group__content">
            {(agendas ? agendas.length >= 1 : null) && (
              <div className="bt-downloads-group__content-section">
                <div className="bt-downloads-group__content-section-title">
                  <span>Agenda</span>
                  <div className="bt-downloads-group__header-btn-download">
                    <Button layout="secondary" label="Download all" onClick={(e) => this.handleOnClickDownloadAll(e)} />
                  </div>
                </div>
                <div className="bt-downloads-group__content-section-items">
                  {agendas.map((agenda, index) => (
                    <div key={index}>
                      <Wrapper marginTop20>
                        <ButtonDownload path={agenda.url} label={agenda.title} iconLeft={IconDownloads20} />
                      </Wrapper>
                    </div>
                  ))}
                </div>
              </div>
            )}

            {(presentations ? presentations.length >= 1 : null) && (
              <div className="bt-downloads-group__content-section">
                <>
                  <div className="bt-downloads-group__content-section-title">
                    <span>Presentations</span>
                  </div>
                  <div className="bt-downloads-group__content-section-items">
                    {presentations.map((presentation, index) => (
                      <div key={index}>
                        <Wrapper marginTop20>
                          <ButtonDownload
                            path={presentation.url}
                            label={`${presentation.title} (${presentation.type}, ${DownloadsUtil.formatFileSize(
                              presentation.size
                            )})`}
                            iconLeft={IconDownloads20}
                          />
                        </Wrapper>
                      </div>
                    ))}
                  </div>
                </>
              </div>
            )}
            {(videos ? videos.length >= 1 : null) && (
              <div className="bt-downloads-group__content-section">
                <div className="bt-downloads-group__content-section-flex">
                  <VideoSectionDownloads videoData={videos} eventId={eventId} />
                </div>
              </div>
            )}
            {(pictures ? pictures.length >= 1 : null) && (
              <div className="bt-downloads-group__content-section">
                <div className="bt-downloads-group__content-section-flex">
                  <ImageSectionDownloads imagesData={pictures} />
                  <div className="bt-downloads-group__header-btn-download">
                    <Button layout="secondary" label="Download all" onClick={(e) => this.handleOnClickDownloadAll(e)} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </ToggleBox>
      </div>
    );
  }
}

DownloadsGroup.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.object,
  // isExtended: PropTypes.bool,
  onClickDownloadAll: PropTypes.func,
  onToggle: PropTypes.func,
  pictures: PropTypes.array,
  videos: PropTypes.array,
  agendas: PropTypes.array,
  presentations: PropTypes.array,
  type: PropTypes.string,
  eventId: PropTypes.number,
  speakerSearch: PropTypes.string,
};

DownloadsGroup.defaultProps = {
  title: '',
  icon: null,
  // isExtended: false,
  onClickDownloadAll: () => false,
  onToggle: () => false,
  type: '',
  eventId: null,
  speakerSearch: '',
};

export default DownloadsGroup;
