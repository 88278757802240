/* eslint-disable */
import React from 'react';

const IconExpandImage = (
    <svg width="39" height="41" viewBox="0 0 39 41" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M18.2072 0C28.2627 0 36.4144 8.12349 36.4144 18.1443C36.4144 23.3137 34.2451 27.9782 30.7642 31.283L39 39.4906L37.4854 41L29.1231 32.6674C26.0815 34.9414 22.3022 36.2887 18.2072 36.2887C8.15163 36.2887 0 28.1652 0 18.1443C0 8.12349 8.15163 0 18.2072 0ZM18.2072 2.13463C9.33464 2.13463 2.14202 9.30241 2.14202 18.1443C2.14202 26.9862 9.33464 34.154 18.2072 34.154C27.0797 34.154 34.2723 26.9862 34.2723 18.1443C34.2723 9.30241 27.0797 2.13463 18.2072 2.13463Z"
            fill="white"
        />
        <line x1="11" y1="18.3333" x2="26" y2="18.3333" stroke="white" strokeWidth="2" strokeLinecap="round" />
        <line x1="18.6665" y1="26" x2="18.6665" y2="11" stroke="white" strokeWidth="2" strokeLinecap="round" />
    </svg>
);

export default IconExpandImage;
