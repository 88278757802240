import React, { useEffect } from 'react';
import LayerViewHeader from '../overlay/LayerViewHeader';
import LayerViewBody from '../overlay/LayerViewBody';
import FellowHeader from '../FellowHeader';
import FellowProfile from '../FellowProfile';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useParams } from 'react-router-dom';
import { fetchFellowData, removeFellowData } from '../../app/slices/fellowsSlice';
import { useFellow } from '../fellows/useFellow';
import './layer-view-fellow.scss';

const LayerViewFellow: React.FC = () => {
  const dispatch = useAppDispatch();
  const { sFellowId } = useParams();
  const { fellowData, fellowDataStatus } = useAppSelector(({ fellowsState }) => fellowsState);
  const fellow = useFellow(fellowData);

  useEffect(() => {
    if (sFellowId) {
      dispatch(
        fetchFellowData({
          id: sFellowId,
        })
      );
    }

    return () => {
      dispatch(removeFellowData());
    };
  }, [dispatch, sFellowId]);

  let className = 'bt-layer-view-fellow';
  if (fellowDataStatus === 'loaded') {
    className += ' bt-layer-view-fellow--visible';
  }

  return (
    <div className={className}>
      <LayerViewHeader>
        <FellowHeader
          fullName={fellow.fullName}
          profession={fellow.professionDegree}
          location={fellow.location}
          imgSrc={fellow.profilePicture?.sizes?.medium}
          initials={fellow.initials}
        />
      </LayerViewHeader>
      <LayerViewBody>
        <FellowProfile
          status={fellow.status?.message}
          employers={fellow.employers}
          cv={fellow.cv}
          links={fellow.links}
          emailArray={fellow.contact?.email}
          linkedinArray={fellow.contact?.linkedin}
          twitterArray={fellow.contact?.twitter}
          phoneArray={fellow.contact?.phone}
          otherArray={fellow.contact?.other}
        />
      </LayerViewBody>
    </div>
  );
};

export default LayerViewFellow;
