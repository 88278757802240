import { useRef } from 'react';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import type { RootState, AppDispatch } from './store';

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useComponentWillMount = (callback: () => void) => {
  const willMount = useRef(true);
  if (willMount.current) {
    callback();
  }
  willMount.current = false;
};
