/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import Cropper from 'react-easy-crop';
import Slider from '@mui/material/Slider';
import AppUtil from '../../utils/AppUtil';
import Button from '../elements/Button';
import ButtonPrimary from '../elements/ButtonPrimary';
import ButtonPrimaryWide from '../elements/ButtonPrimaryWide';
import CopyTextM from '../elements/CopyTextM';
import HeadlineThree from '../elements/HeadlineThree';
import ImageThumb from '../elements/ImageThumb';
import IconImage14 from '../../assets/icons/IconImage14';
import IconImage20 from '../../assets/icons/IconImage20';
import IconUpload40 from '../../assets/icons/IconUpload40';
import IconWarning40 from '../../assets/icons/IconWarning40';
import IconReload14 from '../../assets/icons/IconReload14';
import getCroppedImg from './ProfileImageDialog.utils';
import './profile-image-dialog.scss';

class ProfileImageDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDragOver: false,
      imageSrc: '',
      crop: { x: 0, y: 0 },
      zoom: 1,
      minZoom: 1,
      maxZoom: 4,
      aspect: 1 / 1,
      croppedAreaPixels: null,
      isFileTypeError: false,
    };
  }

  handleOnClickSelectImage() {
    if (this.refFileInput) {
      this.refFileInput.click();
    }
  }

  readFile(file) {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.addEventListener('load', () => resolve(reader.result), false);
      reader.readAsDataURL(file);
    });
  }

  async invalidateFile(file) {
    if (!file) {
      return false;
    }

    if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
      this.setState({
        isFileTypeError: true,
      });
      return false;
    }

    const imageSrc = await this.readFile(file);

    this.setState({
      imageSrc,
      isFileTypeError: false,
    });

    return true;
  }

  async handleOnDropFile(files) {
    await this.invalidateFile(files[0]);
    this.setState({ isDragOver: false });
  }

  async handleChangeFileInput(e) {
    await this.invalidateFile(e.target.files[0]);
  }

  handleOnCropChange(_crop) {
    const { crop } = this.state;
    if (JSON.stringify(_crop) !== JSON.stringify(crop)) {
      this.setState({ crop: _crop });
    }
  }

  handleOnCropComplete(_croppedAreaPixels) {
    const { croppedAreaPixels } = this.state;
    if (JSON.stringify(_croppedAreaPixels) !== JSON.stringify(croppedAreaPixels)) {
      this.setState({ croppedAreaPixels: _croppedAreaPixels });
    }
  }

  handleOnZoomChange(zoom) {
    this.setState({ zoom });
  }

  async handleClickUpload() {
    const { imageSrc, croppedAreaPixels } = this.state;
    const { onClickUpload } = this.props;
    const croppedImgBlob = await getCroppedImg(imageSrc, croppedAreaPixels);
    onClickUpload(croppedImgBlob);
  }

  handleOnClickChangePhoto() {
    this.setState({
      imageSrc: '',
      crop: { x: 0, y: 0 },
      zoom: 1,
      croppedAreaPixels: null,
    });
  }

  render() {
    const { isDragOver, imageSrc, crop, zoom, minZoom, maxZoom, aspect, isFileTypeError } = this.state;

    const { firstName, lastName, imgSrc, onClickCancel } = this.props;

    let classNameDropzone = 'profile-image-dialog__dropzone';
    if (isDragOver) {
      classNameDropzone += ' profile-image-dialog__dropzone--drag-over';
    }

    return (
      <div className="profile-image-dialog">
        <div className="profile-image-dialog__heading">
          <HeadlineThree text="Change Profile Photo" />
        </div>
        <div className="profile-image-dialog__content">
          {!imageSrc && (
            <div className="profile-image-dialog__file">
              <Dropzone
                multiple={false}
                noClick
                onDragEnter={() => this.setState({ isDragOver: true })}
                onDragLeave={() => this.setState({ isDragOver: false })}
                onDrop={(files) => this.handleOnDropFile(files)}
              >
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps()} className={classNameDropzone}>
                      <input {...getInputProps()} />
                      <div className="profile-image-dialog__dropzone-icon">
                        {isFileTypeError ? IconWarning40 : IconUpload40}
                      </div>
                      <div className="profile-image-dialog__dropzone-text">
                        <CopyTextM
                          text={
                            isFileTypeError
                              ? 'Invalid format. Try again<br />with jpg or png.'
                              : 'Drag and drop your<br />image here'
                          }
                        />
                      </div>
                    </div>
                  </section>
                )}
              </Dropzone>
              <div className="profile-image-dialog__or">
                <CopyTextM text={isFileTypeError ? '&nbsp;' : 'or'} />
              </div>
              <div className="profile-image-dialog__image-thumb-mobile">
                {isFileTypeError ? (
                  <div className="profile-image-dialog__file-error-mobile">
                    <div className="profile-image-dialog__file-error-mobile-icon">{IconWarning40}</div>
                    <div className="profile-image-dialog__file-error-mobile-text">
                      <CopyTextM text={'Invalid format. Try again<br />with jpg or png.'} />
                    </div>
                  </div>
                ) : (
                  <ImageThumb size="XL" profileInitials={AppUtil.getInitials(firstName, lastName)} imageSrc={imgSrc} />
                )}
              </div>
              <div className="profile-image-dialog__select">
                <ButtonPrimaryWide label="Select a photo" onClick={() => this.handleOnClickSelectImage()} />
                <input
                  type="file"
                  className="profile-image-dialog__select-file-input"
                  onChange={(e) => this.handleChangeFileInput(e)}
                  ref={(node) => {
                    this.refFileInput = node;
                  }}
                />
              </div>
            </div>
          )}
          {imageSrc && (
            <div className="profile-image-dialog__edit">
              <div className="profile-image-dialog__edit-cropper">
                <Cropper
                  classes={{
                    cropAreaClassName: 'profile-image-dialog__cropper-crop',
                  }}
                  image={imageSrc}
                  cropSize={{ width: 200, height: 200 }}
                  showGrid={false}
                  cropShape="round"
                  restrictPosition={false}
                  crop={crop}
                  zoom={zoom}
                  minZoom={minZoom}
                  maxZoom={maxZoom}
                  aspect={aspect}
                  onCropChange={(crop) => this.handleOnCropChange(crop)}
                  onCropComplete={(croppedArea, croppedAreaPixels) => this.handleOnCropComplete(croppedAreaPixels)}
                  onZoomChange={(zoom) => this.handleOnZoomChange(zoom)}
                />
              </div>
              <div className="profile-image-dialog__edit-zoom">
                <div className="profile-image-dialog__edit-zoom-icon">{IconImage14}</div>
                <div className="profile-image-dialog__edit-zoom-slider">
                  <Slider
                    value={zoom}
                    min={minZoom}
                    max={maxZoom}
                    step={0.1}
                    aria-labelledby="Zoom"
                    onChange={(e, zoom) => this.handleOnZoomChange(zoom)}
                  />
                </div>
                <div className="profile-image-dialog__edit-zoom-icon">{IconImage20}</div>
              </div>
              <div className="profile-image-dialog__change">
                <button onClick={() => this.handleOnClickChangePhoto()}>
                  <span className="profile-image-dialog__change-icon">{IconReload14}</span>
                  <span className="profile-image-dialog__change-label">Change photo</span>
                </button>
              </div>
            </div>
          )}
        </div>
        <div className="profile-image-dialog__options">
          <div className="profile-image-dialog__option">
            <ButtonPrimary label="Upload" disabled={!imageSrc} onClick={() => this.handleClickUpload()} />
          </div>
          <div className="profile-image-dialog__option">
            <Button layout="secondary" label="Cancel" onClick={onClickCancel} />
          </div>
        </div>
      </div>
    );
  }
}

ProfileImageDialog.propTypes = {
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  imgSrc: PropTypes.string,
  onClickUpload: PropTypes.func,
  onClickCancel: PropTypes.func,
};

ProfileImageDialog.defaultProps = {
  firstName: '',
  lastName: '',
  imgSrc: '',
  onClickUpload: () => {},
  onClickCancel: () => {},
};

export default ProfileImageDialog;
