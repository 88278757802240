import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconSearch20 from '../../assets/icons/IconSearch20';
import './SearchInput.scss';

class SearchInput extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
    };
  }

  handleOnChange(e) {
    this.setState({
      value: e.target.value,
    });
  }

  handleOnSubmit(e) {
    e.preventDefault();
    const { onSubmit } = this.props;
    onSubmit(this.state.value);
  }

  render() {
    const { value } = this.state;

    return (
      <div className="bt-search-input">
        <div className="bt-search-input-icon" onClick={(e) => this.handleOnSubmit(e)}>
          {IconSearch20}
        </div>
        <div className="bt-search-input-form">
          <form onSubmit={(e) => this.handleOnSubmit(e)}>
            <input type="text" value={value} placeholder="Search all" onChange={(e) => this.handleOnChange(e)} />
          </form>
        </div>
      </div>
    );
  }
}

SearchInput.propTypes = {
  onSubmit: PropTypes.func,
};

SearchInput.defaultProps = {
  onSubmit: () => false,
};

export default SearchInput;
