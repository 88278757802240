/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { InView } from 'react-intersection-observer';
import { Block, Grid, GridItem, Group } from '@webfox-sc/core';
import ContentSection from '../../atoms/ContentSection';
import CopyTextM from '../elements/CopyTextM';
import PageContentHeader from '../PageContentHeader';
import Select from '../elements/Select';
import SelectSimple from '../elements/SelectSimple';
import { fetchEvents } from '../../redux-actions/actionsEvents';
import { useEvents } from './useEvents';
import HeadlineTwo from '../elements/HeadlineTwo';
import EventTeaserWrapper from './EventTeaserWrapper';
import './Events.scss';

const numEventsInitial = 12;
const numEventsStep = 12;

const Events = () => {
  const dispatch = useDispatch();

  const {
    events,
    categoryOptions,
    yearOptions,
    locationOptions,
    keywordOptions,
    selectedCategory,
    setSelectedCategory,
    selectedYear,
    setSelectedYear,
    selectedLocation,
    setSelectedLocation,
    selectedKeyword,
    setSelectedKeyword,
    upcomingEventsSorted,
    pastEventsSorted,
  } = useEvents();

  const [numEvents, setNumEvents] = useState(numEventsInitial);

  useEffect(() => {
    dispatch(fetchEvents());
  }, []);

  const handleOnToggleAttend = () => {
    dispatch(fetchEvents());
  };

  /**
   * progressive (lazy) list rendering
   */
  const handleOnChangeInView = (isInView) => {
    if (isInView && numEvents < events.length) {
      setNumEvents(numEvents + numEventsStep);
    }
  };

  return (
    <>
      <PageContentHeader title="Events" />
      <div className="bt-events__filter-desktop">
        <Block paddingTop="30px">
          <Grid spacing="20px">
            <GridItem cols="3">
              <Select
                options={categoryOptions}
                value={selectedCategory}
                label="Event"
                onChange={(value) => {
                  setSelectedCategory(value);
                  setNumEvents(numEventsInitial);
                }}
              />
            </GridItem>
            <GridItem cols="3">
              <Select
                options={yearOptions}
                value={selectedYear}
                label="Year"
                onChange={(value) => {
                  setSelectedYear(value);
                  setNumEvents(numEventsInitial);
                }}
              />
            </GridItem>
            <GridItem cols="3">
              <Select
                options={locationOptions}
                value={selectedLocation}
                label="Location"
                onChange={(value) => {
                  setSelectedLocation(value);
                  setNumEvents(numEventsInitial);
                }}
              />
            </GridItem>
            <GridItem cols="3">
              <Select
                options={keywordOptions}
                value={selectedKeyword}
                label="Keyword"
                onChange={(value) => {
                  setSelectedKeyword(value);
                  setNumEvents(numEventsInitial);
                }}
              />
            </GridItem>
          </Grid>
        </Block>
      </div>
      <div className="bt-events__select-mobile">
        <Group vertical spacing="15px" padding="30px 22px 22px">
          <Block justify="left">
            <SelectSimple
              options={categoryOptions}
              value={selectedCategory}
              onChange={(value) => {
                setSelectedCategory(value);
                setNumEvents(numEventsInitial);
              }}
            />
          </Block>
          <Block justify="left">
            <SelectSimple
              options={yearOptions}
              value={selectedYear}
              onChange={(value) => {
                setSelectedYear(value);
                setNumEvents(numEventsInitial);
              }}
            />
          </Block>
          <Block justify="left">
            <SelectSimple
              options={locationOptions}
              value={selectedLocation}
              onChange={(value) => {
                setSelectedLocation(value);
                setNumEvents(numEventsInitial);
              }}
            />
          </Block>
          <Block justify="left">
            <SelectSimple
              options={keywordOptions}
              value={selectedKeyword}
              onChange={(value) => {
                setSelectedKeyword(value);
                setNumEvents(numEventsInitial);
              }}
            />
          </Block>
        </Group>
      </div>
      <ContentSection paddingTop="30px">
        <HeadlineTwo text="Upcoming Events" />
      </ContentSection>
      <div className="bt-events">
        <Block paddingTop="30px">
          {upcomingEventsSorted.length > 0 && (
            <Group vertical spacing="15px">
              {upcomingEventsSorted.slice(0, numEvents).map((event, index) => {
                return (
                  <div key={event.id}>
                    {index % numEventsStep === 0 && (
                      <InView as="div" triggerOnce onChange={(isInView) => handleOnChangeInView(isInView)} />
                    )}
                    <EventTeaserWrapper event={event} onToggleAttend={handleOnToggleAttend} />
                  </div>
                );
              })}
              <InView as="div" onChange={handleOnChangeInView} />
            </Group>
          )}
          {events.length > 0 && !upcomingEventsSorted.length && <CopyTextM text="No results found" />}
        </Block>
      </div>
      <ContentSection paddingTop="30px">
        <HeadlineTwo text="Past Events" />
      </ContentSection>
      <div className="bt-events">
        <Block paddingTop="30px">
          {pastEventsSorted.length > 0 && (
            <Group vertical spacing="15px">
              {pastEventsSorted.slice(0, numEvents).map((event, index) => {
                return (
                  <div key={event.id}>
                    {index % numEventsStep === 0 && (
                      <InView as="div" triggerOnce onChange={(isInView) => handleOnChangeInView(isInView)} />
                    )}
                    <EventTeaserWrapper event={event} onToggleAttend={handleOnToggleAttend} />
                  </div>
                );
              })}
              <InView as="div" onChange={handleOnChangeInView} />
            </Group>
          )}
          {events.length > 0 && !pastEventsSorted.length && <CopyTextM text="No results found" />}
        </Block>
      </div>
    </>
  );
};

Events.propTypes = {};
Events.defaultProps = {};

export default Events;
