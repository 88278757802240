/* eslint-disable */
import React from 'react';

const IconMetaLinkedIn20 = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
    >
        <g fill="currentColor" fillRule="evenodd">
            <path d="M20,20h0V12.66c0-3.58-.77-6.35-5-6.35a4.36,4.36,0,0,0-3.92,2.16h-.06V6.65h-4V20h4.15V13.39c0-1.74.33-3.43,2.48-3.43s2.16,2,2.16,3.54V20Zm0,0" />
            <rect x="0.33" y="6.65" width="4.15" height="13.35" />
            <path d="M2.4,0a2.42,2.42,0,0,0,0,4.83A2.42,2.42,0,0,0,2.4,0Zm0,0" />
        </g>
    </svg>
);

export default IconMetaLinkedIn20;
