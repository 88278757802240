import React from 'react';
import PageContentHeader from '../PageContentHeader';
import CopyTextM from '../elements/CopyTextM';
import ContactElement from '../../molecules/ContactElement/ContactElement';
import imgSrc from '../../assets/images/Mitarbeiterportrait3.jpg';
import './page-view-confirm-signup.scss';

const PageViewRegisterFeedback: React.FC = () => {
  return (
    <div className="bt-confirm-signup">
      <PageContentHeader title="Your registration" />
      <div className="bt-confirm-signup__cta">
        <CopyTextM text="Your BeEP registration: We have just sent you a verification email. Please use the verification link inside to confirm your email address and we will process your registration within the next 48 hours. Once your registration is successful, you will receive a confirmation email." />
        <br />
        <br />
        <CopyTextM heavy text="If you have any questions, please contact:" />
      </div>
      {/* TODO - show contact data via api */}
      <div className="bt-confirm-signup__content">
        <ContactElement
          name="Ines WALDMANN"
          location="BIOTRONIK HQ, Berlin"
          tel="+49 151 6890 1421"
          email="inez.waldmann@biotronik.com"
          imgSrc={imgSrc}
        />
      </div>
    </div>
  );
};

export default PageViewRegisterFeedback;
