/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import _orderBy from 'lodash/orderBy';
import { useEventUtils } from './useEventUtils';

const eventCategoryLabels = {
  other: 'Congress',
  ifp: 'IFP',
  beep: 'BeEP',
  ehra: 'EHRA',
};

export function useEvents() {
  const { getEventLocationFormatted, getEventStartYear, getEventEndYear } = useEventUtils();
  const events = useSelector(({ eventsState }) => eventsState.events) || [];

  const { baseData } = useSelector(({ appState }) => appState);
  const keywords = baseData?.downloads?.keywords || [];

  const [selectedCategory, setSelectedCategory] = useState('');
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedLocation, setSelectedLocation] = useState('');
  const [selectedKeyword, setSelectedKeyword] = useState('');

  /**
   * create select options
   */
  const categoryOptions = useMemo(() => {
    const options = [];
    const categories = [];
    events.forEach((event) => {
      const { category } = event;
      if (!categories.includes(category)) {
        categories.push(category);
        options.push({
          value: category,
          label: eventCategoryLabels[category],
        });
      }
    });
    return [
      {
        value: '',
        label: 'All events',
      },
      ..._orderBy(options, ['label'], ['asc']),
    ];
  }, [events]);

  const yearOptions = useMemo(() => {
    const options = [];
    const years = [];
    events.forEach((event) => {
      const startDateYear = getEventStartYear(event);
      const endDateYear = getEventEndYear(event);
      if (!years.includes(startDateYear)) {
        years.push(startDateYear);
        options.push({
          value: startDateYear,
          label: startDateYear,
        });
      }
      if (!years.includes(endDateYear)) {
        years.push(endDateYear);
        options.push({
          value: endDateYear,
          label: endDateYear,
        });
      }
    });
    return [
      {
        value: '',
        label: 'All years',
      },
      ..._orderBy(options, ['label'], ['asc']),
    ];
  }, [events]);

  const locationOptions = useMemo(() => {
    const options = [];
    const locations = [];
    events.forEach((event) => {
      const locationFormatted = getEventLocationFormatted(event);
      if (!locations.includes(locationFormatted)) {
        locations.push(locationFormatted);
        options.push({
          value: locationFormatted,
          label: locationFormatted,
        });
      }
    });
    return [
      {
        value: '',
        label: 'All locations',
      },
      ..._orderBy(options, ['label'], ['asc']),
    ];
  }, [events]);

  const keywordOptions = useMemo(() => {
    const options = (keywords || []).map((keyword) => ({
      value: keyword,
      label: keyword,
    }));
    return [
      {
        value: '',
        label: 'All keywords',
      },
      ..._orderBy(options, ['label'], ['asc']),
    ];
  }, [keywords]);

  /**
   * filtered events
   */
  const eventsFiltered = useMemo(() => {
    let result = events || [];
    if (selectedCategory) {
      result = result.filter((event) => event.category === selectedCategory);
    }
    if (selectedYear) {
      result = result.filter(
        (event) =>
          String(getEventStartYear(event)) === String(selectedYear) ||
          String(getEventEndYear(event)) === String(selectedYear)
      );
    }
    if (selectedLocation) {
      result = result.filter((event) => getEventLocationFormatted(event) === selectedLocation);
    }
    if (selectedKeyword) {
      result = result.filter((event) => {
        const titleParts = (event.title || '').replace(/[.,-]/g, ' ').split(' ');
        return titleParts.includes(selectedKeyword);
      });
    }
    return result;
  }, [events, selectedCategory, selectedYear, selectedLocation, selectedKeyword]);

  /**
   * upcoming and past events
   */
  const upcomingEventsSorted = useMemo(() => {
    const upcomingEvents = eventsFiltered.filter((event) => event.status === 'current' || event.status === 'upcoming');
    return _orderBy(upcomingEvents, ['startDateUtc'], ['asc']);
  }, [eventsFiltered]);

  const pastEventsSorted = useMemo(() => {
    const pastEvents = eventsFiltered.filter((event) => event.status === 'past');
    return _orderBy(pastEvents, ['startDateUtc'], ['desc']);
  }, [eventsFiltered]);

  return {
    events,
    categoryOptions,
    yearOptions,
    locationOptions,
    keywordOptions,
    selectedCategory,
    setSelectedCategory,
    selectedYear,
    setSelectedYear,
    selectedLocation,
    setSelectedLocation,
    selectedKeyword,
    setSelectedKeyword,
    upcomingEventsSorted,
    pastEventsSorted,
  };
}
