/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './fallback-event-icon.scss';
import LabelM from '../elements/LabelM';
import LabelS from '../elements/LabelS/LabelS';
import { Block } from '@webfox-sc/core';

class FallbackEventIcon extends Component {
  render() {
    const { category, large, isDigital } = this.props;

    return (
      <div
        className={`bt-fallback-event-icon__label bt-fallback-event-icon__label--${category} ${
          large ? 'bt-fallback-event-icon__label--large' : ''
        }`}
      >
        <div className="bt-fallback-event-icon__category">
          <LabelM text={category === 'OTHER' ? 'Congress' : category} />
          {large ? (
            <Block paddingTop={large && '10px'}>{isDigital && <LabelS text="digital" />}</Block>
          ) : (
            isDigital && <LabelS text="digital" />
          )}
        </div>
      </div>
    );
  }
}

FallbackEventIcon.propTypes = {
  category: PropTypes.string,
  large: PropTypes.bool,
};

FallbackEventIcon.defaultProps = {
  category: '',
  large: false,
};

export default FallbackEventIcon;
