import React from 'react';
import ProfileUtil from '../../utils/ProfileUtil';
import ProfileDelete from './ProfileDelete';
import './layer-view-profile-delete.scss';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { logout } from '../../app/slices/authSlice';
import { useNavigate } from 'react-router-dom';

const LayerViewProfileDelete: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { profileFirstName, profileLastName, profileTitle } = useAppSelector(({ profileState }) => profileState);

  const handleDeleteProfile = async () => {
    try {
      ProfileUtil.deleteProfile();
      dispatch(logout());
      navigate('/');
    } catch (error) {
      //
    }
  };

  return (
    <div className="bt-layer-view-profile-delete">
      <ProfileDelete
        onDelete={() => handleDeleteProfile()}
        profileFirstName={profileFirstName}
        profileLastName={profileLastName}
        profileTitle={profileTitle}
      />
    </div>
  );
};

export default LayerViewProfileDelete;
