import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import _orderBy from 'lodash/orderBy';

export function useDownloadsToolbar() {
  const { baseData } = useSelector(({ appState }) => appState);
  const { downloads } = baseData || {};
  const { year, event, category, location, keywords } = downloads || {};

  const yearOptions = useMemo(() => {
    const options = Object.entries(year || {}).map(([value, label]) => ({
      value,
      label: String(label),
    }));
    return _orderBy(options, ['label'], ['desc']);
  }, [year]);

  const eventOptions = useMemo(() => {
    return Object.entries(event || {}).map(([value, label]) => ({
      value,
      label,
    }));
  }, [event]);

  const categoryOptions = useMemo(() => {
    const options = Object.entries(category || {})
      .map(([value, label]) => ({
        value,
        label,
      }))
      .filter(({ value }) => value !== 'all');
    return [
      {
        value: 'all',
        label: 'All',
      },
      ..._orderBy(options, ['label'], ['asc']),
    ];
  }, [category]);

  const locationOptions = useMemo(() => {
    const options = Object.entries(location || {})
      .map(([value, label]) => ({
        value,
        label,
      }))
      .filter(({ value }) => value !== 'all');
    return [
      {
        value: 'all',
        label: 'All',
      },
      ..._orderBy(options, ['label'], ['asc']),
    ];
  }, [location]);

  const keywordOptions = useMemo(() => {
    const options = (keywords || []).map((keyword) => ({
      value: keyword,
      label: keyword,
    }));
    return [
      {
        value: 'all',
        label: 'All',
      },
      ..._orderBy(options, ['label'], ['asc']),
    ];
  }, [keywords]);

  return {
    yearOptions,
    eventOptions,
    categoryOptions,
    locationOptions,
    keywordOptions,
  };
}
