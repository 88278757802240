/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './label-m.scss';

class LabelM extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { text, high } = this.props;
        const classNameHigh = high ? 'bt-copy-text-l--high' : '';

        return <span className={`bt-label-m ${classNameHigh}`}>{text}</span>;
    }
}

LabelM.propTypes = {
    text: PropTypes.string,
    high: PropTypes.bool
};

LabelM.defaultProps = {
    text: '',
    high: false
};

export default LabelM;
