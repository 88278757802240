/* eslint-disable */
import React from 'react';

const IconEmail20 = (
    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.118 3.6c.454 0 .822.37.822.827v11.267a.825.825 0 0 1-.822.828H.822A.824.824 0 0 1 0 15.694V4.427C0 3.97.368 3.6.822 3.6h18.296zM.822 4.25a.176.176 0 0 0-.168.126l9.316 5.863 9.316-5.863a.176.176 0 0 0-.168-.126H.822zm-.177.867v9.572l6.701-5.356-6.7-4.216zm18.65 10.573v-.173l-7.282-5.798-1.871 1.178a.32.32 0 0 1-.344 0L7.927 9.72.645 15.517v.173c0 .098.08.178.177.178h18.296c.098 0 .177-.08.177-.178zm0-1.001V5.156L12.576 9.35l6.719 5.339z" fill="currentColor" fillRule="evenodd" />
    </svg>
);

export default IconEmail20;
