import axios from 'axios';
import { handleErrorResponse } from './actionsApp';
import { REDUX_ACTIONS } from '../global/constants';
import { apiUrl } from '../app/api';

export function updateProfileData(profileData) {
  return {
    type: REDUX_ACTIONS.UPDATE_PROFILE_DATA,
    profileData,
  };
}

export function updateProfileImageData(imageData) {
  return {
    type: REDUX_ACTIONS.UPDATE_PROFILE_IMAGE_DATA,
    imageData,
  };
}

export function uploadProfileCVFile(file) {
  return async (dispatch, getState) => {
    const { authState } = getState();

    dispatch({
      type: REDUX_ACTIONS.REQUEST_UPLOAD_CV_FILE,
    });

    const formData = new FormData();
    formData.append('cv', file);

    return axios({
      method: 'post',
      url: `${apiUrl}/profile/cv`,
      data: formData,
      headers: {
        Authorization: `Bearer ${authState.userToken}`,
      },
    })
      .then((res) => {
        return dispatch({
          type: REDUX_ACTIONS.RECIEVE_UPLOAD_CV_FILE,
          profileCv: res.data.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: REDUX_ACTIONS.REQUEST_UPLOAD_CV_FILE_ERROR,
        });
        dispatch(handleErrorResponse(error.response));
      });
  };
}

export function deleteProfileCv() {
  return {
    type: REDUX_ACTIONS.DELETE_PROFILE_CV,
  };
}

export function addProfileLinkData(linkData) {
  return {
    type: REDUX_ACTIONS.ADD_PROFILE_LINK_DATA,
    linkData,
  };
}

export function deleteProfileLink(linkId) {
  return {
    type: REDUX_ACTIONS.DELETE_PROFILE_LINK,
    linkId,
  };
}

export function removeProfileImage() {
  return async (dispatch, getState) => {
    const { authState } = getState();

    dispatch({
      type: REDUX_ACTIONS.REQUEST_DELETE_PROFILE_IMAGE,
    });

    return axios({
      method: 'delete',
      url: `${apiUrl}/profile/picture`,
      headers: {
        Authorization: `Bearer ${authState.userToken}`,
      },
    })
      .then(() => {
        return dispatch({
          type: REDUX_ACTIONS.RECIEVE_DELETE_PROFILE_IMAGE,
        });
      })
      .catch((error) => {
        dispatch({
          type: REDUX_ACTIONS.REQUEST_DELETE_PROFILE_IMAGE_ERROR,
        });
        dispatch(handleErrorResponse(error.response));
      });
  };
}
