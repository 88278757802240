/* eslint-disable */
import React from 'react';

const IconContact28 = (
    <svg width="28" height="28" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="evenodd">
            <path d="M4.625 11.76h6.007a.463.463 0 0 0 0-.926H4.625a.463.463 0 0 0 0 .927zM16.639 13.613H4.625a.463.463 0 0 0 0 .926H16.64a.463.463 0 0 0 0-.926zM16.639 16.392H4.625a.463.463 0 0 0 0 .926H16.64a.463.463 0 0 0 0-.926z" />
            <path d="M24.99 1.142L9.213 1.11a2.747 2.747 0 0 0-2.74 2.747V6.23l-3.728.007A2.747 2.747 0 0 0 .005 8.982v9.793a2.747 2.747 0 0 0 2.74 2.745H5.55v4.63a.466.466 0 0 0 .462.464.46.46 0 0 0 .342-.152l4.483-4.942 7.682-.034c1.511 0 2.741-1.23 2.741-2.745v-.053l2.43 2.68a.46.46 0 0 0 .804-.312v-4.63h.494a2.747 2.747 0 0 0 2.74-2.746V3.888a2.746 2.746 0 0 0-2.738-2.746zm-4.655 17.599c0 1.003-.813 1.819-1.817 1.819l-7.889.033a.457.457 0 0 0-.339.152l-3.816 4.207v-3.896a.463.463 0 0 0-.463-.463H2.746a1.82 1.82 0 0 1-1.817-1.82V8.981c0-1.003.813-1.82 1.817-1.82l4.19-.008 11.582-.025a1.82 1.82 0 0 1 1.817 1.821v9.79zm6.469-5.063a1.82 1.82 0 0 1-1.817 1.821h-.956a.463.463 0 0 0-.462.463v3.896l-2.31-2.547V8.95a2.747 2.747 0 0 0-2.74-2.748l-11.121.024v-2.37a1.82 1.82 0 0 1 1.814-1.822l15.775.033h.002c1.001 0 1.815.816 1.815 1.82v9.79z" />
        </g>
    </svg>
);

export default IconContact28;
