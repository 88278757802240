/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import LabelM from '../../components/elements/LabelM';
import CopyTextM from '../../components/elements/CopyTextM/CopyTextM';
import './ScheduleTimeItem.scss';

const ScheduleTimeItem = ({ time, title, subtitle }) => (
  <div className="bt-event-schedule__time-item">
    <div className="bt-event-schedule__time">
      <CopyTextM text={time} />
    </div>
    <div className="bt-event-schedule__time-details">
      <LabelM high={true} text={title} />
      <br />
      <CopyTextM grey={true} text={subtitle} />
    </div>
  </div>
);

ScheduleTimeItem.propTypes = {
  time: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

ScheduleTimeItem.defaultProps = {
  time: '',
  title: '',
  subtitle: '',
};

export default ScheduleTimeItem;
