/* eslint-disable */
import React from 'react';

const IconCV40 = (
    <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="evenodd">
            <path d="M36.159 33.4a3.332 3.332 0 0 1 2.641 3.267c0 1.838-1.483 3.333-3.306 3.333H12.808c-2.515 0-4.562-2.053-4.562-4.577V7.333H4.258C2.792 7.333 1.6 5.838 1.6 4c0-2.504 2.028-4 3.988-4h26.901a3.68 3.68 0 0 1 3.67 3.681v29.72zM4 5.958c.084.028.17.043.257.043 1.234 0 1.33-1.458 1.33-1.905l.002-.047L5.588 4c0-.368.297-.667.664-.667h1.802c-.43-1.182-1.509-2-2.467-2-.204 0-.41.025-.61.072C3.89 1.661 2.929 2.591 2.929 4c0 .916.485 1.76 1.072 1.957zM6.478 6h1.768V4.667H6.883c-.06.504-.198.954-.405 1.333zm3.097 29.423a3.242 3.242 0 0 0 3.152 3.242l.01-.006c.02-.011.038-.024.057-.036.03-.019.058-.037.086-.057.02-.013.038-.028.056-.041l.08-.061.053-.045a2.52 2.52 0 0 0 .075-.066l.05-.048a2.49 2.49 0 0 0 .49-.67 2.631 2.631 0 0 0 .108-.247l.023-.062.03-.098.02-.063c.009-.033.017-.067.024-.101l.015-.064c.007-.035.013-.07.018-.106a2.53 2.53 0 0 0 .022-.178l.006-.054c.003-.058.006-.115.006-.173 0-.769-.34-1.488-.934-1.972a.668.668 0 0 1 .418-1.184h21.39V3.681a2.347 2.347 0 0 0-2.34-2.348H8.355A4.71 4.71 0 0 1 9.55 3.96c.016.151.025.303.025.457v31.006zm25.92 3.244c1.089 0 1.976-.898 1.976-2 0-1.103-.887-2-1.977-2h-20.66a3.88 3.88 0 0 1 .44 2.106c-.002.03-.007.061-.01.092a3.98 3.98 0 0 1-.02.187l-.02.108c-.01.056-.019.112-.031.167-.008.038-.018.075-.027.112a3.947 3.947 0 0 1-.042.157l-.036.112a4.215 4.215 0 0 1-.16.405 3.958 3.958 0 0 1-.124.249l-.054.096c-.03.05-.06.099-.093.147-.013.02-.024.042-.038.062h20.875z" />
            <path d="M26.715 15.893H13.72a.676.676 0 0 1 0-1.354h12.995a.676.676 0 0 1 0 1.354zM30.868 21.581H13.72a.676.676 0 0 1 0-1.354h17.148a.676.676 0 0 1 0 1.354zM30.868 10.205H13.72a.676.676 0 0 1 0-1.354h17.148a.676.676 0 0 1 0 1.354z" />
        </g>
    </svg>
);

export default IconCV40;
