import React from 'react';
import PropTypes from 'prop-types';
import { Group } from '@webfox-sc/core';
import PageNavigationItem from './PageNavigationItem';
import IconNewsFeed25 from '../../assets/icons/IconNewsFeed25';
import IconEvents25 from '../../assets/icons/IconEvents25';
import IconFellows25 from '../../assets/icons/IconFellows25';
import IconDownloads25 from '../../assets/icons/IconDownloads25';
import IconQA25 from '../../assets/icons/IconQA25';

const PageNavigation = ({ currentPageId, onClickItem }) => {
  const handleOnClickItem = (e, path) => {
    e.preventDefault();
    onClickItem(path);
  };

  return (
    <Group vertical vSpacing="16px">
      <PageNavigationItem
        label="News feed"
        icon={IconNewsFeed25}
        path="/news"
        isActive={currentPageId === 'news'}
        onClick={(e) => handleOnClickItem(e, 'news')}
      />
      <PageNavigationItem
        label="Events"
        icon={IconEvents25}
        path="/events"
        isActive={currentPageId === 'events'}
        onClick={(e) => handleOnClickItem(e, 'events')}
      />
      <PageNavigationItem
        label="Fellows"
        icon={IconFellows25}
        path="/fellows"
        isActive={currentPageId === 'fellows'}
        onClick={(e) => handleOnClickItem(e, 'fellows')}
      />
      <PageNavigationItem
        label="Media Library"
        icon={IconDownloads25}
        path="/downloads"
        isActive={currentPageId === 'downloads'}
        onClick={(e) => handleOnClickItem(e, 'downloads')}
      />
      <PageNavigationItem
        label="Contact us"
        icon={IconQA25}
        path="/contacts"
        isActive={currentPageId === 'contacts'}
        onClick={(e) => handleOnClickItem(e, 'contacts')}
      />
    </Group>
  );
};

PageNavigation.propTypes = {
  currentPageId: PropTypes.string,
  onClickItem: PropTypes.func,
};

PageNavigation.defaultProps = {
  currentPageId: '',
  onClickItem: () => false,
};

export default PageNavigation;
