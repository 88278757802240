/* eslint-disable */
import React from 'react';

const IconLinkedIn20 = (
    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="nonzero">
            <path d="M18.272 0C19.224 0 20 .753 20 1.684V18.32c0 .93-.776 1.68-1.729 1.68H1.73C.777 20 0 19.249 0 18.32V1.684C0 .753.777 0 1.73 0h16.542zm0 .583H1.73c-.635 0-1.147.496-1.147 1.1V18.32c0 .603.512 1.097 1.147 1.097H18.27c.635 0 1.146-.494 1.146-1.097V1.684c0-.606-.51-1.101-1.145-1.101z" />
            <path d="M3.183 16.9a.294.294 0 0 1-.294-.294V7.759c0-.162.132-.294.294-.294h2.96c.163 0 .295.132.295.294v8.847a.294.294 0 0 1-.294.294h-2.96zm2.667-.588V8.053H3.477v8.259H5.85zM4.661 6.846h-.02c-1.123 0-1.93-.784-1.93-1.824 0-1.057.82-1.825 1.97-1.825 1.14 0 1.926.756 1.949 1.824 0 1.047-.813 1.825-1.969 1.825zm0-.588c.837 0 1.381-.52 1.38-1.23-.015-.74-.538-1.243-1.36-1.243-.834 0-1.382.514-1.382 1.237 0 .711.54 1.236 1.343 1.236h.02zM11.037 8.168c.58-.588 1.316-.912 2.37-.912 2.261 0 3.704 1.555 3.704 4.277v5.073a.294.294 0 0 1-.294.294h-2.955a.294.294 0 0 1-.294-.294v-4.732c0-1.137-.395-1.712-1.212-1.712-.595 0-1.025.353-1.245.906-.05.116-.074.32-.074.597v4.941a.294.294 0 0 1-.294.294H7.781a.294.294 0 0 1-.294-.295v-.159a1600.392 1600.392 0 0 0 .015-4.299c.005-2.436 0-4.037-.015-4.375a.294.294 0 0 1 .294-.307h2.962c.163 0 .294.132.294.294v.409zm-.588-.115H8.082c.01.644.012 2.079.008 4.096a1624.333 1624.333 0 0 1-.014 4.163h2.373v-4.647c0-.352.033-.62.119-.82.298-.755.929-1.271 1.788-1.271 1.199 0 1.8.875 1.8 2.3v4.438h2.367v-4.779c0-2.407-1.19-3.689-3.115-3.689-1.16 0-1.83.445-2.381 1.273a.294.294 0 0 1-.285.22h-.017a.294.294 0 0 1-.276-.397v-.887z" />
        </g>
    </svg>
);

export default IconLinkedIn20;
