/* eslint-disable */
import React from 'react';

const IconMetaAppBiotronik20 = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
    >
        <g fill="currentColor" fillRule="evenodd">
            <path d="M14.88,1.3c2.59,0,4.73,2.25,5.12,5.19H8.4V4.36A2.85,2.85,0,0,1,11,1.3ZM12,11.89a1.22,1.22,0,0,1,.57.11.43.43,0,0,1,.18.39.46.46,0,0,1-.18.4,1,1,0,0,1-.56.12h-.26v-1Zm-3.2,0a.71.71,0,0,1,.48.13.54.54,0,0,1,.15.42.52.52,0,0,1-.18.43.93.93,0,0,1-.55.14H8.47V11.89ZM17,11.23H14.11v.67h1V15h.8V11.9h1Zm-4.87,0H11V15h.8V13.56h.43l.9,1.46H14L12.9,13.37a1.34,1.34,0,0,0,.52-.41,1.13,1.13,0,0,0-.19-1.45,1.79,1.79,0,0,0-1.06-.28Zm-3.21,0H7.67V15h.8V13.67h.35a1.64,1.64,0,0,0,1.08-.32,1.18,1.18,0,0,0,.38-.94,1.08,1.08,0,0,0-.36-.88,1.5,1.5,0,0,0-1-.3Zm-4.24,0H3.75l1.18,1.83L3.67,15h.86l.88-1.43L6.29,15H7.2L5.92,13.11l1.19-1.88H6.25l-.8,1.36ZM20,7.7h0v5.5a5.42,5.42,0,0,1-5.33,5.49H5.33A5.42,5.42,0,0,1,0,13.21,5.42,5.42,0,0,1,5.33,7.72Z" />
        </g>
    </svg>
);

export default IconMetaAppBiotronik20;
