import axios from 'axios';
import { handleErrorResponse } from './actionsApp';
import { REDUX_ACTIONS } from '../global/constants';
import { apiUrl } from '../app/api';

export function fetchEvents() {
  return async (dispatch, getState) => {
    const { authState } = getState();
    const { userToken } = authState;

    dispatch({
      type: REDUX_ACTIONS.REQUEST_EVENTS,
    });

    return axios({
      method: 'get',
      url: `${apiUrl}/events`,
      headers: {
        Authorization: `Bearer ${userToken}`,
      },
    })
      .then((res) => {
        const events = res.data.data;
        dispatch({
          type: REDUX_ACTIONS.RECIEVE_EVENTS,
          events,
        });
        return events;
      })
      .catch((error) => {
        dispatch({
          type: REDUX_ACTIONS.REQUEST_EVENTS_ERROR,
        });
        dispatch(handleErrorResponse(error.response));
      });
  };
}

export function updateEventModels(eventModels) {
  return {
    type: REDUX_ACTIONS.UPDATE_EVENT_MODELS,
    eventModels,
  };
}

export function updateActiveEvent(activeEvent, activeEventModel) {
  return {
    type: REDUX_ACTIONS.UPDATE_ACTIVE_EVENT,
    activeEventModel,
    activeEvent,
  };
}

export function resetActiveEvent() {
  return {
    type: REDUX_ACTIONS.RESET_ACTIVE_EVENT,
  };
}
