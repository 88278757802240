/* eslint-disable */
import React from 'react';

const IconInfo28 = (
    <svg width="28" height="28" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="evenodd">
            <path d="M12.413 9.137c.051.102.103.205.154.282.05.076.128.153.179.23.077.077.153.154.256.23.077.052.179.128.307.154a1.574 1.574 0 0 0 .665.128c.103 0 .23 0 .333-.026.103-.025.205-.05.282-.102h.025a1.656 1.656 0 0 0 .537-.384l.026-.026c.077-.076.128-.153.18-.23a.8.8 0 0 0 .127-.256c0-.025 0-.025.026-.051.051-.102.077-.23.102-.333.026-.102.026-.23.026-.333 0-.102 0-.23-.026-.332a2.377 2.377 0 0 0-.102-.333 1.452 1.452 0 0 0-.307-.46l-.051-.052c-.077-.077-.154-.128-.256-.205-.103-.05-.18-.102-.282-.153a1.443 1.443 0 0 0-.307-.103c-.128-.025-.23-.025-.333-.025-.102 0-.23 0-.332.025a1.443 1.443 0 0 0-.307.103h-.026a2.792 2.792 0 0 0-.282.153c-.102.077-.179.128-.256.205-.076.077-.153.154-.204.23-.051.077-.103.18-.154.282-.051.102-.077.205-.102.307-.026.128-.026.23-.026.333 0 .102 0 .23.026.333.025.204.077.307.102.41M15.97 18.505h-.588v-6.271a.66.66 0 0 0-.665-.665h-2.688a.66.66 0 0 0-.665.665v1.382a.66.66 0 0 0 .665.666h.538v4.248h-.538a.66.66 0 0 0-.665.666v1.382a.66.66 0 0 0 .665.665h3.942a.66.66 0 0 0 .665-.665v-1.382a.683.683 0 0 0-.665-.691" />
            <path d="M4.1 4.102c5.458-5.46 14.34-5.46 19.798-.003 5.46 5.46 5.46 14.343 0 19.802-5.458 5.458-14.34 5.456-19.799-.003-5.458-5.458-5.458-14.338 0-19.796zm19.09 19.09c5.07-5.07 5.069-13.316 0-18.385C18.122-.26 9.878-.26 4.807 4.81c-5.068 5.068-5.068 13.312 0 18.38 5.069 5.069 13.315 5.07 18.383.003z" />
        </g>
    </svg>
);

export default IconInfo28;
