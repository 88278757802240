/* eslint-disable */

class StoreModel {
    constructor() {
        this._store = null;
    }

    set store(store) {
        this._store = store;
    }

    get store() {
        return this._store;
    }

    get state() {
        return this._store.getState();
    }

    get appState() {
        const { appState } = this.state;
        return appState;
    }

    dispatch(action) {
        this._store.dispatch(action);
    }

}

const storeModel = new StoreModel();
export default storeModel;
