import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ProfileData from './ProfileData';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';

const LayerViewProfileData: React.FC = () => {
  const navigate = useNavigate();
  const { bgSegment1: pageId, sContentId: contentId } = useParams();

  const { userEmail } = useAppSelector(({ authState }) => authState);

  const {
    profileFirstName,
    profileLastName,
    profileProfession,
    profileDegree,
    profileCity,
    profileCountry,
    profileStatusMessage,
    profileEmployers,
    profileContact,
  } = useAppSelector(({ profileState }) => profileState);

  const handleOnClickEdit = () => {
    navigate(`/${pageId}/${contentId}/profile/edit`);
  };

  let fullName = profileFirstName;
  if (profileLastName) {
    fullName += ' ' + profileLastName;
  }

  let profession = '';
  profession =
    profileProfession && profileDegree ? profileProfession + ', ' + profileDegree : profession || profileDegree;

  let location = '';
  location = profileCity && profileCountry ? profileCity + ', ' + profileCountry : profileCity || profileCountry;

  return (
    <ProfileData
      fullName={fullName}
      profession={profession}
      location={location}
      statusText={profileStatusMessage}
      employers={profileEmployers}
      contact={profileContact}
      contactPhone="0033-6798 762531"
      email={userEmail}
      editPath={`/${pageId}/${contentId}/profile/edit`}
      onClickEdit={() => handleOnClickEdit()}
    />
  );
};

LayerViewProfileData.propTypes = {
  match: PropTypes.object,
  profileFirstName: PropTypes.string,
  profileLastName: PropTypes.string,
  profileProfession: PropTypes.string,
  profileDegree: PropTypes.string,
  profileCity: PropTypes.string,
  profileCountry: PropTypes.string,
  profileStatusMessage: PropTypes.string,
  profileEmployers: PropTypes.array,
  profileContact: PropTypes.array,
  userEmail: PropTypes.string,
};

LayerViewProfileData.defaultProps = {
  match: {},
  profileFirstName: '',
  profileLastName: '',
  profileProfession: '',
  profileDegree: '',
  profileCity: '',
  profileCountry: '',
  profileStatusMessage: '',
  profileEmployers: [],
  profileContact: [],
  userEmail: '',
};

const mapStateToProps = ({ profileState, authState }) => {
  const {
    profileFirstName,
    profileLastName,
    profileProfession,
    profileDegree,
    profileCity,
    profileCountry,
    profileStatusMessage,
    profileEmployers,
    profileContact,
  } = profileState;
  const { userEmail } = authState;
  return {
    profileFirstName,
    profileLastName,
    profileProfession,
    profileDegree,
    profileCity,
    profileCountry,
    profileStatusMessage,
    profileEmployers,
    profileContact,
    userEmail,
  };
};

export default connect(mapStateToProps)(LayerViewProfileData);
