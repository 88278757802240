import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactPlayer from 'react-player';

const StyledCanvas = styled.div({
  position: 'relative',
  paddingTop: '100%',
  overflow: 'hidden',
});

const StyledWrapper = styled.div({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
});

const VideoCanvas = (props) => {
  const { url, aspectRatio, playing, onEventAction } = props;

  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [secondsPlayed, setSecondsPlayed] = useState(0);

  const handleOnPlay = () => {
    if (typeof onEventAction === 'function') {
      onEventAction('play');
    }
  };

  const handleOnReady = () => {
    //
  };

  const handleOnProgress = (state) => {
    const { played, playedSeconds } = state;
    setSecondsPlayed(playedSeconds);

    let newProgress = 0;
    if (played >= 0.25 && progress < 25) {
      newProgress = 25;
    }
    if (played >= 0.5 && progress < 50) {
      newProgress = 50;
    }
    if (played >= 0.75 && progress < 75) {
      newProgress = 75;
    }
    if (played >= 0.9 && progress < 90) {
      newProgress = 90;
    }
    if (played === 1 && progress < 100) {
      newProgress = 100;
    }

    if (newProgress > 0 && newProgress !== progress) {
      setProgress(newProgress);
      if (typeof onEventAction === 'function') {
        if (newProgress === 100) {
          onEventAction('complete');
        } else {
          onEventAction(`${newProgress}%`);
        }
      }
    }
  };
  const handleOnPause = () => {
    if (typeof onEventAction === 'function' && secondsPlayed + 1 < duration) {
      // pause event is also fired on complete, which we don't want to happen
      onEventAction('pause');
    }
  };

  const [w, h] = aspectRatio.split(':');
  const aspectRatioWidth = parseFloat(w) || 0;
  const aspectRatioHeight = parseFloat(h) || 0;

  const canvasStyle = {
    paddingTop: `${(100 / aspectRatioWidth) * aspectRatioHeight}%`,
  };

  return (
    <StyledCanvas style={canvasStyle}>
      {url && (
        <StyledWrapper>
          <ReactPlayer
            url={url}
            width="100%"
            height="100%"
            controls
            playing={playing}
            onReady={handleOnReady}
            onPlay={handleOnPlay}
            onProgress={handleOnProgress}
            onPause={handleOnPause}
            onDuration={setDuration}
          />
        </StyledWrapper>
      )}
    </StyledCanvas>
  );
};

VideoCanvas.propTypes = {
  url: PropTypes.string,
  aspectRatio: PropTypes.string,
  playing: PropTypes.bool,
  onEventAction: PropTypes.func,
};

VideoCanvas.defaultProps = {
  url: '',
  aspectRatio: '4:3',
  playing: false,
  onEventAction: undefined,
};

export default VideoCanvas;
