/* eslint-disable */
import React from 'react';

const IconMetaWebsiteBiotronik14 = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
    >
        <g fill="currentColor" fillRule="evenodd">
            <path d="M10.3,3.47a2,2,0,0,0-1.48.64,2.29,2.29,0,0,0-.61,1.55,2.13,2.13,0,0,0,.59,1.5,2.06,2.06,0,0,0,1.45.66,2.12,2.12,0,0,0,2.09-2.17,2.17,2.17,0,0,0-.57-1.52A2.08,2.08,0,0,0,10.3,3.47Zm1.33,2.2c-.05.82-.49,1.64-1.37,1.64h0a1.19,1.19,0,0,1-1-.57A2.3,2.3,0,0,1,9,5.67a2.06,2.06,0,0,1,.24-1.11,1.2,1.2,0,0,1,1-.61h0a1.23,1.23,0,0,1,1.09.58A1.92,1.92,0,0,1,11.63,5.67ZM2.44,7.76h2a1.55,1.55,0,0,0,1.13-.38,1,1,0,0,0,.29-.73,1,1,0,0,0-.77-1l-.44-.11.43-.16a.93.93,0,0,0,.59-.83.88.88,0,0,0-.24-.65,1.22,1.22,0,0,0-.87-.3H2.44ZM3.11,4H4.3A.71.71,0,0,1,5,4.72a.69.69,0,0,1-.24.51.77.77,0,0,1-.51.2H3.11Zm0,1.77H4.27a.83.83,0,0,1,.86.74A.71.71,0,0,1,5,7.11a.93.93,0,0,1-.7.27H3.11ZM7,0a7,7,0,1,0,7,7A7,7,0,0,0,7,0ZM6.74,13.44H6.59a6.42,6.42,0,0,1-3.38-1.15A6.11,6.11,0,0,1,1.05,9.51L1,9.31H6.74ZM12.9,9.51a6,6,0,0,1-2.12,2.77,6.29,6.29,0,0,1-3.35,1.15H7.27V9.31H13Zm.27-.78,0,.1H.85l0-.15A6.23,6.23,0,0,1,.57,7,6.43,6.43,0,0,1,3.46,1.58a6.26,6.26,0,0,1,3.11-1h.15V7.73h.57l0-7.17h.16a6.16,6.16,0,0,1,3.12,1A6.43,6.43,0,0,1,13.43,7,6,6,0,0,1,13.17,8.73Z" />
        </g>
    </svg>
);

export default IconMetaWebsiteBiotronik14;
