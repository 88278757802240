import React from 'react';

const IconRegister = (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
    <path
      d="M5 12.71h10v1.34H5Zm0-1.46h10V9.91H5ZM18.13 0v20H1.87V0Zm-5.07 3.73V1.34H6.94v2.39ZM5.6 5.07V1.34H3.21v17.32h13.58V1.34H14.4v3.73ZM5 8.46h10V7.11H5Z"
      fill="currentColor"
    />
  </svg>
);

export default IconRegister;
