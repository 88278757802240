/* eslint-disable */
import React from 'react';

const IconImage20 = (
    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="evenodd">
            <path d="M5.36923077 9.28307692c1.18205128 0 2.14153846-.96 2.14153846-2.14153846C7.51076923 5.96153846 6.55128205 5 5.36923077 5s-2.14153846.96153846-2.14153846 2.14153846c0 1.18153846.95948718 2.14153846 2.14153846 2.14153846zm0-3.51384615c.7574359 0 1.37230769.61692308 1.37230769 1.37230769 0 .75692308-.61487179 1.37230769-1.37230769 1.37230769-.7574359 0-1.37230769-.61538461-1.37230769-1.37230769 0-.75538461.61487179-1.37230769 1.37230769-1.37230769z" />
            <path d="M-.03076923 20H19.9692308V0H-.03076923v20zm.8-19.2H19.1692308v10.2696912l-4.1290667-3.63821752c-.0794667-.06813333-.1813333-.10194386-.2874667-.09989473-.1061333.00409824-.2064.05020351-.2794666.12602105l-3.9162667 4.1202737-1.89813333-1.82115791c-.14986667-.14395088-.38773333-.15163509-.5456-.01792983L1.41723077 15.4h-.648V.8zm0 18.4H19.1692308v-3H.76923077v3zm1.8-3.8l5.74994196-4.8852346 4.13608277 3.9894963c.1568361.1529058.410022.1529058.566858 0 .1568361-.151356.1568361-.3956952 0-.5470512l-1.9302076-1.8622471L14.7783563 8.2l4.3908745 3.8825656V15.4H2.56923077z" />
        </g>
    </svg>
);

export default IconImage20;
