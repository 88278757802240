import React from 'react';
import PropTypes from 'prop-types';
import HeadlineThree from '../elements/HeadlineThree';
import CopyTextL from '../elements/CopyTextL';
import IconCalendar from '../../assets/icons/IconCalendar';
import './newsfeed-event-teaser.scss';
import EventTeaserAttendees from '../events/EventTeaserAttendees';
import EventAttend from '../../molecules/EventAttend/EventAttend';
import IconInfo20 from '../../assets/icons/IconInfo20';
import FallbackEventIcon from '../FallbackEventIcon';
import Button from '../elements/Button';

const NewsfeedEventTeaser = ({
  category,
  title,
  subTitle,
  date,
  time,
  detailsPath,
  onClickShowDocuments,
  onClickDetails,
  eventPath,
  isAttending,
  id,
  attendees,
  canAttend,
  imgSrc,
  onToggleAttend,
  attendanceType,
  isDigital,
  isPast,
}) => (
  <div className="bt-newsfeed-event-teaser">
    <div className="bt-newsfeed-event-teaser__content">
      <div className="bt-newsfeed-event-teaser__image-container">
        <a href={detailsPath} title="Details" onClick={onClickDetails}>
          {imgSrc ? (
            <div className="bt-newsfeed-event-teaser__picture">{imgSrc && <img src={imgSrc} alt="" />}</div>
          ) : (
            <FallbackEventIcon category={category} isDigital={isDigital} />
          )}
        </a>
      </div>
      <div className="bt-newsfeed-event-teaser__info-container">
        <div className="bt-newsfeed-event-teaser__info">
          <a href={detailsPath} title="Details" onClick={onClickDetails}>
            <div className="bt-newsfeed-event-teaser__title">
              <HeadlineThree classes="events-headline" text={title} />
              {IconInfo20}
            </div>
            <div className="bt-newsfeed-event-teaser__sub-title">
              <CopyTextL text={subTitle} />
            </div>
          </a>
          <div className="bt-newsfeed-event-teaser__dateattend">
            <div className="bt-newsfeed-event-teaser__datewrapper">
              <span className="bt-newsfeed-event-teaser__dateicon">{IconCalendar}</span>{' '}
              <span className="bt-newsfeed-event-teaser__date">{date} </span>
              {time && (
                <>
                  {' '}
                  <span
                    className="
                                    bt-newsfeed-event-teaser__datetime"
                  >
                    {time}
                  </span>
                </>
              )}
            </div>

            <div className="bt-newsfeed-event-teaser__attend">
              {attendanceType === 'register' && <EventTeaserAttendees pageId="news" attendees={attendees} id={id} />}
            </div>
          </div>
        </div>
      </div>
    </div>
    {isPast ? (
      <div className="bt-newsfeed-event-teaser__content-lower">
        <div className="bt-newsfeed-event-teaser__show-documents-button">
          <Button layout="default" path="#" label="Show all documents" onClick={onClickShowDocuments} />
        </div>
      </div>
    ) : (
      <div className="bt-newsfeed-event-teaser__content-lower">
        <div className="bt-newsfeed-event-teaser__attend">
          <EventAttend
            id={id}
            isAttending={isAttending}
            canAttend={canAttend}
            eventPath={eventPath}
            attendanceType={attendanceType}
            onToggleAttend={onToggleAttend}
          />
        </div>
      </div>
    )}
  </div>
);

NewsfeedEventTeaser.propTypes = {
  category: PropTypes.string,
  title: PropTypes.string,
  subTitle: PropTypes.string,
  date: PropTypes.string,
  time: PropTypes.string,
  detailsPath: PropTypes.string,
  eventPath: PropTypes.string,
  onClickDetails: PropTypes.func,
  onClickShowDocuments: PropTypes.func,
  attendees: PropTypes.array,
  isAttending: PropTypes.bool,
  id: PropTypes.number,
  canAttend: PropTypes.bool,
  imgSrc: PropTypes.string,
  onToggleAttend: PropTypes.func,
  attendanceType: PropTypes.string,
  isDigital: PropTypes.bool,
  isPast: PropTypes.bool,
};

NewsfeedEventTeaser.defaultProps = {
  category: '',
  title: '',
  subTitle: '',
  date: '',
  time: '',
  detailsPath: '',
  eventPath: '',
  onClickDetails: () => false,
  onClickShowDocuments: () => false,
  attendees: [],
  id: null,
  isAttending: null,
  canAttend: null,
  imgSrc: '',
  onToggleAttend: () => {},
  attendanceType: null,
  isDigital: false,
  isPast: false,
};

export default NewsfeedEventTeaser;
