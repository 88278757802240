export const REDUX_ACTIONS = {
  LOCATION_CHANGED: 'LOCATION_CHANGED',

  SHOW_PROFILE_IMAGE_OVERLAY: 'SHOW_PROFILE_IMAGE_OVERLAY',
  HIDE_PROFILE_IMAGE_OVERLAY: 'HIDE_PROFILE_IMAGE_OVERLAY',
  UPDATE_PROFILE_DATA: 'UPDATE_PROFILE_DATA',
  UPDATE_PROFILE_IMAGE_DATA: 'UPDATE_PROFILE_IMAGE_DATA',
  DELETE_PROFILE_CV: 'DELETE_PROFILE_CV',
  ADD_PROFILE_LINK_DATA: 'ADD_PROFILE_LINK_DATA',
  DELETE_PROFILE_LINK: 'DELETE_PROFILE_LINK',

  UPDATE_EVENT_MODELS: 'UPDATE_EVENT_MODELS',
  UPDATE_ACTIVE_EVENT: 'UPDATE_ACTIVE_EVENT',
  UPDATE_FELLOW_MODELS: 'UPDATE_FELLOW_MODELS',
  RESET_ACTIVE_EVENT: 'RESET_ACTIVE_EVENT',
  UPDATE_EVENT_MODELS_NEWSFEED: 'UPDATE_EVENT_MODELS_NEWSFEED',
  UPDATE_FELLOW_MODELS_NEWSFEED: 'UPDATE_FELLOW_MODELS_NEWSFEED',
  UPDATE_NEWS_TEASER_DATA: 'UPDATE_NEWS_TEASER_DATA',
  UPDATE_DOWNLOADS: 'UPDATE_DOWNLOADS',
  UPDATE_FELLOW_STATUS_MODELS_NEWSFEED: 'UPDATE_FELLOW_STATUS_MODELS_NEWSFEED',
  UPDATE_DOWNLOAD_MODELS_NEWSFEED: 'UPDATE_DOWNLOAD_MODELS_NEWSFEED',
  IS_NEWSFEED_LOADING: 'IS_NEWSFEED_LOADING',
  IS_NEWSFEED_LOADED: 'IS_NEWSFEED_LOADED',
  UPDATE_SEARCH_MODELS: 'UPDATE_SEARCH_MODELS',
  IS_SEARCH_LOADING: 'IS_SEARCH_LOADING',
  IS_SEARCH_LOADED: 'IS_SEARCH_LOADED',
  HIDE_ERROR_DIALOG: 'HIDE_ERROR_DIALOG',
  UPDATE_ACTIVE_DOWNLOAD_VIDEO: 'UPDATE_ACTIVE_DOWNLOAD_VIDEO',
  UPDATE_SCROLL_ANKER_EVENT_ID: 'UPDATE_SCROLL_ANKER_EVENT_ID',
  UPDATE_NEWS: 'UPDATE_NEWS',

  REQUEST_BASE_DATA: 'REQUEST_BASE_DATA',
  REQUEST_BASE_DATA_ERROR: 'REQUEST_BASE_DATA_ERROR',
  RECIEVE_BASE_DATA: 'RECIEVE_BASE_DATA',

  REQUEST_EVENTS: 'REQUEST_EVENTS',
  REQUEST_EVENTS_ERROR: 'REQUEST_EVENTS_ERROR',
  RECIEVE_EVENTS: 'RECIEVE_EVENTS',

  REQUEST_DELETE_PROFILE_IMAGE: 'REQUEST_DELETE_PROFILE_IMAGE',
  REQUEST_DELETE_PROFILE_IMAGE_ERROR: 'REQUEST_DELETE_PROFILE_IMAGE_ERROR',
  RECIEVE_DELETE_PROFILE_IMAGE: 'RECIEVE_DELETE_PROFILE_IMAGE',

  REQUEST_UPLOAD_CV_FILE: 'REQUEST_UPLOAD_CV_FILE',
  REQUEST_UPLOAD_CV_FILE_ERROR: 'REQUEST_UPLOAD_CV_FILE_ERROR',
  RECIEVE_UPLOAD_CV_FILE: 'RECIEVE_UPLOAD_CV_FILE',

  REQUEST_TWEETS: 'REQUEST_TWEETS',
  REQUEST_TWEETS_ERROR: 'REQUEST_TWEETS_ERROR',
  RECIEVE_TWEETS: 'RECIEVE_TWEETS',

  REQUEST_ERROR: 'REQUEST_ERROR',
};
