import React, { useEffect, useState } from 'react';
import PageContentHeader from '../PageContentHeader';
import Button from '../elements/Button';
import CopyTextS from '../elements/CopyTextS';
import FellowTeaser from '../../molecules/FellowTeaser/FellowTeaser';
import FellowTeaserMobile from '../FellowTeaserMobile';
import FellowToolbar from '../FellowToolbar/FellowToolbar';
import FellowsUtil from '../../utils/FellowsUtil';
import AppUtil from '../../utils/AppUtil';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { useInView } from 'react-intersection-observer';
import { UiDiv } from '@webfox-sc/core';
import './page-view-fellows.scss';
import { useOverlayContext } from '../overlay/OverlayProvider';
// import { fetchFellowsData } from '../../app/slices/fellowsSlice';

const numFellowsInitial = 6;
const numFellowsStep = 6;

const PageViewFellows: React.FC = () => {
  const dispatch = useAppDispatch();
  const { overlayNavigate } = useOverlayContext();

  const { fellowModels } = useAppSelector(({ fellowsStateLegacy }) => fellowsStateLegacy);
  const { baseData } = useAppSelector(({ appState }) => appState);
  const availableYears = baseData?.fellows?.year || {};
  const availableCountries = baseData?.fellows?.country || {};

  const [activeGridItemIndex, setActiveGridItemIndex] = useState(0);
  const [searchFilter, setSearchFilter] = useState('');
  const [loadingIndicator, setLoadingIndicator] = useState('loading...');
  const [numFellows, setNumFellows] = useState(numFellowsInitial);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await FellowsUtil.loadFellows();
        setLoadingIndicator('No results found');

        // dispatch(fetchFellowsData());
      } catch (err) {
        //
      }
    };
    fetchData();
  }, [dispatch]);

  const handleOnClickAllStatusMessages = (e) => {
    e.preventDefault();
    overlayNavigate('/fellows/status-messages');
  };

  const handleOnClickGridItem = (gridItemIndex) => {
    setActiveGridItemIndex(gridItemIndex);
  };

  const handleChangeInput = (e) => {
    setSearchFilter(e.target.value);
    setNumFellows(numFellowsInitial);
  };

  const handleChangeSelect = (e, target, ref) => {
    // This API call can be triggered by the 2 different fellow filters (year or country)
    // But in each case we have to pass both as parameters to the call
    // So we use the target value and reference the other value with a ref
    if (target === 'year') {
      FellowsUtil.loadFellowbyParams(e, ref);
    } else if (target === 'country') {
      FellowsUtil.loadFellowbyParams(ref, e);
    }
  };

  const handleOnChangeInView = (isInView: boolean) => {
    if (isInView && numFellows < fellowModels.length) {
      setNumFellows(numFellows + numFellowsStep);
    }
  };

  const { ref } = useInView({
    triggerOnce: true,
    onChange: (isInView) => handleOnChangeInView(isInView),
  });

  // Auto-suggest logic
  // At this point we are taking the entire array of data and filtering it by the value from the input
  // We are converting data and input value to lower case to make search case insensitive
  const fellowModelsFiltered = fellowModels.filter((fellowModel) => {
    return fellowModel.fullName.toLowerCase().indexOf(searchFilter.toLowerCase()) !== -1;
  });

  const fellowModelsDisplayed = fellowModelsFiltered.slice(0, numFellows);

  return (
    <div className="bt-page-view-fellows">
      <PageContentHeader title="Fellows">
        <div className="bt-page-view-fellows__header-button-desktop">
          <Button
            layout="primary"
            path="/fellows/status-messages"
            label="All status messages"
            onClick={(e) => handleOnClickAllStatusMessages(e)}
          />
        </div>
        <div className="bt-page-view-fellows__header-button-mobile">
          <Button
            layout="primary-side"
            path="/fellows/status-messages"
            label="All status messages"
            onClick={(e) => handleOnClickAllStatusMessages(e)}
          />
        </div>
      </PageContentHeader>
      <FellowToolbar
        searchFilter={searchFilter}
        handleChangeInput={handleChangeInput}
        years={AppUtil.formatObjectToArray(availableYears)}
        countries={AppUtil.formatObjectToArray(availableCountries)}
        handleChangeSelect={handleChangeSelect}
      />
      <div className="bt-page-view-fellows__counter">
        <CopyTextS text={`<b>${fellowModelsFiltered.length} results</b> of ${fellowModels.length}`} />
      </div>
      <div className="bt-page-view-fellows__grid">
        {fellowModelsFiltered.length < 1 ? (
          <div>{loadingIndicator}</div>
        ) : (
          fellowModelsDisplayed.map((fellowModel, gridItemIndex) => {
            const activeClassName =
              gridItemIndex === activeGridItemIndex ? 'bt-page-view-fellows__grid-item--active' : '';
            const isActive = gridItemIndex === activeGridItemIndex;

            return (
              <div
                key={fellowModel.id}
                className={`bt-page-view-fellows__grid-item ${activeClassName}`}
                onClick={() => handleOnClickGridItem(gridItemIndex)}
              >
                {gridItemIndex % numFellowsStep === 0 && <UiDiv innerRef={ref} />}
                <div className="bt-page-view-fellows__fellow-teaser-normal">
                  <FellowTeaser
                    id={fellowModel.id}
                    imageSrc={fellowModel.profilePictureUrl}
                    fullName={fellowModel.fullName}
                    profession={fellowModel.profession}
                    location={fellowModel.locationCombined}
                    employers={fellowModel.employers}
                    emailArray={fellowModel.contactEmailArray}
                    linkedinArray={fellowModel.contactLinkedinArray}
                    twitterArray={fellowModel.contactTwitterArray}
                    phoneArray={fellowModel.contactPhoneArray}
                    otherArray={fellowModel.contactOtherArray}
                    statusMessage={fellowModel.statusMessage}
                    statusHidden={fellowModel.statusHidden}
                    profilePath={`/fellows/fellow/${fellowModel.id}`}
                    initials={fellowModel.initials}
                    onClickMore={() => {
                      overlayNavigate(`/fellows/fellow/${fellowModel.id}`);
                    }}
                  />
                </div>
                <div className="bt-page-view-fellows__fellow-teaser-mobile">
                  <FellowTeaserMobile
                    id={fellowModel.id}
                    imageSrc={fellowModel.profilePictureUrl}
                    fullName={fellowModel.fullName}
                    profession={fellowModel.profession}
                    location={fellowModel.locationCombined}
                    employers={fellowModel.employers}
                    statusMessage={fellowModel.statusMessage}
                    statusHidden={fellowModel.statusHidden}
                    profilePath={`/fellows/fellow/${fellowModel.id}`}
                    isActive={isActive}
                    initials={fellowModel.initials}
                    emailArray={fellowModel.contactEmailArray}
                    linkedinArray={fellowModel.contactLinkedinArray}
                    twitterArray={fellowModel.contactTwitterArray}
                    phoneArray={fellowModel.contactPhoneArray}
                    otherArray={fellowModel.contactOtherArray}
                    onClickMore={() => {
                      overlayNavigate(`/fellows/fellow/${fellowModel.id}`);
                    }}
                  />
                </div>
              </div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default PageViewFellows;
