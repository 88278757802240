/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ImageThumb from '../elements/ImageThumb';
import HeadlineTwo from '../elements/HeadlineTwo';
import LabelL from '../elements/LabelL';
import LabelMRegular from '../elements/LabelMRegular';
import './fellow-header.scss';

class FellowHeader extends Component {
    render() {
        const { fullName, profession, location, imgSrc, initials } = this.props;

        return (
            <div className="bt-fellow-header">
                <div className="bt-fellow-header-inner">
                    <div className="bt-fellow-header__picture">
                        <ImageThumb size="L" sizeMobile="M" profileInitials={initials} imageSrc={imgSrc} />
                    </div>
                    <div className="bt-fellow-header__info">
                        <div className="bt-fellow-header__info-name">
                            <HeadlineTwo text={fullName} />
                        </div>
                        <div className="bt-fellow-header__info-profession">
                            <LabelL text={profession} />
                        </div>
                        <div className="bt-fellow-header__info-location">
                            <LabelMRegular text={location} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

FellowHeader.propTypes = {
    fullName: PropTypes.string,
    profession: PropTypes.string,
    location: PropTypes.string,
    imgSrc: PropTypes.string,
    initials: PropTypes.string
};

FellowHeader.defaultProps = {
    fullName: '',
    profession: '',
    location: '',
    imgSrc: '',
    initials: ''
};

export default FellowHeader;
