import React from 'react';

const IconVideoRight = (
  <svg width="22" height="40" viewBox="0 0 22 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.67445e-06 38.3071L1.6857 40L21.6 20L1.68569 -7.36841e-08L7.40011e-08 1.69295L18.2286 20L1.67445e-06 38.3071Z"
      fill="#2183CA"
    />
  </svg>
);

export default IconVideoRight;
