import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Block, Group, InlineSVG } from '@webfox-sc/core';
import ImageThumb from '../../components/elements/ImageThumb';
import HeadlineThree from '../../components/elements/HeadlineThree';
import LabelL from '../../components/elements/LabelL';
import IconLink from '../../atoms/IconLink';
import IconContact from '../../assets/icons/IconContact';
import IconPhone from '../../assets/icons/IconPhone';
import IconEmail from '../../assets/icons/IconEmail';
import { useResponsive } from '../../hooks/responsive';
import { themeColors } from '../../styles/theme';

const StyledTeaser = styled.div({
  maxWidth: '550px',
  height: '100%',
  background: 'white',
  boxShadow: '0 0 30px 0 rgba(0, 0, 0, 0.08)',
});

const StyledContactsBlock = styled.div((isMobile) => {
  return {
    padding: isMobile ? '30px' : '40px',
    borderTop: '1px solid #EBEBEB',
  };
});

const ContactTeaser = ({
  imageSrc,
  profileInitials,
  title,
  subtitle,
  phoneLabel,
  mobileLabel,
  mobileHref,
  emailLabel,
  emailHref,
}) => {
  const { isMobile } = useResponsive();

  return (
    <StyledTeaser>
      <Block padding={isMobile ? '36px 30px 30px 30px' : '40px'}>
        <Group nowrap alignItems={isMobile ? 'flex-start' : 'center'} spacing={isMobile ? '20px' : '26px'}>
          <Block>
            <ImageThumb size="L" sizeMobile="M" profileInitials={profileInitials} imageSrc={imageSrc} />
          </Block>
          <Block paddingTop={isMobile ? '5px' : ''}>
            <HeadlineThree text={title} />
            <Block paddingTop="5px">
              <LabelL text={subtitle} />
            </Block>
          </Block>
        </Group>
      </Block>
      <StyledContactsBlock>
        <Group nowrap alignItems="center" spacing={isMobile ? '15px' : '26px'}>
          <Block width={isMobile ? 'auto' : '120px'} justify={isMobile ? 'flex-start' : 'flex-end'}>
            <InlineSVG width={isMobile ? '28px' : '42px'} height={isMobile ? '28px' : '42px'} color={themeColors.BLUE}>
              {IconContact}
            </InlineSVG>
          </Block>
          <HeadlineThree text="Contact" />
        </Group>
        <Group vertical alignItems="flex-start" spacing="20px" paddingTop="20px" paddingLeft={isMobile ? '' : '146px'}>
          {phoneLabel && <IconLink label={phoneLabel} icon={IconPhone} disabled />}
          {mobileLabel && <IconLink label={mobileLabel} href={mobileHref} icon={IconPhone} />}
          {emailLabel && <IconLink label={emailLabel} href={emailHref} icon={IconEmail} />}
        </Group>
      </StyledContactsBlock>
    </StyledTeaser>
  );
};

ContactTeaser.propTypes = {
  imageSrc: PropTypes.string,
  profileInitials: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  phoneLabel: PropTypes.string,
  mobileLabel: PropTypes.string,
  mobileHref: PropTypes.string,
  emailLabel: PropTypes.string,
  emailHref: PropTypes.string,
};

ContactTeaser.defaultProps = {
  imageSrc: '',
  profileInitials: '',
  title: '',
  subtitle: '',
  phoneLabel: '',
  mobileLabel: '',
  mobileHref: '',
  emailLabel: '',
  emailHref: '',
};

export default ContactTeaser;
