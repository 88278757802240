/* eslint-disable */
import React from 'react';

const IconNewsFeed27 = (
    <svg width="27" height="27" xmlns="http://www.w3.org/2000/svg">
        <path d="M.81818182 15.2733431v10.9084751h6.00000001l-1e-8-10.9084751h-6zM0 14.4551613h7.63636364V27H0V14.4551613zm10.3636364 11.7266569h6.2727272V15.2733431h-6.2727272v10.9084751zm-.81818184-11.7266569h7.90909084V27H9.54545456V14.4551613zM20.1818182 26.1818182h6V15.2733431h-6v10.9084751zm-.8181818-11.7266569H27V27h-7.6363636V14.4551613zM.81818182.81818182V11.7266569h6.00000001l-1e-8-10.90847508h-6zM0 0h7.63636364v12.5448387H0V0zm10.3636364 11.7266569h6.2727272V.81818182h-6.2727272V11.7266569zM9.54545456 0h7.90909084v12.5448387H9.54545456V0zM20.1818182.81818182V11.7266569h6V.81818182h-6zM19.3636364 0H27v12.5448387h-7.6363636V0z" fill="currentColor" fillRule="nonzero" />
    </svg>
);

export default IconNewsFeed27;
