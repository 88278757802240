/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import Input from '../elements/Input';
import Button from '../elements/Button';
import './forgot-password-form.scss';

const ForgotPasswordForm = (props) => {
  const { onClickToLogin, onChangeEmail, onSubmit } = props;

  return (
    <div className="bt-fortgot-password">
      <div className="bt-fortgot-password__email">
        <Input label="Email *" errorMessage={'Bitte ausfüllen'} onChange={onChangeEmail} />
      </div>
      <div className="bt-fortgot-password__back-button">
        <Button path="/login" label="Back to login" onClick={onClickToLogin} />
      </div>

      <Button layout="primary" path="#" label="Reset Password" onClick={onSubmit} />
    </div>
  );
};

ForgotPasswordForm.propTypes = {
  onClickToLogin: PropTypes.func,
  onChangeEmail: PropTypes.func,
  onSubmit: PropTypes.func,
};

ForgotPasswordForm.defaultProps = {
  onClickToLogin: () => false,
  onChangeEmail: () => false,
  onSubmit: () => false,
};

export default ForgotPasswordForm;
