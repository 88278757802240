import React from 'react';

const IconQA25 = (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 5800 5800">
    <path
      fill="currentColor"
      d="M1200 1500v-100c0-220 180-400 400-400h400c220 0 400 180 400 400 0 206-158 379-364 398-77 7-136 72-136 150v52h-200v-52c0-182 138-332 318-348 103-10 182-97 182-200 0-110-90-200-200-200h-400c-111 0-200 89-200 200v100h-200zm90 1534c15-276 274-338 510-338 572 0 1300-347 1300-998s-728-998-1300-998c-571 0-1300 344-1300 995 0 339 202 579 466 764 2 252 110 443 324 575zm410-834h200v200h-200v-200zm3330 2363c-12 148-73 298-174 426-104 133-250 243-432 306l-172 59 43-177c61-251-65-281-295-281-686 0-1500-432-1500-1198 0-767 814-1198 1500-1198 685 0 1500 428 1500 1195 0 355-192 659-470 868zm-331 303c104-132 134-245 135-407 264-185 466-425 466-764 0-651-729-995-1300-995-572 0-1300 347-1300 998s728 998 1300 998c236 0 495 62 510 338 75-46 139-104 189-168zM770 2563c-278-209-470-513-470-868 0-767 815-1195 1500-1195 686 0 1500 431 1500 1198 0 766-814 1198-1500 1198-230 0-356 30-295 281l43 177-172-59c-315-109-577-392-606-732zm3130 1437V3000h200v1000h-200zm0 200h200v200h-200v-200z"
    />
  </svg>
);

export default IconQA25;
