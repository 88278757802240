import React from 'react';
import PageContentHeader from '../PageContentHeader';
import HeadlineThree from '../elements/HeadlineThree';
import CopyTextM from '../elements/CopyTextM';
import CopyTextXL from '../elements/CopyTextXL';
import aboutUsImg from '../../assets/images/pic_about-us.jpg';
import { useBaseData } from '../../hooks/useBaseData';
import './page-view-about.scss';

const defaultTexts = {
  title: 'About us',
  subtitle: 'Welcome to the International Fellows Program!',
};

const PageViewAbout = () => {
  const { baseData } = useBaseData();
  const { about } = baseData?.texts || {};

  return (
    <div className="bt-about-us">
      <div className="bt-about-us__main-header">
        <PageContentHeader title={about?.title || defaultTexts.title} />
      </div>
      <div className="bt-about-us__outer-container">
        <div className="bt-about-us__upper-container">
          <div className="bt-about-us__paragraph1">
            <div className="bt-about-us__sub-header">
              <HeadlineThree text={about?.subtitle || defaultTexts.subtitle} />
            </div>
            <CopyTextM text={about?.textLeft} />
          </div>
          <div className="bt-about-us__image-container">
            <img src={aboutUsImg} alt="about" />
          </div>
        </div>
        <CopyTextM text={about?.textBottom} />
      </div>

      <div className="bt-about-us__testimonial">
        <div className="bt-about-us__testimonial-inner">
          <CopyTextXL text={about?.testimonialText} />
          <HeadlineThree text={about?.testimonialAuthor} />
        </div>
      </div>
    </div>
  );
};

PageViewAbout.propTypes = {};
PageViewAbout.defaultProps = {};

export default PageViewAbout;
