/* eslint-disable */
import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import './layer-view-header.scss';

class LayerViewHeader extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        return (
            <div className="bt-layer-view-header">
                {this.props.children}
            </div>
        );
    }
}

// LayerViewHeader.propTypes = {
//     //
// };
//
// LayerViewHeader.defaultProps = {
//     //
// };

export default LayerViewHeader;
