/* eslint-disable */
import React from 'react';

const IconHospital28 = (
    <svg width="28" height="28" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="nonzero">
            <path d="M27.567 9.7h-4.735V2.39a.432.432 0 0 0-.433-.431H5.6a.432.432 0 0 0-.433.431V9.7H.433A.432.432 0 0 0 0 10.13V25.61c0 .238.194.431.433.431h27.134c.24 0 .433-.193.433-.431V10.13a.432.432 0 0 0-.433-.43zM.866 25.177V10.562h4.302v14.616H.866zM6.034 2.822h15.932v22.356h-4.303V16.58a.432.432 0 0 0-.433-.432h-6.46a.432.432 0 0 0-.433.432v8.598H6.034V2.822zm5.168 22.356V17.01h5.595v8.167h-5.595zm11.63 0V10.562h4.302v14.616h-4.302z" />
            <path d="M14.591 7.957H13.52V5.615c0-.353-.243-.596-.597-.596h-1.666c-.354 0-.597.243-.597.596v7.338c0 .353.243.595.597.595h1.666c.354 0 .597-.242.597-.595V10.51h1.071v2.442c0 .353.243.595.597.595h1.666c.354 0 .597-.242.597-.595V5.615c0-.353-.243-.596-.597-.596h-1.666c-.354 0-.597.243-.597.596v2.342zm.772-2.168h1.316v6.99h-1.316v-2.564a.462.462 0 0 0-.475-.473h-1.665a.462.462 0 0 0-.475.473v2.564h-1.316v-6.99h1.316v2.464c0 .272.202.474.475.474h1.665a.462.462 0 0 0 .475-.474V5.789z" />
        </g>
    </svg>
);

export default IconHospital28;
