/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import HeadlineTwo from '../elements/HeadlineTwo';
import CopyTextL from '../elements/CopyTextL';
import './event-header.scss';
import CopyTextM from '../elements/CopyTextM';
import IconDownloads20 from '../../assets/icons/IconDownloads20';
import Button from '../elements/Button';
import FallbackEventIcon from '../FallbackEventIcon';

const EventHeader = ({
    title,
    date,
    startDate,
    location,
    imgSrc,
    onClickCalendar,
    agenda,
    category,
    isDigital,
    showCalendarDownload
}) => {
    const isCalendarEntryShown = startDate ? new Date() < new Date(startDate?.date) : false;

    return (
        <div className="bt-event-header">
            <div className="bt-event-header-details">
                {imgSrc ? (
                    <div className="bt-event-header__picture">{imgSrc && <img src={imgSrc} alt="" />}</div>
                ) : (
                    <FallbackEventIcon category={category.toUpperCase()} isDigital={isDigital} large />
                )}
                <div className="bt-event-header__info">
                    <div className="bt-event-header__info-name">
                        <HeadlineTwo text={title} />
                    </div>
                    <div className="bt-event-header__info-profession">
                        <CopyTextL text={date} />
                    </div>
                    <div className="bt-event-header__info-profession">
                        <CopyTextM text={location} />
                    </div>
                </div>
            </div>
            <div className="bt-event-header-buttons">
                {(isCalendarEntryShown || showCalendarDownload) && (
                    <div className="bt-event-teaser__button-calendar">
                        <Button label="To calendar (iCal)" iconLeft={IconDownloads20} onClick={onClickCalendar} />
                    </div>
                )}
                {agenda && (
                    <div className="bt-event-teaser__button-download">
                        <Button layout="secondary" path={agenda} label="Agenda (PDF)" link target="_blank" />
                    </div>
                )}
            </div>
        </div>
    );
};

EventHeader.propTypes = {
    title: PropTypes.string,
    date: PropTypes.string,
    location: PropTypes.string,
    startDate: PropTypes.any,
    imgSrc: PropTypes.string,
    onClickCalendar: PropTypes.func,
    agenda: PropTypes.string,
    category: PropTypes.string,
    isDigital: PropTypes.bool,
    showCalendarDownload: PropTypes.bool
};

EventHeader.defaultProps = {
    title: '',
    date: '',
    location: '',
    imgSrc: '',
    onClickCalendar: () => false,
    agenda: '',
    category: '',
    isDigital: false,
    showCalendarDownload: false
};

export default EventHeader;
