/* eslint-disable */
import { REDUX_ACTIONS } from '../global/constants';

export function updateDownloads(downloads, downloadModels) {
    return {
        type: REDUX_ACTIONS.UPDATE_DOWNLOADS,
        downloads,
        downloadModels
    };
}

export function updateScrollAnkerEventId(eventId) {
    return {
        type: REDUX_ACTIONS.UPDATE_SCROLL_ANKER_EVENT_ID,
        eventId
    };
}
