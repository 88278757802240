import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { TwitterTweetEmbed } from 'react-twitter-embed';
import styled from 'styled-components';
import { Block, InlineSVG } from '@webfox-sc/core';
import IconSpinner from '../../assets/icons/IconSpinner';

const StyledTweetWrapper = styled.div({
  boxSizing: 'border-box',
  width: '100%',
  maxWidth: '100%',

  '& .twitter-tweet': {
    boxSizing: 'border-box',
    display: 'inline-block',
    margin: '0 !important',
    minWidth: '100% !important',
    maxWidth: '100% !important',
    iframe: {
      width: '100% !important',
    },
  },
});

const Tweet = ({ tweetId, index, show, onLoad }) => {
  const [showSpinner, setShowSpinner] = useState(true);

  if (!tweetId) {
    return null;
  }

  const handleOnLoad = () => {
    setShowSpinner(false);
    onLoad(index);
  };

  if (!show) {
    return null;
  }

  return (
    <Block padding="30px" borderTop={index === 0 ? undefined : '1px solid #ebebeb'}>
      {showSpinner && (
        <Block justify="center">
          <InlineSVG width="24px">{IconSpinner}</InlineSVG>
        </Block>
      )}
      <StyledTweetWrapper>
        <TwitterTweetEmbed tweetId={String(tweetId)} onLoad={handleOnLoad} options={{ dnt: true }} />
      </StyledTweetWrapper>
    </Block>
  );
};

Tweet.propTypes = {
  tweetId: PropTypes.any,
  index: PropTypes.number,
  show: PropTypes.bool,
  onLoad: PropTypes.func,
};

Tweet.defaultProps = {
  tweetId: '',
  index: -1,
  show: false,
  onLoad: () => {},
};

export default React.memo(Tweet);
