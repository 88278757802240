import React from 'react';
import Section from '../elements/Section';
import EventAttendee from '../EventAttendee/EventAttendee';
import AppUtil from '../../utils/AppUtil';
import { useAppSelector } from '../../app/hooks';
import { useParams } from 'react-router-dom';
import './layer-view-event-attendees.scss';
import { useOverlayContext } from '../overlay/OverlayProvider';

const LayerViewEventAttendees: React.FC = () => {
  const { overlayNavigate } = useOverlayContext();
  const { bgSegment1 } = useParams();
  const { activeEvent } = useAppSelector(({ eventsState }) => eventsState);
  const eventData = activeEvent as EventData | undefined;

  return (
    <div className="bt-layer-view-event-attendees">
      {(eventData?.attendees || []).map((attendee, i) => {
        return (
          <Section key={attendee.id} paddingTop={i === 0 ? '' : 'M'}>
            <EventAttendee
              imageUrl={attendee.profilePicture?.url || ''}
              initials={AppUtil.getInitials(attendee.firstName, attendee.lastName)}
              title={attendee.profession}
              name={attendee.firstName + ' ' + attendee.lastName}
              location={attendee.location}
              profilePath={`/${bgSegment1}/fellow/${attendee.id}`}
              onClick={(profilePath: string) => overlayNavigate(profilePath)}
            />
          </Section>
        );
      })}
    </div>
  );
};

export default LayerViewEventAttendees;
