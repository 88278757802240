import React from 'react';

const IconEmail = (
  <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M95.5910394 18c2.2681004 0 4.1078853 1.8521389 4.1078853 4.13725v56.3333333c0 2.2865556-1.8397849 4.1386945-4.1078853 4.1386945H4.1078853C1.83835125 82.6092778 0 80.7571389 0 78.4705833V22.13725C0 19.8521389 1.83835125 18 4.1078853 18zM60.0637993 48.5929722l-9.3541219 5.8933334c-.5247312.3343888-1.1953405.3343888-1.7204301 0l-9.3555555-5.8933334L3.22580645 77.5833333v.8663056c0 .49075.39498208.8883333.88207885.8883333h91.4831541c.4874552 0 .8820789-.3975833.8820789-.8883333v-.8663056l-36.409319-28.9903611zM3.22580645 25.5833333v47.8613056l33.50537635-26.78L3.22580645 25.5833333zm93.24731185.1946389L62.8820789 46.7505833l33.5910394 26.6940556V25.7779722zM95.5910394 21.25H4.1078853c-.38637992.0014444-.72652329.25675-.83870967.6276111l46.57956987 29.315 46.5810036-29.315c-.111828-.3708611-.4523297-.6261667-.8387097-.6276111z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default IconEmail;
