import React from 'react';
import MobileMenu from '../MobileMenu';
import './mobile-menu-overlay.scss';

interface MobileMenuOverlayProps {
  show: boolean;
}

const MobileMenuOverlay: React.FC<MobileMenuOverlayProps> = ({ show }) => {
  const cssLayer = show ? '' : 'bt-mobile-menu-overlay--hidden';

  return (
    <div className={`bt-mobile-menu-overlay ${cssLayer}`}>
      <MobileMenu />
    </div>
  );
};

export default MobileMenuOverlay;
