/* eslint-disable */
import { REDUX_ACTIONS } from '../global/constants';

export function updateSearchModels(searchModels) {
    return {
        type: REDUX_ACTIONS.UPDATE_SEARCH_MODELS,
        searchModels
    };
}

export function updateIsSearchLoading(isSearchLoading) {
    return {
        type: REDUX_ACTIONS.IS_SEARCH_LOADING,
        isSearchLoading
    };
}

export function updateIsSearchLoaded() {
    return {
        type: REDUX_ACTIONS.IS_SEARCH_LOADED
    };
}
