/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect } from 'react';
import LayerViewHeader from '../overlay/LayerViewHeader';
import LayerViewBody from '../overlay/LayerViewBody';
import { Outlet, useOutlet, useParams } from 'react-router-dom';
import LayerNavigation from '../LayerNavigation';
import EventsUtil from '../../utils/EventsUtil';
import { useAppSelector } from '../../app/hooks';
import EventHeaderWrapper from './EventHeaderWrapper';
import LayerViewEventDescription from './LayerViewEventDescription';
import LayerViewEventSchedule from './LayerViewEventSchedule';
import LayerViewEventOnlineVenue from './LayerViewEventOnlineVenue';
import LayerViewEventLocation from './LayerViewEventLocation';
import LayerViewEventEvaluation from './LayerViewEventEvaluation';
import LayerViewEventAttendees from './LayerViewEventAttendees';
import LayerViewEventAssets from './LayerViewEventAssets';
import LayerViewEventRegister from './LayerViewEventRegister';
import { useOverlayContext } from '../overlay/OverlayProvider';

const LayerViewEvent: React.FC = () => {
  const { overlayNavigate } = useOverlayContext();
  const { bgSegment1, sEventId } = useParams();
  const outlet = useOutlet();

  const { activeEvent: eventData } = useAppSelector(({ eventsState }) => eventsState);

  const handleOnClickItem = (e: any, path: string) => {
    e.preventDefault();
    overlayNavigate(path);
  };

  useEffect(() => {
    EventsUtil.loadSingleEvent(sEventId);
  }, [sEventId]);

  const layerNavigationModel: any = [];

  if (eventData) {
    if (eventData.canAttend && eventData.registration?.isEnabled) {
      layerNavigationModel.push({
        label: 'Registration',
        path: `/${bgSegment1}/event/${eventData.id}/register`,
        tabId: 'register',
      });
    }

    if (eventData.description) {
      layerNavigationModel.push({
        label: 'Description',
        path: `/${bgSegment1}/event/${eventData.id}/description`,
        tabId: 'description',
      });
    }

    if (eventData.schedule.length >= 1) {
      layerNavigationModel.push({
        label: 'Schedule',
        path: `/${bgSegment1}/event/${eventData.id}/schedule`,
        tabId: 'schedule',
      });
    }

    if (eventData.venue) {
      layerNavigationModel.push({
        label: 'Venue',
        path: `/${bgSegment1}/event/${eventData.id}/location`,
        tabId: 'location',
      });
    }

    if (eventData.onlineVenue) {
      layerNavigationModel.push({
        label: 'Online Venue',
        path: `/${bgSegment1}/event/${eventData.id}/onlinevenue`,
        tabId: 'onlinevenue',
      });
    }

    if (!!eventData.evaluation.description || !!eventData.evaluation.url) {
      layerNavigationModel.push({
        label: 'Evaluation',
        path: `/${bgSegment1}/event/${eventData.id}/evaluation`,
        tabId: 'evaluation',
      });
    }

    if (!!eventData.attendees && eventData.attendees.length >= 1) {
      layerNavigationModel.push({
        label: 'Attendees',
        path: `/${bgSegment1}/event/${eventData.id}/attendees`,
        tabId: 'attendees',
      });
    }

    if (
      (eventData.assets?.pictures || []).length > 0 ||
      (eventData.assets?.presentations || []).length > 0 ||
      (eventData.assets?.agenda || []).length > 0 ||
      (eventData.assets?.videos || []).length > 0
    ) {
      layerNavigationModel.push({
        label: 'Assets & Downloads',
        path: `/${bgSegment1}/event/${eventData.id}/assets`,
        tabId: 'assets',
      });
    }
  }

  const firstTabId: string | undefined = layerNavigationModel[0]?.tabId;

  return (
    <>
      <LayerViewHeader>
        <EventHeaderWrapper eventData={eventData} />
      </LayerViewHeader>
      {/* @ts-ignore */}
      <LayerNavigation
        // @ts-ignore
        layerNavigationModel={layerNavigationModel}
        // @ts-ignore
        handleClickItem={handleOnClickItem}
      />
      <LayerViewBody layout="event">
        {outlet ? (
          <Outlet />
        ) : firstTabId === 'description' ? (
          <LayerViewEventDescription />
        ) : firstTabId === 'schedule' ? (
          <LayerViewEventSchedule />
        ) : firstTabId === 'onlinevenue' ? (
          <LayerViewEventOnlineVenue />
        ) : firstTabId === 'location' ? (
          <LayerViewEventLocation />
        ) : firstTabId === 'location' ? (
          <LayerViewEventLocation />
        ) : firstTabId === 'evaluation' ? (
          <LayerViewEventEvaluation />
        ) : firstTabId === 'attendees' ? (
          <LayerViewEventAttendees />
        ) : firstTabId === 'assets' ? (
          <LayerViewEventAssets />
        ) : firstTabId === 'register' ? (
          <LayerViewEventRegister />
        ) : null}
      </LayerViewBody>
    </>
  );
};

export default LayerViewEvent;
