/* eslint-disable */
import React from 'react';

const IconContact40 = (
    <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="evenodd">
            <path d="M6.60764 16.80096h8.58086c.36436 0 .66007-.2964.66007-.6616 0-.3652-.29571-.6616-.66007-.6616H6.60764c-.36435 0-.66006.2964-.66006.6616 0 .3652.2957.6616.66006.6616zM23.76936 19.44736H6.60764c-.36435 0-.66006.2964-.66006.6616 0 .3652.2957.6616.66006.6616h17.16172c.36436 0 .66007-.2964.66007-.6616 0-.3652-.29571-.6616-.66007-.6616zM23.76936 23.41696H6.60764c-.36435 0-.66006.2964-.66006.6616 0 .3652.2957.6616.66006.6616h17.16172c.36436 0 .66007-.2964.66007-.6616 0-.3652-.29571-.6616-.66007-.6616z" />
            <path d="M35.69895 1.6318l-22.53553-.04764c-2.15886 0-3.91551 1.76074-3.91551 3.92461V8.8997l-5.32541.0097c-2.15886 0-3.91552 1.76074-3.91552 3.92197v13.98976c0 2.16034 1.75666 3.92108 3.91552 3.92108h4.00528v6.616c0 .27258.1681.51693.42244.6175.07569.03087.15841.0441.23762.0441a.65832.65832 0 0 0 .48845-.217l6.4044-7.0606 10.97382-.04763c2.15885 0 3.91551-1.7581 3.91551-3.92197v-.07586l3.47195 3.82846a.65832.65832 0 0 0 .48845.217c.0792 0 .16193-.01323.23762-.0441.25435-.09704.42244-.34492.42244-.6175v-6.616h.70495c2.15886 0 3.91551-1.76074 3.91551-3.92108V5.55376c0-2.16123-1.75665-3.92197-3.91199-3.92197zM29.0499 26.7726c0 1.43347-1.16172 2.59876-2.59538 2.59876l-11.26953.04764c-.1857 0-.36083.0794-.48493.217l-5.45214 6.00998V30.0806c0-.3652-.29571-.6616-.66007-.6616H3.9225c-1.43367 0-2.59538-1.16794-2.59538-2.60141V12.83136c0-1.43347 1.16171-2.59876 2.59538-2.59876l5.98547-.01324 16.54654-.0344c1.43366 0 2.59538 1.16795 2.59538 2.60141V26.7726zm9.24092-7.23261c0 1.43346-1.16171 2.6014-2.59538 2.6014h-1.36501c-.36436 0-.66007.2964-.66007.6616v5.56539l-3.30033-3.6388v-11.9432c0-2.16388-1.75666-3.92462-3.91551-3.92462l-15.88647.0344V5.50877c0-1.43347 1.16172-2.60141 2.59186-2.60141l22.53553.04763h.00352c1.43015 0 2.59186 1.1653 2.59186 2.59877v13.98623z" />
        </g>
    </svg>
);

export default IconContact40;
