/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './headline-one.scss';

class HeadlineOne extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { text } = this.props;

        return (
            <h1 className="bt-headline-one">
                {text}
            </h1>
        );
    }
}

HeadlineOne.propTypes = {
    text: PropTypes.string,
};

HeadlineOne.defaultProps = {
    text: '',
};

export default HeadlineOne;
