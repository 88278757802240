import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../../components/elements/Button';
import HeadlineOne from '../../components/elements/HeadlineOne';
import CopyTextM from '../../components/elements/CopyTextM';
import IconClose30 from '../../assets/icons/IconClose30';
import './ErrorDialog.scss';

class ErrorDialog extends Component {
  render() {
    const { heading, text, onClickClose } = this.props;

    return (
      <div className="error-dialog">
        <HeadlineOne text={heading} />
        <div className="error-dialog__text">
          <CopyTextM text={text} />
        </div>
        <div className="error-dialog__close">
          <Button layout="layer-close" label="Close" iconRight={IconClose30} onClick={onClickClose} />
        </div>
      </div>
    );
  }
}

ErrorDialog.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
  onClickClose: PropTypes.func,
};

ErrorDialog.defaultProps = {
  heading: '',
  text: '',
  onClickClose: () => false,
};

export default ErrorDialog;
