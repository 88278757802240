/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './copy-text-xl.scss';

class CopyTextXL extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { text } = this.props;

        return <span className="bt-copy-text-xl">{text}</span>;
    }
}

CopyTextXL.propTypes = {
    text: PropTypes.string
};

CopyTextXL.defaultProps = {
    text: ''
};

export default CopyTextXL;
