/* eslint-disable */
import React from 'react';

const IconLink20 = (
    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
        <path d="M15.4446886 4.0258842H.5979061v15.3750865h15.3462576V4.4665084l-6.8381854 6.7146493c-.1316083.1292308-.3424747.1292395-.4740937.0000196a.326678.326678 0 0 1-.0042227-.0042224c-.1262155-.1285365-.1243339-.3350538.0042026-.4612692l6.8128241-6.6898015zm.6100453-.5990293L18.8629323.6693656l-5.7644398.009332c-.1829944.0003008-.3318523-.1488366-.3319717-.3328216.0003005-.1833248.150506-.3338124.3337153-.3341136l6.5481183-.0113096c.0063177.0014183.0121558.0016598.0188672.0005151L20 0l-.0098391 6.9135544c-.0003006.183532-.1505061.3340197-.3337154.3343209-.1834117-.0001198-.3322695-.1492573-.3319693-.332593l.0079697-5.534663.0003478-.2415232-2.7907238 2.7403077V20H0V3.4268549h16.0547339z" fill="currentColor" fillRule="nonzero" />
    </svg>
);

export default IconLink20;
