import { useSelector } from 'react-redux';

export function useBaseData() {
  const baseData = useSelector(({ appState }) => appState.baseData);

  const contactPage = baseData.contactPage || {};
  const contactPageHeadline = contactPage.headline || '';
  const contactPageIntroText = contactPage.introText || '';
  const contacts = contactPage.contacts || [];

  const timeline = baseData?.timeline;

  return {
    baseData,
    contactPageHeadline,
    contactPageIntroText,
    contacts,
    timeline,
  };
}
