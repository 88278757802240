/* eslint-disable */
import React from 'react';

const IconBack30 = (
    <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="evenodd">
            <path d="M14.998 0C6.728 0 0 6.728 0 14.998 0 23.27 6.728 30 14.998 30 23.27 30 30 23.27 30 14.998 30 6.728 23.27 0 14.998 0zm0 28.927c-7.679 0-13.925-6.249-13.925-13.929 0-7.679 6.246-13.925 13.925-13.925 7.683 0 13.93 6.246 13.93 13.925 0 7.68-6.247 13.93-13.93 13.93z" />
            <path d="M12.595 10.053a.524.524 0 0 1 .74 0c.201.198.201.527 0 .724L9.58 14.484h12.394c.289 0 .526.227.526.512 0 .285-.237.52-.526.52H9.58l3.756 3.7a.52.52 0 1 1-.74.73L7.95 15.363a.511.511 0 0 1 0-.724l4.645-4.585z" />
        </g>
    </svg>
);

export default IconBack30;
