/* eslint-disable */
import React from 'react';

const IconPhone = (
    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="evenodd">
            <path d="M75.7099304 0H24.2898069C20.8216791 0 18 2.84018506 18 6.33073364V93.6684732C18 97.1600793 20.8216791 100 24.2898069 100h51.4201235C79.1780582 100 82 97.1600793 82 93.6684732V6.33073364C82 2.84018506 79.1780582 0 75.7099304 0zM21.1521271 82.0462657h57.6957458V15.9122274H21.1521271v66.1340383zm3.1376798-78.87376074h51.4201235c1.7299924 0 3.1379425 1.41705221 3.1379425 3.15822868v7.46649046H21.1521271V6.33073364c0-1.74117647 1.4076874-3.15822868 3.1376798-3.15822868zM75.7099304 96.827495H24.2898069c-1.7299924 0-3.1376798-1.4167878-3.1376798-3.1590218v-9.5082617h57.6957458v9.5082617c0 1.742234-1.4079501 3.1590218-3.1379425 3.1590218z" />
            <path d="M50.000394 85.5627231c2.8463708 0 5.1613454 2.3307336 5.1613454 5.1955056s-2.3160253 5.19577-5.1613454 5.19577c-2.8463707 0-5.1621334-2.330998-5.1621334-5.19577 0-2.864772 2.3157627-5.1955056 5.1621334-5.1955056zm0 2.1150033c-1.687964 0-3.0607154 1.3818903-3.0607154 3.0805023 0 1.6988764 1.3727514 3.0807667 3.0607154 3.0807667 1.6869134 0 3.0599274-1.3818903 3.0599274-3.0807667 0-1.698612-1.373014-3.0805023-3.0599274-3.0805023zM42.8547846 10.077462h14.2901681c.5797287 0 1.050709-.47402512 1.050709-1.05750166 0-.58453403-.4709803-1.05750165-1.050709-1.05750165H42.8547846c-.5797287 0-1.050709.47296762-1.050709 1.05750165 0 .58347654.4709803 1.05750166 1.050709 1.05750166zM39.0738082 10.077462c.2747604 0 .5458433-.11262393.7407499-.30879049.1959572-.19933906.3099591-.47005948.3099591-.74871117 0-.27997356-.1140019-.55042961-.3099591-.74871117-.1949066-.19748843-.4659895-.30879048-.7407499-.30879048-.2781752 0-.546894.11130205-.743902.30879048-.1962199.19616656-.306807.46873761-.306807.74871117 0 .27865169.1105871.54937211.306807.74871117.1949065.19616656.4657268.30879049.743902.30879049z" />
        </g>
    </svg>
);

export default IconPhone;
