import React from 'react';
import { useAppSelector } from '../../app/hooks';
import './layer-view-event-location.scss';

const LayerViewEventLocation: React.FC = () => {
  const { activeEvent } = useAppSelector(({ eventsState }) => eventsState);
  const eventData = activeEvent as EventData | undefined;

  return <span className="bt-event-venue" dangerouslySetInnerHTML={{ __html: eventData?.venue || '' }} />;
};

export default LayerViewEventLocation;
