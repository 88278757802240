/* eslint-disable */
import React from 'react';

const IconImage30 = (
    <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="evenodd">
            <path d="M8.054 13.925a3.214 3.214 0 0 0 3.212-3.213c0-1.77-1.44-3.212-3.212-3.212a3.216 3.216 0 0 0-3.212 3.212 3.214 3.214 0 0 0 3.212 3.213zm0-5.271c1.136 0 2.058.925 2.058 2.058a2.06 2.06 0 0 1-2.058 2.059 2.06 2.06 0 0 1-2.059-2.059c0-1.133.923-2.058 2.059-2.058z" />
            <path d="M-.046 30h30V0h-30v30zm1.2-28.8h27.6v15.405l-6.194-5.458a.617.617 0 0 0-.85.04l-5.875 6.18-2.847-2.732a.615.615 0 0 0-.818-.027L2.126 23.1h-.972V1.2zm0 27.6h27.6v-4.5h-27.6v4.5zm2.7-5.7l8.625-7.328 6.204 5.984c.235.23.615.23.85 0a.565.565 0 0 0 0-.82l-2.895-2.794 5.53-5.842 6.586 5.824V23.1h-24.9z" />
        </g>
    </svg>
);

export default IconImage30;
