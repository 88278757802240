/* eslint-disable */
import axios from 'axios';
import { saveAs } from 'file-saver';
import storeModel from '../models/storeModel';
import DownloadModel from '../models/DownloadModel';
import { handleErrorResponse } from '../redux-actions/actionsApp';
import { updateDownloads } from '../redux-actions/actionsDownloads';
import { apiUrl } from '../app/api';

class DownloadsUtil {
  static createDownloadModels(downloadsData) {
    const downloadModels = [];
    downloadsData.forEach((data) => {
      downloadModels.push(new DownloadModel(data));
    });
    storeModel.dispatch(updateDownloads(downloadsData, downloadModels));
  }

  static async loadDownloads() {
    try {
      const { authState } = storeModel.state;
      const { userToken } = authState;

      const response = await axios({
        method: 'get',
        url: `${apiUrl}/downloads`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      DownloadsUtil.createDownloadModels(response.data.data);
    } catch (error) {
      storeModel.dispatch(handleErrorResponse(error.response));
    }
  }

  static async loadDownloadsByParams(params) {
    try {
      const { authState } = storeModel.state;
      const { userToken } = authState;

      const response = await axios({
        method: 'get',
        url: `${apiUrl}/downloads`,
        params,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      DownloadsUtil.createDownloadModels(response.data.data);
    } catch (error) {
      storeModel.dispatch(handleErrorResponse(error.response));
    }
  }

  static calculateTotalFileSize(downloadsModel, type) {
    let totalFileSize = 0;
    let videosFileSize = 0;
    let presentationsFileSize = 0;
    let agendaFileSize = 0;
    let picturesFileSize = 0;

    if (type === 'presentations') {
      downloadsModel.agenda.forEach((agenda) => {
        if (agenda.size) {
          agendaFileSize += agenda.size;
        }
      });
      downloadsModel.presentations.forEach((presentation) => {
        if (presentation.size) {
          presentationsFileSize += presentation.size;
        }
      });
    }

    if (type === 'videos') {
      downloadsModel.videos.forEach((video) => {
        if (video.size) {
          videosFileSize += video.size;
        }
      });
    }
    if (type === 'pictures') {
      downloadsModel.pictures.forEach((picture) => {
        if (picture.size) {
          picturesFileSize += picture.size;
        }
      });
    }

    totalFileSize = videosFileSize + picturesFileSize + agendaFileSize + presentationsFileSize;

    totalFileSize = DownloadsUtil.formatFileSize(totalFileSize);
    return totalFileSize;
  }

  static formatFileSize(number) {
    let formattedNumber = 0;
    formattedNumber = (Number(number) / 1024).toFixed(1);
    if (formattedNumber > 100) {
      formattedNumber = (Number(formattedNumber) / 1024).toFixed(1);
      formattedNumber += ' MB';
    } else {
      formattedNumber += ' KB';
    }
    return formattedNumber;
  }

  static str2bytes(str) {
    var bytes = new Uint8Array(str.length);
    for (var i = 0; i < str.length; i++) {
      bytes[i] = str.charCodeAt(i);
    }
    return bytes;
  }

  static async handleDownloadAll(type, eventId) {
    try {
      const { authState } = storeModel.state;
      const { userToken } = authState;
      const response = await axios({
        method: 'get',
        url: `${apiUrl}/downloads/download`,
        params: {
          type: type,
          eventId: eventId,
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      var blob = await new Blob([response.data], {
        type: 'application/zip',
      });

      saveAs(blob, 'data.zip');
    } catch (error) {
      storeModel.dispatch(handleErrorResponse(error.response));
    }
  }
}

export default DownloadsUtil;
