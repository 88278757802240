/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../elements/Button';
import HeadlineThree from '../../elements/HeadlineThree';
import CopyTextM from '../../elements/CopyTextM';
import Wrapper from '../../elements/Wrapper';
import IconUser40 from '../../../assets/icons/IconUser40';
import IconInfo40 from '../../../assets/icons/IconInfo40';
import IconHospital40 from '../../../assets/icons/IconHospital40';
import IconContact40 from '../../../assets/icons/IconContact40';
import IconLogin40 from '../../../assets/icons/IconLogin40';
import IconUser28 from '../../../assets/icons/IconUser28';
import IconInfo28 from '../../../assets/icons/IconInfo28';
import IconHospital28 from '../../../assets/icons/IconHospital28';
import IconContact28 from '../../../assets/icons/IconContact28';
import IconLogin28 from '../../../assets/icons/IconLogin28';
// import IconUpload20 from '../../assets/icons/IconUpload20';
import IconEmail20 from '../../../assets/icons/IconEmail20';
import IconPhone20 from '../../../assets/icons/IconPhone20';
import IconLink20 from '../../../assets/icons/IconLink20';
import IconLinkedIn20 from '../../../assets/icons/IconLinkedIn20';
import IconTwitter20 from '../../../assets/icons/IconTwitter20';
import IconInvisible20 from '../../../assets/icons/IconInvisible20';
import IconKey20 from '../../../assets/icons/IconKey20';
import IconUser20 from '../../../assets/icons/IconUser20';
import './profile-data.scss';
import IconVisible20 from '../../../assets/icons/IconVisible20';

class ProfileData extends React.Component {
  // constructor(props) {
  //     super(props);
  // }

  handleOnClickEdit(e) {
    e.preventDefault();
    const { onClickEdit } = this.props;
    onClickEdit();
  }

  render() {
    const { fullName, profession, location, statusText, employers, contact, email, editPath } = this.props;

    return (
      <div className="bt-profile-data">
        <div className="bt-profile-data__button-edit">
          <Button layout="primary" path={editPath} label="Edit" onClick={(e) => this.handleOnClickEdit(e)} />
        </div>
        <div className="bt-profile-data__content">
          <div className="bt-profile-data__section">
            <div className="bt-profile-data__section-top">
              <div className="bt-profile-data__section-icon">{IconUser40}</div>
              <div className="bt-profile-data__section-icon--mobile">{IconUser28}</div>
              <div className="bt-profile-data__section-title">
                <HeadlineThree text="Details" />
              </div>
            </div>
            <div className="bt-profile-data__section-info">
              <CopyTextM text={fullName} />
              <br />
              <CopyTextM text={profession} />
              <br />
              <CopyTextM text={location} />
            </div>
          </div>
          <div className="bt-profile-data__section">
            <div className="bt-profile-data__section-top">
              <div className="bt-profile-data__section-icon">{IconInfo40}</div>
              <div className="bt-profile-data__section-icon--mobile">{IconInfo28}</div>
              <div className="bt-profile-data__section-title">
                <HeadlineThree text="Status" />
              </div>
            </div>
            <div className="bt-profile-data__section-info">
              <CopyTextM text={statusText} />
              {/* <Wrapper
                                marginTop20
                            >
                                <Button
                                    path="#"
                                    label="Upload PDF"
                                    iconLeft={IconUpload20}
                                />
                            </Wrapper> */}
            </div>
          </div>
          <div className="bt-profile-data__section">
            <div className="bt-profile-data__section-top">
              <div className="bt-profile-data__section-icon">{IconHospital40}</div>
              <div className="bt-profile-data__section-icon--mobile">{IconHospital28}</div>
              <div className="bt-profile-data__section-title">
                <HeadlineThree text="Hospital/Company" />
              </div>
            </div>
            <div className="bt-profile-data__section-info">
              {employers.map((employer, i) => {
                if (!employer.name && !employer.email) {
                  return null;
                }

                return (
                  <div key={i} className="bt-profile-data__employer">
                    <CopyTextM text={employer.name} />
                    {employer.email && (
                      <Wrapper marginTop8>
                        <div className="bt-profile-data__link">
                          <div className="bt-profile-data__link-icon">{IconEmail20}</div>
                          <div className="bt-profile-data__link-label">{employer.email}</div>
                        </div>
                      </Wrapper>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="bt-profile-data__section">
            <div className="bt-profile-data__section-top">
              <div className="bt-profile-data__section-icon">{IconContact40}</div>
              <div className="bt-profile-data__section-icon--mobile">{IconContact28}</div>
              <div className="bt-profile-data__section-title">
                <HeadlineThree text="Contact" />
              </div>
            </div>
            <div className="bt-profile-data__section-info">
              {contact.map((contactItem, i) => {
                // let path = contactItem.contact;
                let icon = IconLink20;

                if (contactItem.type === 'email') {
                  // path = 'mailto:' + contactItem.contact;
                  icon = IconEmail20;
                } else if (contactItem.type === 'phone') {
                  // path = 'tel:' + contactItem.contact;
                  icon = IconPhone20;
                } else if (contactItem.type === 'linkedin') {
                  if (contactItem.contact.indexOf('http') === -1) {
                    // path = 'http://' + contactItem.contact;
                  }
                  icon = IconLinkedIn20;
                } else if (contactItem.type === 'twitter') {
                  if (contactItem.contact.indexOf('http') === -1) {
                    // path = 'http://' + contactItem.contact;
                  }
                  icon = IconTwitter20;
                } else {
                  if (contactItem.contact.indexOf('http') === -1) {
                    // path = 'http://' + contactItem.contact;
                  }
                }

                let linkClassName = 'bt-profile-data__link';
                let linkIconVisibility = IconVisible20;
                if (!contactItem.isPublic) {
                  linkClassName += ' bt-profile-data__link--disabled';
                  linkIconVisibility = IconInvisible20;
                }

                return (
                  <div key={i} className="bt-profile-data__contact">
                    <div className={linkClassName}>
                      <div className="bt-profile-data__link-icon">{icon}</div>
                      <div className="bt-profile-data__link-label">{contactItem.contact}</div>
                      <div className="bt-profile-data__link-visibility">{linkIconVisibility}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="bt-profile-data__section">
            <div className="bt-profile-data__section-top">
              <div className="bt-profile-data__section-icon">{IconLogin40}</div>
              <div className="bt-profile-data__section-icon--mobile">{IconLogin28}</div>
              <div className="bt-profile-data__section-title">
                <HeadlineThree text="Login" />
              </div>
            </div>
            <div className="bt-profile-data__section-info">
              <div className="bt-profile-data__login-item">
                <div className="bt-profile-data__link bt-profile-data__link--disabled">
                  <div className="bt-profile-data__link-icon">{IconUser20}</div>
                  <div className="bt-profile-data__link-label">{email}</div>
                  <div className="bt-profile-data__link-visibility">{IconInvisible20}</div>
                </div>
              </div>
              <div className="bt-profile-data__login-item">
                <div className="bt-profile-data__link bt-profile-data__link--disabled">
                  <div className="bt-profile-data__link-icon">{IconKey20}</div>
                  <div className="bt-profile-data__link-label">********</div>
                  <div className="bt-profile-data__link-visibility">{IconInvisible20}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ProfileData.propTypes = {
  fullName: PropTypes.string,
  profession: PropTypes.string,
  location: PropTypes.string,
  statusText: PropTypes.string,
  employers: PropTypes.array,
  contact: PropTypes.array,
  email: PropTypes.string,
  editPath: PropTypes.string,
  onClickEdit: PropTypes.func,
};

ProfileData.defaultProps = {
  fullName: '',
  profession: '',
  location: '',
  statusText: '',
  employers: [],
  contact: [],
  email: '',
  editPath: '',
  onClickEdit: () => false,
};

export default ProfileData;
