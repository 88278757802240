/* eslint-disable */
import React from 'react';

const IconArrowRight12 = (
    <svg width="12" height="12" xmlns="http://www.w3.org/2000/svg">
        <path fill="currentColor" fillRule="nonzero" d="M2.76 11.4921155L8.2285832 6 2.76.50788455 3.2657084 0 9.24 6l-5.9742916 6z" />
    </svg>
);

export default IconArrowRight12;
