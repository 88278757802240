import React from 'react';
import ScheduleDayItem from '../../molecules/ScheduleDayItem/ScheduleDayItem';
import Button from '../elements/Button';
import { useAppSelector } from '../../app/hooks';
import './layer-view-event-schedule.scss';

const LayerViewEventSchedule: React.FC = () => {
  const { activeEventModel } = useAppSelector(({ eventsState }) => eventsState);

  return (
    <div className="bt-event-schedule">
      {activeEventModel.schedulePdf && (
        <div className="bt-event-schedule__download-button">
          <Button
            link
            layout="secondary"
            path={activeEventModel.schedulePdf.url}
            label="Download Welcome Letter (PDF)"
            target="_blank"
          />
        </div>
      )}
      <div className="bt-event-schedule__list">
        {activeEventModel.scheduleFormatted &&
          activeEventModel.scheduleFormatted.map((scheduleItem: any, i: number) => {
            return (
              <div key={i} className="bt-event-schedule__item">
                <ScheduleDayItem date={scheduleItem.date} schedule={scheduleItem.schedule} />
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default LayerViewEventSchedule;
