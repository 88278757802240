/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './layer-navigation-item.scss';

class LayerNavigationItem extends Component {
    render() {
        const { label, path, isActive, onClick } = this.props;
        const cssComponent = isActive ? 'active' : '';

        return (
            <div className={`bt-layer-navigation-item ${cssComponent}`}>
                <a href={path} onClick={(e) => onClick(e, path)}>
                    <span className="bt-layer-navigation-item__label">{label}</span>
                </a>
                <div className="bt-layer-navigation-item__ind" />
            </div>
        );
    }
}

LayerNavigationItem.propTypes = {
    label: PropTypes.string,
    icon: PropTypes.object,
    path: PropTypes.string,
    isActive: PropTypes.bool,
    onClick: PropTypes.func
};

LayerNavigationItem.defaultProps = {
    label: '',
    icon: null,
    path: '',
    isActive: false,
    onClick: () => false
};

export default LayerNavigationItem;
