/* eslint-disable */
import React from 'react';

const IconLogin28 = (
    <svg width="28" height="28" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="nonzero">
            <path d="M23.367 10.429v-2.91C23.367 3.373 19.165 0 14 0 8.835 0 4.633 3.373 4.633 7.519v2.91c-1.991.304-3.513 1.84-3.513 3.69v10.133C1.12 26.318 3.019 28 5.352 28h17.296c2.333 0 4.232-1.682 4.232-3.748V14.119c0-1.85-1.522-3.386-3.513-3.69zM5.74 7.397C5.74 3.858 9.445.98 14 .98s8.26 2.878 8.26 6.417v2.823H5.74V7.397zM25.9 24.378c0 1.534-1.396 2.782-3.111 2.782H5.21c-1.715 0-3.111-1.248-3.111-2.782V13.982c0-1.534 1.396-2.782 3.111-2.782H22.79c1.715 0 3.111 1.248 3.111 2.782v10.396z" />
            <path d="M14 14.56c-1.315 0-2.385.933-2.385 2.08v3.12c0 1.147 1.07 2.08 2.385 2.08 1.315 0 2.385-.933 2.385-2.08v-3.12c0-1.147-1.07-2.08-2.385-2.08zm1.431 5.32c0 .618-.642 1.12-1.431 1.12-.79 0-1.431-.502-1.431-1.12v-3.36c0-.618.642-1.12 1.431-1.12.79 0 1.431.502 1.431 1.12v3.36z" />
        </g>
    </svg>
);

export default IconLogin28;
