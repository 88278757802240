/* eslint-disable */
import React from 'react';

const IconCV28 = (
    <svg width="28" height="28" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="evenodd">
            <path d="M25.311 23.38a2.333 2.333 0 0 1 1.849 2.287A2.326 2.326 0 0 1 24.846 28H8.966a3.202 3.202 0 0 1-3.194-3.204V5.133H2.981C1.955 5.133 1.12 4.086 1.12 2.8 1.12 1.047 2.54 0 3.911 0h18.832a2.576 2.576 0 0 1 2.568 2.577V23.38zM2.801 4.17c.059.02.119.03.18.03.863 0 .93-1.02.93-1.333l.002-.034-.002-.033c0-.258.209-.467.466-.467h1.26c-.3-.827-1.055-1.4-1.726-1.4a1.86 1.86 0 0 0-.427.05c-.76.18-1.433.831-1.434 1.817 0 .641.34 1.232.751 1.37zm1.734.03h1.237v-.933h-.954a2.578 2.578 0 0 1-.283.933zm2.168 20.596a2.27 2.27 0 0 0 2.206 2.27l.007-.004.04-.026.06-.04.04-.029.055-.043.037-.03.053-.047.034-.033a1.743 1.743 0 0 0 .42-.642l.015-.044.022-.068c.004-.015.01-.03.013-.045.007-.023.012-.047.018-.07l.01-.045.013-.074.007-.043a1.77 1.77 0 0 0 .008-.082l.004-.038a1.775 1.775 0 0 0-.65-1.502.468.468 0 0 1 .293-.828h14.973V2.577c0-.906-.735-1.644-1.638-1.644H5.849a3.297 3.297 0 0 1 .836 1.84c.011.105.018.211.018.319v21.705zm18.143 2.27c.763 0 1.383-.627 1.383-1.4 0-.771-.62-1.4-1.383-1.4H10.383a2.716 2.716 0 0 1 .309 1.475l-.007.064a2.786 2.786 0 0 1-.015.132c-.003.025-.009.05-.013.075-.007.04-.013.078-.022.117l-.02.078c-.008.037-.018.074-.028.11l-.025.078a2.95 2.95 0 0 1-.146.357 2.74 2.74 0 0 1-.054.101l-.037.067a2.56 2.56 0 0 1-.065.104c-.009.014-.017.029-.027.043h14.613z" />
            <path d="M18.7 11.125H9.604a.473.473 0 0 1 0-.948H18.7a.473.473 0 0 1 0 .948zM21.608 15.107H9.604a.473.473 0 0 1 0-.948h12.004a.473.473 0 0 1 0 .948zM21.608 7.144H9.604a.473.473 0 0 1 0-.948h12.004a.473.473 0 0 1 0 .948z" />
        </g>
    </svg>
);

export default IconCV28;
