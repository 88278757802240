/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './fellow-toolbar.scss';
import Select from '../elements/Select';
import InputFellowSearch from '../elements/InputFellowSearch/InputFellowSearch';

class FellowToolbar extends Component {
    constructor(props) {
        super(props);
        this.state = {};
        this.yearSelect = React.createRef();
        this.countrySelect = React.createRef();
    }

    render() {
        const { searchFilter, handleChangeInput, years, countries, handleChangeSelect } = this.props;

        return (
            <div className="bt-fellow-toolbar">
                <div className="bt-fellow-toolbar__select-year">
                    <Select
                        options={years}
                        value="all"
                        placeholder="Auswählen..."
                        label="Year"
                        onChange={(e) => handleChangeSelect(e, 'year', this.countrySelect.current.state.value)}
                        ref={this.yearSelect}
                    />
                </div>
                <div className="bt-fellow-toolbar__select-country">
                    <Select
                        options={countries}
                        value="all"
                        placeholder="Auswählen..."
                        label="Country"
                        onChange={(e) => handleChangeSelect(e, 'country', this.yearSelect.current.state.value)}
                        ref={this.countrySelect}
                    />
                </div>
                <div className="bt-fellow-toolbar__input-search">
                    <InputFellowSearch
                        searchFilter={searchFilter}
                        handleChangeInput={handleChangeInput}
                        placeholder="Search for fellows by name..."
                        label="Name (Fellow & Faculties)"
                        search
                    />
                </div>
            </div>
        );
    }
}

FellowToolbar.propTypes = {
    searchFilter: PropTypes.string,
    yearArray: PropTypes.array,
    countryArray: PropTypes.array,
    handleChangeInput: PropTypes.func,
    years: PropTypes.array,
    countries: PropTypes.array,
    handleChangeSelect: PropTypes.func
};

FellowToolbar.defaultProps = {
    searchFilter: '',
    handleChangeInput: () => false
};

export default FellowToolbar;
