/* eslint-disable */
import React from 'react';

const IconContact = (
    <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="evenodd">
            <path d="M16.6843004 42.4224288h21.6666667c.92 0 1.6666666-.7484021 1.6666666-1.6705404s-.7466666-1.6705403-1.6666666-1.6705403H16.6843004c-.92 0-1.6666667.748402-1.6666667 1.6705403 0 .9221383.7466667 1.6705404 1.6666667 1.6705404zM60.0176337 49.1045904H16.6843004c-.92 0-1.6666667.748402-1.6666667 1.6705403 0 .9221383.7466667 1.6705404 1.6666667 1.6705404h43.3333333c.92 0 1.6666667-.7484021 1.6666667-1.6705404s-.7466667-1.6705403-1.6666667-1.6705403zM60.0176337 59.1278327H16.6843004c-.92 0-1.6666667.748402-1.6666667 1.6705403 0 .9221383.7466667 1.6705404 1.6666667 1.6705404h43.3333333c.92 0 1.6666667-.7484021 1.6666667-1.6705404s-.7466667-1.6705403-1.6666667-1.6705403z" />
            <path d="M90.1398559 4.12027891L33.2376337 4c-5.4511111 0-9.8866666 4.44586481-9.8866666 9.9096456v8.5620763l-13.44666671.0245012c-5.45111111 0-9.88666666 4.4458648-9.88666666 9.9029634v35.3241333c0 5.4548712 4.43555555 9.900736 9.88666666 9.900736H20.0176337v16.7054038c0 .6882627.4244445 1.3052489 1.0666667 1.559171.1911111.0779586.4.1113694.6.1113694.46 0 .9111111-.1915553 1.2333333-.5479372l16.1711111-17.828007 27.7088889-.1202789c5.4511111 0 9.8866667-4.4391827 9.8866667-9.9029634v-.1915553l8.7666667 9.6668603c.3222222.356382.7733333.5479373 1.2333333.5479373.2 0 .4088889-.0334108.6-.1113694.6422222-.2450126 1.0666667-.8709084 1.0666667-1.559171V59.2481116h1.78c5.4511111 0 9.8866669-4.4458648 9.8866669-9.900736V14.0232423c0-5.45709859-4.4355558-9.90296339-9.8777781-9.90296339zM73.3509671 67.6008135c0 3.6195041-2.9333334 6.5618826-6.5533334 6.5618826l-28.4555555.1202789c-.4688889 0-.9111111.2004649-1.2244445.5479373L23.3509671 90.0061011V75.9535154c0-.9221383-.7466667-1.6705404-1.6666667-1.6705404H9.90430039c-3.62 0-6.55333333-2.9490606-6.55333333-6.5685648V32.3991865c0-3.6195041 2.93333333-6.5618826 6.55333333-6.5618826l15.11333331-.0334108 41.78-.0868681c3.62 0 6.5533334 2.9490606 6.5533334 6.5685648v35.3152237zM96.6843004 49.338466c0 3.6195042-2.9333333 6.5685648-6.5533333 6.5685648h-3.4466667c-.92 0-1.6666667.7484021-1.6666667 1.6705404v14.0525857l-8.3333333-9.1879721v-30.156595c0-5.4637808-4.4355556-9.9096456-9.8866667-9.9096456l-40.1133333.0868681v-8.5531667c0-3.6195042 2.9333333-6.56856483 6.5444444-6.56856483l56.9022223.1202789h.0088888c3.6111112 0 6.5444445 2.94237843 6.5444445 6.56188263V49.338466z" />
        </g>
    </svg>
);

export default IconContact;
