import axios from 'axios';
import storeModel from '../models/storeModel';
import SearchModel from '../models/SearchModel';
import { handleErrorResponse } from '../redux-actions/actionsApp';
import { updateSearchModels, updateIsSearchLoading, updateIsSearchLoaded } from '../redux-actions/actionsSearch';
import { apiUrl } from '../app/api';

class AppUtil {
  static getBaseUrl() {
    return window.location.origin;
  }

  static createSearchModels(searchData) {
    const searchModels = [];
    searchData.events.forEach((data) => {
      searchModels.push(new SearchModel(data, 'events'));
    });
    searchData.downloads.forEach((data) => {
      searchModels.push(new SearchModel(data, 'downloads'));
    });
    searchData.fellows.forEach((data) => {
      searchModels.push(new SearchModel(data, 'fellows'));
    });
    storeModel.dispatch(updateSearchModels(searchModels));
  }

  static async submitSearch(searchValue) {
    storeModel.dispatch(updateIsSearchLoading(true));
    try {
      const { authState } = storeModel.state;
      const { userToken } = authState;

      const response = await axios({
        method: 'get',
        url: `${apiUrl}/search?q=${searchValue}`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      AppUtil.createSearchModels(response.data.data);
      storeModel.dispatch(updateIsSearchLoading(false));
      storeModel.dispatch(updateIsSearchLoaded());
    } catch (error) {
      storeModel.dispatch(updateIsSearchLoading(false));
      storeModel.dispatch(handleErrorResponse(error.response));
    }
  }

  static getInitials(firstName, lastName) {
    let initials = '';
    initials =
      (firstName ? firstName.charAt(0).toUpperCase() : '') + (lastName ? lastName.charAt(0).toUpperCase() : '');
    return initials;
  }

  static formatObjectToArray(object) {
    let array = Object.keys(object).map(function (item) {
      return { value: item, label: item };
    });
    return array;
  }

  static formatObjectToArrayByValue(object) {
    let array = Object.values(object).map(function (item) {
      return { value: item, label: item };
    });
    return array;
  }

  static transposeObjectToArray(object) {
    if (!object) {
      return [];
    }
    var array = Object.entries(object).map(([key, value]) => ({
      value: key,
      label: value,
    }));
    return array;
  }

  static getAMPM(hours) {
    return hours < 12 ? 'am' : 'pm';
  }

  static twoDigit(num) {
    return num < 10 ? `0${num}` : num;
  }

  static to12Hours(hours) {
    let hoursFormatted = hours % 12;
    if (hours === 0 || hours === 12) {
      hoursFormatted = 12;
    }
    return hoursFormatted;
  }

  /**
   * @return string 'YYYY'
   */
  static getYear(timestamp) {
    const date = new Date(timestamp.replace(/\s/, 'T'));
    return date.getFullYear();
  }

  /**
   * @return string 'DD.MM.YYYY'
   */
  static getDateSpanFormatted(startTimestamp, endTimestamp) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const startDate = new Date(startTimestamp.replace(/\s/, 'T'));
    const endDate = new Date(endTimestamp.replace(/\s/, 'T'));
    let endDay = endDate.getDate();
    const endMonth = months[endDate.getMonth()];
    const endYear = endDate.getFullYear();
    const endHours = endDate.getHours();
    const endMinutes = endDate.getMinutes();
    let startDay = startDate.getDate();
    let startYear = startDate.getFullYear();
    const startHours = startDate.getHours();
    const startMinutes = startDate.getMinutes();
    if (endYear === startYear) {
      startYear = '';
    }
    let startMonth = months[startDate.getMonth()];
    if (endMonth === startMonth && !startYear) {
      startMonth = '';
    }
    if (endDay === startDay) {
      startDay = '';
    }

    const dateString =
      startDay +
      (startMonth && ' ') +
      startMonth +
      (startYear && ' ') +
      startYear +
      ((startMonth || startYear) && ' ') +
      (startDay && '-') +
      ((startMonth || startYear) && ' ') +
      endDay +
      ' ' +
      endMonth +
      ' ' +
      endYear;

    let timeString = '';
    // assume full day event if start and end is at midnight
    if (startHours !== 0 || startMinutes !== 0 || endHours !== 0 || endMinutes !== 0) {
      if (startMinutes === 0 && endMinutes === 0) {
        // short format with hours only
        timeString = ` ${this.to12Hours(startHours)} ${this.getAMPM(startHours)} to ${this.to12Hours(
          endHours
        )} ${this.getAMPM(endHours)}`;
      } else {
        // full format with minutes
        timeString = ` ${this.to12Hours(startHours)}:${this.twoDigit(startMinutes)} ${this.getAMPM(
          startHours
        )} to ${this.to12Hours(endHours)}:${this.twoDigit(endMinutes)} ${this.getAMPM(endHours)}`;
      }
    }

    // CEST and CET is disabled for now
    // return dateString + (timeString && dateString && ' |') + timeString + ' CEST';
    return dateString + (timeString && dateString && ' |') + timeString;
  }

  static getDateSpanFormattedSeparate(startTimestamp, endTimestamp) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const startDate = new Date(startTimestamp.replace(/\s/, 'T'));
    const endDate = new Date(endTimestamp.replace(/\s/, 'T'));
    let endDay = endDate.getDate();
    const endMonth = months[endDate.getMonth()];
    const endYear = endDate.getFullYear();
    const endHours = endDate.getHours();
    const endMinutes = endDate.getMinutes();
    let startDay = startDate.getDate();
    let startYear = startDate.getFullYear();
    const startHours = startDate.getHours();
    const startMinutes = startDate.getMinutes();
    if (endYear === startYear) {
      startYear = '';
    }
    let startMonth = months[startDate.getMonth()];
    if (endMonth === startMonth && !startYear) {
      startMonth = '';
    }
    if (endDay === startDay) {
      startDay = '';
    }

    const dateString =
      startDay +
      (startMonth && ' ') +
      startMonth +
      (startYear && ' ') +
      startYear +
      ((startMonth || startYear) && ' ') +
      (startDay && '-') +
      ((startMonth || startYear) && ' ') +
      endDay +
      ' ' +
      endMonth +
      ' ' +
      endYear;

    let timeString = '';
    // assume full day event if start and end is at midnight
    if (startHours !== 0 || startMinutes !== 0 || endHours !== 0 || endMinutes !== 0) {
      if (startMinutes === 0 && endMinutes === 0) {
        // short format with hours only
        timeString = ` ${this.to12Hours(startHours)} ${this.getAMPM(startHours)} to ${this.to12Hours(
          endHours
        )} ${this.getAMPM(endHours)}`;
      } else {
        // full format with minutes
        timeString = ` ${this.to12Hours(startHours)}:${this.twoDigit(startMinutes)} ${this.getAMPM(
          startHours
        )} to ${this.to12Hours(endHours)}:${this.twoDigit(endMinutes)} ${this.getAMPM(endHours)}`;
      }
    }

    return { dateString, timeString };
  }

  static getFormattedDate(inputDate) {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    const months = [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ];

    const date = new Date(inputDate);

    const day = days[date.getDay()];
    const numericDay = date.getDate();
    const month = months[date.getMonth()];
    const year = date.getFullYear();
    return `${day}, ${month} ${numericDay}, ${year} `;
  }

  static getFormattedTime(time) {
    // Check correct time format and split into components
    time = time.toString();

    time = time.substring(0, time.length - 3);

    time = time.match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      // If time format correc
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? ' am' : ' pm'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }

    return time.join(''); // return adjusted time or original string
  }
}

export default AppUtil;
