/* eslint-disable */
import React from 'react';

const IconCamera15 = (
    <svg width="15" height="15" xmlns="http://www.w3.org/2000/svg">
        <path d="M4.712 2.201c0-.305.25-.551.556-.551H9.98c.307 0 .557.246.557.551 0 .172-.08.325-.204.426h3.523c.63 0 1.144.506 1.144 1.13v8.613c0 .624-.513 1.13-1.144 1.13H1.144C.514 13.5 0 12.994 0 12.37V3.756c0-.623.514-1.13 1.144-1.13h3.771a.548.548 0 0 1-.203-.425zm9.144 10.197c.019 0 .032-.014.032-.028V3.756c0-.013-.013-.027-.032-.027h-5.04c1.981.474 3.457 2.237 3.457 4.334 0 2.098-1.476 3.861-3.457 4.335h5.04zm-7.175 0C4.7 11.924 3.223 10.16 3.223 8.063c0-2.097 1.477-3.86 3.458-4.334H1.144c-.018 0-.03.013-.03.027v8.614c0 .015.012.028.03.028H6.68zm1.067-.977c1.882 0 3.412-1.507 3.412-3.358 0-1.85-1.53-3.357-3.412-3.357-1.881 0-3.412 1.507-3.412 3.357 0 1.851 1.53 3.358 3.412 3.358z" fill="currentColor" fillRule="nonzero" />
    </svg>
);

export default IconCamera15;
