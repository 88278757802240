import React, { useEffect } from 'react';
import LayerViewBody from '../overlay/LayerViewBody';
import StatusList from './StatusList';
import NewsfeedUtil from '../../utils/NewsfeedUtil';
import { useAppSelector } from '../../app/hooks';

const LayerViewStatus: React.FC = () => {
  const { fellowStatusModels } = useAppSelector(({ newsfeedState }) => newsfeedState);

  useEffect(() => {
    NewsfeedUtil.loadAllStatuses();
  }, []);

  return (
    <LayerViewBody>
      <StatusList attendeeData={fellowStatusModels} />
    </LayerViewBody>
  );
};

export default LayerViewStatus;
