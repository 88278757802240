/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import IconVideoPlay from '../../assets/icons/IconVideoPlay';
import IconArrowDown20 from '../../assets/icons/IconArrowDown20';
import IconArrowUp20 from '../../assets/icons/IconArrowUp20';
import IconDownloads20 from '../../assets/icons/IconDownloads20';
import IconPdf30 from '../../assets/icons/IconPdf30';
import IconImage30 from '../../assets/icons/IconImage30';
import IconExpandImage from '../../assets/icons/IconExpandImage';
import Button from '../../components/elements/Button';
import { useResponsive } from '../../hooks/responsive';
import ToggleBox from '../../components/elements/ToggleBox';

import VideoCanvas from '../../atoms/VideoCanvas/VideoCanvas';

import Wrapper from '../../components/elements/Wrapper';
import ButtonDownload from '../../components/elements/ButtonDownload';
import DownloadsUtil from '../../utils/DownloadsUtil';
import ImageLayer from '../../components/ImageLayer/ImageLayer';

const Container = styled.div({});

const MainVideoContainer = styled.div({
    position: 'relative',
    width: '100%',
    height: '400px',
    display: 'flex',
    cursor: 'pointer',
    img: {
        // height: '100%',
        width: '100%',
        objectFit: 'contain',
        objectPosition: '50% 50%'
    },
    svg: {
        height: '60px',
        width: '60px'
    }
});

const MainVideoOuterContainer = styled.div({
    width: '100%',
    height: '375px',
    marginBottom: 50
});

const MainVideoCaption = styled.p({
    fontWeight: 500,
    fontSize: '30px',
    lineHeight: '36px',
    color: '#00234C',
    marginBottom: '5px'
});

const MainVideoDescription = styled.p({
    fontWeight: 300,
    fontSize: '18px',
    lineHeight: '24px',
    color: '#00234C',
    margin: 0,
    marginBottom: '30px'
});

const VideoPlayButton = styled.div({
    position: 'absolute',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
});

const SectionHeader = styled.div({
    borderTop: '1px solid #EBEBEB',
    display: 'flex',
    flexFlow: 'row nowrap',
    alignItem: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    padding: '25px 30px 50px 30px'
});
const VideoSection = styled.div(({ isMobile }) => {
    return {
        paddingTop: '30px',
        paddingLeft: '30px',
        display: 'flex',
        width: '100%',
        overflowX: 'scroll',
        whiteSpace: 'nowrap',
        marginBottom: '50px',
        paddingBottom: '30px',
        '-ms-overflow-style': isMobile && 'none' /* IE and Edge */,
        scrollbarWidth: 'thin' /* Firefox */,
        scrollbarColor: '#EBEBEB #8E9295' /* Firefox */,
        scrollbarWidth: isMobile && 'none' /* Firefox */,
        '::-webkit-scrollbar': {
            width: '8px',
            height: '8px',
            display: isMobile && 'none'
        },
        '::-webkit-scrollbar-track': {
            backgroundColor: '#EBEBEB',
            margin: '0 60px 0 30px',
            borderRadius: '4px'
        },
        '::-webkit-scrollbar-thumb': {
            background: '#8E9295',
            borderRadius: '4px',
            widht: '10px',
            backgroundClip: 'content-box'
        }
    };
});
const VideoOuterContainer = styled.div({
    width: '215px',
    marginRight: '30px'
});
const VideoContainer = styled.div(({ activeIndex, currentIndex }) => {
    return {
        position: 'relative',
        width: '215px',
        height: '120px',
        border: activeIndex === currentIndex ? '4px solid #2183CA' : '4px solid transparent',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        img: {
            height: '100%',
            width: '100%'
        },
        svg: {
            height: '20px',
            width: '20px'
        }
    };
});
const VideoCaption = styled.p(({ activeIndex, currentIndex }) => {
    return {
        fontWeight: 300,
        fontSize: '16px',
        lineHeight: '23px',
        color: activeIndex === currentIndex ? '#00234C' : '#2183CA',
        whiteSpace: 'normal',
        overflow: 'auto'
    };
});

const DocumentSection = styled.div(({ top }) => {
    return {
        paddingLeft: '30px',
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
        width: '100%',
        paddingBottom: '30px'
    };
});

const PictureSection = styled.div(({ top }) => {
    return {
        paddingLeft: '30px',
        display: 'flex',
        width: '100%',
        whiteSpace: 'nowrap'
    };
});

const DocumentContainer = styled.div({
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '20px'
});

const ImageContainer = styled.div({
    position: 'relative',
    width: '180px',
    height: '120px',
    img: {
        height: '100%',
        width: '100%',
        objectFit: 'cover'
    }
});
const ImageGrid = styled.div(({ isMobile }) => {
    return {
        marginRight: '60px',
        display: 'grid',
        gridTemplateRows: 'auto auto auto',
        gridAutoFlow: 'column',
        overflowX: 'scroll',
        gap: '30px',
        whiteSpace: 'nowrap',
        marginBottom: '30px',
        paddingBottom: '20px',
        '-ms-overflow-style': isMobile && 'none' /* IE and Edge */,
        scrollbarWidth: 'thin' /* Firefox */,
        scrollbarColor: '#EBEBEB #8E9295' /* Firefox */,
        scrollbarWidth: isMobile && 'none' /* Firefox */,
        '::-webkit-scrollbar': {
            width: '8px',
            height: '8px',
            display: isMobile && 'none'
        },
        '::-webkit-scrollbar-track': {
            backgroundColor: '#EBEBEB',
            margin: '0 60px 0 30px',
            borderRadius: '4px'
        },
        '::-webkit-scrollbar-thumb': {
            background: '#8E9295',
            borderRadius: '4px',
            widht: '10px',
            backgroundClip: 'content-box'
        }
    };
});

const ImageOverlay = styled.div({
    position: 'absolute',
    background: 'linear-gradient(0deg, rgba(110, 110, 110, 0.7), rgba(110, 110, 110, 0.7))',
    height: '99%',
    width: '100%',
    left: 0,
    top: 0,
    bottom: 0,
    right: 0,
    opacity: 0,
    '-webkit-transition': 'all 0.4s ease-in-out 0s',
    '-moz-transition': 'all 0.4s ease-in-out 0s',
    transition: 'all 0.4s ease-in-out 0s',
    '&:hover': {
        opacity: 1
    },
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer'
});
const ButtonContainer = styled.div({
    paddingBottom: 50,
    paddingLeft: 30
});

const EventAssets = (props) => {
    const { eventModel } = props;
    const [activeIndex, setActiveIndex] = useState(0);
    const [videoIsPlaying, setVideoIsPlaying] = useState(false);
    const [videoSectionIsExtended, setVideoSectionIsExtended] = useState(true);
    const [documentSectionIsExtended, setDocumentSectionIsExtended] = useState(true);
    const [pictureSectionIsExtended, setPictureSectionIsExtended] = useState(true);
    const [showImageCarousel, setShowImageCarousel] = useState(false);
    const [slideIndex, setSlideIndex] = useState(0);
    const { isMobile } = useResponsive();

    if (!eventModel.assets) {
        return null;
    }
    const picturesDownloadUrl = eventModel.downloads.pictures.link || '';
    const documentsDownloadUrl = eventModel.downloads.presentations.link || '';
    const picturesDownloadSize = DownloadsUtil.formatFileSize(eventModel.downloads.pictures.size) || '';
    const documentsDownloadSize = DownloadsUtil.formatFileSize(eventModel.downloads.presentations.size) || '';

    const handleOnClickHeader = (downloadGroupId) => {
        switch (downloadGroupId) {
            case 1:
                setVideoSectionIsExtended(!videoSectionIsExtended);
                break;
            case 2:
                setDocumentSectionIsExtended(!documentSectionIsExtended);
                break;
            case 3:
                setPictureSectionIsExtended(!pictureSectionIsExtended);
                break;
            default:
                return;
        }
    };
    const handleOnClickDownload = (e, url) => {
        e.stopPropagation();
        window.location.href = url;
    };

    const handleOnClickImage = (index) => {
        setSlideIndex(index);
        setShowImageCarousel(true);
    };
    const closeOverlay = () => {
        setShowImageCarousel(false);
    };
    const handleOnClickThumbnail = (index) => {
        setActiveIndex(index);
        setVideoIsPlaying(false);
    };
    const handleOnClickVideo = () => {
        setVideoIsPlaying(true);
    };

    const handleOnVideoPlayerEvent = (eventAction) => {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            eventAction,
            videoTitle: eventModel.videoCaptions[activeIndex],
            eventName: eventModel.title,
            event: 'video'
        });
    };

    return (
        <Container>
            {eventModel.videos.length > 0 && (
                <>
                    <MainVideoCaption>{eventModel.videoCaptions[activeIndex]}</MainVideoCaption>
                    <MainVideoDescription>{eventModel.videoDescriptions[activeIndex]}</MainVideoDescription>
                    {videoIsPlaying ? (
                        <VideoCanvas aspectRatio={'16:9'} url={eventModel.videoUrls[activeIndex]} playing onEventAction={handleOnVideoPlayerEvent} />
                    ) : (
                        <MainVideoOuterContainer>
                            <MainVideoContainer onClick={() => handleOnClickVideo()}>
                                <img src={eventModel.videoThumbnails[activeIndex][0]?.url} alt="" />
                                <VideoPlayButton>{IconVideoPlay}</VideoPlayButton>
                            </MainVideoContainer>
                        </MainVideoOuterContainer>
                    )}
                    <VideoSection isMobile={isMobile}>
                        {eventModel.videoThumbnails.map((item, i) => (
                            <div key={String(i)}>
                                <VideoOuterContainer>
                                    <VideoContainer
                                        activeIndex={activeIndex}
                                        currentIndex={i}
                                        onClick={() => handleOnClickThumbnail(i)}
                                    >
                                        <img src={item[0]?.url} alt="" />
                                        <VideoPlayButton>{IconVideoPlay}</VideoPlayButton>
                                    </VideoContainer>
                                    <VideoCaption activeIndex={activeIndex} currentIndex={i}>
                                        {eventModel.videoCaptions[i]}
                                    </VideoCaption>
                                </VideoOuterContainer>
                            </div>
                        ))}
                    </VideoSection>
                </>
            )}

            {eventModel.documents.length > 0 && (
                <>
                    <SectionHeader onClick={() => handleOnClickHeader(2)}>
                        <div className="bt-downloads-group__header-group">
                            <div className="bt-downloads-group__header-icon">{IconPdf30}</div>
                            <div className="bt-downloads-group__header-title">
                                <span>{'Related documents'}</span>
                            </div>
                        </div>
                        <div className="bt-downloads-group__header-group">
                            <div className="bt-downloads-group__header-btn-toggle">
                                <Button
                                    layout="akkordeon-toggle"
                                    path="#"
                                    iconRight={documentSectionIsExtended ? IconArrowUp20 : IconArrowDown20}
                                />
                            </div>
                        </div>
                    </SectionHeader>
                    <ToggleBox show={documentSectionIsExtended}>
                        <DocumentSection>
                            {eventModel.documents.map((agenda, index) => (
                                <DocumentContainer key={String(index)}>
                                    <Wrapper marginTop20>
                                        <ButtonDownload
                                            path={agenda.url}
                                            label={`${agenda.title} (PDF ${DownloadsUtil.formatFileSize(agenda.size)})`}
                                            iconLeft={IconDownloads20}
                                        />
                                    </Wrapper>
                                </DocumentContainer>
                            ))}
                        </DocumentSection>
                        <ButtonContainer>
                            <Button
                                layout="secondary"
                                label={`Download all PDFs (${documentsDownloadSize})`}
                                onClick={(e) => handleOnClickDownload(e, documentsDownloadUrl)}
                            />
                        </ButtonContainer>
                    </ToggleBox>
                </>
            )}

            {eventModel.pictures.length > 0 && (
                <>
                    <SectionHeader onClick={() => handleOnClickHeader(3)}>
                        <div className="bt-downloads-group__header-group">
                            <div className="bt-downloads-group__header-icon">{IconImage30}</div>
                            <div className="bt-downloads-group__header-title">
                                <span>{'Pictures'}</span>
                            </div>
                        </div>
                        <div className="bt-downloads-group__header-group">
                            <div className="bt-downloads-group__header-btn-toggle">
                                <Button
                                    layout="akkordeon-toggle"
                                    path="#"
                                    iconRight={pictureSectionIsExtended ? IconArrowUp20 : IconArrowDown20}
                                />
                            </div>
                        </div>
                    </SectionHeader>
                    <ToggleBox show={pictureSectionIsExtended}>
                        <PictureSection>
                            <ImageGrid isMobile={isMobile}>
                                {eventModel.videoRelatedPictures[activeIndex].map((item, i) => (
                                    <div key={String(i)}>
                                        <ImageContainer onClick={() => handleOnClickImage(i)}>
                                            <img src={item.url} alt="" />
                                            <ImageOverlay>
                                                <div className="bt-downloads-group__header-icon">{IconExpandImage}</div>
                                            </ImageOverlay>
                                        </ImageContainer>
                                    </div>
                                ))}
                            </ImageGrid>
                        </PictureSection>
                        <ButtonContainer>
                            <Button
                                layout="secondary"
                                label={`Download all pictures (${picturesDownloadSize})`}
                                onClick={(e) => handleOnClickDownload(e, picturesDownloadUrl)}
                            />
                        </ButtonContainer>
                    </ToggleBox>
                </>
            )}

            {showImageCarousel && (
                <ImageLayer
                    slideIndex={slideIndex}
                    data={eventModel.videoRelatedPictures[activeIndex]}
                    closeOverlay={closeOverlay}
                />
            )}
        </Container>
    );
};

EventAssets.propTypes = {
    match: PropTypes.object,
    eventModel: PropTypes.object
};

EventAssets.defaultProps = {
    match: {},
    eventModel: {}
};

export default EventAssets;
