export const colors = {
  btBlue: '#00234C',
  btBlueGrey: '#587992',
  btLightBlueGrey: '#EEF1F4',
  btSky: '#2183CA',
  btDarkSky: '#0361A5',
  btLightSky: '#F6F8F9',
  btDarkGray: '#6E6E6E',
  btGray: '#8E9295',
  btMidGray: '#CFD1D2',
  btLightGray: '#EBEBEB',
  btVeryLightGray: '#F5F5F5',
  btGreen: '#4ABC3C',
  btLightGreen: '#75EE66',
};
