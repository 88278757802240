/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './radio-button.scss';

class RadioButton extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valueByProp: '',
            value: ''
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.value !== state.valueByProp) {
            return {
                valueByProp: props.value,
                value: props.value
            };
        }
        return null;
    }

    handleOnChange(e) {
        const { onChange } = this.props;
        const { value } = e.target;
        this.setState({
            value
        });
        onChange(value);
    }

    // handleChangeRadio(e) {
    //     this.setState({
    //         value: e.target.value
    //     });
    // }

    render() {
        // If the checked parameter is provided, the checkbox will stay checked (true) or unchecked (false)
        const { label, checked, name } = this.props;

        return (
            <div className={`bt-radio-button`}>
                <input
                    type='radio'
                    name={name}
                    value={this.state.value}
                    onChange={e => this.handleOnChange(e)}
                    checked={checked}
                />
                <label>{label}</label>
            </div>
        );
    }
}

RadioButton.propTypes = {
    label: PropTypes.string,
    checked: PropTypes.bool,
    name: PropTypes.string
};

RadioButton.defaultProps = {
    label: '',
    name: ''
};

export default RadioButton;
