/* eslint-disable */
import { REDUX_ACTIONS } from '../global/constants';

function searchReducer(
    state = {
        searchModels: [],
        isSearchLoading: false,
        isSearchLoaded: false
    },
    action
) {
    switch (action.type) {
        case REDUX_ACTIONS.UPDATE_SEARCH_MODELS:
            return Object.assign({}, state, {
                searchModels: action.searchModels
            });

        case REDUX_ACTIONS.IS_SEARCH_LOADING:
            return Object.assign({}, state, {
                isSearchLoading: action.isSearchLoading
            });

        case REDUX_ACTIONS.IS_SEARCH_LOADED:
            return Object.assign({}, state, {
                isSearchLoaded: true
            });

        default:
            return state;
    }
}

export default searchReducer;
