import React from 'react';
// import PropTypes from 'prop-types';
import { Block, Grid, GridItem } from '@webfox-sc/core';
import PageContentHeader from '../PageContentHeader';
import CopyTextM from '../elements/CopyTextM';
import ContactTeaser from '../../molecules/ContactTeaser/ContactTeaser';
import AppUtil from '../../utils/AppUtil';
import { useResponsive } from '../../hooks/responsive';
import { useBaseData } from '../../hooks/useBaseData';

const teaserBlockTheme = {
  root: {
    maxWidth: '1130px',
  },
};

const PageViewContact = () => {
  const { isMobile, isMinDesktopM } = useResponsive();
  const { contactPageHeadline, contactPageIntroText, contacts } = useBaseData();

  return (
    <>
      <PageContentHeader title={contactPageHeadline} />
      <Block padding={isMobile ? '20px 22px' : '30px 0'}>
        <CopyTextM text={contactPageIntroText} />
        <Block paddingTop={isMobile ? '50px' : '70px'} theme={teaserBlockTheme}>
          <Grid spacing="30px">
            {contacts.map((contact, i) => {
              const image = contact.image || {};
              const imageSizes = image.sizes || {};
              const phoneNumber = contact.phone || '';
              const mobile = contact.mobile || {};
              const email = contact.email || {};
              return (
                <GridItem key={String(i)} cols={isMinDesktopM ? 6 : 12}>
                  <ContactTeaser
                    imageSrc={imageSizes.medium_large}
                    profileInitials={AppUtil.getInitials(contact.firstName, contact.lastName)}
                    title={`${contact.firstName} ${contact.lastName}${contact.type ? `, ${contact.type}` : ''}`}
                    subtitle={contact.role}
                    phoneLabel={phoneNumber}
                    mobileLabel={mobile.title}
                    mobileHref={mobile.url}
                    emailLabel={email.title}
                    emailHref={email.url}
                  />
                </GridItem>
              );
            })}
          </Grid>
        </Block>
      </Block>
    </>
  );
};

PageViewContact.propTypes = {
  //
};

PageViewContact.defaultProps = {
  //
};

export default PageViewContact;
