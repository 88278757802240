/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import HeadlineThree from '../../components/elements/HeadlineThree';
import ScheduleTimeItem from './ScheduleTimeItem';
import './ScheduleDayItem.scss';

const ScheduleDayItem = ({ date, schedule }) => (
  <div className="bt-event-schedule__day-item">
    <div className="bt-event-schedule__date-title">
      <HeadlineThree text={date} />
    </div>
    {schedule.map((scheduleItem, i) => {
      return (
        <div key={i} className="bt-event-schedule__time-list">
          <ScheduleTimeItem time={scheduleItem.time} title={scheduleItem.title} subtitle={scheduleItem.subtitle} />
        </div>
      );
    })}
  </div>
);

ScheduleDayItem.propTypes = {
  date: PropTypes.string,
  schedule: PropTypes.array,
};

ScheduleDayItem.defaultProps = {
  date: '',
  schedule: [],
};

export default ScheduleDayItem;
