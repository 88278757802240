import { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { themeBreakpoints } from '../styles/theme';

export function useResponsive() {
  const [isClient, setIsClient] = useState(false);

  const isMobile = useMediaQuery({
    maxWidth: themeBreakpoints.TABLET - 1,
  });

  const isMinTablet = useMediaQuery({
    minWidth: themeBreakpoints.TABLET,
  });

  const isMinDesktopS = useMediaQuery({
    minWidth: themeBreakpoints.DESKTOP_S,
  });

  const isMinDesktopM = useMediaQuery({
    minWidth: themeBreakpoints.DESKTOP_M,
  });

  useEffect(() => {
    if (typeof window !== 'undefined') setIsClient(true);
  }, []);

  return {
    isMinDesktopM: isClient ? isMinDesktopM : true,
    isMinDesktopS: isClient ? isMinDesktopS : false,
    isMinTablet: isClient ? isMinTablet : false,
    isMobile: isClient ? isMobile : false,
  };
}
