import React from 'react';
import { useAppSelector } from '../../app/hooks';
import './layer-view-event-location.scss';

const LayerViewEventOnlineVenue: React.FC = () => {
  const { activeEventModel } = useAppSelector(({ eventsState }) => eventsState);

  return <span className="bt-event-venue" dangerouslySetInnerHTML={{ __html: activeEventModel.onlineVenue }} />;
};

export default LayerViewEventOnlineVenue;
