import React from 'react';
import PropTypes from 'prop-types';
import { useEvent } from './useEvent';
import EventTeaser from '../../molecules/EventTeaser/EventTeaser';
import { useOverlayContext } from '../overlay/OverlayProvider';
import { apiUrl } from '../../app/api';

const EventTeaserWrapper = ({ event, onToggleAttend }) => {
  const { overlayNavigate } = useOverlayContext();

  const {
    eventCategoryFormatted,
    eventLocationFormatted,
    eventDateFormatted,
    eventTimeFormatted,
    eventCoverPictureMediumLarge,
    eventAttendanceType,
    eventIsDigital,
  } = useEvent(event);

  if (!event) {
    return null;
  }

  const handleOnClickDetails = (e) => {
    e.preventDefault();
    overlayNavigate(`/events/event/${event.id}`);
  };

  const handleOnClickCalender = () => {
    window.location.href = `${apiUrl}/events/${event.id}/ics`;
  };

  return (
    <EventTeaser
      id={event.id}
      category={event.category}
      categoryFormatted={eventCategoryFormatted}
      title={event.title}
      subTitle={event.subTitle}
      date={eventDateFormatted}
      time={eventTimeFormatted}
      location={eventLocationFormatted}
      imgSrc={eventCoverPictureMediumLarge}
      attendees={event.attendees}
      isAttending={event.isAttending}
      canAttend={event.canAttend}
      attendanceType={eventAttendanceType}
      isDigital={eventIsDigital}
      detailsPath={`/events/event/${event.id}/description`}
      eventPath={`/events/event/${event.id}`}
      hrefCalendar={`${apiUrl}/events/${event.id}/ics`}
      onClickDetails={handleOnClickDetails}
      onClickCalendar={handleOnClickCalender}
      onToggleAttend={onToggleAttend}
      registrationLink={event.registrationLink}
      informationLink={event.informationLink}
    />
  );
};

EventTeaserWrapper.propTypes = {
  event: PropTypes.object,
  onToggleAttend: PropTypes.func,
};

EventTeaserWrapper.defaultProps = {
  event: undefined,
  onToggleAttend: undefined,
};

export default EventTeaserWrapper;
