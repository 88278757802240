/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './toggle.scss';

class Toggle extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valueByProp: false,
            value: false
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.value !== state.valueByProp) {
            return {
                valueByProp: props.value,
                value: props.value
            };
        }
        return null;
    }

    handleChangeToggle(e) {
        const { onChange } = this.props;
        const { checked } = e.target;

        this.setState({
            value: checked
        });

        onChange(checked);
    }

    render() {
        const { value } = this.state;

        return (
            <div className={`bt-toggle`}>
                <label className="bt-toggle__switch">
                    <input type="checkbox" value={value} checked={value} onChange={(e) => this.handleChangeToggle(e)} />
                    <span className="bt-toggle__label" data-on="Yes" data-off="No" />
                    <span className="bt-toggle__slider" />
                </label>
            </div>
        );
    }
}

Toggle.propTypes = {
    onChange: PropTypes.func
};

Toggle.defaultProps = {
    onChange: () => false
};

export default Toggle;
