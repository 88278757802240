/* eslint-disable */
import React from 'react';

const IconImage17 = (
    <svg width="17" height="17" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="evenodd">
            <path d="M4.56384615 7.89061538c1.00474359 0 1.8203077-.816 1.8203077-1.82030769 0-1.003-.81556411-1.82030769-1.8203077-1.82030769-1.00474359 0-1.82030769.81730769-1.82030769 1.82030769 0 1.00430769.8155641 1.82030769 1.82030769 1.82030769zm0-2.98676923c.64382052 0 1.16646154.52438462 1.16646154 1.16646154 0 .64338462-.52264102 1.16646154-1.16646154 1.16646154-.64382051 0-1.16646153-.52307692-1.16646153-1.16646154 0-.64207692.52264102-1.16646154 1.16646153-1.16646154z" />
            <path d="M-.02615385 17H16.9738462V0H-.02615385v17zm.68-16.32H16.2938462v8.72923754l-3.5097067-3.09248491c-.0675467-.05791333-.1541333-.08665228-.2443467-.08491052-.0902133.0034835-.17544.04267298-.2375466.10711789L8.97341949 9.84119263 7.36000615 8.29320842c-.12738666-.12235824-.32957333-.12888982-.46376-.01524035L1.20464615 13.09h-.5508V.68zm0 15.64H16.2938462v-2.55H.65384615v2.55zm1.53-3.23l4.88745067-4.15244942 3.51567038 3.39107192c.1333107.1299698.3485186.1299698.4818293 0 .1333106-.1286526.1333106-.336341 0-.4649936l-1.64067652-1.58291L12.5616028 6.97l3.7322434 3.3001808V13.09H2.18384615z" />
        </g>
    </svg>
);

export default IconImage17;
