import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ToggleBox from '../../components/elements/ToggleBox';
import PageTitle from '../../components/PageTitle';
import SearchInput from '../SearchInput/SearchInput';
import IconMenu40 from '../../assets/icons/IconMenu40';
import IconSearch16 from '../../assets/icons/IconSearch16';
import IconClose20 from '../../assets/icons/IconClose20';
import AppUtil from '../../utils/AppUtil';
import './PageHeaderMobile.scss';

class PageHeaderMobile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showSearch: false,
    };
  }

  handleOnClickSearchButton() {
    const { showSearch } = this.state;
    this.setState({
      showSearch: !showSearch,
    });
  }

  handleOnSubmitSearchInput(searchValue) {
    AppUtil.submitSearch(searchValue);
    this.setState({
      showSearch: false,
    });

    const { onSubmitSearch } = this.props;
    onSubmitSearch();
  }

  render() {
    const { showSearch } = this.state;
    const { onClickMenu, onClickTitle } = this.props;

    let className = 'bt-page-header-mobile';
    if (showSearch) {
      className += ' bt-page-header-mobile--show-search';
    }

    let classNameSearchButton = 'bt-page-header-mobile__search-button';
    if (showSearch) {
      classNameSearchButton += ' bt-page-header-mobile__search-button--show-search';
    }

    return (
      <div className={className}>
        <div className="bt-page-header-mobile__main">
          <div className="bt-page-header-mobile__menu" onClick={onClickMenu}>
            {IconMenu40}
          </div>
          <div className="bt-page-header-mobile__title">
            <PageTitle mobile onClick={onClickTitle} />
          </div>
          <div className={classNameSearchButton} onClick={() => this.handleOnClickSearchButton()}>
            {showSearch ? IconClose20 : IconSearch16}
          </div>
        </div>
        <ToggleBox show={showSearch}>
          <div className="bt-page-header-mobile__search">
            <SearchInput onSubmit={(searchValue) => this.handleOnSubmitSearchInput(searchValue)} />
          </div>
        </ToggleBox>
      </div>
    );
  }
}

PageHeaderMobile.propTypes = {
  onClickMenu: PropTypes.func,
  onClickTitle: PropTypes.func,
  onSubmitSearch: PropTypes.func,
};

PageHeaderMobile.defaultProps = {
  onClickMenu: () => false,
  onClickTitle: () => false,
  onSubmitSearch: () => false,
};

export default PageHeaderMobile;
