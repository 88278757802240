import React from 'react';
import Button from '../elements/Button';
import ImageThumb from '../elements/ImageThumb';
import LabelMRegular from '../elements/LabelMRegular';
import IconNewsFeed27 from '../../assets/icons/IconNewsFeed27';
import IconEvents27 from '../../assets/icons/IconEvents27';
import IconFellows27 from '../../assets/icons/IconFellows27';
import IconDownloads27 from '../../assets/icons/IconDownloads27';
import IconClose30 from '../../assets/icons/IconClose30';
import IconLogout30 from '../../assets/icons/IconLogout30';
import IconMetaWebsiteBiotronik20 from '../../assets/icons/IconMetaWebsiteBiotronik20';
import IconMetaAppBiotronik20 from '../../assets/icons/IconMetaAppBiotronik20';
import IconMetaTwitter20 from '../../assets/icons/IconMetaTwitter20';
import IconMetaLinkedIn20 from '../../assets/icons/IconMetaLinkedIn20';
import './mobile-menu.scss';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { logout } from '../../app/slices/authSlice';
import AppUtil from '../../utils/AppUtil';

const metaNavigationModels = [
  {
    label: 'About us',
    path: '/about',
    pageId: 'about',
  },
  {
    label: 'Contact us',
    path: '/contacts',
    pageId: 'contacts',
  },
  {
    label: 'Imprint',
    path: '/imprint',
    pageId: 'imprint',
  },
  {
    label: 'Terms and Conditions',
    path: '/terms',
    pageId: 'terms',
  },
  {
    label: 'Privacy Statement',
    path: '/privacy',
    pageId: 'privacy',
  },
];

const MobileMenu: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { authStatus } = useAppSelector(({ authState }) => authState);
  const { pageId, contentId, baseDataLinks } = useAppSelector(({ appState }) => appState);
  const { profileFirstName, profileLastName, profilePictureSrc } = useAppSelector(({ profileState }) => profileState);

  const handleOnClickLink = (e: any, path: string) => {
    e.preventDefault();
    navigate(path);
  };

  const handleOnClickClose = () => {
    navigate(`/${pageId}`);
  };

  const handleOnClickLogout = (e) => {
    e.preventDefault();
    dispatch(logout());
    navigate('/');
  };

  const cssMainItem = 'bt-mobile-menu__main-item';
  const cssMainItemActive = 'bt-mobile-menu__main-item--active';

  return (
    <div className="bt-mobile-menu">
      <div className="bt-mobile-menu__shader" />
      <div className="bt-mobile-menu__canvas">
        <div className="bt-mobile-menu__canvas-content">
          {authStatus === 'loggedIn' && (
            <div className="bt-mobile-menu__main">
              <div
                className={`
                    ${cssMainItem}
                    ${cssMainItem}--news-feed
                    ${pageId === 'news' ? cssMainItemActive : ''}
                `}
                onClick={() => navigate('/news')}
              >
                {IconNewsFeed27}
                <div className="bt-mobile-menu__main-item-label">
                  <LabelMRegular text="News feed" />
                </div>
              </div>
              <div
                className={`
                    ${cssMainItem}
                    ${cssMainItem}--events
                    ${pageId === 'events' ? cssMainItemActive : ''}
                `}
                onClick={() => navigate('/events')}
              >
                {IconEvents27}
                <div className="bt-mobile-menu__main-item-label">
                  <LabelMRegular text="Events" />
                </div>
              </div>
              <div
                className={`
                    ${cssMainItem}
                    ${cssMainItem}--fellows
                    ${pageId === 'fellows' ? cssMainItemActive : ''}
                `}
                onClick={() => navigate('/fellows')}
              >
                {IconFellows27}
                <div className="bt-mobile-menu__main-item-label">
                  <LabelMRegular text="Fellows" />
                </div>
              </div>
              <div
                className={`
                    ${cssMainItem}
                    ${cssMainItem}--downloads
                    ${pageId === 'downloads' ? cssMainItemActive : ''}
                `}
                onClick={() => navigate('/downloads')}
              >
                {IconDownloads27}
                <div className="bt-mobile-menu__main-item-label">
                  <LabelMRegular text="Downloads" />
                </div>
              </div>
              <div
                className="bt-mobile-menu__main-item bt-mobile-menu__main-item--profile"
                onClick={() => navigate(`/${pageId}/${contentId}/profile/data`)}
              >
                <div className="bt-mobile-menu__main-item-profile-picture">
                  <ImageThumb
                    profileInitials={AppUtil.getInitials(profileFirstName, profileLastName)}
                    imageSrc={profilePictureSrc}
                    isMobileMenu={true}
                  />
                </div>
                <div className="bt-mobile-menu__main-item-label">
                  <LabelMRegular text="My Profile" />
                </div>
              </div>
            </div>
          )}
          <div
            className={
              authStatus === 'loggedIn'
                ? 'bt-mobile-menu__meta bt-mobile-menu__meta--logged-in'
                : 'bt-mobile-menu__meta'
            }
          >
            {metaNavigationModels.map((navigationModel) => {
              return (
                <div key={navigationModel.pageId} className="bt-mobile-menu__meta-item">
                  <Button
                    layout={pageId === navigationModel.pageId ? 'meta-mobile-active' : 'meta-mobile'}
                    path={navigationModel.path}
                    label={navigationModel.label}
                    onClick={(e: any) => handleOnClickLink(e, navigationModel.path)}
                  />
                </div>
              );
            })}
          </div>

          <div
            className={
              authStatus === 'loggedIn'
                ? 'bt-mobile-menu__links bt-mobile-menu__links--logged-in'
                : 'bt-mobile-menu__links'
            }
          >
            {Object.keys(baseDataLinks).map((key, i) => {
              const link = baseDataLinks[key];

              let icon: any = null;
              switch (key) {
                case 'biotronikHome':
                  icon = IconMetaWebsiteBiotronik20;
                  break;

                case 'biotronikApp':
                  icon = IconMetaAppBiotronik20;
                  break;

                case 'twitter':
                  icon = IconMetaTwitter20;
                  break;

                case 'linkedin':
                  icon = IconMetaLinkedIn20;
                  break;

                default:
                  icon = IconMetaWebsiteBiotronik20;
                  break;
              }

              return (
                <a key={String(i)} className="bt-mobile-menu__link" href={link.url} target={link.target}>
                  <div className="bt-mobile-menu__link-icon">{icon}</div>
                  <div className="bt-mobile-menu__link-title">
                    <span>{link.title}</span>
                  </div>
                </a>
              );
            })}
          </div>

          {authStatus === 'loggedIn' && (
            <div className="bt-mobile-menu__logout" onClick={handleOnClickClose}>
              <Button
                iconRight={IconLogout30}
                layout="meta-mobile"
                label={'Logout'}
                onClick={(e) => handleOnClickLogout(e)}
              />
            </div>
          )}
        </div>
      </div>

      <div className="bt-mobile-menu__close" onClick={handleOnClickClose}>
        {IconClose30}
      </div>
    </div>
  );
};

export default MobileMenu;
