import React, { useEffect, useState } from 'react';
import Button from '../elements/Button';
import IconClose30 from '../../assets/icons/IconClose30';
import IconBack30 from '../../assets/icons/IconBack30';
import { Outlet, useNavigate, useOutlet, useParams } from 'react-router-dom';
import { useOverlayContext } from './OverlayProvider';
import { useAppSelector } from '../../app/hooks';
import './layer.scss';

const Layer: React.FC = () => {
  const { closeOverlay, setOverlayParams } = useOverlayContext();
  const navigate = useNavigate();
  const params = useParams();

  const outlet = useOutlet();
  const isOverlayVisible = outlet !== null;

  const { contentId, previousContentId, subContentId } = useAppSelector(({ appState }) => appState);
  const [showBackbutton, setShowBackbutton] = useState(false);

  useEffect(() => {
    setOverlayParams(params);
  }, [params, setOverlayParams]);

  /**
   * block page scrolling if overlay is shown
   */
  useEffect(() => {
    const body = document.querySelector('body');
    if (isOverlayVisible) {
      body?.style.setProperty('overflow-y', 'hidden');
    } else {
      body?.style.setProperty('overflow-y', 'auto');
    }
  }, [isOverlayVisible]);

  useEffect(() => {
    if (contentId === 'fellow' && previousContentId === 'event') {
      setShowBackbutton(true);
    } else {
      setShowBackbutton(false);
    }
  }, [contentId, previousContentId]);

  const classNameLayer = isOverlayVisible ? 'bt-layer' : 'bt-layer bt-layer--hidden';
  const classNameLayerHeader =
    subContentId === 'profile' ? 'bt-layer__header bt-layer__header--profile' : 'bt-layer__header';

  return (
    <div className={classNameLayer}>
      <div className="bt-layer__shader" onClick={() => closeOverlay()} />
      <div className="bt-layer__canvas-wrapper">
        <div className="bt-layer__canvas">
          <div className="bt-layer__canvas-content">
            <div>
              <Outlet />
            </div>
          </div>
          <div className={classNameLayerHeader}>
            <div className="bt-layer__header-back">
              {showBackbutton && (
                <Button layout="layer-back" label="Back" iconLeft={IconBack30} onClick={() => navigate(-1)} />
              )}
            </div>
            <div className="bt-layer__header-close">
              <Button layout="layer-close" label="Close" iconRight={IconClose30} onClick={() => closeOverlay()} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Layer;
