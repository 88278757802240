/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './button.scss';

class Button extends Component {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  get layoutClassName() {
    const { layout } = this.props;

    switch (layout) {
      case 'primary':
        return 'bt-button--primary';

      case 'primary-side':
        return 'bt-button--primary-side';

      case 'primary-side-small':
        return 'bt-button--primary-side-small';

      case 'mobile-side-info':
        return 'bt-button--mobile-side-info';

      case 'primary-side-info':
        return 'bt-button--primary-side-info';

      case 'secondary':
        return 'bt-button--secondary';

      case 'secondary-active':
        return 'bt-button--secondary-active';

      case 'secondary-side':
        return 'bt-button--secondary-side';

      case 'secondary-side-small':
        return 'bt-button--secondary-side-small';

      case 'secondary-white-side':
        return 'bt-button--secondary-white-side';

      case 'meta-mobile':
        return 'bt-button--meta-mobile';

      case 'meta-mobile-active':
        return 'bt-button--meta-mobile-active';

      case 'akkordeon-toggle':
        return 'bt-button--akkordeon-toggle';

      case 'layer-back':
        return 'bt-button--layer-back';

      case 'layer-close':
        return 'bt-button--layer-close';

      case 'grey':
        return 'bt-button--grey';

      default:
        return '';
    }
  }

  renderIconLeft() {
    const { iconLeft } = this.props;
    if (!iconLeft) {
      return null;
    }
    return <span className="bt-button__icon-left">{iconLeft}</span>;
  }

  renderLabel() {
    const { label } = this.props;
    if (!label) {
      return null;
    }
    return <span className="bt-button__label">{label}</span>;
  }

  renderIconRight() {
    const { iconRight } = this.props;
    if (!iconRight) {
      return null;
    }
    return <span className="bt-button__icon-right">{iconRight}</span>;
  }

  renderIconInfo() {
    const { iconInfo } = this.props;
    if (!iconInfo) {
      return null;
    }
    return <span className="bt-button__icon-info">{iconInfo}</span>;
  }

  handleClick(e) {
    const { path, onClick, link } = this.props;
    if (!link) {
      e.preventDefault();
    }
    onClick(e, path);
  }

  render() {
    const { path } = this.props;

    return (
      <a
        href={path}
        className={`bt-button ${this.layoutClassName} ${(this.props.lineBreak && 'bt-button-line-break') || ''}`}
        onClick={this.handleClick}
        target={this.props.target}
        download={this.props.download}
      >
        <span className="bt-button__wrapper">
          {this.renderIconLeft()}
          {this.renderLabel()}
          {this.renderIconRight()}
          {this.renderIconInfo()}
        </span>
      </a>
    );
  }
}

Button.propTypes = {
  layout: PropTypes.string,
  path: PropTypes.string,
  label: PropTypes.string,
  iconLeft: PropTypes.object,
  iconRight: PropTypes.object,
  iconInfo: PropTypes.object,
  onClick: PropTypes.func,
  lineBreak: PropTypes.bool,
  target: PropTypes.string,
  download: PropTypes.bool,
  link: PropTypes.bool,
};

Button.defaultProps = {
  layout: '',
  path: '',
  label: '',
  iconLeft: null,
  iconRight: null,
  iconInfo: null,
  onClick: () => false,
  lineBreak: false,
  target: '',
  download: false,
  link: false,
};

export default Button;
