import React, { useState } from 'react';
import HeadlineOne from '../elements/HeadlineOne';
import CopyTextM from '../elements/CopyTextM';
import ContactElement from '../../molecules/ContactElement/ContactElement';
import UserUtil from '../../utils/UserUtil';
import ToggleBox from '../elements/ToggleBox';
import IconInfo20 from '../../assets/icons/IconInfo20';
import ForgotPasswordForm from '../ForgotPasswordForm/ForgotPasswordForm';
import imgSrc from '../../assets/images/Mitarbeiterportrait3.jpg';
import { useNavigate } from 'react-router-dom';
import './page-view-forgot-password.scss';

const PageViewForgotPassword: React.FC = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);
  const [resetPasswordFailed, setResetPasswordFailed] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleClickToLogin = () => {
    navigate('/');
  };

  const handleSubmit = async () => {
    try {
      await UserUtil.initiatePasswordReset(email);
      setEmail('');
      setResetPasswordSuccess(true);
    } catch (error: any) {
      setResetPasswordFailed(true);
      setErrorMessage(error.response.data.error);
    }
  };

  return (
    <div className="page-view-forgot-password">
      <HeadlineOne text="Reset Password" />
      <div className="page-view-forgot-password__text">
        <CopyTextM text="Please fill in the email address associated with your account below and we will send you an email containing a link to reset your password. Please make sure to activate this link within 48 hours" />
      </div>

      <ToggleBox show={resetPasswordSuccess}>
        <div className="page-view-forgot-password__confirmation">
          <span>{IconInfo20}</span>
          <CopyTextM text="We have sent you an email with a link to reset your password to the address associated with your account. Please check this email and follow the instructions. If you have not received an email, please check your spam folder and ensure that you have entered your details in correctly." />
        </div>
      </ToggleBox>

      <ToggleBox show={resetPasswordFailed}>
        <div className="page-view-forgot-password__error">
          <span>{IconInfo20}</span>
          <CopyTextM text={errorMessage} />
        </div>
      </ToggleBox>

      <div className="page-view-forgot-password__form">
        <ForgotPasswordForm
          onClickToLogin={() => handleClickToLogin()}
          onChangeEmail={(value) => setEmail(value)}
          onSubmit={() => handleSubmit()}
        />
      </div>
      <CopyTextM text={'In case you cannot remember your email, please reach out to:'} />
      <div className="page-view-forgot-password__contact">
        <ContactElement
          name="Ines WALDMANN"
          location="BIOTRONIK HQ, Berlin"
          tel="+49 151 6890 1421"
          email="inez.waldmann@biotronik.com"
          imgSrc={imgSrc}
        />
      </div>
    </div>
  );
};

export default PageViewForgotPassword;
