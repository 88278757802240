/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './select.scss';

class Select extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valueByProp: '',
            value: ''
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.value !== state.valueByProp) {
            return {
                valueByProp: props.value,
                value: props.value
            };
        }
        return null;
    }

    handleOnChange(e) {
        const { onChange } = this.props;
        const { value } = e.target;
        this.setState({
            value
        });
        onChange(value);
    }

    render() {
        const { value } = this.state;
        const { options, placeholder, label, error, errorMessage } = this.props;

        return (
            <div className={`bt-select ${error && 'error-select'} ${!value && 'unselected'}`}>
                <div className={`bt-select__interior`}>
                    <label>{label}</label>
                    <select value={value} onChange={(e) => this.handleOnChange(e)}>
                        {placeholder && (
                            <option value="" disabled display="none">
                                {placeholder}
                            </option>
                        )}

                        {options.map((option, i) => (
                            <option key={i} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </select>
                </div>
                {error && <div className="bt-select__error-message">{errorMessage}</div>}
            </div>
        );
    }
}

Select.propTypes = {
    /**
     * @prop options
     * @type array
     * @description array with data objects (value | label)
     */
    options: PropTypes.array,

    /**
     * @prop value
     * @type string | number | boolean
     * @description default value
     */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),

    placeholder: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,

    /**
     * @prop onChange
     * @type function (callback)
     */
    onChange: PropTypes.func
};

Select.defaultProps = {
    options: [],
    value: '',
    placeholder: '',
    label: '',
    error: false,
    unselected: false,
    errorMessage: 'Error',
    onChange: () => false
};

export default Select;
