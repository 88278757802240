import AppUtil from '../../utils/AppUtil';

export const useFellow = (fellowData: FellowData | undefined) => {
  const { firstName, lastName, profession, degree, city, country } = fellowData || {};

  const fullName = `${firstName} ${lastName}`;
  const initials = AppUtil.getInitials(firstName, lastName);

  const aProfessionDegree: string[] = [];
  if (profession) aProfessionDegree.push(profession);
  if (degree) aProfessionDegree.push(degree);
  const professionDegree = aProfessionDegree.join(' ');

  const aLocation: string[] = [];
  if (city) aLocation.push(city);
  if (country) aLocation.push(country);
  const location = aLocation.join(', ');

  return {
    ...fellowData,
    fullName,
    initials,
    professionDegree,
    location,
  };
};
