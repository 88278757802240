import React from 'react';
import PropTypes from 'prop-types';
import './page-navigation-item.scss';

const PageNavigationItem = ({ label, icon, path, isActive, onClick }) => {
  return (
    <div className={`bt-page-navigation-item ${isActive && 'active'}`}>
      <a href={path} onClick={(e) => onClick(e, path)}>
        <span className="bt-page-navigation-item__icon">{icon}</span>
        <span className="bt-page-navigation-item__label">{label}</span>
      </a>
      <div className="bt-page-navigation-item__ind" />
    </div>
  );
};

PageNavigationItem.propTypes = {
  label: PropTypes.string,
  icon: PropTypes.object,
  path: PropTypes.string,
  isActive: PropTypes.bool,
  onClick: PropTypes.func,
};

PageNavigationItem.defaultProps = {
  label: '',
  icon: null,
  path: '',
  isActive: false,
  onClick: () => false,
};

export default PageNavigationItem;
