/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import iconVideoLeft from '../../assets/icons/IconVideoLeft';
import iconVideoRight from '../../assets/icons/IconVideoRight';
import { themeColors } from '../../styles/theme';
import './carousel.scss';

const StyledCarousel = styled.div({
  position: 'relative',
  // border: '5px solid green',
  height: '100%',
});

const Container = styled.div({
  height: '99%',
  overflow: 'hidden',
  position: 'relative',

  '.carousel__slide': {
    // border: '6px solid green',
    // height: '100%'
  },
});

const CarouselHeader = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  paddingBottom: 20,
  svg: {
    cursor: 'pointer',
  },
});
const Counter = styled.div({
  color: themeColors.BLUE,
  fontSize: '20px',
});

const Carousel = (props) => {
  const {
    speed,
    infinite,
    children,
    dimensions: { slideWidth, offset, gap, slideWidthActive, slidePaddingTop, slidePaddingTopActive },
    onBeforeChange,
    onAfterChange,
    log,
    swipingDisabled,
    startIndex,
  } = props;

  const aChildren = React.Children.toArray(children);

  const countSlides = aChildren.length;

  const [slideIndex, setSlideIndex] = useState(startIndex);
  const [isSwiping, setIsSwiping] = useState(false);
  const [isTransition, setIsTransition] = useState(false);
  const [swipeDistance, setSwipeDistance] = useState(0);
  const [swipeXStart, setSwipeXStart] = useState(0);
  const [isSwipeStarted, setIsSwipeStarted] = useState(false);
  const [lockSliding, setLockSliding] = useState(false);

  let slideTimeout;
  let slideDelayTimeout;
  let autoplayTimeout;

  useEffect(() => {
    return () => {
      clearTimeout(slideTimeout);
      clearTimeout(slideDelayTimeout);
      clearTimeout(autoplayTimeout);
    };
  }, []);

  const _handleOnBeforeChange = (slideIndexNew) => {
    if (log) {
      //
    }
    onBeforeChange(slideIndex, slideIndexNew);
  };

  const _handleOnAfterChange = (slideIndexNew) => {
    let _slideIndex = slideIndex;
    if (_slideIndex < 0) {
      _slideIndex = countSlides - 1;
    } else if (_slideIndex > countSlides - 1) {
      _slideIndex = 0;
    }

    if (log) {
      //
    }
    onAfterChange(_slideIndex, slideIndexNew);
  };

  const _slideTo = (slideIndexNew) => {
    clearTimeout(slideTimeout);
    clearTimeout(slideDelayTimeout);
    clearTimeout(autoplayTimeout);

    slideDelayTimeout = setTimeout(() => {
      setSlideIndex(slideIndexNew);
      setIsTransition(true);
      setLockSliding(false);
      setSwipeDistance(0);

      slideTimeout = setTimeout(() => {
        _handleOnAfterChange(slideIndexNew);
      }, speed);
    }, 100);
  };

  const goTo = (slideIndexNew) => {
    if (lockSliding) {
      return false;
    }

    let _slideIndexNew = slideIndexNew;
    if (slideIndexNew > countSlides - 1) {
      _slideIndexNew = infinite ? 0 : countSlides - 1;
    } else if (slideIndexNew < 0) {
      _slideIndexNew = infinite ? countSlides - 1 : 0;
    }

    _handleOnBeforeChange(_slideIndexNew);

    _slideTo(_slideIndexNew);

    return true;
  };

  const _getTrackStyle = () => {
    const _offset = offset || '0px';
    const _gap = gap || '0';
    const _swipeDistance = `${swipeDistance}px`;

    let left = `calc(((${slideWidth} * ${slideIndex}) + (${slideIndex} * ${_gap}) - ${_offset} + ${_swipeDistance}) * -1)`;
    if (infinite) {
      const delta = `((${slideWidth} * ${countSlides}) + (${_gap} * ${countSlides}))`;
      left = `calc(((${slideWidth} * ${slideIndex}) + (${slideIndex} * ${_gap}) - ${_offset} + ${delta} + ${_swipeDistance}) * -1)`;
    }

    return {
      left,
      transition: isTransition ? `left ${speed / 1000}s ease` : '',
    };
  };

  const _getSlideStyle = (index) => {
    let width = slideWidth;
    if (slideWidthActive !== '' && index === slideIndex) {
      width = slideWidthActive;
    }

    let paddingTop = slidePaddingTop;
    if (slidePaddingTopActive !== '' && index === slideIndex) {
      paddingTop = slidePaddingTopActive;
    }

    let marginRight = '0';
    if (gap) {
      marginRight = gap;
    }

    return {
      width,
      marginRight,
      paddingTop,
      transition: isTransition ? `width ${speed / 1000}s ease, padding-top ${speed / 1000}s ease` : '',
    };
  };

  const _handleOnSwipeStart = (clientX) => {
    if (swipingDisabled) {
      return;
    }
    setIsTransition(false);
    setIsSwipeStarted(true);
    setSwipeXStart(clientX);
  };

  const _handleOnSwipeMove = (clientX) => {
    if (isSwipeStarted) {
      setSwipeDistance(swipeXStart - clientX);
      setIsSwiping(Math.abs(swipeDistance) > 3); // tolerance for onCLick slide content
    }
  };

  const _handleOnSwipeEnd = () => {
    setIsSwipeStarted(false);
    setIsSwiping(false);
  };

  useEffect(() => {
    if (!isSwipeStarted && !isSwiping) {
      if (swipeDistance > 0) {
        goTo(slideIndex + 1);
      } else if (swipeDistance < 0) {
        goTo(slideIndex - 1);
      }
    }
  }, [isSwipeStarted, isSwiping]);

  const _handleOnMouseEnter = () => {
    //
  };

  const _handleOnMouseDown = (e) => {
    e.preventDefault();
    _handleOnSwipeStart(e.clientX);
  };

  const _handleOnMouseMove = (e) => {
    _handleOnSwipeMove(e.clientX);
  };

  const _handleOnMouseUp = () => {
    _handleOnSwipeEnd();
  };

  const _handleOnMouseLeave = () => {
    _handleOnSwipeEnd();
  };

  const _handleOnTouchStart = (e) => {
    _handleOnSwipeStart(e.targetTouches[0].clientX);
  };

  const _handleOnTouchMove = (e) => {
    _handleOnSwipeMove(e.targetTouches[0].clientX);
  };

  const _handleOnTouchEnd = () => {
    _handleOnSwipeEnd();
  };

  let aTrackChildren;
  if (infinite) {
    aTrackChildren = [...aChildren, ...aChildren, ...aChildren];
  } else {
    aTrackChildren = [...aChildren];
  }

  return (
    <Container>
      {countSlides > 1 && (
        <CarouselHeader>
          <div onClick={() => goTo(slideIndex - 1)}>{iconVideoLeft}</div>
          <Counter>{`${slideIndex + 1}/${countSlides}`}</Counter>
          <div onClick={() => goTo(slideIndex + 1)}>{iconVideoRight}</div>
        </CarouselHeader>
      )}
      <StyledCarousel
        onMouseEnter={_handleOnMouseEnter}
        onMouseDown={_handleOnMouseDown}
        onMouseMove={_handleOnMouseMove}
        onMouseUp={_handleOnMouseUp}
        onMouseLeave={_handleOnMouseLeave}
        onTouchStart={_handleOnTouchStart}
        onTouchMove={_handleOnTouchMove}
        onTouchEnd={_handleOnTouchEnd}
      >
        {isSwiping && <div className="carousel__track-overlay" />}
        <div className="carousel__track" style={_getTrackStyle()}>
          {aTrackChildren.map((child, index) => {
            const key = String(index);
            let className = 'carousel__slide';

            const dataIndex = infinite ? index - countSlides : index;
            const isActive = index % countSlides === slideIndex;

            if (isActive) {
              className += ' carousel__slide--active';
            }

            return (
              <div key={key} data-index={dataIndex} className={className} style={_getSlideStyle(dataIndex)}>
                {child}
              </div>
            );
          })}
        </div>
      </StyledCarousel>
    </Container>
  );
};

Carousel.propTypes = {
  children: PropTypes.any,
  dimensions: PropTypes.shape({
    slideWidth: PropTypes.string,
    slideWidthActive: PropTypes.string,
    slidePaddingTop: PropTypes.string,
    slidePaddingTopActive: PropTypes.string,
    offset: PropTypes.string,
    gap: PropTypes.string,
  }),
  speed: PropTypes.number,
  infinite: PropTypes.bool,
  onBeforeChange: PropTypes.func,
  onAfterChange: PropTypes.func,
  log: PropTypes.bool,
  swipingDisabled: PropTypes.bool,
  startIndex: PropTypes.number,
};

Carousel.defaultProps = {
  children: null,
  dimensions: {
    slideWidth: '400px',
    slideWidthActive: '',
    slidePaddingTop: '0px',
    slidePaddingTopActive: '',
    offset: '',
    gap: '',
  },
  speed: 500,
  infinite: false,
  onBeforeChange: () => false,
  onAfterChange: () => false,
  log: false,
  swipingDisabled: false,
  startIndex: 0,
};

export default Carousel;
