/* eslint-disable */
import React from 'react';

const IconUser40 = (
    <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="evenodd">
            <path d="M11.2322 9.824c0 2.33887.91968 5.03913 2.4603 7.22325 1.74527 2.475 3.9504 3.83788 6.20792 3.83788 2.25764 0 4.46277-1.36288 6.20792-3.83788 1.54061-2.18412 2.46043-4.88437 2.46043-7.22325 0-4.75537-3.88859-8.624-8.66835-8.624-4.77926 0-8.66823 3.86862-8.66823 8.624zm8.66822-7.21775c4.0004 0 7.25489 3.23787 7.25489 7.21775 0 2.03037-.84469 4.48837-2.20375 6.41563-1.45215 2.0585-3.29305 3.23925-5.05114 3.23925-1.75797 0-3.59886-1.18076-5.05115-3.23925-1.35893-1.92725-2.20362-4.38575-2.20362-6.41563 0-3.97987 3.25436-7.21775 7.25477-7.21775z" />
            <path d="M32.60247 24.742c-1.52729-2.35787-3.6775-4.24025-6.21772-5.44388-.27-.12737-.5914-.07275-.80297.13725-1.70306 1.6895-3.66759 2.5825-5.68186 2.5825-2.01416 0-3.9783-.893-5.68036-2.5825-.21145-.21-.53297-.26462-.80285-.13725-2.54033 1.20363-4.69093 3.086-6.21822 5.44388C5.62936 27.16437 4.8 29.9745 4.8 32.869v.01212c0 .19825.08443.38775.23168.521 4.07793 3.67926 9.35825 5.70563 14.86824 5.70563 5.5101 0 10.79042-2.02638 14.86835-5.70563.14776-.13324.23219-.32274.23219-.521V32.869c0-2.8945-.82948-5.70463-2.39799-8.127zM19.89992 37.7015c-5.05253 0-9.89914-1.82038-13.68307-5.13238.05453-2.5175.80197-4.954 2.16982-7.06537 1.29712-2.0025 3.08952-3.6265 5.2038-4.72075 1.88902 1.73 4.06072 2.64112 6.30945 2.64112 2.24834 0 4.42105-.91112 6.31057-2.64112 2.11428 1.09375 3.90668 2.71825 5.2038 4.72075 1.36735 2.11088 2.11428 4.54788 2.16932 7.06537-3.78406 3.312-8.63104 5.13238-13.6837 5.13238z" />
        </g>
    </svg>
);

export default IconUser40;
