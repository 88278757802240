/* eslint-disable */
import React from 'react';

const IconInfo40 = (
    <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="evenodd">
            <path d="M17.733 13.053c.073.146.146.293.22.402.073.11.182.22.255.33.11.109.22.219.366.328.11.073.256.183.439.22a2.249 2.249 0 0 0 .95.183c.147 0 .33 0 .476-.037.146-.037.292-.073.402-.146h.037c.146-.073.292-.147.438-.256.11-.073.22-.183.33-.293l.036-.036c.11-.11.183-.22.256-.33.073-.109.146-.219.183-.365 0-.037 0-.037.036-.073.073-.146.11-.33.146-.475.037-.147.037-.33.037-.476 0-.146 0-.329-.037-.475a3.396 3.396 0 0 0-.146-.475 2.075 2.075 0 0 0-.439-.659l-.073-.073c-.11-.11-.219-.182-.365-.292-.147-.073-.256-.146-.402-.22-.147-.073-.293-.11-.44-.146-.182-.036-.328-.036-.475-.036-.146 0-.329 0-.475.036-.146.037-.292.073-.439.146h-.036c-.146.074-.293.147-.402.22-.147.11-.256.183-.366.292-.11.11-.22.22-.293.33-.073.11-.146.255-.219.402-.073.146-.11.292-.146.438-.037.183-.037.33-.037.476 0 .146 0 .329.037.475.036.293.11.439.146.585M22.815 26.435h-.84v-8.958a.944.944 0 0 0-.951-.95h-3.84a.944.944 0 0 0-.95.95v1.975c0 .511.402.95.95.95h.768v6.07h-.767a.944.944 0 0 0-.951.95v1.975c0 .512.402.95.95.95h5.631c.512 0 .951-.402.951-.95v-1.975c0-.548-.439-.987-.95-.987" />
            <path d="M5.856 5.86c7.798-7.799 20.487-7.801 28.285-.004 7.798 7.798 7.798 20.49 0 28.288-7.798 7.797-20.487 7.795-28.285-.003-7.797-7.798-7.797-20.484 0-28.282zM33.13 33.131c7.243-7.243 7.24-19.024 0-26.264-7.24-7.24-19.018-7.24-26.261.003-7.24 7.24-7.24 19.018 0 26.258 7.24 7.24 19.021 7.243 26.261.003z" />
        </g>
    </svg>
);

export default IconInfo40;
