import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import LabelXXL from '../elements/LabelXXL';
import Button from '../elements/Button';
import NewsFeedBoxSwipe from '../NewsFeedBoxSwipe';
import './news-feed-box.scss';

const StyledContent = styled.div({
  minHeight: '100px',
});

const NewsFeedBox = ({ title, showButton, buttonLabel, buttonPath, contentMaxHeight, children, onClickButton }) => {
  const handleOnClickButton = (e, path) => {
    e.preventDefault();
    onClickButton(path);
  };

  return (
    <div className="bt-news-feed-box">
      {title && (
        <div className="bt-news-feed-box__header">
          <div className="bt-news-feed-box__header-title">
            <LabelXXL text={title} />
          </div>
          {showButton && (
            <div className="bt-news-feed-box__button">
              <Button
                layout="default"
                path={buttonPath}
                label={buttonLabel}
                onClick={(e) => handleOnClickButton(e, buttonPath)}
              />
            </div>
          )}
        </div>
      )}
      <StyledContent>
        <NewsFeedBoxSwipe contentMaxHeight={contentMaxHeight}>{children}</NewsFeedBoxSwipe>
      </StyledContent>
    </div>
  );
};

NewsFeedBox.propTypes = {
  title: PropTypes.string,
  showButton: PropTypes.bool,
  buttonLabel: PropTypes.string,
  buttonPath: PropTypes.string,
  contentMaxHeight: PropTypes.string,
  children: PropTypes.any,
  onClickButton: PropTypes.func,
};

NewsFeedBox.defaultProps = {
  title: '',
  showButton: false,
  buttonLabel: '',
  buttonPath: '',
  contentMaxHeight: null,
  children: null,
  onClickButton: () => false,
};

export default NewsFeedBox;
