import React from 'react';
import ButtonChecked from '../../components/elements/ButtonChecked';
import Button from '../../components/elements/Button';
import { useOverlayContext } from '../../components/overlay/OverlayProvider';

interface EventAttendProps {
  isAttending: boolean;
  canAttend: boolean;
  eventPath: string;
  attendanceType: string;
}

const EventAttend: React.FC<EventAttendProps> = ({ isAttending, canAttend, attendanceType, eventPath }) => {
  const { overlayNavigate } = useOverlayContext();

  if ((!canAttend && !isAttending) || attendanceType !== 'register') {
    return null;
  }

  const handleOnClickRegister = async () => {
    overlayNavigate(`${eventPath}/register`);
  };

  return (
    <>
      {isAttending === true ? (
        <ButtonChecked label="Already registered" />
      ) : (
        <Button layout="secondary" onClick={handleOnClickRegister} label="Register for this Event" />
      )}
    </>
  );
};

export default EventAttend;
