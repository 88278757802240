import React from 'react';

const IconCheckCircle30 = (
  <svg width="30" height="30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="15" cy="15" r="15" fill="#fff" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.998 0C6.728 0 0 6.728 0 14.998 0 23.27 6.728 30 14.998 30 23.27 30 30 23.27 30 14.998 30 6.728 23.27 0 14.998 0zm0 28.927c-7.679 0-13.925-6.249-13.925-13.929 0-7.679 6.246-13.925 13.925-13.925 7.683 0 13.93 6.246 13.93 13.925 0 7.68-6.247 13.93-13.93 13.93z"
      fill="currentColor"
    />
    <path
      d="M21.98 9.925l-9.106 10.29-4.802-3.993"
      stroke="currentColor"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default IconCheckCircle30;
