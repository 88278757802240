/* eslint-disable */
import React from 'react';

const IconUpload40 = (
    <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M19.1544666 32.47298268l.1920898.00760324v6.4496968c0 .36783219.3008035.66971728.6668682.66971728.3658248 0 .6668683-.30203284.6668683-.66971728v-6.46299964l.1868745-.01229056C29.3453415 31.89739028 36 24.812258 36 16.2539007 36 7.2918752 28.732486 0 19.8 0 10.8672292 0 3.6 7.2909951 3.6 16.2539007c0 8.7152017 6.8932981 15.87625762 15.5544666 16.21908198zM10.2383844 19.0730263c-.2595779.2604311-.2595779.6867642-.0003238.9468711.2607851.2604461.6832931.2604461.9425665.0003206l8.1674787-8.1939166v19.31770008l-.2088506-.00925132c-7.918236-.3507496-14.2051314-6.90954806-14.2051314-14.87929496 0-8.2239302 6.6692944-14.915632 14.8658762-14.915632 8.1963386 0 14.86587624 6.6914579 14.86587624 14.9140775 0 7.8086802-6.04027374 14.28772094-13.77080064 14.86067902l-.2147827.01591888V11.8248657l.3416252.3426123 7.8285683 7.8511891c.2592699.260122.6817779.260122.94223924 0 .25957792-.2604311.25957792-.6867642.00003036-.9471648l-9.779122-9.8070487-.4708966.473639c-.0079032.011146-.0165306.0190892-.0275055.026046l-9.2768475 9.3088877z"
            fill="currentColor"
            fillRule="nonzero"
        />
    </svg>
);

export default IconUpload40;
