/* eslint-disable */
import React from 'react';

const IconClose30 = (
  <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 14.15l4.86-4.86a.61.61 0 0 1 .87-.02c.23.24.22.62-.02.87L15.85 15l4.86 4.86c.24.25.25.63.02.87a.61.61 0 0 1-.87-.02L15 15.85l-4.86 4.86a.61.61 0 0 1-.87.02.61.61 0 0 1 .02-.87L14.15 15 9.3 10.14a.61.61 0 0 1-.02-.87.61.61 0 0 1 .87.02L15 14.15zM15 0c8.27 0 15 6.73 15 15s-6.73 15-15 15S0 23.27 0 15 6.73 0 15 0zm0 28.93c7.68 0 13.93-6.25 13.93-13.93S22.68 1.07 15 1.07A13.94 13.94 0 0 0 1.07 15c0 7.68 6.25 13.93 13.93 13.93z"
      fill="currentColor"
      fillRule="evenodd"
    />
  </svg>
);

export default IconClose30;
