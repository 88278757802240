import React from 'react';
import PageContentHeader from '../PageContentHeader';
import CopyTextM from '../elements/CopyTextM';
import { useBaseData } from '../../hooks/useBaseData';
import './page-view-imprint.scss';

const defaultTexts = {
  title: 'Imprint',
};

const PageViewImprint = () => {
  const { baseData } = useBaseData();
  const { imprint } = baseData?.texts || {};

  return (
    <div className="bt-imprint">
      <PageContentHeader title={imprint?.title || defaultTexts.title} />
      <div className="bt-imprint__content">
        <CopyTextM text={imprint?.content} />
      </div>
    </div>
  );
};

PageViewImprint.propTypes = {};
PageViewImprint.defaultProps = {};

export default PageViewImprint;
