import React, { useState } from 'react';
import { useResponsive } from '../../hooks/responsive';
import NewsfeedNewsTeaser from '../NewsfeedNewsTeaser/NewsfeedNewsTeaser';
import Button from '../elements/Button';
import { UiDiv } from '@webfox-sc/core';
import NewsFeedBox from './NewsFeedBox';
import { useOverlayContext } from '../overlay/OverlayProvider';

interface NewsTeaserListProps {
  data?: any;
}

const NewsTeaserList: React.FC<NewsTeaserListProps> = ({ data }) => {
  const { overlayNavigate } = useOverlayContext();
  const [newsDisplayCount, setNewsDisplayCount] = useState(3);
  const { isMobile } = useResponsive();

  const handleClickNews = (e: any, link: any, eventId: any, eventTab: any, newsId: any) => {
    // case: news
    if (link === 'news') {
      e.preventDefault();
      overlayNavigate('/news/news/' + newsId);
    }
    // case: event
    if (link === 'event') {
      e.preventDefault();
      switch (eventTab) {
        case 'registration':
          return overlayNavigate('/news/event/' + eventId + '/registration');
        case 'venue':
          return overlayNavigate('/news/event/' + eventId + '/location');
        case 'schedule':
          return overlayNavigate('/news/event/' + eventId + '/schedule');
        case 'attendees':
          return overlayNavigate('/news/event/' + eventId + '/attendees');
        case 'evaluation':
          return overlayNavigate('/news/event/' + eventId + '/evaluation');
        default:
          return overlayNavigate('/news/event/' + eventId);
      }
    }
  };

  const handleClickLoadMoreNews = () => {
    setNewsDisplayCount(newsDisplayCount + 3);
  };

  return (
    <>
      {newsDisplayCount < data.length && data.length > 3 && !isMobile ? (
        <NewsFeedBox>
          {data.slice(0, newsDisplayCount).map((item: any, i: number) => {
            // const linkIsExternal = item.externalUrl || false;
            const headline = item.headline || '';
            const intro = item.intro || '';
            const link = item.link || '';
            const linkText = item.linkText || '';
            const url = item.url || '';
            // case: event
            const event = item.event || {};
            const eventId = event.id || '';
            const eventTab = item.eventTab || '';
            // case: news
            const news = item.news || {};
            const newsId = news.id || '';
            // case: file
            const file = item.file || {};
            const fileUrl = file.url || '';
            // for the cases 'file' or 'url' we dont have to specify handleClick as
            // the element acts as a normal link, we only have to decide between url and fileUrl
            // For the other two cases we specify the onClick function

            return (
              <div key={String(i)}>
                <NewsfeedNewsTeaser
                  title={headline}
                  intro={intro}
                  linkPath={fileUrl ? fileUrl : url}
                  linkText={linkText}
                  onClickDetails={(e) => handleClickNews(e, link, eventId, eventTab, newsId)}
                  linkTarget={fileUrl || url ? '_blank' : '_self'}
                />
              </div>
            );
          })}
          {newsDisplayCount < data.length && data.length > 3 && !isMobile && (
            <UiDiv padding="30px 0 30px 30px">
              <Button
                layout="secondary"
                path="#"
                label="Load more"
                onClick={() => {
                  handleClickLoadMoreNews();
                }}
              />
            </UiDiv>
          )}
        </NewsFeedBox>
      ) : (
        <NewsFeedBox>
          {data.map((item: any, i: number) => {
            // const linkIsExternal = item.externalUrl || false;
            const headline = item.headline || '';
            const intro = item.intro || '';
            const link = item.link || '';
            const linkText = item.linkText || '';
            const url = item.url || '';
            // case: event
            const event = item.event || {};
            const eventId = event.id || '';
            const eventTab = item.eventTab || '';
            // case: news
            const news = item.news || {};
            const newsId = news.id || '';
            // case: file
            const file = item.file || {};
            const fileUrl = file.url || '';
            // for the cases 'file' or 'url' we dont have to specify handleClick as
            // the element acts as a normal link, we only have to decide between url and fileUrl
            // For the other two cases we specify the onClick function
            return (
              <div key={String(i)}>
                <NewsfeedNewsTeaser
                  title={headline}
                  intro={intro}
                  linkPath={fileUrl ? fileUrl : url}
                  linkText={linkText}
                  linkTarget={fileUrl || url ? '_blank' : '_self'}
                  onClickDetails={(e) => handleClickNews(e, link, eventId, eventTab, newsId)}
                />
              </div>
            );
          })}
        </NewsFeedBox>
      )}
    </>
  );
};

export default NewsTeaserList;
