/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation, Navigate, useNavigate, ScrollRestoration } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { useAppDispatch, useAppSelector } from './app/hooks';
import { autoLogin, selectAuthState } from './app/slices/authSlice';
import { appTheme } from './theme/appTheme';
import GlobalStyle from './theme/GlobalStyle';
import PageLayout from './components/page/PageLayout';
import PageViewLogin from './components/auth/PageViewLogin';
import { fetchBaseData, locationChanged } from './redux-actions/actionsApp';
import PageViewForgotPassword from './components/auth/PageViewForgotPassword';
import ProfileUtil from './utils/ProfileUtil';
import PageViewNewsFeed from './components/news/PageViewNewsFeed';
import Events from './components/events/Events';
import PageViewFellows from './components/fellows/PageViewFellows';
import PageViewDownloads from './components/media/PageViewDownloads';
import PageViewContact from './components/content-pages/PageViewContact';
import PageViewImprint from './components/content-pages/PageViewImprint';
import PageViewAbout from './components/content-pages/PageViewAbout';
import PageViewTerms from './components/content-pages/PageViewTerms';
import PageViewPrivacy from './components/content-pages/PageViewPrivacy';
import SearchView from './components/search/SearchView';
import Layer from './components/overlay/Layer';
import LayerViewEvent from './components/event-overlay/LayerViewEvent';
import LayerViewEventAttendees from './components/event-overlay/LayerViewEventAttendees';
import LayerViewEventDescription from './components/event-overlay/LayerViewEventDescription';
import LayerViewEventSchedule from './components/event-overlay/LayerViewEventSchedule';
import LayerViewEventOnlineVenue from './components/event-overlay/LayerViewEventOnlineVenue';
import LayerViewEventLocation from './components/event-overlay/LayerViewEventLocation';
import LayerViewEventEvaluation from './components/event-overlay/LayerViewEventEvaluation';
import LayerViewEventAssets from './components/event-overlay/LayerViewEventAssets';
import LayerViewEventRegister from './components/event-overlay/LayerViewEventRegister';
import OverlayProvider from './components/overlay/OverlayProvider';
import LayerViewNews from './components/news-overlay/LayerViewNews';
import LayerViewFellow from './components/fellow-overlay/LayerViewFellow';
import LayerViewStatus from './components/status-overlay/LayerViewStatus';
import LayerViewDownloadVideo from './components/media-overlay/LayerViewDownloadVideo';
import LayerViewProfile from './components/profile-overlay/LayerViewProfile';
import LayerViewProfileData from './components/profile-overlay/LayerViewProfileData';
import LayerViewProfileEdit from './components/profile-overlay/LayerViewProfileEdit';
import LayerViewProfileLinks from './components/profile-overlay/LayerViewProfileLinks';
import LayerViewProfileDelete from './components/profile-overlay/LayerViewProfileDelete';
import PageHeaderMobile from './molecules/PageHeaderMobile/PageHeaderMobile';
import MobileMenuOverlay from './components/page/MobileMenuOverlay';
import ProfileImageOverlay from './components/profile-overlay/ProfileImageOverlay';
import ErrorDialogOverlay from './components/error-dialog/ErrorDialogOverlay';
import PageViewRegister from './components/auth/PageViewRegister';
import PageViewRegisterConfirmation from './components/auth/PageViewRegisterConfirmation';
import PageViewPasswordReset from './components/auth/PageViewPasswordReset';
import PageViewRegisterFeedback from './components/auth/PageViewRegisterFeedback';
import './app.scss';

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { authStatus, userToken } = useAppSelector(selectAuthState);
  const { pageId, contentId } = useAppSelector(({ appState }) => appState);

  const [isMounted, setIsMounted] = useState(false);
  const [showMobileMenuOverlay, setShowMobileMenuOverlay] = useState(false);

  useEffect(() => {
    if (!isMounted) {
      dispatch(autoLogin());
      dispatch(fetchBaseData());
      setIsMounted(true);
    }
  }, [dispatch, isMounted]);

  useEffect(() => {
    if (authStatus === 'loggedIn') {
      ProfileUtil.loadProfile(userToken);
    }
  }, [authStatus, userToken]);

  useEffect(() => {
    setShowMobileMenuOverlay(contentId === 'menu');
  }, [contentId]);

  useEffect(() => {
    const aPathname = pathname.split('/');
    dispatch(locationChanged(pathname, aPathname[1], aPathname[2], aPathname[3]));

    /**
     * google tag manager custom tracking
     */
    (window as any).dataLayer = (window as any).dataLayer || [];
    (window as any).dataLayer.push({ event: 'pageview' });
  }, [dispatch, pathname]);

  if (!isMounted || authStatus === 'autoLogin') {
    return null;
  }

  const handleOnClickMobileMenu = () => {
    navigate(`/${pageId}/menu`);
  };

  const handleOnClickTitle = () => {
    if (authStatus === 'loggedIn') {
      navigate('/news');
    } else {
      navigate('/');
    }
  };

  let classNameHeaderMobile = 'bt-app__header-mobile';
  if (showMobileMenuOverlay) {
    classNameHeaderMobile += ' bt-app__header-mobile--blur';
  }

  return (
    <ThemeProvider theme={appTheme}>
      <OverlayProvider>
        <PageLayout showMobileMenuOverlay={showMobileMenuOverlay}>
          <Routes>
            <Route path="about/*" element={<PageViewAbout />} />
            <Route path="contacts/*" element={<PageViewContact />} />
            <Route path="imprint/*" element={<PageViewImprint />} />
            <Route path="terms/*" element={<PageViewTerms />} />
            <Route path="privacy/*" element={<PageViewPrivacy />} />

            {authStatus === 'loggedIn' ? (
              <>
                <Route index element={<PageViewNewsFeed />} />
                <Route path="news/*" element={<PageViewNewsFeed />} />
                <Route path="events/*" element={<Events />} />
                <Route path="fellows/*" element={<PageViewFellows />} />
                {/* @ts-ignore */}
                <Route path="downloads/*" element={<PageViewDownloads />} />
                <Route path="search/*" element={<SearchView />} />
              </>
            ) : (
              <>
                <Route path="/" element={<PageViewLogin />} />
                <Route path="/login" element={<PageViewLogin />} />
                <Route path="register" element={<PageViewRegister />} />
                <Route path="register/feedback" element={<PageViewRegisterFeedback />} />
                <Route path="register/confirmEmail" element={<PageViewRegisterConfirmation />} />
                <Route path="password" element={<PageViewForgotPassword />} />
                <Route path="password/reset" element={<PageViewPasswordReset />} />
              </>
            )}

            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </PageLayout>
        {authStatus === 'loggedIn' && (
          <Routes>
            <Route path="/*" element={<Layer />}>
              <Route path=":bgSegment1/news/:sNewsId/*" element={<LayerViewNews />} />
              <Route path=":bgSegment1/event/:sEventId/*" element={<LayerViewEvent />}>
                <Route path="description" element={<LayerViewEventDescription />} />
                <Route path="schedule" element={<LayerViewEventSchedule />} />
                <Route path="onlinevenue" element={<LayerViewEventOnlineVenue />} />
                <Route path="location" element={<LayerViewEventLocation />} />
                <Route path="evaluation" element={<LayerViewEventEvaluation />} />
                <Route path="attendees" element={<LayerViewEventAttendees />} />
                <Route path="assets" element={<LayerViewEventAssets />} />
                <Route path="register" element={<LayerViewEventRegister />} />
              </Route>
              <Route path=":bgSegment1/fellow/:sFellowId/*" element={<LayerViewFellow />} />
              <Route path=":bgSegment1/status-messages" element={<LayerViewStatus />} />
              <Route path=":bgSegment1/media-library/:sEventId/:sVideoIndex" element={<LayerViewDownloadVideo />} />
              <Route path=":bgSegment1/:sContentId/profile/*" element={<LayerViewProfile />}>
                <Route index element={<LayerViewProfileData />} />
                <Route path="data" element={<LayerViewProfileData />} />
                <Route path="edit" element={<LayerViewProfileEdit />} />
                {/* @ts-ignore */}
                <Route path="links" element={<LayerViewProfileLinks />} />
                <Route path="delete" element={<LayerViewProfileDelete />} />
              </Route>
            </Route>
          </Routes>
        )}
        <div className={classNameHeaderMobile}>
          <PageHeaderMobile
            onClickMenu={handleOnClickMobileMenu}
            onClickTitle={handleOnClickTitle}
            onSubmitSearch={() => navigate('/search')}
          />
        </div>
        <MobileMenuOverlay show={showMobileMenuOverlay} />
        {/* @ts-ignore */}
        <ProfileImageOverlay />
        {/* @ts-ignore */}
        <ErrorDialogOverlay />
      </OverlayProvider>
      <ScrollRestoration />
      <GlobalStyle />
    </ThemeProvider>
  );
};

export default App;
