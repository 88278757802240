/* eslint-disable */
import React from 'react';

const IconEvents25 = (
    <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg">
        <path d="M18.532 3.205h6.434V23.29H.25V3.205h6.434A2.137 2.137 0 0 1 8.773 1.5c1.174 0 2.13.957 2.13 2.13 0 1.175-.956 2.131-2.13 2.131a.426.426 0 0 1 0-.852 1.279 1.279 0 0 0 0-2.557c-.555 0-1.024.358-1.2.853h.774a.426.426 0 0 1 0 .852H1.102v3.835h23.012V4.057h-5.582a2.137 2.137 0 0 1-2.089 1.704.426.426 0 0 1 0-.852 1.279 1.279 0 0 0 0-2.557.426.426 0 0 1 0-.852c1.03 0 1.889.732 2.089 1.705zM1.102 22.437h23.012V8.745H1.102v13.694zM11.33 2.352a.426.426 0 0 1 0-.852c1.173 0 2.13.957 2.13 2.13 0 1.175-.957 2.131-2.13 2.131a.426.426 0 0 1 0-.852 1.279 1.279 0 0 0 0-2.557zm2.556 0a.426.426 0 0 1 0-.852c1.174 0 2.131.957 2.131 2.13 0 1.175-.957 2.131-2.13 2.131a.426.426 0 0 1 0-.852 1.279 1.279 0 0 0 0-2.557z" fill="currentColor" fillRule="nonzero" />
    </svg>
);

export default IconEvents25;
