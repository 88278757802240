/* eslint-disable */

class DownloadModel {
  constructor(data, category) {
    this._data = data || {};
    this._category = category || "";

    /**
     * custom values
     */

    this._title = "";
    this._imageSrc = "";
    this._breadcrumb = [];
    this._text = "";
    this._tags = [];
    this._objectId = 0;

    /**
     * Custom values events
     */
    if (this._category === "events") {
      this._title = this._data.title || "";
      this._eventCategory = this._data.category || "";
      this._city = this._data.city || "";
      this._breadcrumb.push("Events");
      this._text = [this._data.city, this._data.country]
        .filter(Boolean)
        .join(", ");
      this._tags.push(this._category, this._city, this._eventCategory);
      this._categoryLabel = "Site" || "";
      this._url = "/events/event/" + this._data.id;
      this._objectId = this._data.id;
    }
    /**
     * custom values fellows
     */
    if (this._category === "fellows") {
      this._categoryLabel = "Site" || "";
      this._title = [this._data.firstName, this._data.lastName]
        .filter(Boolean)
        .join(" ");
      this._breadcrumb.push("Fellows");
      this._profilePicture = this._data.profilePicture || "";
      this._sizes = this._profilePicture.sizes || "";
      this._imageSrc = this._sizes.thumbnail || "";
      this._text = [
        this._data.city,
        this._data.country,
        this._data.profession,
        this._data.status
      ]
        .filter(Boolean)
        .join(", ");
      this._tags.push(this._category, this._data.city);
      this._url = "/fellows/fellow/" + this._data.id;
      this._objectId = this._data.id;
    }
    /**
     * custom values downloads
     */
    if (this._category === "downloads") {
      this._title = this._data.file.title || "";
      this._breadcrumb.push("Downloads");
      this._imageSrc = this._data.file.thumbnail || "";
      this._text = [this._data.title, this._data.subTitle, this._data.speaker]
        .filter(Boolean)
        .join(", ");

      this._categoryLabel = "Download";
      if (this._data.file.mimeType === "application/pdf") {
        this._categoryLabel = "PDF";
      } else if (this._data.file.mimeType.substring(0, 5) === "video") {
        this._categoryLabel = "Video";
      } else if (this._data.file.mimeType.substring(0, 5) === "image") {
        this._categoryLabel = "Picture";
      } else {
        this._categoryLabel = "File";
      }
      this._tags.push(this._category, this._categoryLabel);
      this._url = this._data.file.url || "";
    }
  }

  // site pdf video picture

  //               categories: ["Schlagwort 3"]
  // city: "Berlin"
  // country: "Germany"
  // file: {title: "1_Huang_Anatomy of the Conduction System", url: "https://biotronik2-cms.webfox01.net/wp-content/upl…9/08/1_Huang_Anatomy-of-the-Conduction-System.pdf", size: 3481008, mimeType: "application/pdf"}
  // speaker: "Huang"
  // subTitle: "Dr. Gregory Michaud"
  // title: "IFP New York"

  // attendees: (2) [{…}, {…}]
  // category: "ifp"
  // city: "Berlin"
  // country: "Germany"
  // endDate: {date: "2019-11-05 00:00:00.000000", timezone_type: 3, timezone: "UTC"}
  // id: 44
  // isAttending: false
  // startDate: {date: "2019-10-03 00:00:00.000000", timezone_type: 3, timezone: "UTC"}
  // subTitle: "Dr. Gregory Michaud"
  // title: "IFP New York"

  // city: "Sao Paulo"
  // contact: {email: Array(0), linkedin: Array(0), twitter: Array(0), phone: Array(0), other: Array(0)}
  // country: "Brazil"
  // degree: "Dr."
  // employers: [{…}]
  // firstName: "Kleber"
  // id: 1415
  // lastName: "Oliveira Souza"
  // profession: null
  // profilePicture: null
  // status: null
  // title: "mr"
  //     }

  //     New York, USA, 5–7 April, 2019, Course director: Dr. Gregory Michaud, Description: Lorem ipsum dolor procet, etabili et lumina nuda. Caruso dolore gusto paginare sua lavora tu hon…

  get data() {
    return this._data;
  }
  get category() {
    return this._category;
  }

  get categoryLabel() {
    return this._categoryLabel;
  }
  set breadcrumb(_breadcrumb) {
    this._breadcrumb = _breadcrumb;
  }
  get breadcrumb() {
    return this._breadcrumb;
  }
  get imageSrc() {
    return this._imageSrc;
  }
  get title() {
    return this._title;
  }
  get text() {
    return this._text;
  }
  get tags() {
    return this._tags;
  }
  get url() {
    return this._url;
  }

  get objectId() {
    return this._objectId;
  }
}

export default DownloadModel;
