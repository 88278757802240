export const useEventUtils = () => {
  /**
   * location
   */
  const getEventLocationFormatted = (event) => {
    const { city, country } = event || {};
    return city && country ? `${city}, ${country}` : city || country;
  };

  /**
   * dates
   */
  const getEventStartYear = (event) => {
    const startDate = new Date(event.startDateUtc);
    return startDate.getFullYear();
  };

  const getEventEndYear = (event) => {
    const endDate = new Date(event.endDateUtc);
    return endDate.getFullYear();
  };

  return {
    getEventLocationFormatted,
    getEventStartYear,
    getEventEndYear,
  };
};
