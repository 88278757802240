/* eslint-disable */
import React from 'react';

const IconEvents27 = (
    <svg width="27" height="27" xmlns="http://www.w3.org/2000/svg">
        <path d="M19.97131 3.37744H27V25.5H0V3.37744h7.02869C7.24717 2.30668 8.18752 1.5 9.31034 1.5c1.28235 0 2.3276 1.05387 2.3276 2.3468 0 1.29294-1.04525 2.34681-2.3276 2.34681-.25696 0-.46551-.21027-.46551-.46936s.20855-.46936.46551-.46936c.7709 0 1.39656-.63082 1.39656-1.40808 0-.77727-.62566-1.40809-1.39656-1.40809-.6064 0-1.11786.39364-1.31151.93872h.846c.25696 0 .46551.21028.46551.46937 0 .25908-.20855.46936-.46551.46936H.93103v4.22425h25.13794V4.31617H19.9713c-.21848 1.07077-1.15883 1.87744-2.28165 1.87744-.25697 0-.46552-.21027-.46552-.46936s.20855-.46936.46552-.46936c.7709 0 1.39655-.63082 1.39655-1.40808 0-.77727-.62566-1.40809-1.39655-1.40809-.25697 0-.46552-.21027-.46552-.46936S17.43269 1.5 17.68966 1.5c1.12468 0 2.06317.80668 2.28165 1.87744zM.93103 24.56128h25.13794V9.47914H.93103v15.08214zM12.10345 2.43872c-.25697 0-.46552-.21027-.46552-.46936S11.84648 1.5 12.10345 1.5c1.28234 0 2.32758 1.05387 2.32758 2.3468 0 1.29294-1.04524 2.34681-2.32758 2.34681-.25697 0-.46552-.21027-.46552-.46936s.20855-.46936.46552-.46936c.7709 0 1.39655-.63082 1.39655-1.40808 0-.77727-.62566-1.40809-1.39655-1.40809zm2.7931 0c-.25696 0-.46552-.21027-.46552-.46936S14.6396 1.5 14.89655 1.5c1.28235 0 2.32759 1.05387 2.32759 2.3468 0 1.29294-1.04524 2.34681-2.32759 2.34681-.25696 0-.46552-.21027-.46552-.46936s.20856-.46936.46552-.46936c.7709 0 1.39655-.63082 1.39655-1.40808 0-.77727-.62565-1.40809-1.39655-1.40809z" fill="currentColor" fillRule="nonzero" />
    </svg>
);

export default IconEvents27;
