import '@fontsource/barlow';
import '@fontsource/barlow/300.css';
import '@fontsource/barlow/500.css';
import TagManager from 'react-gtm-module';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { Provider as ReduxProvider } from 'react-redux';
import { store } from './app/store';
import App from './App';
import storeModel from './models/storeModel';
import './styles/main.scss';

// TODO REF
storeModel.store = store;

const router = createBrowserRouter([
  {
    path: '/*',
    element: <App />,
  },
]);

/**
 * google tag manager
 */
if (process.env.REACT_APP_GMT_ID) {
  TagManager.initialize({
    gtmId: process.env.REACT_APP_GMT_ID,
  });
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <ReduxProvider store={store}>
    <RouterProvider router={router} />
  </ReduxProvider>
);
