/* eslint-disable */
import React from 'react';

const IconTag12 = (
    <svg id="Ebene_1" xmlns="http://www.w3.org/2000/svg" width="12" height="12">
        <path d="M10.5 0H6.1c-.1 0-.3.1-.4.2L.4 5.5c-.6.6-.6 1.5 0 2.1l4 4c.3.3.7.4 1 .4.4 0 .8-.2 1-.4l5.3-5.3c.2-.1.3-.3.3-.4V1.5c0-.8-.7-1.5-1.5-1.5zm.3 1.5v4.1l-5.1 5.1c-.1.1-.3.1-.5 0l-4-4c-.1-.1-.1-.3 0-.5l5.1-5.1h4.1c.3.1.4.2.4.4zm-2.9.8c-.3.2-.4.6-.4.9 0 .3.1.7.4.9.2.2.6.4.9.4s.7-.1.9-.4c.2-.2.4-.6.4-.9 0-.3-.1-.7-.4-.9-.5-.5-1.3-.5-1.8 0zm.8.8h.2v.2c-.1.1-.1.1-.2 0v-.1c-.1 0 0-.1 0-.1z" fill="currentColor" />
    </svg>
);

export default IconTag12;
