/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './layer-view-body.scss';

class LayerViewBody extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        const { layout } = this.props;

        let className = 'bt-layer-view-body';
        if (layout === 'event') {
            className += ' bt-layer-view-body--event';
        }

        return (
            <div className={className}>
                {this.props.children}
            </div>
        );
    }
}

LayerViewBody.propTypes = {
    layout: PropTypes.string
};

LayerViewBody.defaultProps = {
    layout: ''
};

export default LayerViewBody;
