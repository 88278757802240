import React, { useEffect } from 'react';
import PageContentHeader from '../PageContentHeader';
import CopyTextM from '../elements/CopyTextM';
import './page-view-confirm-signup.scss';
import ContactElement from '../../molecules/ContactElement/ContactElement';
import UserUtil from '../../utils/UserUtil';
import imgSrc from '../../assets/images/Mitarbeiterportrait3.jpg';
import { useSearchParams } from 'react-router-dom';

const PageViewRegisterConfirmation: React.FC = () => {
  const [searchParams] = useSearchParams();
  const key = searchParams.get('key');

  useEffect(() => {
    if (key) {
      UserUtil.confirmEmail({
        key,
      });
    }
  }, [key]);

  return (
    <div className="bt-confirm-signup">
      <PageContentHeader title="Email Confirmation" />
      <div className="bt-confirm-signup__cta">
        <CopyTextM text="Thank you! You have successfully verified your email address." />
        <br />
        <CopyTextM text="Our team is now reviewing your registration." />
        <br />
        <br />
        <CopyTextM heavy text="If you have any further questions, do not hesitate to contact:" />
      </div>
      <div className="bt-confirm-signup__content">
        <ContactElement
          name="Ines WALDMANN"
          location="BIOTRONIK HQ, Berlin"
          tel="+49 151 6890 1421"
          email="inez.waldmann@biotronik.com"
          imgSrc={imgSrc}
        />
      </div>
    </div>
  );
};

export default PageViewRegisterConfirmation;
