import React, { Fragment } from 'react';
import PageContentHeader from '../PageContentHeader';
import CopyTextM from '../elements/CopyTextM';
import './page-view-terms.scss';
import HeadlineThree from '../elements/HeadlineThree';
import Link from '../elements/Link';
import { useBaseData } from '../../hooks/useBaseData';

const defaultTexts = {
  title: 'Terms and Conditions',
};

const PageViewTerms = () => {
  const { baseData } = useBaseData();
  const { terms } = baseData?.texts || {};

  return (
    <div className="bt-terms">
      <PageContentHeader title={terms?.title || defaultTexts.title} />
      <div className="bt-terms__content">
        <br />
        <CopyTextM text={terms?.intro} />
        <br />
        <br />
        {(terms?.sections || []).map((section, index) => {
          return (
            <Fragment key={`achor-link-${String(index)}`}>
              <Link label={section?.title} path={`section-${String(index + 1)}`} />
              <br />
              <br />
            </Fragment>
          );
        })}
        <br />
        <br />
        {(terms?.sections || []).map((section, index) => {
          return (
            <Fragment key={`content-${String(index)}`}>
              <HeadlineThree id={`section-${String(index + 1)}`} text={section?.title} />
              <br />
              <CopyTextM text={section?.content} />
              <br />
              <br />
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

PageViewTerms.propTypes = {};
PageViewTerms.defaultProps = {};

export default PageViewTerms;
