/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './copy-text-l.scss';

class CopyTextL extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { text, bold } = this.props;

        const classNameBold = bold ? 'bt-copy-text-l--bold' : '';

        return (
            <span className={`bt-copy-text-l ${classNameBold}`}>
                {text}
            </span>
        );
    }
}

CopyTextL.propTypes = {
    text: PropTypes.string,
    bold: PropTypes.bool,
};

CopyTextL.defaultProps = {
    text: '',
    bold: false,
};

export default CopyTextL;
