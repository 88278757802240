/* eslint-disable */
import React from 'react';

const IconWarning40 = (
    <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="nonzero">
            <path d="M19.998 0C31.026 0 40 8.97 40 19.998S31.026 40 19.998 40C8.97 40 0 31.026 0 19.998 0 8.97 8.97 0 19.998 0zm0 1C9.523 1 1 9.523 1 19.998 1 30.474 9.524 39 19.998 39 30.473 39 39 30.473 39 19.998 39 9.524 30.474 1 19.998 1z" />
            <path d="M20 10c.527 0 .96.336.997.762l.003.062v12.352c0 .455-.448.824-1 .824-.527 0-.96-.336-.997-.762L19 23.176V10.824c0-.455.448-.824 1-.824zM20 27c.527 0 .96.35.997.793l.003.064v1.286c0 .473-.448.857-1 .857-.527 0-.96-.35-.997-.793L19 29.143v-1.286c0-.473.448-.857 1-.857z" />
        </g>
    </svg>
);

export default IconWarning40;
