import React from 'react';

const IconDownloadImage = (
  <svg width="30" height="37" viewBox="0 0 30 37" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.4199 6.59205L15.5977 6.59909C23.6173 6.91652 30 13.5471 30 21.6168C30 29.9157 23.2711 36.6667 15 36.6667C6.72918 36.6667 0 29.9149 0 21.6168C0 13.6924 6.16172 7.13205 14.0119 6.61575L14.1849 6.60437V0.620109C14.1849 0.27966 14.4637 0 14.8024 0C15.1413 0 15.4199 0.279523 15.4199 0.620109V6.59205ZM23.8536 18.1297C24.0937 18.3706 24.0937 18.7653 23.8533 19.0065L15.2637 27.6258C15.2535 27.6323 15.2455 27.6396 15.2382 27.6499L14.8022 28.0885L5.74745 19.0079C5.50713 18.7668 5.50712 18.372 5.74748 18.1309C5.98864 17.89 6.37985 17.89 6.61992 18.1309L13.8686 25.4005L14.1849 25.7177V7.84216L13.986 7.85689C6.82814 8.38741 1.2353 14.3865 1.2353 21.6168C1.2353 29.2303 7.4108 35.4261 15 35.4261C22.5894 35.4261 28.7647 29.2301 28.7647 21.6153C28.7647 14.2359 22.9435 8.16299 15.6118 7.83822L15.4184 7.82965V25.7164L22.9809 18.1295C23.221 17.8886 23.6122 17.8886 23.8536 18.1297Z"
        fill="#2183CA"
      />
      <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="30" height="37">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M15.4199 6.59205L15.5977 6.59909C23.6173 6.91652 30 13.5471 30 21.6168C30 29.9157 23.2711 36.6667 15 36.6667C6.72918 36.6667 0 29.9149 0 21.6168C0 13.6924 6.16172 7.13205 14.0119 6.61575L14.1849 6.60437V0.620109C14.1849 0.27966 14.4637 0 14.8024 0C15.1413 0 15.4199 0.279523 15.4199 0.620109V6.59205ZM23.8536 18.1297C24.0937 18.3706 24.0937 18.7653 23.8533 19.0065L15.2637 27.6258C15.2535 27.6323 15.2455 27.6396 15.2382 27.6499L14.8022 28.0885L5.74745 19.0079C5.50713 18.7668 5.50712 18.372 5.74748 18.1309C5.98864 17.89 6.37985 17.89 6.61992 18.1309L13.8686 25.4005L14.1849 25.7177V7.84216L13.986 7.85689C6.82814 8.38741 1.2353 14.3865 1.2353 21.6168C1.2353 29.2303 7.4108 35.4261 15 35.4261C22.5894 35.4261 28.7647 29.2301 28.7647 21.6153C28.7647 14.2359 22.9435 8.16299 15.6118 7.83822L15.4184 7.82965V25.7164L22.9809 18.1295C23.221 17.8886 23.6122 17.8886 23.8536 18.1297Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0)">
        <rect x="-3.7041" y="-0.370605" width="37.037" height="37.037" fill="#2183CA" />
      </g>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect width="30" height="36.6667" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default IconDownloadImage;
