import React, { Component } from 'react';
import PropTypes from 'prop-types';
import LabelXXL from '../../components/elements/LabelXXL';
import LabelMRegular from '../../components/elements/LabelMRegular';
import FallbackEventIcon from '../../components/FallbackEventIcon/FallbackEventIcon';
import './DownloadsPanel.scss';

class DownloadsPanel extends Component {
  render() {
    const { imgSrc, title, date, description, isExtended, onClick, category, isDigital } = this.props;

    let className = 'bt-downloads-panel';
    if (!isExtended) {
      className += ' bt-downloads-panel--compact';
    }

    return (
      <div className={className} onClick={onClick}>
        <div className="bt-downloads-panel__header">
          <div className="bt-downloads-panel__header-top">
            {imgSrc ? (
              <div className="bt-downloads-header__picture">{imgSrc && <img src={imgSrc} alt="" />}</div>
            ) : (
              <FallbackEventIcon category={category.toUpperCase()} large isDigital={isDigital} />
            )}

            <div className="bt-downloads-header__info">
              <div className="bt-downloads-header__info-title">
                <LabelXXL text={title} />
              </div>
              <div className="bt-downloads-header__info-date">
                <span>{date}</span>
              </div>
              <div className="bt-downloads-header__info-description">
                <LabelMRegular text={description} />
              </div>
            </div>
          </div>
          <div className="bt-downloads-header__info-description--mobile">
            <LabelMRegular text={description} />
          </div>
        </div>
        <div className="bt-downloads-panel__content">{this.props.children}</div>
      </div>
    );
  }
}

DownloadsPanel.propTypes = {
  title: PropTypes.string,
  date: PropTypes.string,
  description: PropTypes.string,
  imgSrc: PropTypes.string,
  isExtended: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  onClick: PropTypes.func,
  category: PropTypes.string,
  isDigital: PropTypes.bool,
};

DownloadsPanel.defaultProps = {
  title: '',
  date: '',
  description: '',
  imgSrc: '',
  isExtended: false,
  children: null,
  onClick: () => false,
  category: '',
  isDigital: false,
};

export default DownloadsPanel;
