import React from 'react';
import PropTypes from 'prop-types';
import Input from '../elements/Input';
import Button from '../elements/Button';
import './ResetPasswordForm.scss';

const ResetPasswordForm = (props) => {
  const { onChangePassword, onChangePasswordRepeat, onSubmit } = props;

  return (
    <div className="bt-reset-password-form">
      <div className="bt-reset-password-form__input">
        <Input type="password" label="New password *" errorMessage={'Bitte ausfüllen'} onChange={onChangePassword} />
      </div>
      <div className="bt-reset-password-form__input">
        <Input
          type="password"
          label="Validate new password *"
          errorMessage={'Bitte ausfüllen'}
          onChange={onChangePasswordRepeat}
        />
      </div>
      <div className="bt-reset-password-form__submit-button">
        <Button layout="primary" path="#" label="Confirm Password" onClick={onSubmit} />
      </div>
    </div>
  );
};

ResetPasswordForm.propTypes = {
  onChangePassword: PropTypes.func,
  onChangePasswordRepeat: PropTypes.func,
  onSubmit: PropTypes.func,
};

ResetPasswordForm.defaultProps = {
  onChangePassword: () => false,
  onChangePasswordRepeat: () => false,
  onSubmit: () => false,
};

export default ResetPasswordForm;
