/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import './status-list.scss';
import HeadlineTwo from '../elements/HeadlineTwo';
import HeadlineThree from '../elements/HeadlineThree';
import CopyTextM from '../elements/CopyTextM';

const StatusList = ({ attendeeData }) => {
    return (
        <div className="bt-status-list">
            <HeadlineTwo text="All status messages" />
            {attendeeData.map((attendee, i) => {
                return (
                    <div className="bt-status-list__item" key={i}>
                        <div className="bt-status-list__item-header">
                            <div className="bt-status-list__picture">
                                {attendee.profilePictureUrl && <img src={attendee.profilePictureUrl} alt="" />}
                            </div>
                            <div className="bt-status-list__item-name">
                                <HeadlineThree text={attendee.fullName} />
                            </div>
                        </div>
                        <div className="bt-status-list__item-status">
                            <CopyTextM text={attendee.status.message} />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

StatusList.propTypes = {
    attendeeData: PropTypes.array
};

StatusList.defaultProps = {};

export default StatusList;
