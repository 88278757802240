/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './meta-navigation-link.scss';

class MetaNavigationLink extends Component {
    // constructor(props) {
    //     super(props);
    // }

    handleOnClick(e) {
        const { isExternalLink, path, onClick } = this.props;

        if (e && !isExternalLink) {
            e.preventDefault();
        }

        onClick(path);
    }

    render() {
        const { icon, label, href, target, isActive } = this.props;

        let className = 'meta-navigation-link';
        if (isActive) {
            className += ' meta-navigation-link--active';
        }

        return (
            <div className={className}>
                <a href={href} target={target} onClick={(e) => this.handleOnClick(e)}>
                    {icon && <span className="meta-navigation-link__icon">{icon}</span>}
                    <span>{label}</span>
                </a>
                <div className="meta-navigation-link__ind" />
            </div>
        );
    }
}

MetaNavigationLink.propTypes = {
    isExternalLink: PropTypes.bool,
    label: PropTypes.string,
    icon: PropTypes.object,
    href: PropTypes.string,
    path: PropTypes.string,
    target: PropTypes.string,
    isActive: PropTypes.bool,
    onClick: PropTypes.func
};

MetaNavigationLink.defaultProps = {
    isExternalLink: false,
    label: '',
    icon: null,
    href: '',
    path: '',
    target: '',
    isActive: false,
    onClick: () => false
};

export default MetaNavigationLink;
