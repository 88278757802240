/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import IconWarning20 from '../../assets/icons/IconWarning20';
import './inline-error-message.scss';

class InlineErrorMessage extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        const { text } = this.props;

        return (
            <div className="inline-error-message">
                <div className="inline-error-message__icon">{IconWarning20}</div>
                <div className="inline-error-message__text">
                    <span dangerouslySetInnerHTML={{ __html: text }} />
                </div>
            </div>
        );
    }
}

InlineErrorMessage.propTypes = {
    text: PropTypes.string
};

InlineErrorMessage.defaultProps = {
    text: ''
};

export default InlineErrorMessage;
