/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './image-thumb.scss';

class ImageThumb extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            size,
            sizeMobile,
            profileInitials,
            imageSrc,
            isMobileMenu
        } = this.props;

        let className = 'bt-image-thumb';

        if (size === 'XS') {
            className += ' bt-image-thumb--size-xs';
        } else if (size === 'S') {
            className += ' bt-image-thumb--size-s';
        } else if (size === 'M') {
            className += ' bt-image-thumb--size-m';
        } else if (size === 'L') {
            className += ' bt-image-thumb--size-l';
        } else if (size === 'XL') {
            className += ' bt-image-thumb--size-xl';
        }

        if (sizeMobile === 'XS') {
            className += ' bt-image-thumb--mobile-size-xs';
        } else if (sizeMobile === 'S') {
            className += ' bt-image-thumb--mobile-size-s';
        } else if (sizeMobile === 'M') {
            className += ' bt-image-thumb--mobile-size-m';
        } else if (sizeMobile === 'L') {
            className += ' bt-image-thumb--mobile-size-l';
        }

        if (imageSrc) {
            className += ' bt-image-thumb--is-image';
        }

        if (isMobileMenu) {
            className += ' bt-image-thumb--is-mobile-menu';
        }

        // <img src={imageSrc} alt='' />
        return (
            <div className={className}>
                {profileInitials && !imageSrc && (
                    <div className="bt-image-thumb__profile-initials">
                        {profileInitials}
                    </div>
                )}
                {imageSrc && (
                    <div
                        className="bt-image-thumb__profile-image"
                        style={{
                            backgroundImage: `url(${imageSrc})`
                        }}
                    />
                )}
            </div>
        );
    }
}

ImageThumb.propTypes = {
    /**
     * @prop size
     * @type string
     * @values 'XS' (30x30) | 'S' (36x36) | 'M' (60x60) | 'L' (120x120)
     */
    size: PropTypes.string,
    sizeMobile: PropTypes.string,
    profileInitials: PropTypes.string,
    imageSrc: PropTypes.string,
    isMobileMenu: PropTypes.bool
};

ImageThumb.defaultProps = {
    size: '',
    sizeMobile: '',
    profileInitials: '',
    imageSrc: '',
    isMobileMenu: false
};

export default ImageThumb;
