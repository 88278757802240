/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import ImageThumb from '../../components/elements/ImageThumb';
import HeadlineThree from '../../components/elements/HeadlineThree';
import LabelL from '../../components/elements/LabelL';
import CopyTextM from '../../components/elements/CopyTextM';
import FellowTeaserNavigation from '../../components/FellowTeaserNavigation';
import Button from '../../components/elements/Button';
import IconEmail20 from '../../assets/icons/IconEmail20';
import IconLinkedIn20 from '../../assets/icons/IconLinkedIn20';
import IconTwitter20 from '../../assets/icons/IconTwitter20';
import IconPhone20 from '../../assets/icons/IconPhone20';
import IconLink20 from '../../assets/icons/IconLink20';
import './FellowTeaser.scss';

class FellowTeaser extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      activeMenu: 'employer',
    };
    this.handleClickNavigationItem = this.handleClickNavigationItem.bind(this);
    this.handleClickMore = this.handleClickMore.bind(this);
  }

  handleClickMore(e, path) {
    const { onClickMore } = this.props;
    onClickMore(e, path);
  }

  handleClickNavigationItem(menuItem) {
    this.setState({
      activeMenu: menuItem,
    });
  }

  render() {
    const {
      imageSrc,
      fullName,
      profession,
      location,
      profilePath,
      employers,
      statusMessage,
      statusHidden,
      initials,
      emailArray,
      linkedinArray,
      twitterArray,
      phoneArray,
      otherArray,
    } = this.props;

    return (
      <div className="bt-fellow-teaser">
        <div className="bt-fellow-teaser__picture">
          <ImageThumb size="L" profileInitials={initials} imageSrc={imageSrc} />
        </div>
        <div className="bt-fellow-teaser__info">
          <HeadlineThree text={fullName} />
          <LabelL text={profession} />
          <br />
          <LabelL text={location} />
        </div>
        <FellowTeaserNavigation
          onClickNavigationItem={this.handleClickNavigationItem}
          onClickMore={this.handleClickMore}
          activeMenu={this.state.activeMenu}
          profilePath={profilePath}
          employers={employers}
          emailArray={emailArray}
          linkedinArray={linkedinArray}
          twitterArray={twitterArray}
          phoneArray={phoneArray}
          otherArray={otherArray}
          statusMessage={statusMessage}
          statusHidden={statusHidden}
        />
        <div className="bt-fellow-teaser__body">
          {this.state.activeMenu === 'employer' &&
            employers.map((employer, i) => (
              <div key={i}>
                <CopyTextM grey heavy text={employer.name} />
              </div>
            ))}
          {this.state.activeMenu === 'contact' &&
            emailArray.length > 0 &&
            emailArray.map((item, index) => (
              <div className="bt-fellow-teaser__contact-element" key={index}>
                <Button path={`mailto:${emailArray[index]}`} link label={emailArray[index]} iconLeft={IconEmail20} />
              </div>
            ))}
          {this.state.activeMenu === 'contact' &&
            linkedinArray.length > 0 &&
            linkedinArray.map((item, index) => (
              <div className="bt-fellow-teaser__contact-element" key={index}>
                <Button
                  path={linkedinArray[index]}
                  link
                  label={linkedinArray[index]}
                  iconLeft={IconLinkedIn20}
                  target="_blank"
                />
              </div>
            ))}
          {this.state.activeMenu === 'contact' &&
            twitterArray.length > 0 &&
            twitterArray.map((item, index) => (
              <div className="bt-fellow-teaser__contact-element" key={index}>
                <Button
                  path={twitterArray[index]}
                  link
                  label={twitterArray[index]}
                  iconLeft={IconTwitter20}
                  target="_blank"
                />
              </div>
            ))}
          {this.state.activeMenu === 'contact' &&
            phoneArray.length > 0 &&
            phoneArray.map((item, index) => (
              <div className="bt-fellow-teaser__contact-element" key={index}>
                <Button path={`tel:${phoneArray[index]}`} link label={phoneArray[index]} iconLeft={IconPhone20} />
              </div>
            ))}
          {this.state.activeMenu === 'contact' &&
            otherArray.length > 0 &&
            otherArray.map((item, index) => (
              <div className="bt-fellow-teaser__contact-element" key={index}>
                <Button path={otherArray[index]} link label={otherArray[index]} iconLeft={IconLink20} target="_blank" />
              </div>
            ))}

          <br />
          {this.state.activeMenu === 'status' && <CopyTextM grey text={statusMessage} />}
        </div>
      </div>
    );
  }
}

FellowTeaser.propTypes = {
  id: PropTypes.number,
  imageSrc: PropTypes.string,
  fullName: PropTypes.string,
  profession: PropTypes.string,
  location: PropTypes.string,
  employers: PropTypes.array,
  email: PropTypes.string,
  statusMessage: PropTypes.string,
  statusHidden: PropTypes.bool,
  profilePath: PropTypes.string,
  initials: PropTypes.string,
  contact: PropTypes.object,
  emailArray: PropTypes.array,
  linkedinArray: PropTypes.array,
  twitterArray: PropTypes.array,
  phoneArray: PropTypes.array,
  otherArray: PropTypes.array,
  onClickMore: PropTypes.func,
};

FellowTeaser.defaultProps = {
  id: '',
  initials: '',
  imageSrc: '',
  fullName: '',
  profession: '',
  location: '',
  employers: [],
  emailArray: [],
  linkedinArray: [],
  twitterArray: [],
  phoneArray: [],
  otherArray: [],
  email: '',
  contact: {},
  statusMessage: '',
  statusHidden: false,
  profilePath: '/fellows/',
  onClickMore: () => {},
};

export default FellowTeaser;
