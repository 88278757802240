import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ContentSection from '../../atoms/ContentSection';
import PageContentHeader from '../PageContentHeader';
import DownloadsToolbar from './DownloadsToolbar';
import DownloadsUtil from '../../utils/DownloadsUtil';
import { updateScrollAnkerEventId } from '../../redux-actions/actionsDownloads';
import DownloadLists from './DownloadLists';
import './page-view-downloads.scss';

class PageViewDownloads extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingIndicator: 'loading...',
      speakerSearch: undefined,
    };
  }

  async componentDidMount() {
    await this.fetchData();

    // If the user clicks on show all documents on the newsfeed,
    // he will be redirected here and should scroll to the selected eleent
    const { activeScrollAnkerId } = this.props;
    const scrollId = `bt-${activeScrollAnkerId}`;
    await setTimeout(() => {
      if (activeScrollAnkerId) {
        const selectedDownload = document.getElementById(scrollId);
        selectedDownload.scrollIntoView({ inline: 'start', block: 'center', behavior: 'smooth' });
      }
    }, 300);
  }

  componentWillUnmount() {
    const { dispatch } = this.props;
    dispatch(updateScrollAnkerEventId(''));
  }

  async fetchData() {
    try {
      await DownloadsUtil.loadDownloads();
      await this.setState({
        loadingIndicator: '',
      });
    } catch (err) {
      //
    }
  }

  handleChangeSpeakerSearch(value) {
    this.setState({
      speakerSearch: value,
    });
  }

  render() {
    const { speakerSearch, loadingIndicator } = this.state;

    return (
      <div className="bt-page-view-downloads">
        <PageContentHeader title="Media Library" />
        <DownloadsToolbar onChangeSpeaker={(value) => this.handleChangeSpeakerSearch(value)} />

        {loadingIndicator ? (
          <ContentSection paddingTop="30px">{loadingIndicator}</ContentSection>
        ) : (
          <DownloadLists speakerSearch={speakerSearch} />
        )}
      </div>
    );
  }
}

PageViewDownloads.propTypes = {
  activeScrollAnkerId: PropTypes.any,
  dispatch: PropTypes.func,
};

PageViewDownloads.defaultProps = {
  activeScrollAnkerId: undefined,
  dispatch: () => {},
};

const mapStateToProps = ({ downloadsState }) => {
  const { activeScrollAnkerId } = downloadsState;
  return { activeScrollAnkerId };
};

export default connect(mapStateToProps)(PageViewDownloads);
