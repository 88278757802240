/* eslint-disable */
import React from 'react';

const IconDownloads27 = (
    <svg width="27" height="27" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.53117 4.81074l-.1315-.00514V.45206c0-.24829-.2059-.45206-.45648-.45206-.25041 0-.45649.20387-.45649.45206v4.36252l-.12792.0083C7.55528 5.19926 3 9.98173 3 15.75862 3 21.80798 7.97479 26.73 14.08929 26.73c6.11469 0 11.08928-4.92142 11.08928-10.97138 0-5.88276-4.71863-10.71648-10.6474-10.94788zm6.10327 9.04497c.17769-.1758.17769-.46357.00022-.63914-.17851-.1758-.46773-.1758-.6452-.00022l-5.59084 5.5309V5.7078l.14296.00624c5.42022.23676 9.72375 4.66395 9.72375 10.04353 0 5.55115-4.56529 10.06805-10.17604 10.06805-5.6106 0-10.17605-4.51673-10.17605-10.067 0-5.27086 4.13471-9.64421 9.42644-10.03096l.14702-.01075v13.0313l-.23385-.23126-5.35884-5.29955c-.17747-.17558-.4667-.17558-.64498 0-.17769.1758-.17769.46357-.00002.63934l6.69404 6.61975.32234-.3197a.06382.06382 0 0 1 .01883-.01758l6.35022-6.2835z" fill="currentColor" fillRule="nonzero" />
    </svg>
);

export default IconDownloads27;
