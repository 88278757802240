import React from 'react';
import EventAssets from '../EventAssets';
import { useAppSelector } from '../../app/hooks';

const LayerViewEventAssets: React.FC = () => {
  const { activeEventModel } = useAppSelector(({ eventsState }) => eventsState);
  return <EventAssets eventModel={activeEventModel} />;
};

export default LayerViewEventAssets;
