export const useEvent = (event: EventData | undefined) => {
  const { category, city, country, datum, coverPicture, registration, assets } = event || {};

  /**
   * category
   */
  const eventCategoryFormatted =
    category === 'beep' ? 'BeEP' : category !== 'Other' ? category?.toUpperCase() : category;

  /**
   * location
   */
  const eventLocationFormatted = city && country ? `${city}, ${country}` : city || country;

  /**
   * date and time formatted
   */
  const { date, time } = datum || {};
  const eventDateFormatted = date;
  const eventTimeFormatted = time;
  const eventDateTimeFormatted = `${eventDateFormatted}${eventTimeFormatted ? ' | ' : ''}${eventTimeFormatted || ''}`;

  /**
   * cover picture
   */
  const eventCoverPictureMediumLarge = coverPicture ? coverPicture.sizes?.medium_large : undefined;

  /**
   * attendance / registration
   */
  let eventAttendanceType;
  let eventIsDigital = false;
  if (registration) {
    eventAttendanceType = registration.isEnabled ? 'register' : undefined;
    eventIsDigital = registration.isEnabled || false;
  }

  /**
   * assets/agenda
   */
  const eventAgendaFileUrl = assets?.agenda?.[0]?.url;

  return {
    event,
    eventCategoryFormatted,
    eventLocationFormatted,
    eventDateFormatted,
    eventTimeFormatted,
    eventDateTimeFormatted,
    eventCoverPictureMediumLarge,
    eventAttendanceType,
    eventIsDigital,
    eventAgendaFileUrl,
  };
};
