/* eslint-disable */
import React from 'react';

const IconWarning20 = (
    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="evenodd">
            <path d="M10 1.5625c-4.6634766 0-8.4375 3.773711-8.4375 8.4375 0 4.6634766 3.773711 8.4375 8.4375 8.4375 4.6634766 0 8.4375-3.7737109 8.4375-8.4375 0-4.6634766-3.7737109-8.4375-8.4375-8.4375zM10 20C4.4729297 20 0 15.5274609 0 10 0 4.4729297 4.472539 0 10 0c5.5270703 0 10 4.472539 10 10 0 5.5270703-4.4725391 10-10 10z" />
            <path d="M10 11.6277344c-.4314844 0-.78125-.3497657-.78125-.78125V5.8155078c0-.4314844.3497656-.78125.78125-.78125s.78125.3497656.78125.78125v5.0309766c0 .4314843-.3497656.78125-.78125.78125M10 12.584375c.5825 0 1.0546875.4721875 1.0546875 1.0546875S10.5825 14.69375 10 14.69375s-1.0546875-.4721875-1.0546875-1.0546875S9.4175 12.584375 10 12.584375" />
        </g>
    </svg>
);

export default IconWarning20;
