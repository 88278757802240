import AppUtil from '../utils/AppUtil';

/* eslint-disable */

class FellowModel {
    constructor(data) {
        this._data = data || {};

        /**
         * data values (db)
         */
        this._id = this._data.id || '';
        this._profilePicture = this._data.profilePicture || {};
        this._title = this._data.title || '';
        this._firstName = this._data.firstName || '';
        this._lastName = this._data.lastName || '';
        this._profession = this._data.profession || '';
        this._degree = this._data.degree || '';
        this._city = this._data.city || '';
        this._country = this._data.country || '';
        this._status = this._data.status || null;
        this._employers = this._data.employers || [];
        this._contact = this._data.contact || {};
        this._links = this._data.links || [];

        /**
         * custom values
         */
        this._fullName = this._firstName + ' ' + this._lastName;
        this._statusMessage = this._status && this._status.message;
        this._statusHidden = this._status && this._status.hideOnNewsfeed;
        this._profilePictureUrl =
            this._profilePicture && this._profilePicture.url;
        this._locationCombined =
            this._city && this._country
                ? this._city + ', ' + this._country
                : this._city || this._country;
        this._initials = AppUtil.getInitials(this._firstName, this._lastName);
        this._contactEmailArray = this._contact.email || [];
        this._contactLinkedinArray = this._contact.linkedin || [];
        this._contactOtherArray = this._contact.other || [];
        this._contactPhoneArray = this._contact.phone || [];
        this._contactTwitterArray = this._contact.twitter || [];
        this._email = this._emailArray && this._emailArray[0];
        this._jobDegree =
            (this.profession && this.profession) +
            (this._degree && ' ' + this._degree);
    }

    get data() {
        return this._data;
    }

    get id() {
        return this._id;
    }

    get profilePicture() {
        return this._profilePicture;
    }

    get firstName() {
        return this._firstName;
    }
    get lastName() {
        return this._lastName;
    }

    get profession() {
        return this._profession;
    }

    get degree() {
        return this._degree;
    }

    get city() {
        return this._city;
    }
    get country() {
        return this._country;
    }

    get status() {
        return this._status;
    }

    get employers() {
        return this._employers;
    }
    get contact() {
        return this._contact;
    }
    get fullName() {
        return this._fullName;
    }
    get statusMessage() {
        return this._statusMessage;
    }
    get statusHidden() {
        return this._statusHidden;
    }
    get profilePictureUrl() {
        return this._profilePictureUrl;
    }
    get initials() {
        return this._initials;
    }
    get locationCombined() {
        return this._locationCombined;
    }
    get contactEmailArray() {
        return this._contactEmailArray;
    }
    get contactLinkedinArray() {
        return this._contactLinkedinArray;
    }
    get contactOtherArray() {
        return this._contactOtherArray;
    }
    get contactPhoneArray() {
        return this._contactPhoneArray;
    }
    get contactTwitterArray() {
        return this._contactTwitterArray;
    }
    get email() {
        return this._email;
    }
    get jobDegree() {
        return this._jobDegree;
    }
    get links() {
        return this._links;
    }
}

export default FellowModel;
