/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import HeadlineThree from '../elements/HeadlineThree';
import CopyTextL from '../elements/CopyTextL';
import Button from '../elements/Button';
import './newsfeed-event-attendee.scss';
import IconInfo20 from '../../assets/icons/IconInfo20';

class NewsfeedEventAttendee extends React.Component {
  render() {
    const { imageUrl, name, title, location, onClickDetails, initials } = this.props;
    return (
      <div className="bt-newsfeed-event-attendees__list-item">
        <div className="bt-newsfeed-event-attendees__image">
          {imageUrl ? (
            <img src={imageUrl} alt="" />
          ) : (
            <span className="bt-newsfeed-event-attendees__no-picture">{initials}</span>
          )}
        </div>
        <div className="bt-newsfeed-event-attendees__info-container">
          <div className="bt-newsfeed-event-attendees__info">
            <HeadlineThree text={name} />
            <CopyTextL text={title} />
            <br />
            <CopyTextL text={location} />
          </div>
          <div className="bt-newsfeed-event-attendees__button">
            <Button layout="secondary-side" path="#" label="Details" onClick={(e) => onClickDetails(e)} />
          </div>
          <div className="bt-newsfeed-event-attendees__button-mobile">
            <Button layout="primary-side-small" path={'#'} iconInfo={IconInfo20} onClick={(e) => onClickDetails(e)} />
          </div>
        </div>
      </div>
    );
  }
}

NewsfeedEventAttendee.propTypes = {
  imageUrl: PropTypes.string,
  name: PropTypes.string,
  title: PropTypes.string,
  location: PropTypes.string,
  onClickDetails: PropTypes.func,
  initials: PropTypes.string,
};

NewsfeedEventAttendee.defaultProps = {
  initials: '',
  imageUrl: '',
  name: '',
  title: '',
  location: '',
  onClickDetails: () => false,
};

export default NewsfeedEventAttendee;
