/* eslint-disable */
import { REDUX_ACTIONS } from '../global/constants';

function newsfeedReducer(
    state = {
        fellowModels: [],
        eventModels: [],
        fellowStatusModels: [],
        downloadModels: [],
        isNewsfeedLoading: false,
        isNewsfeedLoaded: false,
        tweets: [],
        newsTeaserData: [],
        activeNewsData: []
    },
    action
) {
    switch (action.type) {
        case REDUX_ACTIONS.UPDATE_FELLOW_MODELS_NEWSFEED:
            return Object.assign({}, state, {
                fellowModels: action.fellowModels
            });

        case REDUX_ACTIONS.UPDATE_EVENT_MODELS_NEWSFEED:
            return Object.assign({}, state, {
                eventModels: action.eventModels
            });

        case REDUX_ACTIONS.UPDATE_NEWS_TEASER_DATA:
            return Object.assign({}, state, {
                newsTeaserData: action.newsTeaserData
            });

        case REDUX_ACTIONS.UPDATE_NEWS:
            return Object.assign({}, state, {
                activeNewsData: action.newsData
            });

        case REDUX_ACTIONS.UPDATE_FELLOW_STATUS_MODELS_NEWSFEED:
            return Object.assign({}, state, {
                fellowStatusModels: action.fellowStatusModels
            });

        case REDUX_ACTIONS.UPDATE_DOWNLOAD_MODELS_NEWSFEED:
            return Object.assign({}, state, {
                downloadModels: action.downloadModels
            });

        case REDUX_ACTIONS.IS_NEWSFEED_LOADING:
            return Object.assign({}, state, {
                isNewsfeedLoading: action.isNewsfeedLoading
            });

        case REDUX_ACTIONS.IS_NEWSFEED_LOADED:
            return Object.assign({}, state, {
                isNewsfeedLoaded: true
            });

        case REDUX_ACTIONS.RECIEVE_TWEETS:
            return Object.assign({}, state, {
                tweets: action.tweets
            });

        default:
            return state;
    }
}

export default newsfeedReducer;
