/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './button.scss';

class ButtonDownload extends Component {
    constructor(props) {
        super(props);
    }

    get layoutClassName() {
        const { layout } = this.props;

        switch (layout) {
            case 'primary':
                return 'bt-button-download--primary';

            case 'primary-side':
                return 'bt-button-download--primary-side';

            case 'primary-side-small':
                return 'bt-button-download--primary-side-small';

            case 'secondary':
                return 'bt-button-download--secondary';

            case 'secondary-active':
                return 'bt-button-download--secondary-active';

            case 'secondary-side':
                return 'bt-button-download--secondary-side';

            case 'secondary-side-small':
                return 'bt-button-download--secondary-side-small';

            case 'secondary-white-side':
                return 'bt-button-download--secondary-white-side';

            case 'meta-mobile':
                return 'bt-button-download--meta-mobile';

            case 'meta-mobile-active':
                return 'bt-button-download--meta-mobile-active';

            case 'akkordeon-toggle':
                return 'bt-button-download--akkordeon-toggle';

            case 'layer-back':
                return 'bt-button-download--layer-back';

            case 'layer-close':
                return 'bt-button-download--layer-close';

            case 'grey':
                return 'bt-button-download--grey';

            default:
                return '';
        }
    }

    renderIconLeft() {
        const { iconLeft } = this.props;
        if (!iconLeft) {
            return null;
        }
        return <span className="bt-button-download__icon-left">{iconLeft}</span>;
    }

    renderLabel() {
        const { label } = this.props;
        if (!label) {
            return null;
        }
        return <span className="bt-button-download__label">{label}</span>;
    }

    renderIconRight() {
        const { iconRight } = this.props;
        if (!iconRight) {
            return null;
        }
        return <span className="bt-button-download__icon-right">{iconRight}</span>;
    }

    renderIconInfo() {
        const { iconInfo } = this.props;
        if (!iconInfo) {
            return null;
        }
        return <span className="bt-button-download__icon-info">{iconInfo}</span>;
    }

    render() {
        const { path } = this.props;

        return (
            <a
                href={path}
                className={`bt-button-download ${this.layoutClassName} ${
                    this.props.lineBreak && 'bt-button-download-line-break'
                }`}
                target="_blank"
                download
            >
                <span className="bt-button-download__wrapper">
                    {this.renderIconLeft()}
                    {this.renderLabel()}
                    {this.renderIconRight()}
                    {this.renderIconInfo()}
                </span>
            </a>
        );
    }
}

ButtonDownload.propTypes = {
    layout: PropTypes.string,
    path: PropTypes.string,
    label: PropTypes.string,
    iconLeft: PropTypes.object,
    iconRight: PropTypes.object,
    iconInfo: PropTypes.object,
    lineBreak: PropTypes.bool,
    target: PropTypes.string
};

ButtonDownload.defaultProps = {
    layout: '',
    path: '',
    label: '',
    iconLeft: null,
    iconRight: null,
    iconInfo: null,
    lineBreak: false,
    target: ''
};

export default ButtonDownload;
