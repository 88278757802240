/* eslint-disable */
import AppUtil from '../utils/AppUtil';
import EventsUtil from '../utils/EventsUtil';
import FormatUtil from '../utils/FormatUtil';

class EventModel {
  constructor(data) {
    this._data = data || {};
    this._event = data;

    /**
     * basic data values
     */
    this._id = this._data.id || '';
    this._category = this._data.category || '';
    this._attendees = this._data.attendees || [];
    this._city = this._data.city || '';
    this._country = this._data.country || '';
    this._coverPicture = this._data.coverPicture || '';
    this._startDate = this._data.startDate || {};
    this._endDate = this._data.endDate || {};
    this._canAttend = this._data.canAttend || null;
    this._isAttending = this._data.isAttending || false;
    this._subTitle = this._data.subTitle || '';
    this._title = this._data.title || '';
    this._registration = this._data.registration || {};

    /**
     * detail data additional values
     */
    this._evaluation = this._data.evaluation || '';
    this._schedule = this._data.schedule || [];
    this._schedulePdf = this._data.schedulePDF || false;
    this._venue = this._data.venue || '';
    this._onlineVenue = this._data.onlineVenue || '';
    this._agenda = this._data.agenda || {};
    this._description = this._data.description || '';
    this._isDigital = this._registration.isEnabled || false;
    this._assets = this._data.assets || {};

    /**
     * custom values
     */
    this._startDateYear = this._startDate.date ? AppUtil.getYear(this._startDate.date) : '';
    this._endDateYear = this._endDate.date ? AppUtil.getYear(this._endDate.date) : '';

    this._isPast = this._endDate.date ? new Date(this._endDate.date) < new Date() : false;

    this._dateSpan =
      this._startDate.date && this._endDate.date
        ? AppUtil.getDateSpanFormatted(this._startDate.date, this._endDate.date)
        : '';

    this._dateSpanSep =
      this._startDate.date && this._endDate.date
        ? AppUtil.getDateSpanFormattedSeparate(this._startDate.date, this._endDate.date)
        : '';

    this._dateSpanSolo = this._dateSpanSep && this._dateSpanSep.dateString;
    this._timeSpanSolo = this._dateSpanSep && this._dateSpanSep.timeString;

    // Disabled CEST of CET for now
    // this._dateSpanSep && this._dateSpanSep.timeString ? this._dateSpanSep.timeString + ' CEST' : null;

    this._locationCombined =
      this._city && this._country ? this._city + ', ' + this._country : this._city || this._country;

    this._categoryCapitalised =
      this._category && (this._category !== 'Other' ? this._category.toUpperCase() : this._category);

    this._coverPictureMedium = this._coverPicture ? this._coverPicture.sizes.medium_large : '';

    this._scheduleFormatted = EventsUtil.formatSchedule(this._schedule);

    this._agendaUrl = this._agenda.url;

    if (this._data.registration) {
      this._attendanceType = this._data.registration.isEnabled ? 'register' : null;
      this._successText = this._data.registration.successText || null;
      this._introText = this._data.registration.introText || null;
    } else {
      this._attendanceType = null;
      this._successText = null;
      this._introText = null;
    }

    /**
     * custom values assets
     */
    this._agenda = this._assets.agenda || [];
    this._presentations = this._assets.presentations || [];
    this._videos = this._assets.videos || [];
    this._pictures = this._assets.pictures || [];
    this._downloads = this._assets.downloads || [];
    this._documents = [];

    this._presentations.forEach((item) => {
      this._documents.push(item);
    });
    this._agenda.forEach((item) => {
      this._documents.push(item);
    });

    this._videoUrls = [];
    this._videoCaptions = [];
    this._videoDescriptions = [];
    this._videoThumbnails = [];
    this._videoRelatedPictures = [];
    this._videoRelatedDocuments = [];
    this._videoDownloads = [];

    this._videos.forEach((item) => {
      this._videoUrls.push(item.url);
      this._videoCaptions.push(item.caption);
      this._videoDescriptions.push(item.description);
      this._videoThumbnails.push(item.thumbnails);
      this._videoRelatedPictures.push(item.pictures);
      this._videoRelatedDocuments.push(item.documents);
      this._videoDownloads.push(item.downloads);
    });
  }

  get data() {
    return this._data;
  }

  get event() {
    return this._event;
  }

  get id() {
    return this._id;
  }

  get category() {
    return this._category;
  }

  get agenda() {
    return this._agenda;
  }

  get attendees() {
    return this._attendees;
  }

  get city() {
    return this._city;
  }

  get country() {
    return this._country;
  }

  get coverPicture() {
    return this._coverPicture;
  }

  get description() {
    return this._description;
  }

  get startDate() {
    return this._startDate;
  }

  get endDate() {
    return this._endDate;
  }

  get dateSpanSolo() {
    return this._dateSpanSolo;
  }

  get timeSpanSolo() {
    return this._timeSpanSolo;
  }

  get evaluation() {
    return this._evaluation;
  }

  get country() {
    return this._country;
  }

  get schedule() {
    return this._schedule;
  }
  get schedulePdf() {
    return this._schedulePdf;
  }

  get subTitle() {
    return this._subTitle;
  }

  get title() {
    return this._title;
  }

  get venue() {
    return this._venue;
  }
  get onlineVenue() {
    return this._onlineVenue;
  }

  get dateSpan() {
    return this._dateSpan;
  }

  get locationCombined() {
    return this._locationCombined;
  }

  get categoryCapitalised() {
    return this._categoryCapitalised;
  }

  get coverPictureMedium() {
    return this._coverPictureMedium;
  }

  get scheduleFormatted() {
    return this._scheduleFormatted;
  }

  get isAttending() {
    return this._isAttending;
  }

  get agendaUrl() {
    return this._agendaUrl;
  }

  get canAttend() {
    return this._canAttend;
  }

  get startDateYear() {
    return this._startDateYear;
  }

  get endDateYear() {
    return this._endDateYear;
  }

  get attendanceType() {
    return this._attendanceType;
  }

  get canRegister() {
    return this._canAttend && this._attendanceType === 'register';
  }

  get successText() {
    return this._successText;
  }

  get introText() {
    return this._introText;
  }

  get isDigital() {
    return this._isDigital;
  }

  get isPast() {
    return this._isPast;
  }
  get assets() {
    return this._assets;
  }
  get agenda() {
    return this._agenda;
  }
  get presentations() {
    return this._presentations;
  }
  get videos() {
    return this._videos;
  }
  get pictures() {
    return this._pictures;
  }
  get documents() {
    return this._documents;
  }
  get downloads() {
    return this._downloads;
  }
  get videoUrls() {
    return this._videoUrls;
  }
  get videoCaptions() {
    return this._videoCaptions;
  }
  get videoDescriptions() {
    return this._videoDescriptions;
  }
  get videoThumbnails() {
    return this._videoThumbnails;
  }
  get videoRelatedPictures() {
    return this._videoRelatedPictures;
  }
  get videoRelatedDocuments() {
    return this._videoRelatedDocuments;
  }
  get videoDownloads() {
    return this._videoDownloads;
  }
}

export default EventModel;
