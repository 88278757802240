import React from 'react';
import AppUtil from '../../utils/AppUtil';
import ImageThumb from '../elements/ImageThumb';
import './event-teaser-attendees.scss';
import { useOverlayContext } from '../overlay/OverlayProvider';

interface EventTeaserAttendeesProps {
  onClickAttendees: () => void;
  attendees: any;
  id: number;
  pageId: string;
}

const EventTeaserAttendees: React.FC<EventTeaserAttendeesProps> = ({ attendees, id, pageId, onClickAttendees }) => {
  const { overlayNavigate } = useOverlayContext();

  const handleClickAttendees = (e: any, path: string) => {
    e.preventDefault();
    overlayNavigate(path);
  };

  const firstAttendees = attendees.slice(0, 2);

  return (
    <div
      className="bt-event-attendees__image-group"
      onClick={(e) => handleClickAttendees(e, `/${pageId}/event/${id}/attendees`)}
    >
      {firstAttendees.map((attendee, i) => (
        <div key={String(i)} className="bt-event-attendees__image">
          <ImageThumb
            size="XS"
            profileInitials={AppUtil.getInitials(attendee.firstName, attendee.lastName)}
            imageSrc={attendee.profilePicture && attendee.profilePicture.url}
          />
        </div>
      ))}
      {attendees.length > 2 && (
        <div onClick={onClickAttendees} className="bt-event-attendees__counter">
          <span>{`+ ${attendees.length - 2}`}</span>
        </div>
      )}
    </div>
  );
};

export default EventTeaserAttendees;
