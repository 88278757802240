/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import HeadlineTwo from '../elements/HeadlineTwo';
import './registration-teaser.scss';
import CopyTextM from '../elements/CopyTextM';
import Button from '../elements/Button';

const RegistrationTeaser = (props) => {
    const { onClickRegister } = props;

    return (
        <div className="bt-registration-teaser">
            <div className="bt-registration-teaser__register">
                <div className="bt-registration-teaser__title">
                    <HeadlineTwo text={props.registerTitle} />
                </div>

                <CopyTextM text={props.registerText} />
            </div>

            <div className="bt-registration-teaser__benefits">
                <div className="bt-registration-teaser__title">
                    <HeadlineTwo text={props.benefitsTitle} />
                </div>

                <CopyTextM text={props.benefitsText} />
            </div>

            <div className="bt-registration-teaser__button">
                <Button layout="primary-side" path="#" label="Create my account" onClick={onClickRegister} />
            </div>
        </div>
    );
};

RegistrationTeaser.propTypes = {
    registerTitle: PropTypes.string,
    registerText: PropTypes.string,
    benefitsTitle: PropTypes.string,
    benefitsText: PropTypes.string,
    onClickRegister: PropTypes.func
};

RegistrationTeaser.defaultProps = {
    registerTitle: '',
    registerText: '',
    benefitsTitle: '',
    benefitsText: '',
    onClickRegister: () => false
};

export default RegistrationTeaser;
