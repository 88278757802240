import React from 'react';
import PropTypes from 'prop-types';
import CopyTextM from '../elements/CopyTextM';
import './event-description.scss';

class EventDescription extends React.Component {
  render() {
    return (
      <div className="bt-event-description">
        <CopyTextM text={this.props.copyText} />
      </div>
    );
  }
}

EventDescription.propTypes = {
  copyText: PropTypes.string,
};

EventDescription.defaultProps = {
  copyText: '',
};

export default EventDescription;
