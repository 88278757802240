/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import CopyTextM from '../elements/CopyTextM';
import Button from '../elements/Button';
import './event-register.scss';
import HeadlineThree from '../elements/HeadlineThree';
import EventsUtil from '../../utils/EventsUtil';

const FALLBACK_TEXT_REGISTERED = '<p>You have succcessfully registered</p><p>Please note:</p>';
const FALLBACK_TEXT_NOT_REGISTERED = '<p>You can register here</p>';

class EventRegister extends React.Component {
  async handleRegister() {
    const { eventId, onRegister } = this.props;
    await EventsUtil.attendEvent(eventId, false);
    onRegister();
  }

  render() {
    const { copyText, isRegistered, links, onClickLink } = this.props;

    // DefaultTexts
    let text;
    if (copyText) {
      text = copyText;
    } else {
      if (isRegistered) {
        text = FALLBACK_TEXT_REGISTERED;
      } else {
        text = FALLBACK_TEXT_NOT_REGISTERED;
      }
    }
    return (
      <div className="bt-event-register">
        {isRegistered && (
          <div className="bt-event-register__title">
            <HeadlineThree text="You are registered" />
          </div>
        )}
        <CopyTextM text={text} />
        {isRegistered && !!links && links.length > 0 && (
          <div className="bt-event-register__actions">
            {links.map((link, index) => (
              <Button
                key={`action_${index}`}
                layout="blue"
                path={link.url}
                label={link.label}
                link
                onClick={onClickLink}
              />
            ))}
          </div>
        )}
        {!isRegistered && (
          <div className="bt-event-register__button">
            <Button layout="primary" onClick={() => this.handleRegister()} label="Register" />
          </div>
        )}
      </div>
    );
  }
}

EventRegister.propTypes = {
  copyText: PropTypes.string,
  eventId: PropTypes.string,
  links: PropTypes.array,
  isRegistered: PropTypes.bool,
  onRegister: PropTypes.func,
  onClickLink: PropTypes.func,
};

EventRegister.defaultProps = {
  copyText: null,
  eventId: null,
  links: [],
  isRegistered: false,
  onRegister: () => {},
  onClickLink: () => {},
};

export default EventRegister;
