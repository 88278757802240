import React from 'react';
import { useEvent } from '../events/useEvent';
import EventHeader from '../EventHeader';
import { apiUrl } from '../../app/api';

interface EventHeaderWrapperProps {
  eventData: EventData | undefined;
}

const EventHeaderWrapper: React.FC<EventHeaderWrapperProps> = ({ eventData }) => {
  const {
    eventCategoryFormatted,
    eventLocationFormatted,
    eventDateTimeFormatted,
    eventCoverPictureMediumLarge,
    eventIsDigital,
    eventAgendaFileUrl,
  } = useEvent(eventData);

  if (!eventData) {
    return null;
  }

  const handleOnClickCalender = () => {
    window.location.href = `${apiUrl}/events/${eventData.id}/ics`;
  };

  return (
    <EventHeader
      title={eventData.title}
      date={eventDateTimeFormatted}
      location={eventLocationFormatted}
      category={eventCategoryFormatted}
      isDigital={eventIsDigital}
      imgSrc={eventCoverPictureMediumLarge}
      showCalendarDownload={eventData.status !== 'past'}
      onClickCalendar={handleOnClickCalender}
      agenda={eventAgendaFileUrl}
    />
  );
};

export default EventHeaderWrapper;
