import { UiDiv } from '@webfox-sc/core';
import React from 'react';
import { useBaseData } from '../../hooks/useBaseData';

const EventsStaticTimeline = () => {
  const { timeline } = useBaseData();

  const imageSrcDesktop = timeline?.desktop?.url || '';
  const imageSrcTablet = timeline?.tablet?.url || '';
  const imageSrcMobile = timeline?.mobile?.url || '';

  if (!imageSrcDesktop && !imageSrcTablet && !imageSrcMobile) {
    return null;
  }

  return (
    <UiDiv background="btLightBlueGrey">
      <UiDiv
        boxSizing="border-box"
        maxWidth="1140px"
        padding={{
          mobile: '50px 22px 50px 22px',
          tablet: '70px 30px 60px 30px',
          desktop: '70px 30px 40px 30px',
        }}
      >
        <picture>
          {imageSrcDesktop && <source srcSet={imageSrcDesktop} media="(min-width: 1440px)" />}
          {imageSrcTablet && <source srcSet={imageSrcTablet} media="(min-width: 768px)" />}
          <img src={imageSrcMobile} alt="timeline" style={{ maxWidth: '100%', height: 'auto' }} />
        </picture>
      </UiDiv>
    </UiDiv>
  );
};

EventsStaticTimeline.propTypes = {};
EventsStaticTimeline.defaultProps = {};

export default EventsStaticTimeline;
