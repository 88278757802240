import React from 'react';
import PropTypes from 'prop-types';
import { Block } from '@webfox-sc/core';
import { useResponsive } from '../../hooks/responsive';

const ContentSection = ({ paddingTop, paddingBottom, children }) => {
  const { isMobile } = useResponsive();

  return (
    <Block paddingTop={paddingTop} paddingBottom={paddingBottom} paddingLeft={isMobile ? '22px' : '0'}>
      {children}
    </Block>
  );
};

ContentSection.propTypes = {
  paddingTop: PropTypes.string,
  paddingBottom: PropTypes.string,
  children: PropTypes.node,
};

ContentSection.defaultProps = {
  paddingTop: undefined,
  paddingBottom: undefined,
  children: undefined,
};

export default ContentSection;
