/* eslint-disable */
import { REDUX_ACTIONS } from '../global/constants';

function downloadsReducer(
    state = {
        downloads: [],
        downloadModels: [],
        activeScrollAnkerId: ''
    },
    action
) {
    switch (action.type) {
        case REDUX_ACTIONS.UPDATE_DOWNLOADS:
            return Object.assign({}, state, {
                downloads: action.downloads,
                downloadModels: action.downloadModels
            });

        case REDUX_ACTIONS.UPDATE_SCROLL_ANKER_EVENT_ID:
            return Object.assign({}, state, {
                activeScrollAnkerId: action.eventId
            });

        default:
            return state;
    }
}

export default downloadsReducer;
