/* eslint-disable */
import React from 'react';

const IconMetaWebsiteBiotronik20 = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
    >
        <g fill="currentColor" fillRule="evenodd">
            <path d="M5.69,5.52A.85.85,0,0,1,6.34,7a1,1,0,0,1-.67.25H4V5.52Zm9.49-.14a1.74,1.74,0,0,1,1.55.8,2.89,2.89,0,0,1,.41,1.7c-.07,1.13-.65,2.38-2,2.39a1.68,1.68,0,0,1-1.34-.81,3.22,3.22,0,0,1-.46-1.59,3,3,0,0,1,.35-1.65A1.67,1.67,0,0,1,15.18,5.38Zm-9.53,3a1.11,1.11,0,0,1,1.15,1c0,.73-.52,1.06-1.17,1.07H4V8.33Zm.47-3.92H2.52v7h3.4c1.44,0,2.49-.72,2.49-2A1.84,1.84,0,0,0,7,7.68,1.7,1.7,0,0,0,8.11,6.14C8.11,5,7.25,4.41,6.12,4.41Zm9.13-.25a3.31,3.31,0,0,0-2.52,1.08,3.84,3.84,0,0,0-1,2.63,3.63,3.63,0,0,0,1,2.54,3.51,3.51,0,0,0,2.47,1.12,3.62,3.62,0,0,0,3.57-3.66,3.66,3.66,0,0,0-1-2.59A3.54,3.54,0,0,0,15.25,4.16ZM10.66,0a9.59,9.59,0,0,1,4.87,1.54A10,10,0,0,1,20,10a9.77,9.77,0,0,1-.4,2.68H.38l0-.08A9.17,9.17,0,0,1,0,10,10,10,0,0,1,4.49,1.57,9.69,9.69,0,0,1,9.33,0V11.39h1.36Zm8.52,13.9a9.28,9.28,0,0,1-3.3,4.31A9.67,9.67,0,0,1,10.66,20V13.9Zm-9.82,0V20a9.88,9.88,0,0,1-5.25-1.79A9.27,9.27,0,0,1,.75,13.9Z" />
        </g>
    </svg>
);

export default IconMetaWebsiteBiotronik20;
