import { screens, mediaQueries } from './screensAndMediaQueries';
import { colors } from './colors';
import { textVariants } from './textVariants';

export const spacings = {
  xs: '15px',
  s: '30px',
  m: '50px',
  l: '70px',
  xl: '100px',
  xxl: '120px',
};

export const appTheme = {
  screens,
  mediaQueries,
  spacings,
  colors,
  textVariants,
  page: {
    contentMaxWidth: '1400px',
  },
};

export type AppTheme = typeof appTheme;
