/* eslint-disable */
import React from 'react';

const IconVideo17 = (
    <svg width="17" height="17" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="evenodd">
            <path d="M6.79361846 8.12004597c.04561231.0267777.09792.03995403.15022769.03995403.05900308 0 .1175877-.01657667.16822154-.04973002l3.45230771-2.23147516c.0899692-.05823087.1456246-.15939108.1456246-.26905214 0-.10966107-.0556554-.21082128-.1456246-.26905215L7.11206769 3.10921537c-.09624615-.06120617-.21885538-.06630669-.32012307-.00977598C6.69193231 3.15426992 6.63 3.26223081 6.63 3.37826752v4.46295031c0 .11603671.06193231.22399761.16361846.27882814zm.46407385-4.16074482l2.55345231 1.65044153-2.55345231 1.65044153V3.95930115zM14.8165625 13.6425h-7.5225v-.595c0-.16422-.14042-.2975-.3134375-.2975s-.3134375.13328-.3134375.2975v.595h-1.25375C5.24042 13.6425 5.1 13.77578 5.1 13.94c0 .16422.14042.2975.3134375.2975h1.25375v.595c0 .16422.14042.2975.3134375.2975s.3134375-.13328.3134375-.2975v-.595h7.5225c.1730175 0 .3134375-.13328.3134375-.2975 0-.16422-.14042-.2975-.3134375-.2975zM2.21 12.75c-.18768 0-.34.13328-.34.2975v1.785c0 .16422.15232.2975.34.2975s.34-.13328.34-.2975v-1.785c0-.16422-.15232-.2975-.34-.2975zM3.4 12.75c-.18768 0-.34.13328-.34.2975v1.785c0 .16422.15232.2975.34.2975s.34-.13328.34-.2975v-1.785c0-.16422-.15232-.2975-.34-.2975z" />
            <path d="M0 17h17V0H0v17zm.62962963-6.1818182H16.3703704V.61818182H.62962963V10.8181818zm0 5.5636364H16.3703704v-4.9454546H.62962963v4.9454546z" />
        </g>
    </svg>
);

export default IconVideo17;
