/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import './fellow-teaser-navigation.scss';
import Button from '../elements/Button';
import IconInfo20 from '../../assets/icons/IconInfo20';

const FellowTeaserNavigation = ({
  activeMenu,
  profilePath,
  onClickNavigationItem,
  onClickMore,
  email,
  employers,
  statusHidden,
  statusMessage,
  contact,
  emailArray,
  linkedinArray,
  twitterArray,
  phoneArray,
  otherArray,
}) => {
  return (
    <div className="bt-fellow-teaser-navigation">
      <div className="bt-fellow-teaser-navigation__item-list">
        {employers && (
          <div
            className={`bt-fellow-teaser-navigation__navigation-item  ${activeMenu === 'employer' && 'active'}`}
            onClick={() => onClickNavigationItem('employer')}
          >
            <span className="bt-fellow-teaser-navigation__navigation-item-label">{'Employer'}</span>
            <div className="bt-fellow-teaser-navigation__ind" />
          </div>
        )}
        {(emailArray.length > 0 ||
          linkedinArray.length > 0 ||
          twitterArray.length > 0 ||
          phoneArray.length > 0 ||
          otherArray.length > 0) && (
          <div
            className={`bt-fellow-teaser-navigation__navigation-item  ${activeMenu === 'contact' && 'active'}`}
            onClick={() => onClickNavigationItem('contact')}
          >
            <span className="bt-fellow-teaser-navigation__navigation-item-label">{'Contact'}</span>
            <div className="bt-fellow-teaser-navigation__ind" />
          </div>
        )}
        {!statusHidden && statusMessage && (
          <div
            className={`bt-fellow-teaser-navigation__navigation-item  ${activeMenu === 'status' && 'active'}`}
            onClick={() => onClickNavigationItem('status')}
          >
            <div className="bt-fellow-teaser__new-tag">NEW</div>
            <span className="bt-fellow-teaser-navigation__navigation-item-label">{'Status'}</span>
            <div className="bt-fellow-teaser-navigation__ind" />
          </div>
        )}
      </div>

      <div className="bt-fellow-teaser-navigation__more-button">
        <div className="bt-fellow-teaser-navigation__more-button-text">
          <Button
            layout="secondary-side-small"
            label="More"
            onClick={(e) => {
              e.preventDefault();
              onClickMore(e, profilePath);
            }}
            path={profilePath}
          />
        </div>

        <div className="bt-fellow-teaser-navigation__more-button-info">
          <Button
            layout="primary-side-small"
            onClick={(e) => {
              e.preventDefault();
              onClickMore(e, profilePath);
            }}
            path={profilePath}
            iconInfo={IconInfo20}
          />
        </div>
      </div>
    </div>
  );
};
FellowTeaserNavigation.propTypes = {
  activeMenu: PropTypes.string,
  profilePath: PropTypes.string,
  onClickNavigationItem: PropTypes.func,
  onClickMore: PropTypes.func,
  email: PropTypes.string,
  employers: PropTypes.array,
  statusHidden: PropTypes.bool,
  statusMessage: PropTypes.string,
  contact: PropTypes.object,
  emailArray: PropTypes.array,
  linkedinArray: PropTypes.array,
  twitterArray: PropTypes.array,
  phoneArray: PropTypes.array,
  otherArray: PropTypes.array,
};

FellowTeaserNavigation.defaultProps = {
  profilePath: '/fellows/',
  email: '',
  employers: [],
  emailArray: [],
  linkedinArray: [],
  twitterArray: [],
  phoneArray: [],
  otherArray: [],
  statusHidden: false,
  statusMessage: '',
  onClickNavigationItem: () => {},
  onClickMore: () => {},
};

export default FellowTeaserNavigation;
