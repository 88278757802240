import axios from 'axios';
import storeModel from '../models/storeModel';
import { handleErrorResponse } from '../redux-actions/actionsApp';
import {
  updateProfileData,
  updateProfileImageData,
  deleteProfileCv,
  addProfileLinkData,
  deleteProfileLink,
} from '../redux-actions/actionsProfile';
import { apiUrl } from '../app/api';

class ProfileUtil {
  static async loadProfile(token = '') {
    try {
      const { authState } = storeModel.state;
      const userToken = token || authState.userToken;

      const response = await axios({
        method: 'get',
        url: `${apiUrl}/profile`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      storeModel.dispatch(updateProfileData(response.data.data));
    } catch (error) {
      storeModel.dispatch(handleErrorResponse(error.response));
      throw error;
    }
  }

  static async updateProfileData(profileData) {
    const {
      title,
      firstName,
      lastName,
      profession,
      degree,
      city,
      postalCode,
      country,
      statusMessage,
      statusHideOnNewsFeed,
      employers,
      contact,
      email,
      password,
      passwordRepeat,
    } = profileData;

    const requestData = {
      title,
      firstName,
      lastName,
      profession,
      degree,
      city,
      postalCode,
      country,
      status: {
        message: statusMessage,
        hideOnNewsFeed: statusHideOnNewsFeed,
      },
      employers,
      contact: contact.filter((contactItem) => contactItem.contact !== ''),
      email,
    };

    if (passwordRepeat) {
      requestData.password = password;
      requestData.passwordRepeat = passwordRepeat;
    }

    try {
      const { authState } = storeModel.state;
      const { userToken } = authState;

      await axios({
        method: 'put',
        url: `${apiUrl}/profile`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        data: requestData,
      });

      storeModel.dispatch(updateProfileData(requestData));
    } catch (error) {
      storeModel.dispatch(handleErrorResponse(error.response));
      throw error;
    }
  }

  static async uploadProfileImage(imgBlob, filename) {
    const formData = new FormData();
    formData.append('image', imgBlob, filename);

    try {
      const { authState } = storeModel.state;
      const { userToken } = authState;

      const response = await axios({
        method: 'post',
        url: `${apiUrl}/profile/picture`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        data: formData,
      });

      storeModel.dispatch(updateProfileImageData(response.data.data));
    } catch (error) {
      storeModel.dispatch(handleErrorResponse(error.response));
      throw error;
    }
  }

  static async deleteProfileCv() {
    try {
      const { authState } = storeModel.state;
      const { userToken } = authState;

      await axios({
        method: 'delete',
        url: `${apiUrl}/profile/cv`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      storeModel.dispatch(deleteProfileCv());
    } catch (error) {
      storeModel.dispatch(handleErrorResponse(error.response));
      throw error;
    }
  }

  static async addProfileLink(linkUrl) {
    try {
      const { authState } = storeModel.state;
      const { userToken } = authState;

      const response = await axios({
        method: 'post',
        url: `${apiUrl}/profile/link`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
        data: {
          url: linkUrl,
        },
      });

      storeModel.dispatch(addProfileLinkData(response.data.data));
    } catch (error) {
      storeModel.dispatch(handleErrorResponse(error.response));
      throw error;
    }
  }

  static async deleteProfileLink(linkId) {
    try {
      const { authState } = storeModel.state;
      const { userToken } = authState;

      await axios({
        method: 'delete',
        url: `${apiUrl}/profile/link/${linkId}`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      storeModel.dispatch(deleteProfileLink(linkId));
    } catch (error) {
      storeModel.dispatch(handleErrorResponse(error.response));
      throw error;
    }
  }

  static async deleteProfile() {
    try {
      const { authState } = storeModel.state;
      const { userToken } = authState;

      await axios({
        method: 'delete',
        url: `${apiUrl}/profile`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
    } catch (error) {
      storeModel.dispatch(handleErrorResponse(error.response));
      throw error;
    }
  }
}

export default ProfileUtil;
