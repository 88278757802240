import React from 'react';

const IconVideoClose = (
  <svg width="30px" height="30px" viewBox="0 0 30 30" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <title>Close</title>
    <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="Close">
        <circle id="Oval" fill="#FFFFFF" cx="15" cy="15" r="15" />
        <path
          d="M14.9983,0 C6.72775,0 0,6.72775 0,14.9983 C0,23.2696 6.72775,30 14.9983,30 C23.2696,30 30,23.2696 30,14.9983 C30,6.72775 23.2696,0 14.9983,0 Z M14.9983,28.9273 C7.31903,28.9273 1.07274,22.6783 1.07274,14.9983 C1.07274,7.31903 7.31903,1.07274 14.9983,1.07274 C22.681,1.07274 28.9273,7.31903 28.9273,14.9983 C28.9273,22.6783 22.681,28.9273 14.9983,28.9273 Z M15,15.8485 L19.8622,20.7107 C20.1056,20.9541 20.494,20.9611 20.7289,20.7262 C20.9632,20.4919 20.9541,20.1056 20.7107,19.8622 L15.8485,15 L20.7107,10.1378 C20.9536,9.89495 20.9611,9.50595 20.7262,9.27111 C20.4919,9.03679 20.105,9.04642 19.8622,9.28928 L15,14.1515 L10.1378,9.28928 C9.89495,9.04642 9.50808,9.03679 9.27376,9.27111 C9.03892,9.50595 9.04642,9.89495 9.28928,10.1378 L14.1515,15 L9.28928,19.8622 C9.04587,20.1056 9.03679,20.4919 9.27111,20.7262 C9.50595,20.9611 9.8944,20.9541 10.1378,20.7107 L15,15.8485 Z"
          id="Shape"
          fill="#2183CA"
        />
      </g>
    </g>
  </svg>
);

export default IconVideoClose;
