/* eslint-disable */
import React from 'react';

const IconUser28 = (
    <svg width="28" height="28" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="evenodd">
            <path d="M7.863 6.877c0 1.637.643 3.527 1.722 5.056 1.221 1.733 2.765 2.687 4.345 2.687 1.58 0 3.124-.954 4.346-2.687 1.078-1.529 1.722-3.419 1.722-5.056 0-3.329-2.722-6.037-6.068-6.037-3.345 0-6.067 2.708-6.067 6.037zm6.067-5.053c2.8 0 5.079 2.267 5.079 5.053 0 1.421-.592 3.142-1.543 4.49-1.016 1.442-2.305 2.268-3.536 2.268-1.23 0-2.519-.826-3.536-2.267-.95-1.35-1.542-3.07-1.542-4.491 0-2.786 2.278-5.053 5.078-5.053z" />
            <path d="M22.822 17.32a10.579 10.579 0 0 0-4.353-3.811.497.497 0 0 0-.562.096c-1.192 1.182-2.567 1.808-3.977 1.808-1.41 0-2.785-.626-3.976-1.808a.497.497 0 0 0-.562-.096 10.579 10.579 0 0 0-4.353 3.81 10.44 10.44 0 0 0-1.679 5.69v.008c0 .139.06.271.162.364a15.507 15.507 0 0 0 10.408 3.994c3.857 0 7.553-1.418 10.408-3.994a.491.491 0 0 0 .162-.364v-.009c0-2.026-.58-3.993-1.678-5.689zM13.93 26.39c-3.537 0-6.93-1.274-9.578-3.593a9.453 9.453 0 0 1 1.519-4.945 9.588 9.588 0 0 1 3.642-3.305c1.323 1.211 2.843 1.849 4.417 1.849 1.574 0 3.095-.638 4.417-1.849a9.585 9.585 0 0 1 3.643 3.305 9.458 9.458 0 0 1 1.519 4.945 14.518 14.518 0 0 1-9.58 3.593z" />
        </g>
    </svg>
);

export default IconUser28;
