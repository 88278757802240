import React, { Component } from 'react';
import PropTypes from 'prop-types';
import MetaNavigationLink from '../../atoms/MetaNavigationLink';
import IconMetaAppBiotronik14 from '../../assets/icons/IconMetaAppBiotronik14';
import IconMetaLinkedIn14 from '../../assets/icons/IconMetaLinkedIn14';
import IconMetaTwitter14 from '../../assets/icons/IconMetaTwitter14';
import IconMetaWebsiteBiotronik14 from '../../assets/icons/IconMetaWebsiteBiotronik14';
import './meta-navigation.scss';

class MetaNavigation extends Component {
  render() {
    const { isUserLoggedIn, navigationModels, currentPageId, links, onClickItem } = this.props;

    return (
      <div className="bt-meta-navigation">
        <div>
          {navigationModels.map((navigationModel) => {
            if (isUserLoggedIn && navigationModel.pageId === 'contacts') {
              return null;
            }

            return (
              <div key={navigationModel.pageId} className="bt-meta-navigation__item">
                <MetaNavigationLink
                  label={navigationModel.label}
                  href={navigationModel.path}
                  path={navigationModel.path}
                  isActive={navigationModel.pageId === currentPageId}
                  onClick={(path) => onClickItem(path)}
                />
              </div>
            );
          })}
        </div>
        <div className="bt-meta-navigation__links">
          {Object.keys(links).map((key, i) => {
            const link = links[key];

            let icon = null;
            switch (key) {
              case 'biotronikHome':
                icon = IconMetaWebsiteBiotronik14;
                break;

              case 'biotronikApp':
                icon = IconMetaAppBiotronik14;
                break;

              case 'twitter':
                icon = IconMetaTwitter14;
                break;

              case 'linkedin':
                icon = IconMetaLinkedIn14;
                break;

              default:
                icon = IconMetaWebsiteBiotronik14;
                break;
            }

            return (
              link.title && (
                <div key={String(i)} className="bt-meta-navigation__item">
                  <MetaNavigationLink
                    isExternalLink
                    label={link.title}
                    icon={icon}
                    href={link.url}
                    target={link.target}
                  />
                </div>
              )
            );
          })}
        </div>
      </div>
    );
  }
}

MetaNavigation.propTypes = {
  isUserLoggedIn: PropTypes.bool,
  navigationModels: PropTypes.array,
  currentPageId: PropTypes.string,
  links: PropTypes.object,
  onClickItem: PropTypes.func,
};

MetaNavigation.defaultProps = {
  isUserLoggedIn: false,
  navigationModels: [],
  currentPageId: '',
  links: {},
  onClickItem: () => false,
};

export default MetaNavigation;
