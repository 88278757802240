/* eslint-disable */
import React from 'react';

const IconArrowDown12 = (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
        <path fill="currentColor" d="M6 7.8L.8 2.6l-.8.8 6 6 6-6-.8-.8z" />
    </svg>
);

export default IconArrowDown12;
