/* eslint-disable */
import { REDUX_ACTIONS } from '../global/constants';

const initialState = {
  profileTitle: '',
  profileFirstName: '',
  profileLastName: '',
  profileProfession: '',
  profileDegree: '',
  profilePictureSrc: '',
  profileCity: '',
  profilePostalCode: '',
  profileCountry: '',
  profileStatusMessage: '',
  profileStatusHideOnNewsFeed: false,
  profileEmployers: [],
  profileContact: [],
  profileCv: {},
  isCVFileUploading: false,
  isCVFileUploadError: false,
  profileLinks: [],
};

function profileReducer(state = { ...initialState }, action) {
  switch (action.type) {
    case REDUX_ACTIONS.UPDATE_PROFILE_DATA:
      const {
        title,
        firstName,
        lastName,
        profession,
        degree,
        profilePicture,
        city,
        postalCode,
        country,
        status,
        employers,
        contact,
        cv,
        links,
      } = action.profileData;

      const profilePictureValid = profilePicture || {};
      const profilePictureSizes = profilePictureValid.sizes || {};
      const profileStatus = status || {};

      return Object.assign({}, state, {
        profileTitle: title || state.profileTitle,
        profileFirstName: firstName || state.profileFirstName,
        profileLastName: lastName || state.profileLastName,
        profileProfession: profession || state.profileProfession,
        profileDegree: degree || state.profileDegree,
        profilePictureSrc: profilePictureSizes.medium || state.profilePictureSrc,
        profileCity: city || state.profileCity,
        profilePostalCode: postalCode || state.profilePostalCode,
        profileCountry: country || state.profileCountry,
        profileStatusMessage: profileStatus.message || state.profileStatusMessage,
        profileStatusHideOnNewsFeed: profileStatus.hideOnNewsFeed,
        profileEmployers: employers || state.profileEmployers,
        profileContact: contact || state.profileContact,
        profileCv: cv || state.profileCv,
        profileLinks: links || state.profileLinks,
      });

    case REDUX_ACTIONS.UPDATE_PROFILE_IMAGE_DATA:
      const imageDataSizes = action.imageData.sizes || {};
      return Object.assign({}, state, {
        profilePictureSrc: imageDataSizes.medium || state.profilePictureSrc,
      });

    case REDUX_ACTIONS.RECIEVE_DELETE_PROFILE_IMAGE:
      return Object.assign({}, state, {
        profilePictureSrc: '',
      });

    case REDUX_ACTIONS.REQUEST_UPLOAD_CV_FILE:
      return Object.assign({}, state, {
        isCVFileUploading: true,
        isCVFileUploadError: false,
      });

    case REDUX_ACTIONS.REQUEST_UPLOAD_CV_FILE_ERROR:
      return Object.assign({}, state, {
        isCVFileUploading: false,
        isCVFileUploadError: true,
      });

    case REDUX_ACTIONS.RECIEVE_UPLOAD_CV_FILE:
      return Object.assign({}, state, {
        profileCv: action.profileCv,
        isCVFileUploading: false,
        isCVFileUploadError: false,
      });

    case REDUX_ACTIONS.DELETE_PROFILE_CV:
      return Object.assign({}, state, {
        profileCv: {},
      });

    case REDUX_ACTIONS.ADD_PROFILE_LINK_DATA:
      const profileLinksNew = [...state.profileLinks];
      profileLinksNew.push(action.linkData);
      return Object.assign({}, state, {
        profileLinks: profileLinksNew,
      });

    case REDUX_ACTIONS.DELETE_PROFILE_LINK:
      return Object.assign({}, state, {
        profileLinks: state.profileLinks.filter((link) => link.id !== action.linkId),
      });

    case REDUX_ACTIONS.LOG_OUT:
      return Object.assign({}, state, { ...initialState });

    default:
      return state;
  }
}

export default profileReducer;
