/* eslint-disable */
import React from 'react';

const IconArrowUp20 = (
    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
        <path fill="currentColor" fillRule="nonzero" d="M19.6346372 15.2L10 5.5331674.3653628 15.2 0 14.8334163 10 4.8l10 10.0334163z" />
    </svg>
);

export default IconArrowUp20;
