/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import InlineErrorMessage from '../../atoms/InlineErrorMessage';
import Button from '../elements/Button';
import HeadlineThree from '../elements/HeadlineThree';
import Input from '../elements/Input';
import IconCV40 from '../../assets/icons/IconCV40';
import IconProjects40 from '../../assets/icons/IconProjects40';
import IconCV28 from '../../assets/icons/IconCV28';
import IconProjects28 from '../../assets/icons/IconProjects28';
import IconDownloads20 from '../../assets/icons/IconDownloads20';
import IconDelete20 from '../../assets/icons/IconDelete20';
import IconLink20 from '../../assets/icons/IconLink20';
import './profile-links.scss';

class ProfileLinks extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showLinkInput: false,
            linkUrl: ''
        };
    }

    handleOnClickCv(url) {
        window.open(url);
    }

    handleChangeInputCv(e) {
        if (!e.target.files[0]) {
            return false;
        }
        const { onSelectCvFile } = this.props;
        onSelectCvFile(e.target.files[0]);
    }

    handleOnClickUploadCv() {
        if (this.elInputCv) {
            this.elInputCv.click();
        }
    }

    handleOnClickNewLink() {
        this.setState({
            showLinkInput: true
        });
    }

    handleChangeInputValue(key, value) {
        const stateUpdates = {};
        stateUpdates[key] = value;
        this.setState(stateUpdates);
    }

    handleOnCancelNewLink() {
        this.setState({
            showLinkInput: false,
            linkUrl: ''
        });
    }

    handleOnSaveNewLink() {
        const { linkUrl } = this.state;
        if (linkUrl) {
            const { onAddLink } = this.props;
            onAddLink(linkUrl);
        }
        this.setState({
            showLinkInput: false,
            linkUrl: ''
        });
    }

    render() {
        const { showLinkInput, linkUrl } = this.state;
        const { cv, isCVFileUploadError, links, onDeleteCvFile, onDeleteLink } = this.props;
        const isCv = Boolean(typeof cv === 'object' && Object.keys(cv).length > 0);

        let cvButtonLabel = '';
        let cvUploadLabel = 'Upload a PDF file';
        if (isCv) {
            const typeForLabel = cv.mimeType === 'application/pdf' ? 'PDF, ' : '';
            const cvSize = cv.size || 0;
            let sizeForLabel = '';
            if (cvSize < 1000) {
                sizeForLabel = String(cvSize) + ' KB';
            } else {
                sizeForLabel = String(Math.round((parseInt(cvSize, 10) / 1024) * 10) / 10).replace('.', ',') + ' MB';
            }
            cvButtonLabel = `${cv.title}  (${typeForLabel}${sizeForLabel})`;
            cvUploadLabel = 'Upload a PDF file';
        }

        let addLinkLabel = 'Add a link';
        if (links.length) {
            addLinkLabel = 'Add another link';
        }

        return (
            <div className="bt-profile-links">
                <div className="bt-profile-links__content">
                    <div className="bt-profile-links__section">
                        <div className="bt-profile-links__section-top">
                            <div className="bt-profile-links__section-icon">{IconCV40}</div>
                            <div className="bt-profile-links__section-icon--mobile">{IconCV28}</div>
                            <div className="bt-profile-links__section-title">
                                <HeadlineThree text="Curriculum vitae" />
                            </div>
                        </div>
                        <div className="bt-profile-links__section-content">
                            {isCv && (
                                <div className="bt-profile-links__section-content-item bt-profile-links__section-content-item--cv">
                                    <div>
                                        <Button
                                            path={cv.url}
                                            label={cvButtonLabel}
                                            iconLeft={IconDownloads20}
                                            onClick={() => window.open(cv.url)}
                                        />
                                    </div>
                                    <div>
                                        <Button path="#" iconLeft={IconDelete20} onClick={onDeleteCvFile} />
                                    </div>
                                </div>
                            )}
                            <Button
                                layout="secondary"
                                path="#"
                                label={cvUploadLabel}
                                onClick={() => this.handleOnClickUploadCv()}
                            />
                            <input
                                type="file"
                                className="bt-profile-links__cv-input-upload"
                                onChange={(e) => this.handleChangeInputCv(e)}
                                ref={(node) => {
                                    this.elInputCv = node;
                                }}
                            />
                            {isCVFileUploadError && (
                                <div className="bt-profile-links__inline-error">
                                    <InlineErrorMessage text="You can only upload a PDF file." />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className="bt-profile-links__section">
                        <div className="bt-profile-links__section-top">
                            <div className="bt-profile-links__section-icon">{IconProjects40}</div>
                            <div className="bt-profile-links__section-icon--mobile">{IconProjects28}</div>
                            <div className="bt-profile-links__section-title">
                                <HeadlineThree text="Research & Projects" />
                            </div>
                        </div>
                        <div className="bt-profile-links__section-content">
                            {links.length > 0 && (
                                <div className="bt-profile-links__section-content-links">
                                    {links.map((link, i) => {
                                        return (
                                            <div key={i} className="bt-profile-links__section-content-item">
                                                <div>
                                                    <Button
                                                        path={link.url}
                                                        label={link.url}
                                                        iconLeft={IconLink20}
                                                        onClick={() => window.open(link.url)}
                                                    />
                                                </div>
                                                <div>
                                                    <Button
                                                        path="#"
                                                        iconLeft={IconDelete20}
                                                        onClick={() => onDeleteLink(link.id)}
                                                    />
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                            {!showLinkInput && (
                                <Button
                                    layout="secondary"
                                    path="#"
                                    label={addLinkLabel}
                                    onClick={() => this.handleOnClickNewLink()}
                                />
                            )}
                            {showLinkInput && (
                                <div>
                                    <div className="bt-profile-links__link-input">
                                        <Input
                                            label="URL"
                                            value={linkUrl}
                                            onChange={(value) => this.handleChangeInputValue('linkUrl', value)}
                                        />
                                    </div>
                                    <div className="bt-profile-links__buttons">
                                        <div className="bt-profile-links__button">
                                            <Button
                                                layout="secondary"
                                                path="#"
                                                label="Cancel"
                                                onClick={() => this.handleOnCancelNewLink()}
                                            />
                                        </div>
                                        <div className="bt-profile-links__button">
                                            <Button
                                                layout="primary"
                                                path="#"
                                                label="Save"
                                                onClick={() => this.handleOnSaveNewLink()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ProfileLinks.propTypes = {
    cv: PropTypes.object,
    isCVFileUploadError: PropTypes.bool,
    links: PropTypes.array,
    onSelectCvFile: PropTypes.func,
    onDeleteCvFile: PropTypes.func,
    onAddLink: PropTypes.func,
    onDeleteLink: PropTypes.func
};

ProfileLinks.defaultProps = {
    cv: {},
    isCVFileUploadError: false,
    links: [],
    onSelectCvFile: () => false,
    onAddLink: () => false,
    onDeleteLink: () => false
};

export default ProfileLinks;
