/* eslint-disable */
import AppUtil from '../utils/AppUtil';
import FormatUtil from '../utils/FormatUtil';

class DownloadModel {
    constructor(data) {
        this._data = data || {};

        /**
         * data values (db)
         */

        this._id = this._data.id || '';
        this._category = this._data.category || '';
        this._city = this._data.city || '';
        this._country = this._data.country || '';
        this._startDate = this._data.startDate || {};
        this._endDate = this._data.endDate || {};
        this._subTitle = this._data.subTitle || '';
        this._title = this._data.title || '';
        this._coverPicture = this._data.coverPicture || '';
        this._assets = this._data.assets || {};
        this._files = this._data.files || [];
        this._registration = this._data.registration || {};

        /**
         * custom values
         */
        this._agenda = this._assets.agenda || [];
        this._presentations = this._assets.presentations || [];
        this._videos = this._assets.videos || [];
        this._pictures = this._assets.pictures || [];
        this._isDigital = this._registration.isEnabled || false;

        this._speakers = [];
        if (this._agenda.length >= 1) {
            for (let i = 0; i < this._agenda.length; i++) {
                this._agenda[i].speaker && this._speakers.push(this._agenda[i].speaker);
            }
        }
        if (this._presentations.length >= 1) {
            for (let i = 0; i < this._presentations.length; i++) {
                this._presentations[i].speaker && this._speakers.push(this._presentations[i].speaker);
            }
        }
        if (this._videos.length >= 1) {
            for (let i = 0; i < this._videos.length; i++) {
                this._videos[i].speaker && this._speakers.push(this._videos[i].speaker);
            }
        }
        if (this._pictures.length >= 1) {
            for (let i = 0; i < this._pictures.length; i++) {
                this._pictures[i].speaker && this._speakers.push(this._pictures[i].speaker);
            }
        }

        this._locationCombined =
            this._city && this._country ? this._city + ', ' + this._country : this._city || this._country;

        this._dateSpan =
            this._startDate.date && this._endDate.date
                ? AppUtil.getDateSpanFormatted(this._startDate.date, this._endDate.date)
                : '';

        this._dateSpanSep =
            this._startDate.date && this._endDate.date
                ? AppUtil.getDateSpanFormattedSeparate(this._startDate.date, this._endDate.date)
                : '';

        this._dateSpanSolo = this._dateSpanSep && this._dateSpanSep.dateString;

        this._categoryCapitalised =
            this._category && (this._category !== 'Other' ? this._category.toUpperCase() : this._category);
        this._coverPictureMedium = this._coverPicture ? this._coverPicture.sizes.medium_large : '';

        /**
         * custom values - download video
         */
        this._videoUrls = [];
        this._videoCaptions = [];
        this._videoDescriptions = [];
        this._videoThumbnails = [];
        this._videoRelatedPictures = [];
        this._videoRelatedDocuments = [];
        this._videoDownloads = [];

        this._videos.forEach((item) => {
            this._videoUrls.push(item.url);
            this._videoCaptions.push(item.caption);
            this._videoDescriptions.push(item.description);
            this._videoThumbnails.push(item.thumbnails);
            this._videoRelatedPictures.push(item.pictures);
            this._videoRelatedDocuments.push(item.documents);
            this._videoDownloads.push(item.downloads);
        });
    }

    get data() {
        return this._data;
    }

    get id() {
        return this._id;
    }

    get category() {
        return this._category;
    }

    get agenda() {
        return this._agenda;
    }
    get presentations() {
        return this._presentations;
    }
    get videos() {
        return this._videos;
    }
    get pictures() {
        return this._pictures;
    }

    get city() {
        return this._city;
    }
    get country() {
        return this._country;
    }

    get startDate() {
        return this._startDate;
    }
    get endDate() {
        return this._endDate;
    }

    get subTitle() {
        return this._subTitle;
    }

    get title() {
        return this._title;
    }

    get dateSpan() {
        return this._dateSpan;
    }
    get dateSpanSolo() {
        return this._dateSpanSolo;
    }
    get locationCombined() {
        return this._locationCombined;
    }

    get categoryCapitalised() {
        return this._categoryCapitalised;
    }
    get coverPicture() {
        return this._coverPicture;
    }
    get coverPictureMedium() {
        return this._coverPictureMedium;
    }
    get files() {
        return this._files;
    }
    get speakers() {
        return this._speakers;
    }

    get videoUrls() {
        return this._videoUrls;
    }
    get videoCaptions() {
        return this._videoCaptions;
    }
    get videoDescriptions() {
        return this._videoDescriptions;
    }
    get videoThumbnails() {
        return this._videoThumbnails;
    }
    get videoRelatedPictures() {
        return this._videoRelatedPictures;
    }
    get videoRelatedDocuments() {
        return this._videoRelatedDocuments;
    }
    get videoDownloads() {
        return this._videoDownloads;
    }
    get isDigital() {
        return this._isDigital;
    }
}

export default DownloadModel;
