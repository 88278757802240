/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import HeadlineThree from '../elements/HeadlineThree';
import CopyTextM from '../elements/CopyTextM';
import { useResponsive } from '../../hooks/responsive';
import './newsfeed-news-teaser.scss';

const NewsfeedNewsTeaser = (props) => {
    const { title, intro, linkPath, linkText, onClickDetails, linkTarget } = props;
    const { isMobile } = useResponsive();

    return (
        <div className="bt-newsfeed-news-teaser">
            {isMobile ? (
                <a
                    target={linkTarget}
                    href={linkPath}
                    title={linkText}
                    onClick={onClickDetails}
                    className="bt-newsfeed-news-teaser__headlinelink"
                >
                    <HeadlineThree text={title} />
                </a>
            ) : (
                <a
                    target={linkTarget}
                    href={linkPath}
                    title={linkText}
                    onClick={onClickDetails}
                    className="bt-newsfeed-news-teaser__headlinelink"
                >
                    <HeadlineThree text={title} />
                    <p className="bt-newsfeed-news-teaser__intro">
                        <CopyTextM text={intro} />
                    </p>
                </a>
            )}

            <p className="bt-newsfeed-news-teaser__link">
                <a href={linkPath} title={linkText} onClick={onClickDetails} target={linkTarget}>
                    {linkText}
                </a>
            </p>
        </div>
    );
};

NewsfeedNewsTeaser.propTypes = {
    title: PropTypes.string,
    intro: PropTypes.string,
    linkPath: PropTypes.string,
    linkText: PropTypes.string,
    onClickDetails: PropTypes.func,
    onClickShowDocuments: PropTypes.func,
    linkTarget: PropTypes.string
};

NewsfeedNewsTeaser.defaultProps = {
    title: '',
    intro: '',
    linkPath: '',
    linkText: '',
    onClickDetails: () => false,
    onClickShowDocuments: () => false,
    linkTarget: '_self'
};

export default NewsfeedNewsTeaser;
