/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './advice-text.scss';

class AdviceText extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { text } = this.props;

        return (
            <span className="bt-advice-text">
                {text}
            </span>
        );
    }
}

AdviceText.propTypes = {
    text: PropTypes.string,
};

AdviceText.defaultProps = {
    text: '',
};

export default AdviceText;
