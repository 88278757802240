/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './headline-two.scss';

class HeadlineTwo extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { text } = this.props;

        return (
            <h2 className="bt-headline-two">
                {text}
            </h2>
        );
    }
}

HeadlineTwo.propTypes = {
    text: PropTypes.string,
};

HeadlineTwo.defaultProps = {
    text: '',
};

export default HeadlineTwo;
