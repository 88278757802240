/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './wrapper.scss';

class Wrapper extends Component {
    constructor(props) {
        super(props);
    }

    getClassNames() {
        const { marginTop8, marginTop20, marginTop30 } = this.props;
        const classNames = ['bt-wrapper'];

        if (marginTop8) {
            classNames.push('bt-wrapper--margin-top-8');
        }

        if (marginTop20) {
            classNames.push('bt-wrapper--margin-top-20');
        }

        if (marginTop30) {
            classNames.push('bt-wrapper--margin-top-30');
        }

        return classNames.join(' ');
    }

    render() {
        return <div className={this.getClassNames()}>{this.props.children}</div>;
    }
}

Wrapper.propTypes = {
    marginTop8: PropTypes.bool,
    marginTop20: PropTypes.bool,
    marginTop30: PropTypes.bool
};

Wrapper.defaultProps = {
    marginTop8: false,
    marginTop20: false,
    marginTop30: false
};

export default Wrapper;
