import React, { useState } from 'react';
import ProfileUtil from '../../utils/ProfileUtil';
import ProfileEdit from '../ProfileEdit';
import { useAppSelector } from '../../app/hooks';
import { useNavigate, useParams } from 'react-router-dom';

const LayerViewProfileEdit: React.FC = () => {
  const navigate = useNavigate();

  const { bgSegment1: pageId, sContentId: contentId } = useParams();
  const { userEmail } = useAppSelector(({ authState }) => authState);
  const {
    profileTitle,
    profileFirstName,
    profileLastName,
    profileProfession,
    profileDegree,
    profileCity,
    profilePostalCode,
    profileCountry,
    profileStatusMessage,
    profileStatusHideOnNewsFeed,
    profileEmployers,
    profileContact,
  } = useAppSelector(({ profileState }) => profileState);

  const [responseErrorObject, setResponseErrorObject] = useState<any>({});

  const redirectToProfileData = () => {
    navigate(`/${pageId}/${contentId}/profile/data`);
  };

  const handleOnClickCancel = () => {
    redirectToProfileData();
  };

  const handleUpdateProfileData = async (data) => {
    try {
      await ProfileUtil.updateProfileData(data);
      setResponseErrorObject({});
      redirectToProfileData();
    } catch (error: any) {
      if (error.response && error.response.data && error.response.data.error) {
        setResponseErrorObject(error.response.data.error);
      }
    }
  };

  return (
    <ProfileEdit
      title={profileTitle}
      firstName={profileFirstName}
      lastName={profileLastName}
      profession={profileProfession}
      degree={profileDegree}
      city={profileCity}
      postalCode={profilePostalCode}
      country={profileCountry}
      statusMessage={profileStatusMessage}
      statusHideOnNewsFeed={profileStatusHideOnNewsFeed}
      employers={profileEmployers}
      contact={profileContact}
      email={userEmail}
      responseErrorObject={responseErrorObject}
      onUpdate={(data) => handleUpdateProfileData(data)}
      onCancel={() => handleOnClickCancel()}
    />
  );
};

export default LayerViewProfileEdit;
