/* eslint-disable */
import axios from 'axios';
import { apiUrl } from '../app/api';
import FellowModel from '../models/FellowModel';
import storeModel from '../models/storeModel';
import { handleErrorResponse } from '../redux-actions/actionsApp';
import { updateFellowModels } from '../redux-actions/actionsFellows';

class FellowsUtil {
  static createFellowModels(fellowsData) {
    const fellowModels = [];
    fellowsData.forEach((data) => {
      fellowModels.push(new FellowModel(data));
    });
    storeModel.dispatch(updateFellowModels(fellowModels));
  }

  static async loadFellows() {
    try {
      const { authState } = storeModel.state;
      const { userToken } = authState;

      const response = await axios({
        method: 'get',
        url: `${apiUrl}/fellows`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      FellowsUtil.createFellowModels(response.data.data);
    } catch (error) {
      storeModel.dispatch(handleErrorResponse(error.response));
    }
  }

  static async loadFellowbyParams(year, country) {
    try {
      const { authState } = storeModel.state;
      const { userToken } = authState;

      const response = await axios({
        method: 'get',
        url: `${apiUrl}/fellows`,
        params: {
          year,
          country,
        },
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      FellowsUtil.createFellowModels(response.data.data);
    } catch (error) {
      storeModel.dispatch(handleErrorResponse(error.response));
    }
  }
}

export default FellowsUtil;
