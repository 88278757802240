/* xeslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Button from '../elements/Button';
import IconSites17 from '../../assets/icons/IconSites17';
import IconImage17 from '../../assets/icons/IconImage17';
import IconVideo17 from '../../assets/icons/IconVideo17';
import IconPdf17 from '../../assets/icons/IconPdf17';
import './SearchFilter.scss';

class SearchFilter extends Component {
  getResultCounts(searchModels) {
    let results = {};
    let resultCountAll = 0;
    let resultCountSites = 0;
    let resultCountPictures = 0;
    let resultCountVideos = 0;
    let resultCountPdfs = 0;
    searchModels.forEach(function (searchModel) {
      if (searchModel.categoryLabel === 'Site') {
        resultCountSites++;
      } else if (searchModel.categoryLabel === 'PDF') {
        resultCountPdfs++;
      } else if (searchModel.categoryLabel === 'Video') {
        resultCountVideos++;
      } else if (searchModel.categoryLabel === 'Picture') {
        resultCountPictures++;
      }
    });
    resultCountAll = resultCountSites + resultCountPictures + resultCountVideos + resultCountPdfs;

    results.resultCountAll = resultCountAll;
    results.resultCountSites = resultCountSites;
    results.resultCountPictures = resultCountPictures;
    results.resultCountVideos = resultCountVideos;
    results.resultCountPdfs = resultCountPdfs;
    return results;
  }

  render() {
    const { activeFilter, handleChangeActiveFilter } = this.props;
    let results = this.getResultCounts(this.props.searchModels);
    const { resultCountAll, resultCountSites, resultCountPictures, resultCountVideos, resultCountPdfs } = results;

    return (
      <div className="bt-search-filter">
        <div className="bt-search-filter__filters">
          <div className="bt-search-filter__filters-item">
            <Button
              path="#"
              label={`All results (${resultCountAll})`}
              layout={activeFilter === 'all' ? 'secondary-active' : 'secondary'}
              onClick={() => handleChangeActiveFilter('all')}
            />
          </div>
          <div className="bt-search-filter__filters-item">
            <Button
              path="#"
              label={`Sites  (${resultCountSites})`}
              iconLeft={IconSites17}
              layout={activeFilter === 'Site' ? 'secondary-active' : 'secondary'}
              onClick={() => handleChangeActiveFilter('Site')}
            />
          </div>
          <div className="bt-search-filter__filters-item">
            <Button
              path="#"
              label={`Pictures  (${resultCountPictures})`}
              iconLeft={IconImage17}
              layout={activeFilter === 'Picture' ? 'secondary-active' : 'secondary'}
              onClick={() => handleChangeActiveFilter('Picture')}
            />
          </div>
          <div className="bt-search-filter__filters-item">
            <Button
              path="#"
              label={`Videos  (${resultCountVideos})`}
              iconLeft={IconVideo17}
              layout={activeFilter === 'Video' ? 'secondary-active' : 'secondary'}
              onClick={() => handleChangeActiveFilter('Video')}
            />
          </div>
          <div className="bt-search-filter__filters-item">
            <Button
              path="#"
              label={`PDFs  (${resultCountPdfs})`}
              iconLeft={IconPdf17}
              layout={activeFilter === 'PDF' ? 'secondary-active' : 'secondary'}
              onClick={() => handleChangeActiveFilter('PDF')}
            />
          </div>
        </div>
        {/* <div className='bt-search-filter__filters-dropdown'>
                    <SelectSmall
                        label='Show:'
                        options={dropDownFilters}
                        value='0'
                    />
                </div>
                <div className='bt-search-filter__sorting'>
                    <SelectSmall label='Filter by:' options={years} value='0' />
                </div> */}
      </div>
    );
  }
}

SearchFilter.propTypes = {
  searchModels: PropTypes.array,
  activeFilter: PropTypes.string,
  handleChangeActiveFilter: PropTypes.func,
};

SearchFilter.defaultProps = {
  searchModels: [],
  activeFilter: '',
  handleChangeActiveFilter: () => false,
};

export default SearchFilter;
