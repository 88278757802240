/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './textarea.scss';

class Textarea extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valueByProp: '',
            value: ''
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.value !== state.valueByProp) {
            return {
                valueByProp: props.value,
                value: props.value
            };
        }
        return null;
    }

    handleChangeInput(e) {
        let value = e.target.value;
        const { maxLength, onChange } = this.props;

        if (maxLength > 0) {
            if (value.length > maxLength) {
                value = value.slice(0, maxLength);
            }
        }

        this.setState({
            value
        });

        onChange(value);
    }

    render() {
        const { value } = this.state;
        const { placeholder, label, maxLength, showCounter, error, errorMessage } = this.props;

        return (
            <div>
                <div className={`bt-textarea ${error && 'error'}`}>
                    <label>{label}</label>
                    <textarea placeholder={placeholder} value={value} onChange={(e) => this.handleChangeInput(e)} />
                    {error && <span className="bt-textarea__error-message">{errorMessage}</span>}
                </div>
                {showCounter && (
                    <div className="bt-textarea__counter">
                        {value.length}/{maxLength}
                    </div>
                )}
            </div>
        );
    }
}

Textarea.propTypes = {
    placeholder: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    maxLength: PropTypes.number,
    showCounter: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    onChange: PropTypes.func
};

Textarea.defaultProps = {
    placeholder: '',
    label: '',
    value: '',
    maxLength: 0,
    showCounter: false,
    error: false,
    unselected: false,
    errorMessage: 'Error',
    onChange: () => false
};

export default Textarea;
