import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Group, InlineSVG } from '@webfox-sc/core';
import Toggle from '../../components/elements/Toggle/Toggle';
import EventsUtil from '../../utils/EventsUtil';
import LabelM from '../../components/elements/LabelM';
import CopyTextM from '../../components/elements/CopyTextM';
import IconRegister from '../../assets/icons/IconRegister';

const EventTeaserAttend = ({ eventId, isAttending, registrationLink, onToggleAttend }) => {
  const [isAttendingEvent, setIsAttendingEvent] = useState(isAttending);

  useEffect(() => {
    setIsAttendingEvent(isAttending);
  }, [isAttending]);

  const handleChangeToggle = async (toggle) => {
    setIsAttendingEvent(toggle);
    await EventsUtil.attendEvent(eventId, isAttendingEvent);
    onToggleAttend();
  };

  return (
    <Group hSpacing="50px" vSpacing="10px" alignItems="center">
      <Group nowrap spacing="10px" alignItems="center">
        <Toggle value={isAttendingEvent} onChange={(e) => handleChangeToggle(e)} />
        <LabelM text="I want to register" />
      </Group>
      {isAttendingEvent && registrationLink && (
        <Group nowrap hSpacing="10px" alignItems="center">
          <InlineSVG width="20px" height="20px">
            {IconRegister}
          </InlineSVG>
          <CopyTextM text={`Register at <a href="${registrationLink}" target="_blank">${registrationLink}</a>`} />
        </Group>
      )}
    </Group>
  );
};

EventTeaserAttend.propTypes = {
  eventId: PropTypes.number,
  isAttending: PropTypes.bool,
  registrationLink: PropTypes.string,
  onToggleAttend: PropTypes.func,
};

EventTeaserAttend.defaultProps = {
  eventId: null,
  isAttending: false,
  registrationLink: undefined,
  onToggleAttend: () => {},
};

export default EventTeaserAttend;
