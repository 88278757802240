import React from 'react';
import { UiDiv, UiFlex } from '@webfox-sc/core';
import { useTheme } from 'styled-components';
import PageTitle from '../PageTitle';
import PageNavigation from '../PageNavigation';
import MetaNavigation from '../MetaNavigation';
import { useAppSelector } from '../../app/hooks';
import { selectAuthState } from '../../app/slices/authSlice';
import { useNavigate } from 'react-router-dom';

const metaNavigationModels = [
  {
    label: 'About us',
    path: '/about',
    pageId: 'about',
  },
  {
    label: 'Contact us',
    path: '/contacts',
    pageId: 'contacts',
  },
  {
    label: 'Imprint',
    path: '/imprint',
    pageId: 'imprint',
  },
  {
    label: 'Terms and Conditions',
    path: '/terms',
    pageId: 'terms',
  },
  {
    label: 'Privacy Policy',
    path: '/privacy',
    pageId: 'privacy',
  },
];

interface PageSidebarProps {
  isAuthPage: boolean;
  pageId: string;
  baseDataLinks: any;
}

const PageSidebar: React.FC<PageSidebarProps> = ({ isAuthPage, pageId, baseDataLinks }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { authStatus } = useAppSelector(selectAuthState);

  const handleOnClickTitle = () => {
    if (authStatus === 'loggedIn') {
      navigate('/');
    } else {
      navigate('/');
    }
  };

  const handleOnClickNavigationItem = (path: string) => {
    navigate(path);
  };

  return (
    <UiFlex
      position="fixed"
      flexDirection="column"
      justifyContent="space-between"
      boxSizing="border-box"
      height="100vh"
      width="240px"
      paddingLeft="24px"
      borderRight={isAuthPage ? undefined : `1px solid ${theme.colors.btLightGray}`}
      overflowY="auto"
    >
      <div>
        {!isAuthPage && (
          <UiDiv padding="49px 0 0 0">
            <PageTitle onClick={handleOnClickTitle} />
          </UiDiv>
        )}
        {authStatus === 'loggedIn' && (
          <UiDiv padding="82px 0 0 0">
            <PageNavigation currentPageId={pageId} onClickItem={(path) => handleOnClickNavigationItem(path)} />
          </UiDiv>
        )}
      </div>
      <div>
        <UiDiv padding="70px 0 70px 0">
          <MetaNavigation
            isUserLoggedIn={authStatus === 'loggedIn'}
            navigationModels={metaNavigationModels}
            currentPageId={pageId}
            links={baseDataLinks}
            onClickItem={(path) => handleOnClickNavigationItem(path)}
          />
        </UiDiv>
      </div>
    </UiFlex>
  );
};

export default PageSidebar;
