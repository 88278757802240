import React from 'react';
import { UiDiv, UiFlex, UiImg, UiLink } from '@webfox-sc/core';
import ImpulsSVG from '../../assets/svg/impuls.svg';
import BeEPLogo from '../../assets/images/BeEP-logo.svg';
import PageContentTop from './PageContentTop';
import PageSidebar from './PageSidebar';
import { useAppSelector } from '../../app/hooks';
import { selectAuthState } from '../../app/slices/authSlice';
import { useLocation, useNavigate } from 'react-router-dom';
import EventsStaticTimeline from '../events/EventsStaticTimeline';

interface PageLayoutProps {
  showMobileMenuOverlay?: boolean;
  children: React.ReactNode;
}

const PageLayout: React.FC<PageLayoutProps> = ({ showMobileMenuOverlay, children }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { pageId, baseDataLinks } = useAppSelector(({ appState }) => appState);
  const { authStatus } = useAppSelector(selectAuthState);

  const isAuthPage =
    authStatus !== 'loggedIn' &&
    (pathname === '/' ||
      pathname === '/login' ||
      pathname === '/register' ||
      pathname === '/register/feedback' ||
      pathname === '/register/confirmEmail' ||
      pathname === '/password' ||
      pathname === '/password/reset');

  const userProfilePath = `/${pageId || 'news'}/show/profile/data`;

  return (
    <>
      <UiDiv
        display="grid"
        gridTemplateColumns="1fr 240px minmax(auto, 1140px) 1fr"
        minHeight="100vh"
        backgroundRepeat="no-repeat"
        backgroundSize={{ mobile: '130%', tablet: '70%' }}
        backgroundPosition={{ mobile: 'right bottom -90px', tablet: 'right bottom' }}
        backgroundColor={pageId === 'news' ? 'btLightBlueGrey' : 'white'}
        transition="background-color 0.3s ease"
        style={{
          backgroundImage: pageId !== 'about' ? `url(${ImpulsSVG})` : 'none',
          filter: showMobileMenuOverlay ? 'blur(10px)' : 'none',
        }}
      >
        <UiDiv display={{ mobile: 'none', tablet: 'block' }} gridColumn="1/2" background="white" />
        <UiDiv display={{ mobile: 'none', tablet: 'block' }} gridColumn="2/3" background="white">
          <PageSidebar isAuthPage={isAuthPage} pageId={pageId} baseDataLinks={baseDataLinks} />
        </UiDiv>
        <UiDiv gridColumn={{ mobile: '1/5', tablet: '3/5' }} paddingTop={{ mobile: '90px', tablet: '0' }}>
          {isAuthPage && (
            <UiFlex>
              <UiLink
                display={{ mobile: 'none', tablet: 'block' }}
                padding="50px 0 70px 0"
                href="/"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/');
                }}
              >
                <UiImg
                  src={BeEPLogo}
                  alt="Biotronik BeEP"
                  position="relative"
                  left="-90px"
                  width="333px"
                  height="auto"
                  verticalAlign="top"
                />
              </UiLink>
            </UiFlex>
          )}
          {authStatus === 'loggedIn' && (
            <>
              <PageContentTop userProfilePath={userProfilePath} showAdvice={pageId === 'news'} />
              {pathname.startsWith('/events') && <EventsStaticTimeline />}
            </>
          )}
          <UiDiv
            boxSizing="border-box"
            maxWidth="1140px"
            padding={{ mobile: '38px 0 50px 0', tablet: '30px 30px 70px 30px' }}
          >
            {children}
          </UiDiv>
        </UiDiv>
      </UiDiv>
    </>
  );
};

export default PageLayout;
