/* eslint-disable */
import React from 'react';

const IconArrowUp12 = (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12">
        <path fill="currentColor" d="M6 2.6l-6 6 .8.8L6 4.2l5.2 5.2.8-.8-6-6z"/>
    </svg>
);

export default IconArrowUp12;
