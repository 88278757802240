/* eslint-disable */
import React from 'react';

const IconSearch20 = (
    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="nonzero">
            <path d="M9.47 17.702C4.571 17.702.6 13.739.6 8.85.6 3.963 4.571 0 9.47 0c4.899 0 8.87 3.963 8.87 8.85 0 4.89-3.971 8.852-8.87 8.852zm0-1.042c4.323 0 7.827-3.496 7.827-7.81 0-4.312-3.504-7.809-7.827-7.809-4.322 0-7.826 3.497-7.826 7.81s3.504 7.81 7.826 7.81z" />
            <path d="M14.472 15.62l.738-.737 4.39 4.38-.738.737z" />
        </g>
    </svg>
);

export default IconSearch20;
