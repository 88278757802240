/* eslint-disable */
import React from 'react';

const IconDownloads20 = (
    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
        <path d="M10.423 3.764l-.096-.004V.535A.336.336 0 0 0 9.993.2a.336.336 0 0 0-.333.335v3.231l-.094.007C5.327 4.05 2 7.593 2 11.873 2 16.354 5.634 20 10.1 20c4.466 0 8.1-3.645 8.1-8.127 0-4.358-3.447-7.938-7.777-8.11zm4.458 6.7a.333.333 0 1 0-.471-.474l-4.084 4.097V4.428l.104.005c3.96.175 7.103 3.454 7.103 7.44 0 4.111-3.335 7.457-7.433 7.457s-7.433-3.346-7.433-7.457c0-3.904 3.02-7.144 6.885-7.43l.108-.008v9.653l-.171-.172-3.914-3.925a.333.333 0 1 0-.471.473l4.89 4.904.235-.237a.047.047 0 0 1 .013-.013l4.639-4.655z" fill="currentColor" fillRule="nonzero" />
    </svg>
);

export default IconDownloads20;
