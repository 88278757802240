/* eslint-disable */
import React from 'react';

const IconLogin40 = (
    <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="nonzero">
            <path d="M33.382 14.899V10.74C33.382 4.819 27.378 0 20 0S6.618 4.819 6.618 10.74V14.9c-2.844.434-5.018 2.63-5.018 5.271v14.476C1.6 37.598 4.312 40 7.646 40h24.708c3.334 0 6.046-2.402 6.046-5.355V20.17c0-2.642-2.174-4.837-5.018-5.271zM8.2 10.567C8.2 5.512 13.493 1.4 20 1.4c6.507 0 11.8 4.112 11.8 9.167V14.6H8.2v-4.033zM37 34.826c0 2.191-1.994 3.974-4.445 3.974H7.445C4.995 38.8 3 37.017 3 34.826V19.974C3 17.783 4.994 16 7.445 16h25.11C35.005 16 37 17.783 37 19.974v14.852z" />
            <path d="M20 20.8c-1.88 0-3.407 1.333-3.407 2.971v4.458c0 1.638 1.528 2.971 3.407 2.971 1.88 0 3.407-1.333 3.407-2.971V23.77c0-1.638-1.528-2.971-3.407-2.971zm2.044 7.6c0 .882-.916 1.6-2.044 1.6-1.128 0-2.044-.718-2.044-1.6v-4.8c0-.882.916-1.6 2.044-1.6 1.128 0 2.044.718 2.044 1.6v4.8z" />
        </g>
    </svg>
);

export default IconLogin40;
