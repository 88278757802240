import React from 'react';
import Select from '../elements/Select';
import Input from '../elements/Input';
import RadioButton from '../elements/RadioButton';
import Checkbox from '../elements/Checkbox';
import Button from '../elements/Button';
import AppUtil from '../../utils/AppUtil';
import IconInfo20 from '../../assets/icons/IconInfo20';
import CopyTextM from '../elements/CopyTextM';
import ToggleBox from '../elements/ToggleBox';
import './registration-form.scss';

interface RegistrationFormProps {
  handleChange: (e: any, key: any) => void;
  handleSubmit: (e: any) => void;
  availableSeasons: any;
  availableCountries: any;
  registerFailed: boolean;
  registerFailedByServer: boolean;
  errorTitle: boolean;
  errorFirstName: boolean;
  errorLastName: boolean;
  errorEmail: boolean;
  errorEmployer: boolean;
  errorCountry: boolean;
  errorPassword: boolean;
  errorPasswordRepeat: boolean;
  errorPrivacyPolicy: boolean;
  errorTerms: boolean;
  errorMessage: string;
}

const RegistrationForm: React.FC<RegistrationFormProps> = ({
  handleChange,
  handleSubmit,
  availableSeasons,
  availableCountries,
  registerFailed,
  registerFailedByServer,
  errorMessage,
  errorTitle,
  errorFirstName,
  errorLastName,
  errorEmail,
  errorEmployer,
  errorCountry,
  errorPassword,
  errorPasswordRepeat,
  errorPrivacyPolicy,
  errorTerms,
}) => {
  const seasons = AppUtil.transposeObjectToArray(availableSeasons);
  const countries = AppUtil.transposeObjectToArray(availableCountries);

  return (
    <form onSubmit={(e) => handleSubmit(e)}>
      <div className="bt-registration-form">
        <div className="bt-registration-form__left-section">
          <div className="bt-registration-form__gender-selection">
            <RadioButton name="title" value="mr" onChange={(e) => handleChange(e, 'title')} />
            <span>Mr</span>
            <RadioButton name="title" value="ms" onChange={(e) => handleChange(e, 'title')} />
            <span>Ms</span>
            <RadioButton name="title" value="mx" onChange={(e) => handleChange(e, 'title')} />
            <span>Mx</span>
          </div>
          {errorTitle && <span className="bt-registration-form__error-message">Please choose your title</span>}

          <Input
            label="First name *"
            type="text"
            name="firstName"
            error={errorFirstName}
            errorMessage={'Please provide your first name'}
            onChange={(e) => handleChange(e, 'firstName')}
          />
          <Input
            label="Last name *"
            error={errorLastName}
            errorMessage={'Please provide your last name'}
            onChange={(e) => handleChange(e, 'lastName')}
          />
          <Input
            label="Email (will be your username) *"
            error={errorEmail}
            errorMessage={'Please enter a valid email address'}
            onChange={(e) => handleChange(e, 'email')}
          />
          <Input
            label="Hospital or Company *"
            error={errorEmployer}
            errorMessage={'Please provide the name of your hospital or company'}
            onChange={(e) => handleChange(e, 'employer')}
          />
          <Select
            options={countries}
            placeholder="Please select"
            label="Country *"
            error={errorCountry}
            errorMessage={'Please select your country'}
            onChange={(e) => handleChange(e, 'country')}
          />
          <Select
            options={seasons}
            placeholder="Please select"
            label="BeEP Year of Attendence"
            onChange={(e) => handleChange(e, 'season')}
          />
        </div>
        <div className="bt-registration-form__right-section">
          <Input
            label="Password (min. 8 characters) *"
            error={errorPassword}
            errorMessage={'Please provide a password with min. 8 characters'}
            type="password"
            onChange={(e) => handleChange(e, 'password')}
          />
          <Input
            label="Password verification *"
            error={errorPasswordRepeat}
            errorMessage={'Please verify your password'}
            type="password"
            onChange={(e) => handleChange(e, 'passwordRepeat')}
          />
          <div className="bt-registration-form__terms">
            <Checkbox
              onChange={(e) => handleChange(e, 'terms')}
              error={errorTerms}
              errorMessage={'Please review and accept the Terms for registration'}
            />
            <span>
              I accept your general{' '}
              <a href="/terms" target="_blank">
                terms and conditions
              </a>{' '}
              *
            </span>
          </div>
          {errorTerms && (
            <span className="bt-registration-form__error-message">Please accept the Terms to register</span>
          )}
          <div className="bt-registration-form__terms">
            <Checkbox
              onChange={(e) => handleChange(e, 'privacyPolicy')}
              error={errorPrivacyPolicy}
              errorMessage={'Please review and accept the Privacy Policy'}
            />
            <span>
              I accept your{' '}
              <a href="/privacy" target="_blank">
                privacy policy
              </a>{' '}
              *
            </span>
          </div>
          {errorPrivacyPolicy && (
            <span className="bt-registration-form__error-message">Please accept the Privacy Policy to register</span>
          )}

          <div className="bt-registration-form__terms">
            <span>* mandatory</span>
          </div>

          <ToggleBox show={registerFailedByServer && !registerFailed}>
            <div className="bt-registration-form__error">
              <span>{IconInfo20}</span>
              <CopyTextM text={errorMessage} />
            </div>
          </ToggleBox>
        </div>

        <div className="bt-registration-form__button">
          <Button layout="primary-side" path="#" label="Register now" onClick={(e) => handleSubmit(e)} />
        </div>
        <div className="bt-registration-form__button-mobile">
          <Button layout="primary" path="#" label="Register now" onClick={(e) => handleSubmit(e)} />
        </div>
      </div>
    </form>
  );
};

export default RegistrationForm;
