/* eslint-disable */
import React from 'react';

const IconMetaAppBiotronik14 = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
    >
        <g fill="currentColor" fillRule="evenodd">
            <path d="M10.11,1.69H7.44a1.44,1.44,0,0,0-1,.45A1.63,1.63,0,0,0,6,3.25V4.8h7.37a3.17,3.17,0,0,0-.91-2.06l0-.05A3.2,3.2,0,0,0,10.11,1.69ZM8.42,8.18H8.26V8.9h.18a.7.7,0,0,0,.39-.09A.34.34,0,0,0,9,8.53a.29.29,0,0,0-.12-.27A.84.84,0,0,0,8.42,8.18Zm-2.23,0H5.93V9h.19a.57.57,0,0,0,.38-.11.32.32,0,0,0,.13-.29.36.36,0,0,0-.11-.3A.5.5,0,0,0,6.19,8.18Zm5.68-.46v.47h-.71v2.18H10.6V8.19H9.88V7.72Zm-3.41,0h.06a1.23,1.23,0,0,1,.74.2.7.7,0,0,1,.26.59.68.68,0,0,1-.13.42A.78.78,0,0,1,9,9.21c.4.6.65,1,.78,1.16H9.18l-.63-1H8.26v1H7.7V7.72Zm-2.24,0h0A1.12,1.12,0,0,1,7,7.93a.76.76,0,0,1,.25.62.8.8,0,0,1-.27.65,1.15,1.15,0,0,1-.76.23H5.93v.94H5.37V7.72Zm-3,0,.56,1,.56-1H5L4.15,9,5,10.37H4.4l-.61-1-.62,1h-.6L3.45,9,2.63,7.72Zm.63-2.27a3.11,3.11,0,0,0-2.29,1,3.58,3.58,0,0,0,0,4.86,3.19,3.19,0,0,0,2.29,1h6.23a3.2,3.2,0,0,0,2.29-1,3.49,3.49,0,0,0,.95-2.43V5.45Zm6.23-4.4a3.78,3.78,0,0,1,2.75,1.2A4.14,4.14,0,0,1,14,5.12v0a.07.07,0,0,1,0,0,.09.09,0,0,1,0,0V8.88h0a4.2,4.2,0,0,1-1.13,2.87A3.79,3.79,0,0,1,10.12,13H3.88a3.79,3.79,0,0,1-2.75-1.2A4.22,4.22,0,0,1,1.13,6,3.83,3.83,0,0,1,3.88,4.8H5.34V3.25A2.27,2.27,0,0,1,6,1.7a2.07,2.07,0,0,1,1.49-.65Z" />
        </g>
    </svg>
);

export default IconMetaAppBiotronik14;
