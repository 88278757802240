/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import Button from '../elements/Button';
import Input from '../elements/Input';
import Textarea from '../elements/Textarea';
import Checkbox from '../elements/Checkbox';
import HeadlineThree from '../elements/HeadlineThree';
import LabelL from '../elements/LabelL';
import CopyTextS from '../elements/CopyTextS';
import IconUser40 from '../../assets/icons/IconUser40';
import IconInfo40 from '../../assets/icons/IconInfo40';
import IconHospital40 from '../../assets/icons/IconHospital40';
import IconContact40 from '../../assets/icons/IconContact40';
import IconLogin40 from '../../assets/icons/IconLogin40';
import IconUser28 from '../../assets/icons/IconUser28';
import IconInfo28 from '../../assets/icons/IconInfo28';
import IconHospital28 from '../../assets/icons/IconHospital28';
import IconContact28 from '../../assets/icons/IconContact28';
import IconLogin28 from '../../assets/icons/IconLogin28';
import IconVisible20 from '../../assets/icons/IconVisible20';
import IconInvisible20 from '../../assets/icons/IconInvisible20';
import IconWarning20 from '../../assets/icons/IconWarning20';
import IconDelete20 from '../../assets/icons/IconDelete20';
import IconPlus20 from '../../assets/icons/IconPlus20';
import './profile-edit.scss';

class ProfileEdit extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      titleByProp: '',
      title: '',
      firstNameByProp: '',
      firstName: '',
      lastNameByProp: '',
      lastName: '',
      professionByProp: '',
      profession: '',
      degreeByProp: '',
      degree: '',
      cityByProp: '',
      city: '',
      postalCodeByProp: '',
      postalCode: '',
      countryByProp: '',
      country: '',
      statusMessageByProp: '',
      statusMessage: '',
      statusHideOnNewsFeedByProp: false,
      statusHideOnNewsFeed: false,
      employersByProp: [],
      employers: [],
      contactByProp: '',
      contact: '',
      emailByProp: '',
      email: '',
      password: '',
      passwordRepeat: '',
    };
  }

  static getDerivedStateFromProps(props, state) {
    const stateUpdates = {};
    const {
      title,
      firstName,
      lastName,
      profession,
      degree,
      city,
      postalCode,
      country,
      statusMessage,
      statusHideOnNewsFeed,
      employers,
      contact,
      email,
    } = props;

    if (title !== state.titleByProp) {
      stateUpdates.titleByProp = title;
      stateUpdates.title = title;
    }

    if (firstName !== state.firstNameByProp) {
      stateUpdates.firstNameByProp = firstName;
      stateUpdates.firstName = firstName;
    }

    if (lastName !== state.lastNameByProp) {
      stateUpdates.lastNameByProp = lastName;
      stateUpdates.lastName = lastName;
    }

    if (profession !== state.professionByProp) {
      stateUpdates.professionByProp = profession;
      stateUpdates.profession = profession;
    }

    if (degree !== state.degreeByProp) {
      stateUpdates.degreeByProp = degree;
      stateUpdates.degree = degree;
    }

    if (city !== state.cityByProp) {
      stateUpdates.cityByProp = city;
      stateUpdates.city = city;
    }

    if (postalCode !== state.postalCodeByProp) {
      stateUpdates.postalCodeByProp = postalCode;
      stateUpdates.postalCode = postalCode;
    }

    if (country !== state.countryByProp) {
      stateUpdates.countryByProp = country;
      stateUpdates.country = country;
    }

    if (statusMessage !== state.statusMessageByProp) {
      stateUpdates.statusMessageByProp = statusMessage;
      stateUpdates.statusMessage = statusMessage;
    }

    if (statusHideOnNewsFeed !== state.statusHideOnNewsFeedByProp) {
      stateUpdates.statusHideOnNewsFeedByProp = statusHideOnNewsFeed;
      stateUpdates.statusHideOnNewsFeed = statusHideOnNewsFeed;
    }

    if (employers !== state.employersByProp) {
      stateUpdates.employersByProp = employers;
      stateUpdates.employers = employers;
    }

    if (contact !== state.contactByProp) {
      const contactValid = [...contact];
      if (!contactValid.filter((contactItem) => contactItem.type === 'email').length) {
        contactValid.push({
          type: 'email',
          contact: '',
          isPublic: false,
        });
      }
      if (!contactValid.filter((contactItem) => contactItem.type === 'linkedin').length) {
        contactValid.push({
          type: 'linkedin',
          contact: '',
          isPublic: false,
        });
      }
      if (!contactValid.filter((contactItem) => contactItem.type === 'twitter').length) {
        contactValid.push({
          type: 'twitter',
          contact: '',
          isPublic: false,
        });
      }
      if (!contactValid.filter((contactItem) => contactItem.type === 'other').length) {
        contactValid.push({
          type: 'other',
          contact: '',
          isPublic: false,
        });
      }
      if (!contactValid.filter((contactItem) => contactItem.type === 'phone').length) {
        contactValid.push({
          type: 'phone',
          contact: '',
          isPublic: false,
        });
      }
      // if (contactValid.filter(contactItem => contactItem.type === 'phone').length === 1) {
      //     contactValid.push({
      //         type: 'phone',
      //         contact: '',
      //         isPublic: false,
      //     });
      // }

      stateUpdates.contactByProp = contact;
      stateUpdates.contact = contactValid;
    }

    if (email !== state.emailByProp) {
      stateUpdates.emailByProp = email;
      stateUpdates.email = email;
    }

    if (Object.keys(stateUpdates).length) {
      return stateUpdates;
    }

    return null;
  }

  handleOnClickCancel(e) {
    e.preventDefault();
    const { onCancel } = this.props;
    onCancel();
  }

  handleOnClickSave(e) {
    e.preventDefault();
    const { onUpdate } = this.props;
    onUpdate(this.state);
  }

  handleChangeInputValue(key, value) {
    const stateUpdates = {};
    stateUpdates[key] = value;
    this.setState(stateUpdates);
  }

  handleOnClickAddEmployer() {
    const employersNew = [...this.state.employers];
    employersNew.push({
      name: '',
      email: '',
    });
    this.setState({
      employers: employersNew,
    });
  }

  handleOnClickDeleteEmployer(index) {
    const employersNew = [...this.state.employers];
    employersNew.splice(index, 1);
    this.setState({
      employers: employersNew,
    });
  }

  handleChangeEmployerValue(index, key, value) {
    const employersNew = [...this.state.employers];
    employersNew[index][key] = value;
    this.setState({
      employers: employersNew,
    });
  }

  handleChangeContactItemValue(index, value) {
    const contact = [...this.state.contact];
    const contactItem = contact[index];
    contactItem.contact = value;
    this.setState({
      contact,
    });
  }

  handleToogleContactItemIsPublic(index) {
    const contact = [...this.state.contact];
    const contactItem = contact[index];
    contactItem.isPublic = !contactItem.isPublic;
    this.setState({
      contact,
    });
  }

  isRequestError(key) {
    const { responseErrorObject } = this.props;
    const keys = Object.keys(responseErrorObject);
    if (keys.includes(key)) {
      return true;
    }
    return false;
  }

  getRequestErrorMessage(key) {
    const { responseErrorObject } = this.props;
    for (let errorKey in responseErrorObject) {
      if (errorKey === key) {
        return responseErrorObject[errorKey];
      }
    }
    return '';
  }

  render() {
    const {
      firstName,
      lastName,
      profession,
      degree,
      city,
      postalCode,
      country,
      statusMessage,
      statusHideOnNewsFeed,
      employers,
      contact,
      password,
      passwordRepeat,
    } = this.state;

    return (
      <div className="bt-profile-edit">
        <div className="bt-profile-edit__buttons">
          <div className="bt-profile-edit__button">
            <Button layout="secondary" path="#" label="Cancel" onClick={(e) => this.handleOnClickCancel(e)} />
          </div>
          <div className="bt-profile-edit__button">
            <Button layout="primary" path="#" label="Save" onClick={(e) => this.handleOnClickSave(e)} />
          </div>
        </div>
        <div className="bt-profile-edit__content">
          <div className="bt-profile-edit__section">
            <div className="bt-profile-edit__section-top">
              <div className="bt-profile-edit__section-icon">{IconUser40}</div>
              <div className="bt-profile-edit__section-icon--mobile">{IconUser28}</div>
              <div className="bt-profile-edit__section-title">
                <HeadlineThree text="Details" />
              </div>
            </div>
            <div className="bt-profile-edit__section-content">
              <div className="bt-profile-edit__row bt-profile-edit__row--indent-right">
                <Input
                  label="First name*"
                  value={firstName}
                  onChange={(value) => this.handleChangeInputValue('firstName', value)}
                  error={this.isRequestError('firstName')}
                  errorMessage={this.getRequestErrorMessage('firstName')}
                />
                {!firstName && <div className="bt-profile-edit__row-mark">{IconWarning20}</div>}
              </div>
              <div className="bt-profile-edit__row bt-profile-edit__row--indent-right">
                <Input
                  label="Last name*"
                  value={lastName}
                  onChange={(value) => this.handleChangeInputValue('lastName', value)}
                  error={this.isRequestError('lastName')}
                  errorMessage={this.getRequestErrorMessage('lastName')}
                />
                {!lastName && <div className="bt-profile-edit__row-mark">{IconWarning20}</div>}
              </div>
              <div className="bt-profile-edit__row bt-profile-edit__row--indent-right">
                <Input
                  label="Profession*"
                  value={profession}
                  onChange={(value) => this.handleChangeInputValue('profession', value)}
                  error={this.isRequestError('profession')}
                  errorMessage={this.getRequestErrorMessage('profession')}
                />
                {!profession && <div className="bt-profile-edit__row-mark">{IconWarning20}</div>}
              </div>
              <div className="bt-profile-edit__row bt-profile-edit__row--indent-right">
                <Input
                  label="Degree"
                  value={degree}
                  onChange={(value) => this.handleChangeInputValue('degree', value)}
                  error={this.isRequestError('degree')}
                  errorMessage={this.getRequestErrorMessage('degree')}
                />
              </div>
              <div className="bt-profile-edit__row bt-profile-edit__row--indent-right">
                <Input
                  label="City"
                  value={city}
                  onChange={(value) => this.handleChangeInputValue('city', value)}
                  error={this.isRequestError('city')}
                  errorMessage={this.getRequestErrorMessage('city')}
                />
              </div>
              <div className="bt-profile-edit__row bt-profile-edit__row--indent-right">
                <Input
                  label="Postal Code"
                  value={postalCode}
                  onChange={(value) => this.handleChangeInputValue('postalCode', value)}
                  error={this.isRequestError('postalCode')}
                  errorMessage={this.getRequestErrorMessage('postalCode')}
                />
              </div>
              <div className="bt-profile-edit__row bt-profile-edit__row--indent-right">
                <Input
                  label="Country*"
                  value={country}
                  onChange={(value) => this.handleChangeInputValue('country', value)}
                  error={this.isRequestError('country')}
                  errorMessage={this.getRequestErrorMessage('country')}
                />
                {!country && <div className="bt-profile-edit__row-mark">{IconWarning20}</div>}
              </div>
              <div className="bt-profile-edit__row bt-profile-edit__row--indent-right">
                <div className="bt-profile-edit__row-advice">
                  <CopyTextS text="*mandatory" />
                </div>
              </div>
            </div>
          </div>
          <div className="bt-profile-edit__section">
            <div className="bt-profile-edit__section-top">
              <div className="bt-profile-edit__section-icon">{IconInfo40}</div>
              <div className="bt-profile-edit__section-icon--mobile">{IconInfo28}</div>
              <div className="bt-profile-edit__section-title">
                <HeadlineThree text="Status" />
              </div>
            </div>
            <div className="bt-profile-edit__section-content">
              <div className="bt-profile-edit__row bt-profile-edit__row--indent-right">
                <Textarea
                  label="Short description (for dashboard and profile)"
                  value={statusMessage}
                  maxLength={210}
                  showCounter
                  onChange={(value) => this.handleChangeInputValue('statusMessage', value)}
                />
              </div>
              <div className="bt-profile-edit__row">
                <Checkbox
                  label="Hide status on newsfeed"
                  checked={statusHideOnNewsFeed}
                  onChange={(value) => this.handleChangeInputValue('statusHideOnNewsFeed', value)}
                />
              </div>
            </div>
          </div>
          <div className="bt-profile-edit__section">
            <div className="bt-profile-edit__section-top">
              <div className="bt-profile-edit__section-icon">{IconHospital40}</div>
              <div className="bt-profile-edit__section-icon--mobile">{IconHospital28}</div>
              <div className="bt-profile-edit__section-title">
                <HeadlineThree text="Hospital/Company" />
              </div>
            </div>
            {employers.map((employer, i) => {
              return (
                <div key={i} className="bt-profile-edit__section-content">
                  <div className="bt-profile-edit__row">
                    <LabelL text={`Hospital/Company ${i > 0 ? i + 1 : ''}`} />
                  </div>
                  <div className="bt-profile-edit__row bt-profile-edit__row--indent-right">
                    <Input
                      label="Name"
                      value={employer.name}
                      onChange={(value) => this.handleChangeEmployerValue(i, 'name', value)}
                    />
                    {!employer.name && <div className="bt-profile-edit__row-mark">{IconWarning20}</div>}
                  </div>
                  <div className="bt-profile-edit__row bt-profile-edit__row--indent-right">
                    <Input
                      label="Your business email"
                      value={employer.email}
                      onChange={(value) => this.handleChangeEmployerValue(i, 'email', value)}
                    />
                  </div>
                  <div className="bt-profile-edit__row">
                    <Button
                      path="#"
                      label="delete hospital/company"
                      iconLeft={IconDelete20}
                      onClick={() => this.handleOnClickDeleteEmployer(i)}
                    />
                  </div>
                </div>
              );
            })}
            <div className="bt-profile-edit__section-content">
              <div className="bt-profile-edit__row">
                <Button
                  path="#"
                  label="Add hospital/company"
                  iconLeft={IconPlus20}
                  onClick={() => this.handleOnClickAddEmployer()}
                />
              </div>
            </div>
          </div>
          <div className="bt-profile-edit__section">
            <div className="bt-profile-edit__section-top">
              <div className="bt-profile-edit__section-icon">{IconContact40}</div>
              <div className="bt-profile-edit__section-icon--mobile">{IconContact28}</div>
              <div className="bt-profile-edit__section-title">
                <HeadlineThree text="Contact" />
              </div>
            </div>
            <div className="bt-profile-edit__section-content">
              {contact.map((contactItem, i) => {
                let label = '';
                if (contactItem.type === 'email') {
                  label = 'Email (private)';
                } else if (contactItem.type === 'phone') {
                  label = 'Phone number';
                } else if (contactItem.type === 'linkedin') {
                  label = 'LinkedIn';
                } else if (contactItem.type === 'twitter') {
                  label = 'Twitter';
                } else if (contactItem.type === 'other') {
                  label = 'Weblink';
                }

                return (
                  <div key={i} className="bt-profile-edit__row">
                    <Input
                      name={'contact' + contactItem.type + i}
                      label={label}
                      value={contactItem.contact}
                      autoComplete={false}
                      onChange={(value) => this.handleChangeContactItemValue(i, value)}
                    />
                    <div className="bt-profile-edit__row-visibility">
                      <Button
                        layout={contactItem.isPublic ? '' : 'grey'}
                        path="#"
                        iconLeft={contactItem.isPublic ? IconVisible20 : IconInvisible20}
                        onClick={() => this.handleToogleContactItemIsPublic(i)}
                      />
                    </div>
                  </div>
                );
              })}
              {/* <div className='bt-profile-edit__row'>
                                <Button
                                    path='#'
                                    label='Add phone number'
                                    iconLeft={IconPlus20}
                                />
                            </div> */}
            </div>
          </div>
          <div className="bt-profile-edit__section">
            <div className="bt-profile-edit__section-top">
              <div className="bt-profile-edit__section-icon">{IconLogin40}</div>
              <div className="bt-profile-edit__section-icon--mobile">{IconLogin28}</div>
              <div className="bt-profile-edit__section-title">
                <HeadlineThree text="Login" />
              </div>
            </div>
            <div className="bt-profile-edit__section-content">
              {/* <div className='bt-profile-edit__row'>
                                <Input
                                    label='Login name'
                                    value='sebastien-werstergren'
                                    disabled
                                />
                                <div className='bt-profile-edit__row-visibility'>
                                    <Button
                                        layout='grey'
                                        path='#'
                                        iconLeft={IconInvisible20}
                                    />
                                </div>
                            </div> */}
              <div className="bt-profile-edit__row bt-profile-edit__row--indent-right">
                <Input
                  type="password"
                  label="New password"
                  value={password}
                  onChange={(value) => this.handleChangeInputValue('password', value)}
                  error={this.isRequestError('password')}
                  errorMessage={this.getRequestErrorMessage('password')}
                />
              </div>
              <div className="bt-profile-edit__row bt-profile-edit__row--indent-right">
                <Input
                  type="password"
                  label="Repeat new password"
                  value={passwordRepeat}
                  onChange={(value) => this.handleChangeInputValue('passwordRepeat', value)}
                  error={this.isRequestError('passwordRepeat')}
                  errorMessage={this.getRequestErrorMessage('passwordRepeat')}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="bt-profile-edit__buttons  bt-profile-edit__buttons--layer-bottom">
          <div className="bt-profile-edit__button">
            <Button layout="secondary" path="#" label="Cancel" onClick={(e) => this.handleOnClickCancel(e)} />
          </div>
          <div className="bt-profile-edit__button">
            <Button layout="primary" path="#" label="Save" onClick={(e) => this.handleOnClickSave(e)} />
          </div>
        </div>
      </div>
    );
  }
}

ProfileEdit.propTypes = {
  title: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  profession: PropTypes.string,
  degree: PropTypes.string,
  city: PropTypes.string,
  postalCode: PropTypes.string,
  country: PropTypes.string,
  statusMessage: PropTypes.string,
  statusHideOnNewsFeed: PropTypes.bool,
  employers: PropTypes.array,
  contact: PropTypes.array,
  email: PropTypes.string,
  responseErrorObject: PropTypes.object,
  onUpdate: PropTypes.func,
  onCancel: PropTypes.func,
};

ProfileEdit.defaultProps = {
  title: '',
  firstName: '',
  lastName: '',
  profession: '',
  degree: '',
  city: '',
  postalCode: '',
  country: '',
  statusMessage: '',
  statusHideOnNewsFeed: false,
  employers: [],
  contact: [],
  email: '',
  responseErrorObject: {},
  onUpdate: () => false,
  onCancel: () => false,
};

export default ProfileEdit;
