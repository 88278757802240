/* eslint-disable */
import React from 'react';

const ShapeTriangle30x15 = (
    <svg width="30" height="15" xmlns="http://www.w3.org/2000/svg">
        <path fill="currentColor" d="M15 0l15 15H0z" fillRule="evenodd" />
    </svg>
);

export default ShapeTriangle30x15;
