import { useSelector } from 'react-redux';

export const useDownload = (downloadId) => {
  const download = useSelector(({ downloadsState }) =>
    (downloadsState?.downloads || []).find((entity) => entity.id === downloadId)
  );
  const downloadModel = useSelector(({ downloadsState }) =>
    (downloadsState?.downloadModels || []).find((model) => model.id === downloadId)
  );

  const { category, datum, coverPicture, registration } = download || {};

  /**
   * category
   */
  const downloadCategoryFormatted = category !== 'Other' ? category?.toUpperCase() : category;

  /**
   * date and time formatted
   */
  const { date, time } = datum || {};
  const downloadDateFormatted = date;
  const downloadTimeFormatted = time;
  const downloadDateTimeFormatted = `${downloadDateFormatted}${downloadTimeFormatted ? ' | ' : ''}${
    downloadTimeFormatted || ''
  }`;

  /**
   * cover picture
   */
  const downloadCoverPictureMediumLarge = coverPicture ? coverPicture.sizes?.medium_large : undefined;

  /**
   * registration
   */
  let downloadIsDigital = false;
  if (registration) {
    downloadIsDigital = registration.isEnabled || false;
  }

  return {
    download,
    downloadModel,
    downloadCategoryFormatted,
    downloadDateTimeFormatted,
    downloadCoverPictureMediumLarge,
    downloadIsDigital,
  };
};
