/* eslint-disable */
import React from 'react';

const IconDelete20 = (
    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="evenodd">
            <path d="M15.3337682 18.828125H4.6539591L3.4324136 6.8203125h13.1228591L15.3337682 18.828125zM2.2272727 5.6484375h15.5331818V3.7286328H2.2272727v1.9198047zm6.4437137-3.0916797h2.6457545V1.171875H8.6709864v1.3848828zm10.3167409 0h-6.4437137V0h-5.1003v2.5567578H1v4.2635547h1.1993727L3.5401273 20H16.4476l1.3407545-13.1796875h1.1993728V2.5567578z"/>
            <path d="M9.3802273 16.5496094H10.6075V9.1015625H9.3802273zM13.8907818 9.1015625h-1.2286227l-.3654818 7.4479297h1.2285818l.3655227-7.4479297M7.69105 16.5494922l-.3654818-7.4479297H6.0969454l.3654819 7.4479297H7.69105"/>
        </g>
    </svg>
);

export default IconDelete20;
