/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import searchIcon from '../../../assets/icons/IconSearch16';
import './input.scss';

class Input extends Component {
    constructor(props) {
        super(props);
        this.state = {
            valueByProp: '',
            value: ''
        };
    }

    static getDerivedStateFromProps(props, state) {
        if (props.value !== state.valueByProp) {
            return {
                valueByProp: props.value,
                value: props.value
            };
        }
        return null;
    }

    handleChangeInput(e) {
        const { onChange } = this.props;
        const { value } = e.target;

        this.setState({
            value
        });

        onChange(value);
    }

    render() {
        const { value } = this.state;
        const {
            type,
            name,
            placeholder,
            label,
            autoComplete,
            error,
            errorMessage,
            search,
            disabled,
            onKeyDown
        } = this.props;

        return (
            <div
                className={`bt-input ${error && 'error-input'} ${search &&
                    'search'}`}
            >
                <div className='bt-input__interior'>
                    <label>{label}</label>
                    <input
                        type={type}
                        name={name}
                        placeholder={placeholder}
                        value={value}
                        onChange={e => this.handleChangeInput(e)}
                        readOnly={disabled}
                        autoComplete={autoComplete.toString()}
                        onKeyDown={onKeyDown}
                    />
                    {search && (
                        <span className='bt-input__search-icon'>
                            {searchIcon}
                        </span>
                    )}
                </div>

                {error && (
                    <span className='bt-input__error-message'>
                        {errorMessage}
                    </span>
                )}
            </div>
        );
    }
}

Input.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
    value: PropTypes.string,
    autoComplete: PropTypes.bool,
    error: PropTypes.bool,
    errorMessage: PropTypes.string,
    search: PropTypes.bool,
    disabled: PropTypes.bool,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func
};

Input.defaultProps = {
    type: 'text',
    name: '',
    placeholder: '',
    label: '',
    value: '',
    autoComplete: true,
    error: false,
    unselected: false,
    errorMessage: '',
    startValue: '',
    search: false,
    disabled: false,
    onChange: () => false,
    onKeyDown: () => false
};

export default Input;
