import CopyTextM from '../elements/CopyTextM/CopyTextM';
import Button from '../elements/Button/Button';
import { useAppSelector } from '../../app/hooks';
import './layer-view-event-evaluation.scss';

const LayerViewEventEvaluation = () => {
  const { activeEvent } = useAppSelector(({ eventsState }) => eventsState);
  const eventData = activeEvent as EventData | undefined;

  return (
    <div className="bt-event-evaluation">
      <CopyTextM text={eventData?.evaluation?.description} />
      <br />
      <br />
      <br />
      <Button layout="blue" path={eventData?.evaluation?.url} label="Go to evaluation" target="_blank" link />
    </div>
  );
};

export default LayerViewEventEvaluation;
