/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import HeadlineThree from '../elements/HeadlineThree';
import LabelL from '../elements/LabelL';
import ImageThumb from '../elements/ImageThumb';
import Button from '../elements/Button';
import IconInfo20 from '../../assets/icons/IconInfo20';
import './event-attendee.scss';

class EventAttendee extends React.Component {
    // constructor(props) {
    //     super(props);
    // }

    handleOnClickLink(e, profilePath) {
        if (e) {
            e.preventDefault();
        }

        const { onClick } = this.props;
        onClick(profilePath);
    }

    render() {
        const { imageUrl, name, title, location, initials, profilePath, onClick } = this.props;

        return (
            <div className="bt-event-attendee">
                <a
                    className="bt-event-attendee__link"
                    href={profilePath}
                    onClick={(e) => this.handleOnClickLink(e, profilePath)}
                >
                    <div className="bt-event-attendee__image">
                        <ImageThumb size="L" sizeMobile="M" profileInitials={initials} imageSrc={imageUrl} />
                    </div>
                    <div className="bt-event-attendee__info">
                        <HeadlineThree text={name} />
                        {title && (
                            <div>
                                <LabelL text={title} />
                            </div>
                        )}
                        {location && (
                            <div>
                                <LabelL text={location} />
                            </div>
                        )}
                    </div>
                </a>
                <div className="bt-event-attendee__button">
                    <div className="bt-event-attendee__button-desktop">
                        <Button layout="secondary-side" label="Details" onClick={() => onClick(profilePath)} />
                    </div>
                    <div className="bt-event-attendee__button-mobile">
                        <Button layout="mobile-side-info" iconInfo={IconInfo20} onClick={() => onClick(profilePath)} />
                    </div>
                </div>
            </div>
        );
    }
}

EventAttendee.propTypes = {
    imageUrl: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    location: PropTypes.string,
    initials: PropTypes.string,
    profilePath: PropTypes.string,
    onClick: PropTypes.func
};

EventAttendee.defaultProps = {
    imageUrl: '',
    name: '',
    title: '',
    location: '',
    initials: '',
    profilePath: '',
    onClick: () => false
};

export default EventAttendee;
