import React from 'react';
import PropTypes from 'prop-types';
import { Block } from '@webfox-sc/core';
import { useDownloads } from './useDownloads';
import ContentSection from '../../atoms/ContentSection';
import HeadlineTwo from '../elements/HeadlineTwo';
import DownloadsPanelWrapper from './DownloadsPanelWrapper';

const DownloadLists = ({ speakerSearch }) => {
  const { upcomingDownloadsSorted, pastDownloadsSorted } = useDownloads();

  return (
    <>
      <ContentSection paddingTop="30px">
        <HeadlineTwo text="Upcoming Events" />
        <Block paddingTop="30px">
          {upcomingDownloadsSorted.length > 0 ? (
            <Block>
              {upcomingDownloadsSorted.map((download, index) => {
                return (
                  <div id={`bt-${download.id}`} key={String(index)}>
                    <Block paddingBottom="15px">
                      <DownloadsPanelWrapper downloadId={download.id} speakerSearch={speakerSearch} />
                    </Block>
                  </div>
                );
              })}
            </Block>
          ) : (
            <Block>No results found</Block>
          )}
        </Block>
      </ContentSection>
      <ContentSection paddingTop="30px">
        <HeadlineTwo text="Past Events" />
        <Block paddingTop="30px">
          {pastDownloadsSorted.length > 0 ? (
            <Block>
              {pastDownloadsSorted.map((download, index) => {
                return (
                  <div id={`bt-${download.id}`} key={String(index)}>
                    <Block paddingBottom="15px">
                      <DownloadsPanelWrapper downloadId={download.id} speakerSearch={speakerSearch} />
                    </Block>
                  </div>
                );
              })}
            </Block>
          ) : (
            <Block>No results found</Block>
          )}
        </Block>
      </ContentSection>
    </>
  );
};

DownloadLists.propTypes = {
  speakerSearch: PropTypes.string,
};

DownloadLists.defaultProps = {
  speakerSearch: '',
};

export default DownloadLists;
