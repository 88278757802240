/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './section.scss';

class Section extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        const { paddingTop, children } = this.props;

        let className = 'bt-section';

        if (paddingTop === 'M') {
            className += ' bt-section--padding-top-m';
        }

        return <div className={className}>{children}</div>;
    }
}

Section.propTypes = {
    /**
     * @prop paddingTop
     * @type string
     * @values 'M'
     */
    paddingTop: PropTypes.string,

    /**
     * @prop children
     * @type any
     */
    children: PropTypes.any
};

Section.defaultProps = {
    paddingTop: '',
    children: null
};

export default Section;
