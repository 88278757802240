/* eslint-disable */
import axios from 'axios';
import storeModel from '../models/storeModel';
import { handleErrorResponse } from '../redux-actions/actionsApp';
import { apiUrl } from '../app/api';

class UserUtil {
  static async register(data) {
    try {
      const response = await axios({
        method: 'post',
        url: apiUrl + '/profile/registration',
        data: data,
      });
    } catch (error) {
      storeModel.dispatch(handleErrorResponse(error.response));
      throw error;
    }
  }

  static async confirmEmail(body) {
    try {
      const response = await axios({
        method: 'post',
        url: apiUrl + '/profile/registration/emailConfirmation',
        data: body,
      });
    } catch (error) {
      storeModel.dispatch(handleErrorResponse(error.response));
      throw error;
    }
  }

  static async initiatePasswordReset(email) {
    let body = {
      email: email,
    };
    try {
      const response = await axios({
        method: 'post',
        url: apiUrl + '/profile/password/reset',
        data: body,
      });
    } catch (error) {
      storeModel.dispatch(handleErrorResponse(error.response));
      throw error;
    }
  }

  static async resetPassword(key, password, passwordRepeat) {
    let body = {
      key: key,
      password: password,
      passwordRepeat: passwordRepeat,
    };
    try {
      const response = await axios({
        method: 'post',
        url: apiUrl + '/profile/password/',
        data: body,
      });
    } catch (error) {
      storeModel.dispatch(handleErrorResponse(error.response));
      throw error;
    }
  }
}

export default UserUtil;
