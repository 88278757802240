/* eslint-disable */
import React from 'react';

const IconVideoPlay = (
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M30 0C13.433 0 0 13.433 0 30C0 46.567 13.433 60 30 60C46.567 60 60 46.567 60 30C60 13.433 46.567 0 30 0ZM39.6496 30.4621L25.0246 41.1027C24.9444 41.1603 24.8499 41.1947 24.7514 41.2021C24.653 41.2095 24.5544 41.1896 24.4665 41.1446C24.3786 41.0996 24.3048 41.0313 24.2532 40.9471C24.2016 40.8629 24.1743 40.7661 24.1741 40.6674V19.3996C24.1738 19.3006 24.2009 19.2035 24.2523 19.1191C24.3038 19.0346 24.3777 18.966 24.4657 18.9209C24.5538 18.8758 24.6526 18.856 24.7513 18.8637C24.8499 18.8713 24.9445 18.9062 25.0246 18.9643L39.6496 29.5982C39.7186 29.6471 39.775 29.7118 39.8139 29.787C39.8528 29.8621 39.8731 29.9455 39.8731 30.0301C39.8731 30.1148 39.8528 30.1981 39.8139 30.2733C39.775 30.3485 39.7186 30.4132 39.6496 30.4621Z"
            fill="#2183CA"
        />
    </svg>
);

export default IconVideoPlay;
