/* eslint-disable */
import React from 'react';

const IconMenu40 = (
    <svg width="40" height="40" xmlns="http://www.w3.org/2000/svg">
        <path d="M33.095 29c.575 0 1.045.337 1.045.75 0 .414-.47.75-1.045.75H8.045C7.468 30.5 7 30.164 7 29.75c0-.413.468-.75 1.045-.75h25.05zm0-9c.575 0 1.045.337 1.045.75 0 .414-.47.75-1.045.75H8.045C7.468 21.5 7 21.164 7 20.75c0-.413.468-.75 1.045-.75h25.05zm0-10c.575 0 1.045.337 1.045.75 0 .414-.47.75-1.045.75H8.045C7.468 11.5 7 11.164 7 10.75c0-.413.468-.75 1.045-.75h25.05z" fill="currentColor" fillRule="evenodd" />
    </svg>
);

export default IconMenu40;
