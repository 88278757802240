import { createGlobalStyle } from 'styled-components';
import { colors } from './colors';
import { fontFamily } from './textVariants';

const GlobalStyle = createGlobalStyle(() => {
  return {
    html: {
      fontFamily,
      fontSize: '100%',
      WebkitTapHighlightColor: 'transparent',
    },
    body: {
      padding: 0,
      margin: 0,
      background: 'white',
      color: colors.btBlue,
    },
  };
});

export default GlobalStyle;
