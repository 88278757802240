/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import AppUtil from '../../utils/AppUtil';
import ImageThumb from '../elements/ImageThumb';
import CopyTextM from '../elements/CopyTextM';
import AdviceText from '../elements/AdviceText';
import Button from '../elements/Button';
import IconArrowUp12 from '../../assets/icons/IconArrowUp12';
import IconArrowDown12 from '../../assets/icons/IconArrowDown12';
import ShapeTriangle30x15 from '../../assets/shapes/ShapeTriangle30x15';
import './profile-entry.scss';

class ProfileEntry extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isActive: false
        };

        this.handleOnClickOutside = this.handleOnClickOutside.bind(this);
    }

    handleOnClickInfo() {
        if (!this.state.isActive) {
            document.addEventListener('mousedown', this.handleOnClickOutside);
        }

        this.setState({
            isActive: !this.state.isActive
        });
    }

    handleOnClickOutside(event) {
        if (this.refCanvas && !this.refCanvas.contains(event.target)) {
            this.setState({
                isActive: false
            });
            document.removeEventListener('mousedown', this.handleOnClickOutside);
        }
    }

    handleOnClickProfile(e) {
        e.preventDefault();
        const { onClickProfile } = this.props;
        onClickProfile();

        setTimeout(() => {
            this.setState({
                isActive: false
            });
        }, 500);
    }

    render() {
        const { isActive } = this.state;
        const { firstName, lastName, imgSrc, profilePath, showAdvice, adviceText, onClickLogout } = this.props;

        const classNameActive = isActive ? 'bt-profile-entry--active' : '';

        return (
            <div className={`bt-profile-entry ${classNameActive}`}>
                <div
                    className="bt-profile-entry__canvas"
                    ref={(node) => {
                        this.refCanvas = node;
                    }}
                >
                    <div className="bt-profile-entry__info">
                        <div className="bt-profile-entry__info-inner" onClick={() => this.handleOnClickInfo()}>
                            <div className="bt-profile-entry__info-name">
                                <CopyTextM text={`Hello ${firstName} ${lastName}`} />
                            </div>
                            <div className="bt-profile-entry__info-picture">
                                <ImageThumb
                                    size="S"
                                    profileInitials={AppUtil.getInitials(firstName, lastName)}
                                    imageSrc={imgSrc}
                                />
                            </div>
                            <div className="bt-profile-entry__info-icon">
                                {isActive ? IconArrowUp12 : IconArrowDown12}
                            </div>
                        </div>
                    </div>
                    {isActive && (
                        <div className="bt-profile-entry__options">
                            <div className="bt-profile-entry__options-inner">
                                <div className="bt-profile-entry__option">
                                    <Button
                                        layout="secondary"
                                        label="Profile"
                                        path={profilePath}
                                        onClick={(e) => this.handleOnClickProfile(e)}
                                    />
                                </div>
                                <div className="bt-profile-entry__option">
                                    <Button layout="secondary" label="Logout" onClick={onClickLogout} />
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {showAdvice && !isActive && (
                    <div className="bt-profile-entry__advice" onClick={(e) => this.handleOnClickProfile(e)}>
                        <AdviceText text={adviceText} />
                        <div className="bt-profile-entry__advice-triangle">{ShapeTriangle30x15}</div>
                    </div>
                )}
            </div>
        );
    }
}

ProfileEntry.propTypes = {
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    imgSrc: PropTypes.string,
    profilePath: PropTypes.string,
    showAdvice: PropTypes.bool,
    adviceText: PropTypes.string,
    onClickProfile: PropTypes.func,
    onClickLogout: PropTypes.func
};

ProfileEntry.defaultProps = {
    firstName: '',
    lastName: '',
    imgSrc: '',
    profilePath: '',
    showAdvice: false,
    adviceText: '',
    onClickProfile: () => false,
    onClickLogout: () => false
};

export default ProfileEntry;
