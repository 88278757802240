/* eslint-disable */
import React from 'react';

const IconArrowDown20 = (
    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
        <path fill="currentColor" fillRule="nonzero" d="M19.6346372 4.8L20 5.1665837 10 15.2 0 5.1665837.3653628 4.8 10 14.4668327z" />
    </svg>
);

export default IconArrowDown20;
