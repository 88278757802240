/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './label-l.scss';

class LabelL extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { text } = this.props;
        return <span className={`bt-label-l`} dangerouslySetInnerHTML={{ __html: text }} />;
    }
}

LabelL.propTypes = {
    text: PropTypes.string
};

LabelL.defaultProps = {
    text: ''
};

export default LabelL;
