/* eslint-disable */
import React from 'react';

const IconArrowRight10 = (
    <svg width="10" height="10" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.83333367 0L2.166667.66666667 6.50000033 5 2.166667 9.33333333 2.83333367 10l5-5z" fill="currentColor" fillRule="nonzero" />
    </svg>
);

export default IconArrowRight10;
