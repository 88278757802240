/* eslint-disable */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useResponsive } from '../../hooks/responsive';
import Carousel from '../../atoms/Carousel/Carousel';
import iconClose from '../../assets/icons/IconVideoClose';
import iconDownload from '../../assets/icons/IconDownloadImage';

const Container = styled.div(({ showLayer }) => {
  return {
    position: 'fixed',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    transition: '0.5s ease-in-out 0s',
    opacity: showLayer ? 1 : 0,
    zIndex: 100,
  };
});

const ImageContainer = styled.div({
  height: '500px',
  overflow: 'hidden',
  img: { width: '100%', height: '100%', objectFit: 'cover' },
});

const InfoContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  paddingTop: '70px',
  width: '100%',
  overflow: 'hidden',
});

const TextContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  p: {
    marginTop: 0,
    marginRight: '15px',
  },
});

const Layer = styled.div(({ isMobile }) => {
  return {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    background: 'rgba(255, 255, 255, 0.8)',
  };
});

const LayerInner = styled.div(({ isMobile }) => {
  return {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    marginRight: isMobile ? '5%' : '20%',
    marginLeft: isMobile ? '5%' : '20%',
    marginTop: '5%',
    overflow: 'hidden',
  };
});
const Slide = styled.div({
  overflow: 'hidden',
  position: 'relative',
});

const CloseButton = styled.div({
  position: 'absolute',
  top: '5%',
  right: '5%',
  cursor: 'pointer',
  svg: {},
});
const DownloadButton = styled.a({
  cursor: 'pointer',
  img: {},
});

const ImageLayer = (props) => {
  const [showLayer, setShowLayer] = useState(false);
  const { isMobile } = useResponsive();
  const { data, closeOverlay, slideIndex } = props;
  useEffect(() => {
    // code to run on component mount for animating opacity
    setShowLayer(true);
  }, []);

  let sliderDimensions = {
    slideWidth: '100%',
    slideWidthActive: '100%',
    gap: '30px',
  };

  return (
    <Container showLayer={showLayer}>
      <Layer isMobile={isMobile} onClick={() => closeOverlay()} />

      <LayerInner isMobile={isMobile}>
        <Carousel dimensions={sliderDimensions} log={false} infinite startIndex={slideIndex}>
          {data.map((item, i) => {
            const caption = item.caption;
            const description = item.description;

            return (
              <Slide key={String(i)}>
                <ImageContainer>
                  <CloseButton onClick={() => closeOverlay()}>{iconClose}</CloseButton>
                  <img src={item.url} />
                </ImageContainer>
                <InfoContainer>
                  <TextContainer>
                    <p>{caption}</p>
                    <p>{description}</p>
                  </TextContainer>
                  <DownloadButton target="_blank" href={item.url}>
                    {iconDownload}
                  </DownloadButton>
                </InfoContainer>
              </Slide>
            );
          })}
        </Carousel>
      </LayerInner>
    </Container>
  );
};

ImageLayer.propTypes = {
  data: PropTypes.array,
  closeOverlay: PropTypes.func,
  slideIndex: PropTypes.number,
};

ImageLayer.defaultProps = {
  data: [],
  closeOverlay: () => false,
  slideIndex: 0,
};

export default ImageLayer;
