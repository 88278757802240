/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './toggle-box.scss';

class ToggleBox extends Component {
    constructor(props) {
        super(props);

        this.initialized = false;
        this.slideDownTimeoutId = null;
        this.slideUpTimeoutId = null;
    }

    slideDown() {
        const element = this.elComponent;
        const wrapper = this.elWrapper;

        if (!element || !wrapper) {
            return false;
        }

        const wrapperHeight = wrapper.clientHeight;

        if (element.clientHeight === wrapperHeight) {
            element.style.maxHeight = 'none';
            return false;
        }

        element.style.maxHeight = `${wrapperHeight}px`;

        this.slideDownTimeoutId = window.setTimeout(() => {
            element.style.maxHeight = 'none';
            element.style.overflow = 'visible';
        }, 250);

        return true;
    }

    slideUp() {
        window.clearTimeout(this.slideDownTimeoutId);

        const element = this.elComponent;
        const wrapper = this.elWrapper;

        if (!element || !wrapper) {
            return false;
        }

        const wrapperHeight = wrapper.clientHeight;

        if (element.clientHeight === 0) {
            element.style.maxHeight = 0;
            return false;
        }

        element.style.maxHeight = `${wrapperHeight}px`;
        element.style.overflow = 'hidden';

        this.slideUpTimeoutId = setTimeout(() => {
            element.style.maxHeight = 0;
        }, 1);

        return true;
    }

    render() {
        const { show, children } = this.props;
        const cssVisibility = show ? '' : 'bt-toggle-box--hidden';

        if (show) {
            this.slideDown();
        } else {
            this.slideUp();
        }

        return (
            <div
                className={`bt-toggle-box ${cssVisibility}`}
                ref={(node) => {
                    this.elComponent = node;
                }}
            >
                <div
                    ref={(node) => {
                        this.elWrapper = node;
                    }}
                >
                    {children}
                </div>
            </div>
        );
    }
}

ToggleBox.propTypes = {
    show: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

ToggleBox.defaultProps = {
    show: false,
    children: null
};

export default ToggleBox;
