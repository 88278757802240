/* eslint-disable */
import React from 'react';

const IconArrowLeft12 = (
    <svg width='8' height='12'>
        <path
            d='M7.24 11.492L6.734 12 .76 6l5.974-6 .506.508L1.771 6z'
            fill='currentColor'
            fillRule='evenodd'
        />
    </svg>
);

export default IconArrowLeft12;
