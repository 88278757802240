/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './button-primary.scss';

class ButtonPrimary extends Component {
    // constructor(props) {
    //     super(props);
    // }

    render() {
        const { label, disabled, onClick } = this.props;

        return (
            <button type="button" className="button-primary" disabled={disabled} onClick={onClick}>
                <span className="button-primary__label" dangerouslySetInnerHTML={{ __html: label }} />
            </button>
        );
    }
}

ButtonPrimary.propTypes = {
    label: PropTypes.string,
    disabled: PropTypes.bool,
    onClick: PropTypes.func
};

ButtonPrimary.defaultProps = {
    label: '',
    disabled: false,
    onClick: () => {}
};

export default ButtonPrimary;
