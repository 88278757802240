import React from 'react';

const IconVideoLeft = (
  <svg width="21" height="40" viewBox="0 0 21 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M20.7695 38.3071L19.1487 40L0.000300487 20L19.1487 -7.085e-08L20.7695 1.69295L3.24202 20L20.7695 38.3071Z"
      fill="#2183CA"
    />
  </svg>
);

export default IconVideoLeft;
