/* eslint-disable */
import React from 'react';

const IconPhone20 = (
    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="evenodd">
            <path d="M15.142 0H4.858C4.164 0 3.6.568 3.6 1.266v17.468c0 .698.564 1.266 1.258 1.266h10.284c.694 0 1.258-.568 1.258-1.266V1.266C16.4.568 15.836 0 15.142 0zM4.23 16.41h11.54V3.181H4.23V16.41zM4.858.634h10.284a.63.63 0 0 1 .628.631V2.76H4.23V1.266a.63.63 0 0 1 .628-.631zm10.284 18.73H4.858a.63.63 0 0 1-.628-.631v-1.902h11.54v1.902a.63.63 0 0 1-.628.631z" />
            <path d="M10 17.113c.57 0 1.032.466 1.032 1.039S10.57 19.19 10 19.19c-.57 0-1.032-.466-1.032-1.04 0-.572.463-1.038 1.032-1.038zm0 1.655a.615.615 0 0 0 .612-.616.615.615 0 0 0-.612-.616.615.615 0 0 0-.612.616c0 .34.274.616.612.616zM8.571 2.015h2.858c.116 0 .21-.094.21-.211a.21.21 0 0 0-.21-.212H8.571a.21.21 0 0 0-.21.212c0 .117.094.211.21.211zM7.815 2.015a.21.21 0 0 0 .21-.211.21.21 0 1 0-.42 0 .21.21 0 0 0 .21.212z" />
        </g>
    </svg>
);

export default IconPhone20;
