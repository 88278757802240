/* eslint-disable */
import { REDUX_ACTIONS } from '../global/constants';

function appReducer(
  state = {
    pathname: '',
    previousPathname: '',
    pageId: '',
    contentId: '',
    previousContentId: '',
    subContentId: '',
    baseData: {},
    isRequestingBaseData: false,
    baseDataEventType: {},
    baseDataEventDate: {},
    baseDataLinks: {},
    showErrorDialog: false,
    errorStatus: undefined,
    errorResponse: undefined,
    showProfileImageOverlay: false,
  },
  action
) {
  switch (action.type) {
    case REDUX_ACTIONS.LOCATION_CHANGED:
      return Object.assign({}, state, {
        pathname: action.pathname,
        previousPathname: state.pathname,
        pageId: action.pageId,
        contentId: action.contentId,
        previousContentId: state.contentId,
        subContentId: action.subContentId,
        showErrorDialog: false,
      });

    case REDUX_ACTIONS.REQUEST_BASE_DATA:
      return Object.assign({}, state, {
        isRequestingBaseData: true,
      });

    case REDUX_ACTIONS.REQUEST_BASE_DATA_ERROR:
      return Object.assign({}, state, {
        isRequestingBaseData: false,
      });

    case REDUX_ACTIONS.RECIEVE_BASE_DATA:
      return Object.assign({}, state, {
        baseData: action.baseData,
        baseDataEventType: action.baseData.event.type,
        baseDataEventDate: action.baseData.event.date,
        baseDataCountry: action.baseData.profile.country,
        baseDataSeason: action.baseData.profile.season,
        baseDataTitleLeft: action.baseData.texts.registration.left.title,
        baseDataTitleRight: action.baseData.texts.registration.right.title,
        baseDataTextLeft: action.baseData.texts.registration.left.text,
        baseDataTextRight: action.baseData.texts.registration.right.text,
        baseDataLinks: action.baseDataLinks,
        isRequestingBaseData: false,
      });

    case REDUX_ACTIONS.REQUEST_ERROR:
      return Object.assign({}, state, {
        showErrorDialog: action.showErrorDialog,
        errorStatus: action.errorStatus,
        errorResponse: action.errorResponse,
      });

    case REDUX_ACTIONS.HIDE_ERROR_DIALOG:
      return Object.assign({}, state, {
        showErrorDialog: false,
        errorStatus: undefined,
        errorResponse: undefined,
      });

    case REDUX_ACTIONS.SHOW_PROFILE_IMAGE_OVERLAY:
      return Object.assign({}, state, {
        showProfileImageOverlay: true,
      });

    case REDUX_ACTIONS.HIDE_PROFILE_IMAGE_OVERLAY:
      return Object.assign({}, state, {
        showProfileImageOverlay: false,
      });

    default:
      return state;
  }
}

export default appReducer;
