/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './page-title.scss';

class PageTitle extends Component {
    // constructor(props) {
    //     super(props);
    // }

    handleOnClick(e) {
        e.preventDefault();
        const { onClick } = this.props;
        onClick('/');
    }

    render() {
        const { mobile } = this.props;
        const className = mobile ? 'bt-page-title bt-page-title--mobile' : 'bt-page-title';

        return (
            <a className={className} href="/" onClick={(e) => this.handleOnClick(e)}>
                <div className="bt-page-title__main">BIOTRONIK</div>
                <div className="bt-page-title__sub">Electrophysiology Education Program</div>
            </a>
        );
    }
}

PageTitle.propTypes = {
    mobile: PropTypes.bool,
    onClick: PropTypes.func
};

PageTitle.defaultProps = {
    mobile: false,
    onClick: () => false
};

export default PageTitle;
