import React from 'react';
import { useEvent } from '../events/useEvent';
import NewsfeedEventTeaser from '../NewsfeedEventTeaser/NewsfeedEventTeaser';
import { useOverlayContext } from '../overlay/OverlayProvider';

interface NewsfeedEventTeaserWrapperProps {
  // TODO REF
  event: any; // event model !!
  onToggleAttend: () => void;
  onClickShowDocuments: () => void;
}

const NewsfeedEventTeaserWrapper: React.FC<NewsfeedEventTeaserWrapperProps> = ({
  event,
  onToggleAttend,
  onClickShowDocuments,
}) => {
  const { overlayNavigate } = useOverlayContext();

  const {
    eventCategoryFormatted,
    eventDateFormatted,
    eventTimeFormatted,
    eventCoverPictureMediumLarge,
    eventAttendanceType,
    eventIsDigital,
  } = useEvent(event);

  if (!event) {
    return null;
  }

  const handleOnClickDetails = (e) => {
    e.preventDefault();
    overlayNavigate(`/news/event/${event.id}`);
  };

  return (
    <NewsfeedEventTeaser
      id={event.id}
      attendees={event.attendees}
      isAttending={event.isAttending}
      category={eventCategoryFormatted}
      title={event.title}
      subTitle={event.subTitle}
      date={eventDateFormatted}
      time={eventTimeFormatted}
      detailsPath={`/news/event/${event.id}`}
      eventPath={`/news/event/${event.id}`}
      onClickDetails={handleOnClickDetails}
      onClickShowDocuments={onClickShowDocuments}
      canAttend={event.canAttend}
      imgSrc={eventCoverPictureMediumLarge}
      onToggleAttend={onToggleAttend}
      attendanceType={eventAttendanceType}
      isDigital={eventIsDigital}
      isPast={event.status === 'past'}
    />
  );
};

export default NewsfeedEventTeaserWrapper;
