/* eslint-disable */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Button from '../../components/elements/Button';
import LabelS from '../../components/elements/LabelS';
import HeadlineThree from '../../components/elements/HeadlineThree';
import CopyTextM from '../../components/elements/CopyTextM';
import IconSites17 from '../../assets/icons/IconSites17';
import IconVideo17 from '../../assets/icons/IconVideo17';
import IconPdf17 from '../../assets/icons/IconPdf17';
import IconImage17 from '../../assets/icons/IconImage17';
import IconArrowRight10 from '../../assets/icons/IconArrowRight10';
import IconTag12 from '../../assets/icons/IconTag12';
import IconDownloads20 from '../../assets/icons/IconDownloads20';
import './SearchTeaser.scss';

class SearchTeaser extends React.Component {
  constructor(props) {
    super(props);
  }

  handleClickButton(e, path) {
    if (this.props.category !== 'downloads') {
      e.preventDefault();

      const { onClickButton } = this.props;
      onClickButton(path);
    }
  }

  render() {
    const { category, categoryLabel, breadcrumb, imageSrc, title, text, tags, buttonPath } = this.props;

    let categoryIcon = IconSites17;
    if (category === 'pdf') {
      categoryIcon = IconPdf17;
    } else if (category === 'video') {
      categoryIcon = IconVideo17;
    } else if (category === 'picture') {
      categoryIcon = IconImage17;
    }

    let showButton = false;

    if (buttonPath) {
      showButton = true;
    }

    const isTeaserBottom = tags.length !== 0 && showButton;

    let className = 'bt-search-teaser';
    if (isTeaserBottom) {
      className += ' bt-search-teaser--extended';
    }
    let buttonLabel = 'Download';
    if (category === 'events') {
      buttonLabel = 'Go to event';
    }
    if (category === 'fellows') {
      buttonLabel = 'Go to fellow';
    }

    return (
      <div className={className}>
        <div className="bt-search-teaser__top">
          <div className="bt-search-teaser__top-header">
            <div className="bt-search-teaser__top-header-category">
              {categoryIcon}
              <div className="bt-search-teaser__top-header-category-label">
                <LabelS text={categoryLabel} />
              </div>
            </div>
            <div className="bt-search-teaser__top-header-breadcrumb">
              {breadcrumb.map((breadcrumbItem, i) => {
                return (
                  <Fragment key={i}>
                    {i !== 0 && <div className="bt-search-teaser__top-header-breadcrumb-arrow">{IconArrowRight10}</div>}
                    <div className="bt-search-teaser__top-header-breadcrumb-item">
                      <LabelS text={breadcrumbItem} />
                    </div>
                  </Fragment>
                );
              })}
            </div>
          </div>
          <div className="bt-search-teaser__top-info">
            {imageSrc && (
              <div className="bt-search-teaser__top-image">
                <img src={imageSrc} alt="" />
              </div>
            )}
            <div>
              <div className="bt-search-teaser__top-title">
                <HeadlineThree text={text} />
              </div>
              <div className="bt-search-teaser__top-text">
                <CopyTextM text={text} />
              </div>
            </div>
          </div>
        </div>
        <div className="bt-search-teaser__bottom">
          <div className="bt-search-teaser__bottom-tags">
            {tags.map((tag, i) => {
              return (
                <div key={i} className="bt-search-teaser__bottom-tag">
                  <div>{IconTag12}</div>
                  <div className="bt-search-teaser__bottom-tag-label">
                    <LabelS text={tag} />
                  </div>
                </div>
              );
            })}
          </div>
          {showButton && (
            <Fragment>
              <div className="bt-search-teaser__bottom-button-desktop">
                <Button
                  layout="primary-side"
                  label={buttonLabel}
                  path={buttonPath}
                  link
                  target={category === 'downloads' ? '_blank' : ''}
                  onClick={(e) => this.handleClickButton(e, buttonPath)}
                />
              </div>
              <div className="bt-search-teaser__bottom-button-mobile">
                <Button
                  layout="primary-side-small"
                  iconInfo={IconDownloads20}
                  path={buttonPath}
                  link
                  onClick={(e) => this.handleClickButton(e, buttonPath)}
                />
              </div>
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

SearchTeaser.propTypes = {
  category: PropTypes.string,
  categoryLabel: PropTypes.string,
  breadcrumb: PropTypes.array,
  imageSrc: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  tags: PropTypes.array,
  showButton: PropTypes.bool,
  buttonPath: PropTypes.string,
  onClickButton: PropTypes.func,
};

SearchTeaser.defaultProps = {
  category: '',
  categoryLabel: '',
  breadcrumb: [],
  imageSrc: '',
  title: '',
  text: '',
  tags: [],
  showButton: false,
  buttonPath: '',
  onClickButton: () => false,
};

export default SearchTeaser;
