import React from 'react';
import { useNavigate } from 'react-router-dom';
import NewsFeedBox from './NewsFeedBox';

interface NewsFeedBoxEventsProps {
  children?: React.ReactNode;
}

const NewsFeedBoxEvents: React.FC<NewsFeedBoxEventsProps> = ({ children }) => {
  const navigate = useNavigate();

  return (
    <NewsFeedBox
      title="Events"
      showButton
      buttonLabel="See all events"
      buttonPath="/events"
      onClickButton={() => navigate('/events')}
    >
      {children}
    </NewsFeedBox>
  );
};

export default NewsFeedBoxEvents;
