/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './copy-text-m.scss';

class CopyTextM extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { text, blue, grey, heavy, bold } = this.props;

        let classNameColor = '';
        if (blue) {
            classNameColor = 'bt-copy-text-m--blue';
        } else if (grey) {
            classNameColor = 'bt-copy-text-m--grey';
        }

        return (
            <span
                className={`bt-copy-text-m ${classNameColor} ${heavy && 'bt-copy-text-m--heavy'} ${
                    bold && 'bt-copy-text-m--bold'
                }`}
                dangerouslySetInnerHTML={{ __html: text }}
            />
        );
    }
}

CopyTextM.propTypes = {
    text: PropTypes.string,
    blue: PropTypes.bool,
    grey: PropTypes.bool,
    heavy: PropTypes.bool,
    bold: PropTypes.bool
};

CopyTextM.defaultProps = {
    text: '',
    blue: false,
    grey: false,
    heavy: false,
    bold: false
};

export default CopyTextM;
