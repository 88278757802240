export const screens = {
  mobile: '0px',
  mobileLandscape: '480px',
  tablet: '768px',
  tabletLandscape: '1024px',
  desktop: '1440px',
};

export const mediaQueries = {
  mobile: '@media (min-width: 0px)',
  mobileLandscape: '@media (min-width: 480px)',
  tablet: '@media (min-width: 768px)',
  tabletLandscape: '@media (min-width: 1024px)',
  desktop: '@media (min-width: 1440px)',
};
