import React, { useEffect } from 'react';

import styled from 'styled-components';
import IconLink20 from '../../assets/icons/IconLink20';

import NewsfeedUtil from '../../utils/NewsfeedUtil';
import LayerViewHeader from '../overlay/LayerViewHeader';
import Button from '../elements/Button';
import CopyTextM from '../elements/CopyTextM/CopyTextM';
import HeadlineTwo from '../elements/HeadlineTwo';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppSelector } from '../../app/hooks';

const Section = styled.div({
  paddingBottom: 30,
});

const HeaderImage = styled.img({
  width: '100%',
  marginBottom: '30px',
});

const LayerViewNews: React.FC = () => {
  const navigate = useNavigate();
  const { sNewsId } = useParams();

  useEffect(() => {
    NewsfeedUtil.fetchNews(sNewsId);
  }, [sNewsId]);

  const activeNewsData = useAppSelector(({ newsfeedState }) => newsfeedState.activeNewsData);

  const copy = activeNewsData.copy || '';
  const headline = activeNewsData.headline || '';
  const intro = activeNewsData.intro || '';
  const cta = activeNewsData.cta || '';
  const ctaText = activeNewsData.ctaText || '';
  const headerImageData = activeNewsData.image || {};
  const headerImage = headerImageData.url || '';

  // case: event
  const event = activeNewsData.event || {};
  const eventId = event.id || '';
  // case: file
  const file = activeNewsData.file || {};
  const fileUrl = file.url || '';
  // case: url
  const url = activeNewsData.url || '';
  const urlIsExternal = activeNewsData.externalUrl || false;

  const handleClick = (e) => {
    // case: url
    if (cta === 'url') {
      window.open(url, '_self');
    }
    // case: file
    if (cta === 'file') {
      window.open(fileUrl, '_blank');
    }

    // case: event
    if (cta === 'event') {
      e.preventDefault();
      return navigate('/news/event/' + eventId);
    }
  };
  const handleClickExternal = () => {
    if (cta === 'url') {
      window.open(url, '_blank');
    }
  };

  return (
    <div>
      <LayerViewHeader>
        <HeaderImage src={headerImage} alt="" />
        <Section>
          <HeadlineTwo text={headline} />
        </Section>
        <Section>
          <CopyTextM text={intro} />
        </Section>
        <Section>
          <CopyTextM text={copy} />
        </Section>

        {ctaText && urlIsExternal ? (
          <Button
            path={url}
            label={ctaText}
            iconLeft={IconLink20}
            onClick={() => {
              handleClickExternal();
            }}
          />
        ) : (
          <Button
            layout="secondary"
            path="#"
            label={ctaText}
            onClick={(e: any) => {
              handleClick(e);
            }}
          />
        )}
      </LayerViewHeader>
    </div>
  );
};

export default LayerViewNews;
