/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './news-feed-box-swipe.scss';

class NewsFeedBoxSwipe extends Component {
    constructor(props) {
        super(props);

        this.state = {
            itemsLeft: 0,
            itemsLeftCSS: 0,
            isMoving: false,
            currentIndex: 0
        };

        this.itemWidth = 0;
        this.allItemsWidth = 0;
        this.itemsLeftStart = 0;
        this.clientXStart = 0;
        this.isTouched = false;
        this.swipeDirection = '';
        this.nextIndex = 0;
    }

    handleSwipeStart(clientX) {
        const { children } = this.props;
        const itemsWidth = this.refItems.offsetWidth;
        this.itemWidth = itemsWidth / 10;
        this.allItemsWidth = this.itemWidth * children.length;

        const itemsPos = this.refItems.getBoundingClientRect();
        const containerPos = this.refContainer.getBoundingClientRect();
        const itemsLeft = itemsPos.left - containerPos.left;

        this.itemsLeftStart = itemsLeft;
        this.clientXStart = clientX;
        this.isTouched = true;
        this.swipeDirection = '';

        const { currentIndex } = this.state;
        this.nextIndex = currentIndex;

        this.setState({
            itemsLeft,
            itemsLeftCSS: itemsLeft + 'px'
        });
    }

    handleSwipeMove(clientX) {
        if (this.isTouched) {
            let itemsLeftNew = this.itemsLeftStart + clientX - this.clientXStart;
            if (itemsLeftNew > 0) {
                itemsLeftNew = 0;
            } else if (itemsLeftNew < this.itemWidth - this.allItemsWidth) {
                itemsLeftNew = this.itemWidth - this.allItemsWidth;
            }

            const { itemsLeft } = this.state;
            if (itemsLeftNew !== itemsLeft) {
                if (itemsLeftNew < itemsLeft) {
                    if (this.swipeDirection !== 'LEFT') {
                        this.nextIndex = this.nextIndex + 1;
                        this.swipeDirection = 'LEFT';
                    }
                } else {
                    if (this.swipeDirection !== 'RIGHT') {
                        this.nextIndex = this.nextIndex - 1;
                        this.swipeDirection = 'RIGHT';
                    }
                }
            }

            this.setState({
                itemsLeft: itemsLeftNew,
                itemsLeftCSS: itemsLeftNew + 'px',
                isMoving: true
            });
        }
    }

    handleSwipeEnd() {
        const { isMoving } = this.state;
        if (isMoving) {
            const { itemsLeft, currentIndex } = this.state;

            if (itemsLeft !== this.itemsLeftStart) {
                const distance = Math.abs(itemsLeft - this.itemsLeftStart);
                if (distance < 50) {
                    this.nextIndex = currentIndex;
                }

                this.setState({
                    itemsLeftCSS: '-' + this.nextIndex * 100 + '%',
                    isMoving: false,
                    currentIndex: this.nextIndex
                });
            }
        }

        this.isTouched = false;
    }

    handleMouseDown(e) {
        e.preventDefault();
        this.handleSwipeStart(event.clientX);
    }

    handleMouseMove(e) {
        this.handleSwipeMove(e.clientX);
    }

    handleMouseUp() {
        this.handleSwipeEnd();
    }

    handleMouseLeave() {
        this.handleSwipeEnd();
    }

    handleTouchStart(e) {
        this.handleSwipeStart(e.targetTouches[0].clientX);
    }

    handleTouchMove(e) {
        e.preventDefault();
        this.handleSwipeMove(e.targetTouches[0].clientX);
    }

    handleTouchEnd() {
        this.handleSwipeEnd();
    }

    render() {
        const { contentMaxHeight, children } = this.props;

        // -- if there is no child
        if (!children) {
            return null;
        }

        // -- if there is only one child
        if (!Array.isArray(children)) {
            return <div>{children}</div>;
        }

        // -- if there are several children
        const { itemsLeftCSS, isMoving, currentIndex } = this.state;

        const styleItems = {
            left: itemsLeftCSS
        };

        let className = 'bt-news-feed-box-swipe';
        if (isMoving) {
            className += ' bt-news-feed-box-swipe--moving';
        }

        // -- container style
        const containerStyle = {};
        if (contentMaxHeight) {
            containerStyle.maxHeight = contentMaxHeight;
        }

        return (
            <div className={className}>
                <div
                    ref={(node) => {
                        this.refContainer = node;
                    }}
                    className="bt-news-feed-box-swipe__container"
                    style={containerStyle}
                    onMouseDown={(e) => this.handleMouseDown(e)}
                    onMouseMove={(e) => this.handleMouseMove(e)}
                    onMouseUp={() => this.handleMouseUp()}
                    onMouseLeave={(e) => this.handleMouseLeave(e)}
                    onTouchStart={(e) => this.handleTouchStart(e)}
                    onTouchMove={(e) => this.handleTouchMove(e)}
                    onTouchEnd={() => this.handleTouchEnd()}
                >
                    <div
                        ref={(node) => {
                            this.refItems = node;
                        }}
                        className="bt-news-feed-box-swipe__items"
                        style={styleItems}
                    >
                        {children.map((child, i) => {
                            return (
                                <div key={i} className="bt-news-feed-box-swipe__item">
                                    {child}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="bt-news-feed-box-swipe__dots">
                    {children.map((child, i) => {
                        let classNameDot = 'bt-news-feed-box-swipe__dot';
                        if (i === currentIndex) {
                            classNameDot += ' bt-news-feed-box-swipe__dot--active';
                        }
                        return <div key={i} className={classNameDot} />;
                    })}
                </div>
            </div>
        );
    }
}

NewsFeedBoxSwipe.propTypes = {
    contentMaxHeight: PropTypes.string,
    children: PropTypes.any
};

NewsFeedBoxSwipe.defaultProps = {
    contentMaxHeight: null,
    children: null
};

export default NewsFeedBoxSwipe;
