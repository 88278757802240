/* eslint-disable */
import React from 'react';

const IconMetaTwitter14 = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
    >
        <g fill="currentColor" fillRule="evenodd">
            <path d="M12.49,3.84l-.14.05v.42c0,4.45-3.21,8.08-7.13,8.08a10.3,10.3,0,0,1-4.39-.76,5.55,5.55,0,0,0,3.32-1.25l.6-.44H4A2.62,2.62,0,0,1,1.37,8.62a3.89,3.89,0,0,0,1.38.12,5.43,5.43,0,0,1,.57,0l.07-.49A4.35,4.35,0,0,1,.9,6a3.48,3.48,0,0,0,1.47.27h.74l-.6-.45A3,3,0,0,1,1.25,2.3,9.31,9.31,0,0,0,6.84,5l.28,0V4.4A2.71,2.71,0,0,1,9.69,1.61a4.28,4.28,0,0,1,1.71.64.55.55,0,0,0,.43,0l1.26-.49a4.16,4.16,0,0,1-.88,1,.28.28,0,0,0-.07.34.23.23,0,0,0,.31.12L13.37,3A1.65,1.65,0,0,1,12.49,3.84Zm1.43-1.4a.33.33,0,0,0-.29-.07l-.47.17a3.48,3.48,0,0,0,.59-1,.27.27,0,0,0,0-.3.26.26,0,0,0-.29,0,17.63,17.63,0,0,1-1.71.68s0,0-.05,0a4.82,4.82,0,0,0-2-.71,3.2,3.2,0,0,0-3,3.28v.1a8.54,8.54,0,0,1-5.3-2.82l-.24-.24L1,1.73a3.38,3.38,0,0,0,.69,4,1.93,1.93,0,0,1-1-.44.27.27,0,0,0-.33,0,.3.3,0,0,0-.12.31A4.8,4.8,0,0,0,2.3,8.25,1.89,1.89,0,0,1,1.16,8,.33.33,0,0,0,.83,8a.27.27,0,0,0-.1.32,2.88,2.88,0,0,0,2.59,2.08,5.7,5.7,0,0,1-3,.79.32.32,0,0,0-.31.22.34.34,0,0,0,.09.36c.86.71,2.76,1.13,5.11,1.13,4.18,0,7.61-3.85,7.61-8.57V4.23A2.4,2.4,0,0,0,14,2.74.36.36,0,0,0,13.92,2.44Z" />
        </g>
    </svg>
);

export default IconMetaTwitter14;
