import axios from 'axios';
import FellowModel from '../models/FellowModel';
import EventModel from '../models/EventModel';
import storeModel from '../models/storeModel';
import { handleErrorResponse } from '../redux-actions/actionsApp';
import {
  updateFellowModels,
  updateEventModels,
  updateFellowStatusModels,
  updateDownloadModels,
  updateIsNewsfeedLoading,
  updateIsNewsfeedLoaded,
  updateNewsTeaserData,
  updateNews,
} from '../redux-actions/actionsNewsfeed';
import DownloadModel from '../models/DownloadModel';
import { apiUrl } from '../app/api';

class NewsfeedUtil {
  static createFellowModelsNewsfeed(fellowsData) {
    const fellowModels = [];
    fellowsData.forEach((data) => {
      fellowModels.push(new FellowModel(data));
    });
    storeModel.dispatch(updateFellowModels(fellowModels));
  }

  static createEventModelsNewsfeed(eventsData) {
    const eventModels = [];
    eventsData.forEach((data) => {
      eventModels.push(new EventModel(data));
    });
    storeModel.dispatch(updateEventModels(eventModels));
  }

  static createFellowModelsStatus(fellowsData) {
    const fellowModels = [];
    fellowsData.forEach((data) => {
      fellowModels.push(new FellowModel(data));
    });
    storeModel.dispatch(updateFellowStatusModels(fellowModels));
  }

  static createDownloadModelsNewsfeed(downloadsData) {
    const downloadModels = [];
    downloadsData.forEach((data) => {
      downloadModels.push(new DownloadModel(data));
    });
    storeModel.dispatch(updateDownloadModels(downloadModels));
  }

  static async loadNewsfeed() {
    storeModel.dispatch(updateIsNewsfeedLoading(true));

    try {
      const { authState } = storeModel.state;
      const { userToken } = authState;

      const response = await axios({
        method: 'get',
        url: `${apiUrl}/newsfeed`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });

      NewsfeedUtil.createFellowModelsNewsfeed(response.data.data.fellows);
      NewsfeedUtil.createEventModelsNewsfeed(response.data.data.events);
      NewsfeedUtil.createFellowModelsStatus(response.data.data.status);
      NewsfeedUtil.createDownloadModelsNewsfeed(response.data.data.downloads);

      storeModel.dispatch(updateNewsTeaserData(response.data.data.teaser));
      storeModel.dispatch(updateIsNewsfeedLoading(false));
      storeModel.dispatch(updateIsNewsfeedLoaded(false));
    } catch (error) {
      storeModel.dispatch(updateIsNewsfeedLoading(false));
      storeModel.dispatch(handleErrorResponse(error.response));
    }
  }

  static async fetchNews(newsId) {
    try {
      const { authState } = storeModel.state;
      const { userToken } = authState;

      const response = await axios({
        method: 'get',
        url: `${apiUrl}/news/${newsId}`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      storeModel.dispatch(updateNews(response.data.data));
    } catch (error) {
      storeModel.dispatch(handleErrorResponse(error.response));
    }
  }

  static async loadAllStatuses() {
    try {
      const { authState } = storeModel.state;
      const { userToken } = authState;

      const response = await axios({
        method: 'get',
        url: `${apiUrl}/newsfeed/status`,
        headers: {
          Authorization: `Bearer ${userToken}`,
        },
      });
      NewsfeedUtil.createFellowModelsStatus(response.data.data);
    } catch (error) {
      storeModel.dispatch(handleErrorResponse(error.response));
    }
  }
}

export default NewsfeedUtil;
