import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import NewsFeedBox from '../news/NewsFeedBox';
import Tweet from './Tweet';
import { fetchTweets } from '../../redux-actions/actionsNewsfeed';

const NewsFeedBoxTweets = () => {
  const dispatch = useDispatch();
  const tweets = useSelector(({ newsfeedState }) => newsfeedState.tweets);
  const [tweetLoadedIndex, setTweetLoadedIndex] = useState(-1);

  useEffect(() => {
    dispatch(fetchTweets());
  }, [dispatch]);

  return (
    <NewsFeedBox title="Feed" contentMaxHeight="800px">
      {tweets.map((tweet, index) => {
        return (
          <Tweet
            key={tweet.tweetId}
            tweetId={tweet.tweetId}
            index={index}
            show={index <= tweetLoadedIndex + 1}
            onLoad={() => setTweetLoadedIndex(index)}
          />
        );
      })}
    </NewsFeedBox>
  );
};

NewsFeedBoxTweets.propTypes = {};
NewsFeedBoxTweets.defaultProps = {};

export default NewsFeedBoxTweets;
