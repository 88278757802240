/* eslint-disable */
import React from 'react';

const IconDownloads25 = (
    <svg width="25" height="25" xmlns="http://www.w3.org/2000/svg">
        <path d="M13.028 4.704l-.12-.004V.669a.42.42 0 0 0-.416-.419.42.42 0 0 0-.417.419v4.039l-.117.008C6.659 5.064 2.5 9.492 2.5 14.84 2.5 20.443 7.042 25 12.625 25S22.75 20.443 22.75 14.841c0-5.447-4.308-9.922-9.722-10.137zm5.573 8.375a.417.417 0 1 0-.589-.592l-5.105 5.122V5.535l.131.006c4.949.219 8.878 4.318 8.878 9.3 0 5.14-4.168 9.322-9.291 9.322S3.334 19.98 3.334 14.84c0-4.88 3.775-8.93 8.607-9.288l.134-.01V17.61l-.214-.214-4.893-4.907a.416.416 0 1 0-.588.592l6.111 6.13.295-.296a.059.059 0 0 1 .017-.017l5.798-5.818z" fill="currentColor" fillRule="nonzero"/>
    </svg>
);

export default IconDownloads25;
