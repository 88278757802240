import React, { useState } from 'react';
import PageContentHeader from '../PageContentHeader';
import SearchFilter from './SearchFilter';
import SearchTeaser from '../../molecules/SearchTeaser/SearchTeaser';
import CopyTextM from '../elements/CopyTextM';
import Link from '../elements/Link';
import { useAppSelector } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';
import './SearchView.scss';

const SearchView: React.FC = () => {
  const navigate = useNavigate();
  const { searchModels, isSearchLoading, isSearchLoaded } = useAppSelector(({ searchState }) => searchState);

  const [activeFilter, setActiveFilter] = useState('all');

  let filteredSearchModels = searchModels;
  if (activeFilter !== 'all') {
    filteredSearchModels = searchModels.filter((model) => model.categoryLabel === activeFilter);
  }

  let pageTitle = 'Search Results';
  if (!isSearchLoading && isSearchLoaded && !searchModels.length) {
    pageTitle = 'No Results';
  }

  let classNameContent = 'bt-page-view-search__content';
  if (!isSearchLoading && searchModels.length > 0) {
    classNameContent += ' bt-page-view-search__content--show';
  }

  let classNameNoResults = 'bt-page-view-search__no-results';
  if (!isSearchLoading && isSearchLoaded && !searchModels.length) {
    classNameNoResults += ' bt-page-view-search__no-results--show';
  }

  return (
    <div className="bt-page-view-search">
      <PageContentHeader title={pageTitle} />
      {!isSearchLoading && isSearchLoaded && !searchModels.length && (
        <div className={classNameNoResults}>
          <br />
          <CopyTextM text="Search tips:" />
          <br /> <br />
          <CopyTextM text="• Please doublecheck your spelling for typos." />
          <br /> <br />
          <CopyTextM text="• Try the filter option within the main pages:" /> <Link path="/events" label="Events" />
          {', '}
          <Link path="/fellows" label="Fellows" />
          {', '}
          <Link path="/downloads" label="Downloads" />
        </div>
      )}
      <div className={classNameContent}>
        <div className="bt-page-view-search__filter">
          <SearchFilter
            searchModels={searchModels}
            activeFilter={activeFilter}
            handleChangeActiveFilter={(newFilter) => setActiveFilter(newFilter)}
          />
        </div>
        <div className="bt-page-view-search__results">
          <div className="bt-page-view-search__grid">
            {filteredSearchModels.map((result, i) => {
              let buttonPath = result.url;
              if (result.category === 'events') {
                buttonPath = '/search/event/' + result.objectId;
              } else if (result.category === 'fellows') {
                buttonPath = '/search/fellow/' + result.objectId;
              }

              return (
                <div key={i} className="bt-page-view-search__grid-item">
                  <SearchTeaser
                    category={result.category}
                    categoryLabel={result.categoryLabel}
                    breadcrumb={result.breadcrumb}
                    title={result.title}
                    text={result.text}
                    tags={result.tags}
                    imageSrc={result.imageSrc}
                    buttonPath={buttonPath}
                    onClickButton={(path) => navigate(path)}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchView;
