/* eslint-disable */
import React from 'react';

const IconUser20 = (
    <svg width="20" height="20" xmlns="http://www.w3.org/2000/svg">
        <g fill="currentColor" fillRule="evenodd">
            <path d="M5.616 4.912c0 1.17.46 2.52 1.23 3.612.873 1.237 1.975 1.919 3.104 1.919s2.232-.682 3.104-1.92c.77-1.091 1.23-2.442 1.23-3.611C14.284 2.534 12.34.6 9.95.6 7.56.6 5.616 2.534 5.616 4.912zM9.95 1.303c2 0 3.628 1.62 3.628 3.609 0 1.015-.423 2.244-1.102 3.208-.726 1.03-1.647 1.62-2.526 1.62-.879 0-1.8-.59-2.525-1.62-.68-.964-1.102-2.193-1.102-3.208 0-1.99 1.627-3.609 3.627-3.609z" />
            <path d="M16.301 12.371a7.556 7.556 0 0 0-3.109-2.722.355.355 0 0 0-.401.069c-.852.844-1.834 1.29-2.841 1.29-1.007 0-1.99-.446-2.84-1.29a.355.355 0 0 0-.402-.069A7.556 7.556 0 0 0 3.6 12.371 7.457 7.457 0 0 0 2.4 16.434v.007c0 .099.042.193.116.26a11.077 11.077 0 0 0 7.434 2.853c2.755 0 5.395-1.013 7.434-2.853a.351.351 0 0 0 .116-.26v-.006a7.46 7.46 0 0 0-1.199-4.064zm-6.351 6.48c-2.526 0-4.95-.91-6.842-2.566a6.752 6.752 0 0 1 1.085-3.533 6.849 6.849 0 0 1 2.602-2.36c.945.865 2.03 1.32 3.155 1.32 1.124 0 2.21-.455 3.155-1.32a6.847 6.847 0 0 1 2.602 2.36 6.756 6.756 0 0 1 1.085 3.533A10.37 10.37 0 0 1 9.95 18.85z" />
        </g>
    </svg>
);

export default IconUser20;
