/* eslint-disable */
import React from 'react';

const IconMetaTwitter20 = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
    >
        <g fill="currentColor" fillRule="evenodd">
            <path d="M17.94,5.93A8.18,8.18,0,0,0,20,3.8h0a9,9,0,0,1-2.36.65,4.07,4.07,0,0,0,1.8-2.27,8.1,8.1,0,0,1-2.6,1A4.1,4.1,0,0,0,9.75,6a4.73,4.73,0,0,0,.09.93A11.64,11.64,0,0,1,1.39,2.62,4.19,4.19,0,0,0,.83,4.69,4.11,4.11,0,0,0,2.65,8.1,4,4,0,0,1,.8,7.6v0a4.12,4.12,0,0,0,3.29,4A4.2,4.2,0,0,1,3,11.81a4,4,0,0,1-.78-.07,4.15,4.15,0,0,0,3.84,2.85A8.27,8.27,0,0,1,1,16.34a6.29,6.29,0,0,1-1-.06,11.48,11.48,0,0,0,6.29,1.84A11.58,11.58,0,0,0,17.94,5.93Zm0,0" />
        </g>
    </svg>
);

export default IconMetaTwitter20;
