/* eslint-disable */
import React from 'react';

const IconTwitter20 = (
    <svg width='19' height='16' xmlns='http://www.w3.org/2000/svg'>
        <path
            d='M18.891 1.801c-.097-.1-.258-.133-.387-.1l-.645.234c.387-.467.645-.967.806-1.434.065-.166.033-.3-.064-.4a.335.335 0 0 0-.387-.066c-.903.4-1.677.733-2.322.933 0 .033-.032 0-.065 0C15.311.668 14.054 0 13.151 0c-2.29.034-4.128 2.034-4.128 4.467V4.6C5.669 3.934 3.83 2.934 1.83.768L1.51.435l-.194.4C.22 3 .993 4.968 2.25 6.3c-.58-.1-.967-.3-1.322-.6-.129-.1-.29-.133-.451-.033-.13.066-.194.266-.161.433.451 1.566 1.58 2.833 2.805 3.6-.58 0-1.064-.067-1.548-.4a.442.442 0 0 0-.451.033c-.13.1-.194.266-.13.433.581 1.7 1.71 2.6 3.516 2.833-.935.567-2.16 1.034-4.063 1.067a.46.46 0 0 0-.42.3c-.064.2 0 .366.13.5C1.314 15.433 3.894 16 7.087 16c5.676 0 10.32-5.233 10.32-11.666v-.1c.71-.366 1.29-1.066 1.58-2.033.032-.133 0-.3-.097-.4zm-1.935 1.9l-.193.067v.566c0 6.066-4.354 11-9.675 11-2.935 0-4.902-.5-5.966-1.034 2.225-.167 3.515-.9 4.515-1.7l.806-.6h-.968c-1.838 0-2.967-.533-3.611-1.8.58.2 1.193.2 1.87.167.258-.033.516-.033.774-.033l.097-.667c-1.226-.366-2.677-1.5-3.387-3.033.645.3 1.355.367 2 .367h1l-.807-.6C2.734 5.9.735 4.134 1.702 1.6c2.064 2.1 4.031 3.067 7.579 3.733l.387.067v-.933C9.668 2.4 11.216.7 13.15.668c.742-.033 1.967.667 2.322.867.193.1.387.133.58.066.484-.166 1.064-.4 1.71-.666-.29.5-.678.966-1.194 1.4-.129.1-.161.3-.097.466.065.167.258.233.42.167l1.257-.433c-.225.433-.58.933-1.193 1.166z'
            fill='currentColor'
            fillRule='nonzero'
        />
    </svg>
);

export default IconTwitter20;
