import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ThunkAPIConfig } from '../store';
import axios from 'axios';
import { apiUrl } from '../api';
import { handleErrorResponse } from '../../redux-actions/actionsApp';

export interface FellowsState {
  fellowsData: FellowData[] | undefined;
  fellowsDataStatus: RequestStatus;
  fellowData: FellowData | undefined;
  fellowDataStatus: RequestStatus;
}

const initialState: FellowsState = {
  fellowsData: undefined,
  fellowsDataStatus: 'idle',
  fellowData: undefined,
  fellowDataStatus: 'idle',
};

export const fetchFellowsData = createAsyncThunk<FellowData[] | undefined, undefined, ThunkAPIConfig>(
  'fetchFellowsData',
  async (_, store) => {
    try {
      const response = await axios.get(`${apiUrl}/fellows`, {
        headers: {
          Authorization: `Bearer ${store.getState().authState.userToken}`,
        },
      });
      return response.data?.data;
    } catch (error: any) {
      store.dispatch(handleErrorResponse(error.response));
      throw error;
    }
  }
);

export const fetchFellowData = createAsyncThunk<FellowData | undefined, { id: number | string }, ThunkAPIConfig>(
  'fetchFellowData',
  async ({ id }, store) => {
    try {
      const response = await axios.get(`${apiUrl}/fellows/${id}`, {
        headers: {
          Authorization: `Bearer ${store.getState().authState.userToken}`,
        },
      });
      return response.data?.data;
    } catch (error: any) {
      store.dispatch(handleErrorResponse(error.response));
      throw error;
    }
  }
);

export const fellowsSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    removeFellowData(state) {
      state.fellowData = undefined;
      state.fellowDataStatus = 'idle';
    },
  },
  extraReducers: (builder) => {
    builder
      // -- fetchFellowsData
      .addCase(fetchFellowsData.pending, (state) => {
        state.fellowsData = undefined;
        state.fellowsDataStatus = 'loading';
      })
      .addCase(fetchFellowsData.fulfilled, (state, action) => {
        state.fellowsData = action.payload;
        state.fellowsDataStatus = 'loaded';
      })
      .addCase(fetchFellowsData.rejected, (state) => {
        state.fellowsData = undefined;
        state.fellowsDataStatus = 'failed';
      })
      // -- fetchFellowData
      .addCase(fetchFellowData.pending, (state) => {
        state.fellowData = undefined;
        state.fellowDataStatus = 'loading';
      })
      .addCase(fetchFellowData.fulfilled, (state, action) => {
        state.fellowData = action.payload;
        state.fellowDataStatus = 'loaded';
      })
      .addCase(fetchFellowData.rejected, (state) => {
        state.fellowData = undefined;
        state.fellowDataStatus = 'failed';
      });
  },
});

export const { removeFellowData } = fellowsSlice.actions;

export default fellowsSlice.reducer;
