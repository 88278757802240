import React, { useState } from 'react';
import HeadlineOne from '../elements/HeadlineOne';
import CopyTextM from '../elements/CopyTextM';
import ContactElement from '../../molecules/ContactElement/ContactElement';
import UserUtil from '../../utils/UserUtil';
import ToggleBox from '../elements/ToggleBox';
import IconInfo20 from '../../assets/icons/IconInfo20';
import ResetPasswordForm from './ResetPasswordForm';
import imgSrc from '../../assets/images/Mitarbeiterportrait3.jpg';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './page-view-password-reset.scss';

const PageViewPasswordReset: React.FC = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const key = searchParams.get('key');

  const [password, setPassword] = useState('');
  const [passwordRepeat, setPasswordRepeat] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);
  const [resetPasswordFailed, setResetPasswordFailed] = useState(false);

  const handleChangePassword = (value) => {
    if (resetPasswordFailed) {
      setResetPasswordFailed(false);
      setErrorMessage('');
    }
    setPassword(value);
  };

  const handleChangePasswordRepeat = (value) => {
    if (resetPasswordFailed) {
      setResetPasswordFailed(false);
      setErrorMessage('');
    }
    setPasswordRepeat(value);
  };

  const handleSubmit = async () => {
    try {
      await UserUtil.resetPassword(key, password, passwordRepeat);
      setResetPasswordSuccess(true);
      setPassword('');
      setPasswordRepeat('');

      setTimeout(function () {
        navigate('/');
      }, 4000);
    } catch (error: any) {
      let _errorMessage: any = '';
      if (typeof error.response.data.error === 'string') {
        _errorMessage = error.response.data.error;
      } else {
        _errorMessage = Object.values(error.response.data.error)[0];
      }

      setResetPasswordFailed(true);
      setErrorMessage(_errorMessage);
    }
  };

  return (
    <div className="page-view-password-reset">
      <HeadlineOne text="Reset Your Password" />
      <div className="page-view-password-reset__text">
        <CopyTextM text="Create New Password: Passwords must be at least eight characters long and are case-sensitive." />
      </div>

      <ToggleBox show={resetPasswordSuccess}>
        <div className="page-view-password-reset__confirmation">
          <span>{IconInfo20}</span>
          <CopyTextM text="Your password has been updated successfully!" />
        </div>
      </ToggleBox>

      <ToggleBox show={resetPasswordFailed}>
        <div className="page-view-password-reset__error">
          <span>{IconInfo20}</span>
          <CopyTextM text={errorMessage} />
        </div>
      </ToggleBox>

      {!resetPasswordSuccess && (
        <div className="page-view-password-reset__form">
          <ResetPasswordForm
            onChangePassword={(value) => handleChangePassword(value)}
            onChangePasswordRepeat={(value) => handleChangePasswordRepeat(value)}
            onSubmit={() => handleSubmit()}
          />
        </div>
      )}

      <CopyTextM text={'In case you are experiencing any problems, please contact:'} />
      <div className="page-view-password-reset__contact">
        <ContactElement
          name="Ines WALDMANN"
          location="BIOTRONIK HQ, Berlin"
          tel="+49 151 6890 1421"
          email="inez.waldmann@biotronik.com"
          imgSrc={imgSrc}
        />
      </div>
    </div>
  );
};

export default PageViewPasswordReset;
