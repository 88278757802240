/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import './label-xxl.scss';

class LabelXXL extends Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { text } = this.props;

        return <span className={`bt-label-xxl`}>{text}</span>;
    }
}

LabelXXL.propTypes = {
    text: PropTypes.string,
};

LabelXXL.defaultProps = {
    text: '',
};

export default LabelXXL;
