/* eslint-disable */
import React from 'react';

const IconLogout30 = (
    <svg width="30" height="30" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M30,15.2l-4.6,4.7c-0.3,0.2-0.8,0.1-1-0.2c-0.2-0.3-0.2-0.6,0-0.8l3-3H9.3c-0.4,0-0.8-0.3-0.8-0.8
	s0.3-0.8,0.8-0.8h18.1l-3-3c-0.3-0.3-0.3-0.8,0-1.1c0.1-0.1,0.3-0.2,0.5-0.2c0.2,0,0.4,0.1,0.5,0.2L30,15.2z M11,4
	C4.9,3.9-0.1,8.8-0.1,14.9S4.7,25.9,10.8,26c3.7,0,7.2-1.8,9.2-4.8c0.2-0.3,0.1-0.8-0.2-1C19.6,20,19.4,20,19.2,20
	c-0.2,0-0.4,0.2-0.5,0.3c-3,4.3-8.9,5.4-13.2,2.4S0.2,13.9,3.2,9.6C4.9,7,7.9,5.5,11,5.5c3.2,0,6.1,1.6,7.9,4.2
    C19,9.9,19.1,10,19.3,10c0.2,0,0.4,0,0.5-0.1c0.3-0.2,0.4-0.7,0.2-1C18.1,5.8,14.7,4,11,4z"
            fill="currentColor"
            fillRule="evenodd"
        />
    </svg>
);

export default IconLogout30;
