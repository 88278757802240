/* eslint-disable */
import React from 'react';

const IconMetaLinkedIn14 = (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="14"
        height="14"
        viewBox="0 0 14 14"
    >
        <g fill="currentColor" fillRule="evenodd">
            <path d="M8.09,5.17a3,3,0,0,1,2.31-.89c2.2,0,3.6,1.52,3.6,4.16v4.94a.29.29,0,0,1-.29.28H10.84a.28.28,0,0,1-.28-.28V8.77c0-1.1-.39-1.66-1.18-1.66A1.26,1.26,0,0,0,8.17,8a1.55,1.55,0,0,0-.08.58v4.81a.28.28,0,0,1-.28.28H4.93a.29.29,0,0,1-.29-.29v-2c0-.79,0-1.56,0-2.28,0-2.37,0-3.92,0-4.25a.29.29,0,0,1,.29-.3H7.81a.28.28,0,0,1,.28.28ZM1.9,3.31a1.21,1.21,0,1,0,0-2.4,1.21,1.21,0,1,0,0,2.4Zm0,.58h0a1.78,1.78,0,1,1,0-3.55,1.78,1.78,0,1,1,0,3.55Zm1.15,9.2v-8H.74v8Zm-2.59.57a.29.29,0,0,1-.29-.28V4.77a.29.29,0,0,1,.29-.28H3.34a.28.28,0,0,1,.28.28v8.61a.28.28,0,0,1-.28.28Zm7.06-8.6H5.22c0,.63,0,2,0,4,0,.73,0,1.5,0,2.29v1.76h2.3V8.57a2,2,0,0,1,.12-.8A1.83,1.83,0,0,1,9.38,6.54c1.16,0,1.75.85,1.75,2.23v4.32h2.3V8.44c0-2.34-1.16-3.58-3-3.58A2.52,2.52,0,0,0,8.08,6.09a.28.28,0,0,1-.27.22h0a.28.28,0,0,1-.27-.37V5.06Z" />
        </g>
    </svg>
);

export default IconMetaLinkedIn14;
