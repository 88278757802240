/* eslint-disable */
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useResponsive } from '../../hooks/responsive';
import IconExpandImage from '../../assets/icons/IconExpandImage';
import ImageLayer from '../ImageLayer/ImageLayer';

const ImageContainer = styled.div({
  position: 'relative',
  width: '180px',
  height: '120px',

  img: {
    height: '100%',
    width: '100%',
    objectFit: 'cover',
  },
});

const ImageOverlay = styled.div({
  position: 'absolute',
  background: 'linear-gradient(0deg, rgba(110, 110, 110, 0.7), rgba(110, 110, 110, 0.7))',
  height: '99%',
  width: '100%',
  left: 0,
  top: 0,
  bottom: 0,
  right: 0,
  opacity: 0,
  '-webkit-transition': 'all 0.4s ease-in-out 0s',
  '-moz-transition': 'all 0.4s ease-in-out 0s',
  transition: ' 0.4s ease-in-out 0s',
  '&:hover': {
    opacity: 1,
  },
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  cursor: 'pointer',
});

const ImageSection = styled.div(({ isMobile }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: '50px',
    paddingBottom: '30px',
  };
});

const ImageGrid = styled.div(({ isMobile }) => {
  return {
    display: 'grid',
    gridTemplateColumns: '180px 180px 180px',
    gap: '30px',
    marginBottom: '30px',
  };
});
const ImageSectionDownloads = (props) => {
  const [showImageCarousel, setShowImageCarousel] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);
  const { isMobile } = useResponsive();
  const { imagesData, eventId } = props;

  const handleOnClickImage = (index) => {
    setSlideIndex(index);
    setShowImageCarousel(true);
  };
  const closeOverlay = () => {
    setShowImageCarousel(false);
  };

  return (
    <>
      <ImageGrid isMobile={isMobile}>
        {imagesData.map((item, i) => {
          return (
            <ImageContainer key={String(i)} onClick={() => handleOnClickImage(i)}>
              <img src={item.url} alt="" />
              <ImageOverlay>
                <div className="bt-downloads-group__header-icon">{IconExpandImage}</div>
              </ImageOverlay>
            </ImageContainer>
          );
        })}
      </ImageGrid>
      {showImageCarousel && <ImageLayer slideIndex={slideIndex} data={imagesData} closeOverlay={closeOverlay} />}
    </>
  );
};

ImageSectionDownloads.propTypes = {
  downloadModels: PropTypes.array,
};

ImageSectionDownloads.defaultProps = {
  downloadModels: [],
};

export default ImageSectionDownloads;
