import React, { useState } from 'react';
import HeadlineOne from '../elements/HeadlineOne';
import CopyTextM from '../elements/CopyTextM';
import Button from '../elements/Button';
import RegistrationForm from '../RegistrationForm';
import IconArrowLeft12 from '../../assets/icons/IconArrowLeft12';
import UserUtil from '../../utils/UserUtil';
import { useAppSelector } from '../../app/hooks';
import { useNavigate } from 'react-router-dom';
import './page-view-registration.scss';

const initialState = {
  title: '',
  firstName: '',
  lastName: '',
  email: '',
  employer: '',
  country: '',
  season: '',
  password: '',
  passwordRepeat: '',
  privacyPolicy: '',
  terms: '',
  registerFailed: false,
  registerFailedByServer: false,
  errorMessage: '',
};

const PageViewRegister: React.FC = () => {
  const navigate = useNavigate();
  const { baseDataCountry, baseDataSeason } = useAppSelector(({ appState }) => appState);

  const [state, setState] = useState(initialState);

  const handleClickToLogin = () => {
    navigate('/login');
  };

  const handleChange = (value, parameter) => {
    if (state.registerFailedByServer || state.registerFailed) {
      setState((prevState) => ({
        ...prevState,
        registerFailedByServer: false,
        registerFailed: false,
        errorMessage: '',
      }));
    }
    setState((prevState) => ({
      ...prevState,
      [parameter]: value,
    }));
  };

  const validateForm = () => {
    const { title, firstName, lastName, email, employer, country, password, passwordRepeat, privacyPolicy, terms } =
      state;
    if (
      !title ||
      !firstName ||
      !lastName ||
      !email ||
      !employer ||
      !password ||
      !passwordRepeat ||
      !country ||
      !privacyPolicy ||
      !terms
    ) {
      setState((prevState) => ({ ...prevState, registerFailed: true }));
    }
  };

  const handleSubmit = async () => {
    const {
      title,
      firstName,
      lastName,
      email,
      employer,
      country,
      season,
      password,
      passwordRepeat,
      privacyPolicy,
      terms,
    } = state;

    const data = {
      title: title,
      firstName: firstName,
      lastName: lastName,
      email: email,
      employer: employer,
      country: country,
      season: season,
      password: password,
      passwordRepeat: passwordRepeat,
      privacyPolicy: privacyPolicy,
      terms: terms,
    };

    try {
      validateForm();
      await UserUtil.register(data);
      navigate('/register/feedback');
    } catch (error: any) {
      let errorMessage: any = '';
      if (typeof error.response.data.error === 'string') {
        errorMessage = error.response.data.error;
      } else {
        errorMessage = Object.values(error.response.data.error)[0];
      }
      setState((prevState) => ({
        ...prevState,
        registerFailedByServer: true,
        errorMessage: errorMessage,
      }));
    }
  };

  return (
    <div className="bt-page-view-registration">
      <HeadlineOne text="REGISTER" />
      <div className="bt-page-view-registration__text">
        <CopyTextM text="Welcome to the new Biotronik Electrophysiology Education Program website, with update functionalities to keep you in touch with your BeEP network, plan events, and access course information. Please take a few minutes to fill in the information below, to allow other fellows and alumni to find you more easily, and to allow us to register you in a timely manner. Once submitted, we aim to get back to you with account approval within 48 hours." />
      </div>
      <div className="bt-page-view-registration__back-button">
        <Button path="#" label="Back to sign in" iconLeft={IconArrowLeft12} onClick={() => handleClickToLogin()} />
      </div>
      <div className="bt-page-view-registration__form">
        <RegistrationForm
          availableSeasons={baseDataSeason}
          availableCountries={baseDataCountry}
          handleChange={(value, parameter) => handleChange(value, parameter)}
          handleSubmit={() => handleSubmit()}
          registerFailed={state.registerFailed}
          registerFailedByServer={state.registerFailedByServer}
          errorMessage={state.errorMessage}
          errorTitle={state.registerFailed && !state.title}
          errorFirstName={state.registerFailed && !state.firstName}
          errorLastName={state.registerFailed && !state.lastName}
          errorEmail={state.registerFailed && !state.email}
          errorEmployer={state.registerFailed && !state.employer}
          errorCountry={state.registerFailed && !state.country}
          errorPassword={state.registerFailed && !state.password}
          errorPasswordRepeat={state.registerFailed && !state.passwordRepeat}
          errorPrivacyPolicy={state.registerFailed && !state.privacyPolicy}
          errorTerms={state.registerFailed && !state.terms}
        />
      </div>
      <div className="bt-registration-form__submit-button">
        <button type="submit">+</button>
      </div>
    </div>
  );
};

export default PageViewRegister;
