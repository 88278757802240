/* eslint-disable */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CopyTextM from '../elements/CopyTextM';
import Button from '../elements/Button';
import './profile-delete.scss';

class ProfileDelete extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showConfirmation: false
        };
    }

    handleOnClickButtonDelete() {
        this.setState({
            showConfirmation: true
        });
    }

    handleOnClickCancel() {
        this.setState({
            showConfirmation: false
        });
    }

    handleOnClickConfirm() {
        const { onDelete } = this.props;
        onDelete();
    }

    render() {
        const { showConfirmation } = this.state;

        return (
            <div className="bt-profile-delete">
                <CopyTextM
                    text={`Dear Alumni, we are sorry to see you go as a Member. Please be advised that deleting your account is permanent. If you delete your BeEP account, you will lose access to our saved content and information updates. Your personal profile and all messages will also be deleted.`}
                />
                <div className="bt-profile-delete__button-delete">
                    <Button
                        layout="secondary"
                        label="Delete your account"
                        onClick={() => this.handleOnClickButtonDelete()}
                    />
                </div>
                {showConfirmation && (
                    <div className="bt-profile-delete__confirmation">
                        <CopyTextM text="Are you sure you want to delete your account?" />
                        <div className="bt-profile-delete__confirmation-buttons">
                            <div className="bt-profile-delete__confirmation-button">
                                <Button layout="secondary" label="Cancel" onClick={() => this.handleOnClickCancel()} />
                            </div>
                            <div className="bt-profile-delete__confirmation-button">
                                <Button
                                    layout="primary"
                                    label="Yes, delete my account"
                                    onClick={() => this.handleOnClickConfirm()}
                                />
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }
}

ProfileDelete.propTypes = {
    onDelete: PropTypes.func,
    profileFirstName: PropTypes.string,
    profileLastName: PropTypes.string,
    profileTitle: PropTypes.string
};

ProfileDelete.defaultProps = {
    onDelete: () => false,
    profileFirstName: '',
    profileLastName: '',
    profileTitle: ''
};

export default ProfileDelete;
